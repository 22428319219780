import React from "react";
import { Icon, Typography, Button } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function OrderFromTableHeader(props) {
  const { languageStrings } = useLanguageHelper();
  const classes = useStyles({});

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <Icon className="text-32 mr-0 sm:mr-12">store</Icon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className="hidden sm:flex" variant="h6">
            {languageStrings["SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST"]}
          </Typography>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default OrderFromTableHeader;
