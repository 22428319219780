import {
  LOYALTY,
  REWARD_TRANSACTIONS,
  getUserId,
} from "./../../api/Endpoint";
import { Reward } from "app/models/Loyalty";
import { LoyaltyActionTypes } from "./../redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";

import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";

export function setTransactionSearchText(event) {
  return {
    type: LoyaltyActionTypes.SET_TRANSACTION_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getLoyaltyList = () => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.TRANSACTION_LIST_START,
    });

    axios
      .get(REWARD_TRANSACTIONS)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getLoyaltyListSuccess(dispatch, res.data);
        } else {
          getLoyaltyListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getLoyaltyListFail(dispatch, "There was an error connection");
      });
  };
};

const getLoyaltyListFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.TRANSACTION_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getLoyaltyListSuccess = (dispatch, data) => {
  dispatch({
    type: LoyaltyActionTypes.TRANSACTION_LIST_SUCCESS,
    payload: data,
  });
};

export const getRewardList = () => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.LOYALTY_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(LOYALTY, {
        params,
      })
      .then((res) => {
        getRewardListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getRewardListFail(dispatch, "There was an error connection");
      });
  };
};

const getRewardListFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getRewardListSuccess = (dispatch, data) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_LIST_SUCCESS,
    payload: data,
  });
};

export const getLoyaltyDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_START,
    });

    const url = LOYALTY + "/" + id;

    axios
      .get(url)
      .then((res) => {
        getLoyaltyDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getLoyaltyDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getLoyaltyDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/rewardList`,
  });
};

const getLoyaltyDetailSuccess = (dispatch, data) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createReward = (reward: Reward) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.LOYALTY_REWARD_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in reward) {
      if (key == "imageFile") {
        formData.append(key, reward[key], reward[key].name);
      } else if (key != "id") {
        formData.append(key, reward[key]);
      }
    }

    axios
      .post(LOYALTY, formData)
      .then((res) => {
        const data = res.data;
        createRewardSuccess(dispatch, data);
      })
      .catch(() => {
        createRewardFail(dispatch, "There was an error connection");
      });
  };
};

const createRewardFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createRewardSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message.description);
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/rewardList/",
  });
};

export const editReward = (reward: Reward) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.LOYALTY_REWARD_EDIT_START,
    });

    let formData = new FormData();

    for (let key in reward) {
      if (key == "_id") {
        formData.append("rewardId", reward[key]);
      } else if (key == "imageFile") {
        formData.append(key, reward[key], reward[key].name);
      } else {
        formData.append(key, reward[key]);
      }
    }

    axios
      .put(LOYALTY, formData)
      .then((res) => {
        editRewardSuccess(dispatch, res.data);
      })
      .catch(() => {
        editRewardFail(dispatch, "There was an error connection");
      });
  };
};

const editRewardFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editRewardSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message.description);
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/rewardList/",
  });
};

export const deleteReward = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyActionTypes.LOYALTY_REWARD_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(LOYALTY, { data: { rewardId: id } })
        .then((res) => {
          deleteRewardSuccess(dispatch, res.data);
        })
        .catch(() => {
          deleteRewardFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteRewardFail = (dispatch, errorMessage) => {
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteRewardSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getRewardList());
  dispatch({
    type: LoyaltyActionTypes.LOYALTY_REWARD_DELETE_FAIL,
    payload: data,
  });
};
