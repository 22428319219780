import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography, Select, OutlinedInput } from "@material-ui/core";
import clsx from "clsx";
import { Bar, Line } from "react-chartjs-2";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { connect } from "react-redux";
import { getOrderRevenue } from "app/store/orders/OrdersActions";

interface OwnProps {
  orderType: string;
  selectedYear: number;
}
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const styles = (theme) => ({
  layoutRoot: {},
});

const OrdersAnalyticsCard = ({
  orderRevenue,
  orderRevenueWithTypes,
  getOrderRevenue,
  orderType,
  selectedYear,
}: Props) => {
  const { languageStrings } = useLanguageHelper();

  const [orderRevenueFiltered, setOrderRevenueFiltered] = useState(null);

  useEffect(() => {
    getOrderRevenue();
  }, []);

  useEffect(() => {
    let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let i = 1;

    if (orderRevenue && orderRevenue.length > 0 && orderType == "0") {
      for (i = 1; i <= 12; i++) {
        orderRevenue.map((item) => {
          if (
            orderType == "0" &&
            item._id.month === i &&
            item._id.year == selectedYear
          ) {
            data[i - 1] = item.total.toFixed(2);
          }
        });
      }
    } else if (orderRevenueWithTypes && orderRevenueWithTypes.length > 0) {
      for (i = 1; i <= 12; i++) {
        orderRevenueWithTypes.map((item) => {
          if (
            item._id.month === i &&
            item._id.type == orderType &&
            item._id.year == selectedYear
          ) {
            data[i - 1] = item.total.toFixed(2);
          }
        });
      }
    }

    setOrderRevenueFiltered({
      labels: [
        languageStrings["GENERAL.JANUARY"],
        languageStrings["GENERAL.FEBRUARY"],
        languageStrings["GENERAL.MARCH"],
        languageStrings["GENERAL.APRIL"],
        languageStrings["GENERAL.MAY"],
        languageStrings["GENERAL.JUNE"],
        languageStrings["GENERAL.JULY"],
        languageStrings["GENERAL.AUGUST"],
        languageStrings["GENERAL.SEPTEMBER"],
        languageStrings["GENERAL.OCTOBER"],
        languageStrings["GENERAL.NOVEMBER"],
        languageStrings["GENERAL.DECEMBER"],
      ],
      datasets: [
        {
          label: "Revenue ",
          backgroundColor: "rgba(0,153,0,0.2)",
          borderColor: "rgba(0,153,0,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(0,153,0,0.4)",
          hoverBorderColor: "rgba(0,153,0,1)",
          data,
        },
      ],
    });
  }, [orderRevenue, languageStrings, selectedYear, orderType]);

  return (
    <Card className="w-full rounded-8 shadow-none border-1 p-16">
      <div className={"flex w-full justify-between items-center"}>
        <Typography className="h2 pb-16" color="primary">
          {languageStrings["HOME_PAGE.ORDERS_AMOUNT"]}
        </Typography>
      </div>
      <Line
        data={orderRevenueFiltered}
        height={120}
        legend={{ display: false }}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    orderRevenue: state.banzzu.order.orderRevenue.orderRevenue,
    orderRevenueWithTypes:
      state.banzzu.order.orderRevenue.orderRevenueWithTypes,
  };
};
const mapDispatchToProps = {
  getOrderRevenue,
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(OrdersAnalyticsCard)
);
