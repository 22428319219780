import React, { useState, useEffect } from 'react'
import { Card, Button, Typography, Switch, Select, OutlinedInput, TextField } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "@fuse/hooks";

import { getVenueList } from "app/store/venues/VenuesActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

export default function AddressCard({ onSubmit, onBack }) {
    const { languageStrings } = useLanguageHelper();
    const [sendToRestaurant, setSendToRestaurant] = useState(false)
    const [selectedRestaurant, setSelectedRestaurant] = useState(null)

    const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList)

    const dispatch = useDispatch();

    const { form, handleChange, setForm } = useForm();

    const handleSwitchChange = () => {
        setSendToRestaurant(prev => !prev)
        setSelectedRestaurant(venueList[0])
    }

    const handleDropDownChange = (e) => {
        setSelectedRestaurant(venueList.find(venue => venue._id == e.target.value))
        handleChange(e)
    }

    useEffect(() => {
        dispatch(getVenueList());
        setForm({ address: "", restaurant: "" })
    }, [dispatch]);

    return (
        form ? (
            <Card
                className="mx-24 mt-12 p-24 flex flex-col items-center justify-around shadow-lg md:w-1/3 w-full"
                style={{ borderRadius: "2rem" }}
            >
                <div>
                    <Typography
                        variant="h6"
                        className="text-center"
                        style={{ color: "#f5aa00" }}
                    >
                        {languageStrings["MERCHANDISE.ITEM.ADDRESS_TITLE"]}
                    </Typography>

                    <div className="flex flex-row items-center">
                        <Switch
                            checked={sendToRestaurant}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <Typography
                            variant="subtitle1"
                            className="font-bold text-center"
                        >
                            {languageStrings["MERCHANDISE.ITEM.SEND_TO_RESTAURANT"]}
                        </Typography>
                    </div>
                    {!sendToRestaurant ? (
                        <TextField
                            className="mt-8 mb-16"
                            required
                            label={languageStrings["GENERAL.ADDRESS"]}
                            id="address"
                            name="address"
                            value={form.address}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                    ) : (
                            <>
                                <Select
                                    className="mt-8 mb-16"
                                    native
                                    fullWidth
                                    value={form.restaurant}
                                    onChange={handleDropDownChange}
                                    input={
                                        <OutlinedInput
                                            name="restaurant"
                                            labelWidth={100}
                                            id="outlined-venue-native-simple"
                                        />
                                    }
                                >
                                    {venueList.map(venue => <option value={venue._id}>{venue.title}</option>)}
                                </Select>

                                {selectedRestaurant && selectedRestaurant.contactInfo && selectedRestaurant.contactInfo.address && (
                                    <Typography variant="subtitle1" className="ml-6">
                                        {selectedRestaurant.contactInfo.address}
                                    </Typography>
                                )}
                            </>
                        )}
                </div>
                <div className="flex flex-row justify-around mt-24 w-full">
                    <Button
                        variant="contained"
                        onClick={onBack}
                    >
                        {languageStrings["GENERAL.BACK"]}
                    </Button>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#f5aa00", color: "white" }}
                        onClick={() => onSubmit({ ...form, address: sendToRestaurant ? selectedRestaurant.contactInfo.address : form.address })}
                    >
                        {languageStrings["GENERAL.CONTINUE"]}
                    </Button>
                </div>
            </Card>
        ) : null
    )
}
