import { CLIENT, STAFF } from "app/api/Endpoint";
import { StaffActionTypes } from "app/store/redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import { errorAlert, successAlert } from "app/helpers/alerts";
import history from "@history";

export function setStaffSearchText(event) {
  return {
    type: StaffActionTypes.SET_STAFF_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getStaffList = () => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.STAFF_LIST_START,
    });

    axios
      .get(CLIENT)
      .then((res) => {
        getStaffListSuccess(dispatch, res.data);
      })
      .catch(() => {
        getStaffListFail(dispatch, "There was an error connection");
      });
  };
};

const getStaffListFail = (dispatch, errorMessage) => {
  dispatch({
    type: StaffActionTypes.STAFF_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getStaffListSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.STAFF_LIST_SUCCESS,
    payload: data,
  });
};

export const createStaff = (data) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.CREATE_STAFF_START,
    });

    axios
      .post(STAFF, data)
      .then((res) => {
        createStaffSuccess(dispatch, res.data);
      })
      .catch(() => {
        createStaffFail(dispatch, "There was an error connection");
      });
  };
};

const createStaffFail = (dispatch, errorMessage) => {
  dispatch({
    type: StaffActionTypes.CREATE_STAFF_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createStaffSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: StaffActionTypes.CREATE_STAFF_SUCCESS,
    payload: data,
  });
  history.push("/staff-list");
};

export const editStaff = (data) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.EDIT_STAFF_START,
    });

    axios
      .put(STAFF, data)
      .then((res) => {
        editStaffSuccess(dispatch, res.data);
      })
      .catch(() => {
        editStaffFail(dispatch, "There was an error connection");
      });
  };
};

const editStaffFail = (dispatch, errorMessage) => {
  dispatch({
    type: StaffActionTypes.EDIT_STAFF_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editStaffSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: StaffActionTypes.EDIT_STAFF_SUCCESS,
    payload: data,
  });
};

export const getStaffDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.STAFF_DETAIL_START,
    });

    axios
      .get(STAFF + "/" + id)
      .then((res) => {
        getStaffDetailSuccess(dispatch, res.data);
      })
      .catch(() => {
        getStaffDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getStaffDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: StaffActionTypes.STAFF_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getStaffDetailSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.STAFF_DETAIL_SUCCESS,
    payload: data,
  });
};

export const deleteStaff = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.STAFF_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(STAFF, { data: { staffId: id } })
        .then((res) => {
          deleteStaffSuccess(dispatch, res.data);
        })
        .catch((err) => {
          const errMsg =
            err.response.data.message === "Staff already deleted" ||
            err.response.data.message === "Empleado eliminado"
              ? err.response.data.message
              : "There was an error connection";

          deleteStaffFail(dispatch, errMsg);
        });
    });
  };
};
const deleteStaffFail = (dispatch, errorMessage) => {
  dispatch({
    type: StaffActionTypes.STAFF_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
};

const deleteStaffSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getStaffList());
  dispatch({
    type: StaffActionTypes.STAFF_DELETE_SUCCESS,
    payload: data,
  });
};
