import { GalleryActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: GalleryState = {
  galleryList: [],
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const GalleryReducer = (
  state: GalleryState = INITIAL_STATE,
  action: Action
): GalleryState => {
  switch (action.type) {
    case GalleryActionTypes.GALLERY_LIST_START: {
      return { ...state, loading: true };
    }
    case GalleryActionTypes.GALLERY_LIST_SUCCESS: {
      return {
        ...state,
        galleryList: action.payload,
        loading: false,
      };
    }
    case GalleryActionTypes.GALLERY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GalleryActionTypes.GALLERY_CREATE_START: {
      return { ...state, loading: true };
    }
    case GalleryActionTypes.GALLERY_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GalleryActionTypes.GALLERY_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default GalleryReducer;
