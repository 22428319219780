import GalleryDetail from "./gallery/GalleryDetail";

export const GalleryConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/gallery",
      component: GalleryDetail
    }
  ]
};
