import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  getNotificationList,
  setNotificationSearchText,
} from "app/store/notification/NotificationActions";
import { translate } from "app/helpers/LanguageHelper/index";

class NotificationList extends Component {
  componentDidMount() {
    this.props.getNotificationList();
  }
  setSearchText = (ev) => {
    this.props.setNotificationSearchText(ev);
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "title",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.NAME"],
          sort: true,
          type: "text",
        },
        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "time",
        },
      ],
      checkbox: false,
      clickable: false,
      locationSearch: false,
      searchBy: ["title"],
    };

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <ListHeader
              title={languageStrings["NOTIFICATION_LIST_PAGE.TITLE"]}
              setSearchText={this.setSearchText}
              searchType={"notification"}
            />
          }
          content={
            <ListTable
              headerRows={headerRows}
              data={this.props.notificationList}
              searchText={this.props.searchText}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.notification.loading,
    notificationList: state.banzzu.notification.notificationList,
    searchText: state.banzzu.notification.searchText,
  };
};
const mapDispatchToProps = {
  getNotificationList,
  setNotificationSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(NotificationList));
