import RewardList from "./RewardList";
import TransactionList from "./TransactionList";
import RewardDetail from "./RewardDetail";

export const LoyaltyConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/rewardList",
      component: RewardList
    },
    {
      path: "/transactionList",
      component: TransactionList
    },
    {
      path: "/rewardDetail/:productId",
      component: RewardDetail
    }
  ]
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
