import React, { FC } from "react";
import { Select, OutlinedInput } from "@material-ui/core";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

interface Props {
  value: string;
  onChange: (e) => void;
}

const CountryPicker: FC<Props> = ({ value, onChange }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <Select
      className="mt-8 mb-16 mx-8"
      native
      fullWidth
      value={value}
      onChange={onChange}
      input={
        <OutlinedInput
          name="country"
          labelWidth={100}
          id="outlined-venue-native-simple"
        />
      }
    >
      <option value="">
        {languageStrings["REGISTER_STEPS.SELECT_COUNTRY"]}
      </option>
      <option value="España">España</option>
      <option value="Alemania">Alemania</option>
      <option value="Argentina">Argentina</option>
      <option value="Australia">Australia</option>
      <option value="Canada">Canada</option>
      <option value="Colombia">Colombia</option>
      <option value="Estados Unidos">Estados Unidos</option>
      <option value="Francia">Francia</option>
      <option value="Italia">Italia</option>
      <option value="Mexico">Mexico</option>
      <option value="Reino Unido">Reino Unido</option>
    </Select>
  );
};

export default CountryPicker;
