import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import _ from "@lodash";
import {
  Typography,
  Card,
  IconButton,
  Icon,
  Modal,
  Backdrop,
  Fade,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import {
  deleteMenuItem,
  deleteMenuExtraCategory,
  duplicateMenuItem,
  sortExtraCategories,
} from "app/store/menu/MenuActions";
import MenuItemDetailModal from "./MenuItemDetailModal";
import { makeStyles } from "@material-ui/styles";
import ExtraCategoriesModal from "./ExtraCategoriesModal";
import ExtraCategoryItems from "../menuItemList/CategoriesItems/ExtraCategoryItems";
import ExtraCategory from "./components/ExtraCategory";
import VisibilityModal from "./components/VisibilityModal";
import { VISIBILITY } from "app/models/Visibility";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ProductItem = ({ menuItem, extraCategories, index, onlyRead }) => {
  const modalRef = useRef(document.createElement("div"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openVisibility, setOpenVisibility] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [categorySelected, setCategorySelected] = useState({ _id: "new" });
  const { languageStrings } = useLanguageHelper();

  const [anchorEl, setAnchorEl] = React.useState(null);
  let ref;

  const AddhandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //dispatch(getMenuItemExtraCategories(menuItem._id));
  }, [menuItem]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
      menuItemId: menuItem._id,
    };

    dispatch(sortExtraCategories(sortOrder, menuItem._id));
  };

  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
    setAnchorEl(null);
  };
  const executeScroll = (r) => {
    modalRef.current.scrollTo({ top: r.offsetTop, behavior: "smooth" });
  };

  const handleAddClick = (r) => {
    executeScroll(r);
  };
  return (
    <Draggable
      key={menuItem._id}
      draggableId={menuItem._id}
      index={index}
      isDragDisabled={openVisibility || onlyRead}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
              >
                <h2 id="transition-modal-title" className="pb-32">
                  {menuItem.title}
                </h2>
                <MenuItemDetailModal
                  productId={menuItem._id}
                  categoryId={menuItem.categoryId}
                  menuId={menuItem.menuId}
                  onClose={() => {
                    setOpen(false);
                  }}
                />
              </div>
            </Fade>
          </Modal>
          <Modal
            className={classes.modal}
            open={openCategories}
            onClose={() => {
              setOpenCategories(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCategories}>
              <div
                className={["overflow-x-hidden max-h-640", classes.paper].join(
                  " "
                )}
                ref={modalRef}
              >
                <h2 id="transition-modal-title" className="pb-32">
                  Opciones extras
                </h2>
                <ExtraCategoriesModal
                  menuItem={menuItem}
                  category={categorySelected}
                  onClose={() => setOpenCategories(false)}
                />
                {categorySelected._id !== "new" && (
                  <ExtraCategoryItems
                    category={categorySelected._id}
                    onAddClick={handleAddClick}
                  />
                )}
              </div>
            </Fade>
          </Modal>
          <VisibilityModal
            open={openVisibility}
            onClose={onVisibilityClose}
            menuItem={menuItem}
          />
          {/* <div className="min-w-full">
        <Card className="w-full m-8">
          <div className="flex w-full items-center"> */}
          <div className="min-w-full">
            <Card className="m-4">
              <div className="flex flex-wrap sm:flex-no-wrap w-full items-center">
                <img
                  className="object-cover h-96 w-96 ml-8 mt-8 mb-8"
                  key={menuItem._id}
                  src={
                    menuItem.image
                      ? formatImageUrl(menuItem.image)
                      : "assets/images/avatars/noimage.png"
                  }
                />
                <div className="justify-center w-full ml-8 mr-8 sm:w-full min-w-2/5 justify-center items-center">
                  {menuItem.visibility === VISIBILITY.DISABLED && (
                    <span
                      style={{ width: "4rem", fontSize: 10 }}
                      className="bg-red p-6 rounded-12 text-white text-center"
                    >
                      {languageStrings["VISIBILITY.DISABLED"]}
                    </span>
                  )}

                  {menuItem.visibility === VISIBILITY.SHOW_ONLY && (
                    <Typography
                      variant="body1"
                      style={{ maxWidth: "14rem", fontSize: 10 }}
                      className="bg-yellow p-4 rounded-12 text-black text-center whitespace-no-wrap"
                    >
                      {languageStrings["VISIBILITY.PARTIALY_DISABLED"]}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1 bold"
                    className="mt-12 text-center sm:text-left"
                    component="h3"
                  >
                    {menuItem.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="text-center sm:text-left"
                    color="textSecondary"
                  >
                    {menuItem.description}
                  </Typography>
                </div>

                <div className="flex justify-between">
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    className="ml-16 self-center mr-16"
                  >
                    {`${menuItem.price}€`}
                  </Typography>

                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={AddhandleClick}
                    hidden={onlyRead ? true : false}
                  >
                    <Icon className="pr-16 text-green">more_vert</Icon>
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          duplicateMenuItem(menuItem._id, menuItem.menuId)
                        );
                        handleClose();
                      }}
                    >
                      Duplicar
                    </MenuItem>
                    <MenuItem onClick={onVisibilityClick}>
                      {languageStrings["VISIBILITY.TITLE"]}
                    </MenuItem>
                  </Menu>
                  <IconButton
                    onClick={() => setOpen(true)}
                    hidden={onlyRead ? true : false}
                  >
                    <Icon className="pr-16 text-blue">edit</Icon>
                  </IconButton>
                  <IconButton
                    hidden={onlyRead ? true : false}
                    onClick={() =>
                      dispatch(deleteMenuItem([menuItem._id], menuItem.menuId))
                    }
                  >
                    <Icon className="pr-16 text-red">delete</Icon>
                  </IconButton>
                </div>
              </div>
              <Button
                className="mt-8 ml-16 bg-grey-lightest"
                variant="contained"
                onClick={() => {
                  setCategorySelected({ _id: "new" });
                  setOpenCategories(true);
                }}
                hidden={onlyRead ? true : false}
              >
                <Icon className="mr-8">add</Icon>
                {`Añadir opciones extra`}
              </Button>

              <div className="mt-16 mb-16">
                <DragDropContext onDragEnd={onDragEnd}>
                  <DropableExtraCategory
                    list={extraCategories}
                    menuItem={menuItem}
                    setCategorySelected={setCategorySelected}
                    setOpenCategories={setOpenCategories}
                    onlyRead={onlyRead}
                  />
                </DragDropContext>
              </div>
            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const DropableExtraCategory = ({
  list,
  menuItem,
  setCategorySelected,
  setOpenCategories,
  onlyRead,
}) => {
  const dispatch = useDispatch();

  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {list &&
            list.map((extraCategory, index) => (
              <ExtraCategory
                key={extraCategory._id}
                index={index}
                extraCategory={extraCategory}
                onlyRead={onlyRead}
                onEdit={() => {
                  setCategorySelected(extraCategory);
                  setOpenCategories(true);
                }}
                onDelete={() =>
                  dispatch(
                    deleteMenuExtraCategory(
                      extraCategory._id,
                      menuItem._id,
                      menuItem.menuId
                    )
                  )
                }
              />
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ProductItem;
