import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

interface Props {
  title: string;
  orderType: string;
  selectedYear: number;
  textColor: string;
}
const styles = (theme) => ({
  layoutRoot: {
    display: "inline",
    padding: " 0.4rem",
    backgroundColor: "#E3342F",
    color: "white",
  },
});

const TopDishesCard = ({
  title,
  textColor,
  orderType,
  selectedYear,
}: Props) => {
  const { orderAnalytics } = useSelector(({ banzzu }) => banzzu.order);
  const [dishes, setDishes] = useState([]);
  useEffect(() => {
    setDishes([]);
    if (
      orderAnalytics.topFiveDishes &&
      orderAnalytics.topFiveDishes.length > 0 &&
      orderType == "0"
    ) {
      const filterDishes = orderAnalytics.topFiveDishes.filter(
        (item) => item._id.year == selectedYear
      );
      if (filterDishes) {
        setDishes(filterDishes.slice(0, 5));
      }
    } else if (
      orderAnalytics.topFiveDishesWithTypes &&
      orderAnalytics.topFiveDishesWithTypes.length > 0
    ) {
      const filterDishes = orderAnalytics.topFiveDishesWithTypes.filter(
        (item) => item._id.type == orderType && item._id.year == selectedYear
      );
      if (filterDishes) {
        setDishes(filterDishes.slice(0, 5));
      }
    }
  }, [orderAnalytics, orderType, selectedYear]);
  return (
    <div className="widget flex w-full md:w-3/12 md:pr-16 md:pt-0 pt-16">
      <Card className="w-full rounded-8 shadow-none border-1 h-170">
        <div className="p-16 pb-8 flex flex-row h-full">
          <div className="w-full">
            <Typography className="h2" color="primary">
              {title}
            </Typography>

            {dishes &&
              dishes.length > 0 &&
              dishes.map((dish) => (
                <Typography
                  style={{ fontSize: "1.4rem" }}
                  className={`font-300  leading-none mt-8 ${textColor}`}
                >
                  <div className={"flex flex-row w-full justify-between"}>
                    <li>{dish._id.dish}</li>
                    <p>{dish.count}</p>
                  </div>
                </Typography>
              ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(TopDishesCard);
