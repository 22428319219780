import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMenuExtraCategoryItems } from "app/store/menu/MenuActions";
import ExtraCategoriesTable from "./ExtraCategoriesTable";

function ExtraCategoryItems({ category,onAddClick }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMenuExtraCategoryItems(category));
  }, [category, dispatch]);

  const CategoryItems = useSelector(
    ({ banzzu }) => banzzu.menu.menuExtraCategoryItems
  );

  return (
    <div>
      {CategoryItems.length === 0 && (
        <ExtraCategoriesTable items={[]} category={category} 
        onAddClick={onAddClick}
        />
      )}
      {CategoryItems.map((categoryItem) => (
        <ExtraCategoriesTable
          key={categoryItem._id}
          items={categoryItem.items}
          category={category}
          connectedCategoryItem={categoryItem.connectedCategoryItem}
          connectedCategoryTitle={categoryItem.connectedCategoryTitle}
          onAddClick={onAddClick}
        />
      ))}
    </div>
  );
}

export default ExtraCategoryItems;
