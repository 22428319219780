import { WhatsOn } from "./../../models/WhatsOn";
import { axiosInstance as axios } from "app/api/axios";

import { WhatsOnActionTypes } from "../redux/actionTypes";
import {
  getWhatsOnDetailUrl,
  getUserId,
  WHATSON
} from "app/api/Endpoint";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";

export function setWhatsOnSearchText(event) {
  return {
    type: WhatsOnActionTypes.SET_WHATSON_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getWhatsOnList = () => {
  return (dispatch) => {
    dispatch({
      type: WhatsOnActionTypes.WHATSON_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(WHATSON, {
        params,
      })
      .then((res) => {
        getWhatsOnListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getWhatsOnListFail(dispatch, "There was an error connection");
      });
  };
};

const getWhatsOnListFail = (dispatch, errorMessage) => {
  dispatch({
    type: WhatsOnActionTypes.WHATSON_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getWhatsOnListSuccess = (dispatch, data) => {
  dispatch({
    type: WhatsOnActionTypes.WHATSON_LIST_SUCCESS,
    payload: data,
  });
};

export const getWhatsOnDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: WhatsOnActionTypes.WHATSON_DETAIL_START,
    });

    const url = getWhatsOnDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getWhatsOnDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getWhatsOnDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getWhatsOnDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: WhatsOnActionTypes.WHATSON_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/whatsonList`,
  });
};

const getWhatsOnDetailSuccess = (dispatch, data) => {
  dispatch({
    type: WhatsOnActionTypes.WHATSON_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createWhatsOn = (whatsOn: WhatsOn) => {
  return (dispatch) => {
    dispatch({
      type: WhatsOnActionTypes.WHATSON_CREATE_START,
    });

    let formData = new FormData()
    formData.append("userId", getUserId())

    for (let key in whatsOn) {
      if (key == "imageFile") {
        formData.append(key, whatsOn[key], whatsOn[key].name);
      } else if (key != "id") {
        formData.append(key, whatsOn[key])
      }
    }

    axios
      .post(WHATSON, formData)
      .then((res) => {
        const data = res.data;
        createWhatsOnSuccess(dispatch, data);
      })
      .catch(() => {
        createWhatsOnFail(dispatch, "There was an error connection");
      });
  };
};

const createWhatsOnFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: WhatsOnActionTypes.WHATSON_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createWhatsOnSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: WhatsOnActionTypes.WHATSON_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/whatsOnList/",
  });
};

export const editWhatsOn = (whatsOn: WhatsOn) => {
  return (dispatch) => {
    dispatch({
      type: WhatsOnActionTypes.WHATSON_EDIT_START,
    });

    let formData = new FormData()

    formData.append("userId", getUserId())

    for (let key in whatsOn) {
      if (key == "_id") {
        formData.append("whatsonId", whatsOn[key])
      } else if (key == "imageFile") {
        formData.append(key, whatsOn[key], whatsOn[key].name);
      } else {
        formData.append(key, whatsOn[key])
      }
    }

    axios
      .put(WHATSON, formData)
      .then((res) => {
        const data = res.data;
        editWhatsOnSuccess(dispatch, data);
      })
      .catch(() => {
        editWhatsOnFail(dispatch, "There was an error connection");
      });
  };
};

const editWhatsOnFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: WhatsOnActionTypes.WHATSON_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editWhatsOnSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: WhatsOnActionTypes.WHATSON_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/whatsOnList/",
  });
};

export const deleteWhatson = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: WhatsOnActionTypes.WHATSON_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(WHATSON, { data: { whatsonId: id } })
        .then((res) => {
          deleteWhatsonSuccess(dispatch, res.data);
        })
        .catch(() => {
          deleteWhatsonFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteWhatsonFail = (dispatch, errorMessage) => {
  dispatch({
    type: WhatsOnActionTypes.WHATSON_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteWhatsonSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  let content = data.data
  dispatch(getWhatsOnList());
  dispatch({
    type: WhatsOnActionTypes.WHATSON_DELETE_SUCCESS,
    payload: content,
  });
};
