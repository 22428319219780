import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import history from "@history";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
import {
  getOrderList,
  setOrderSearchText,
  getExportOrders,
} from "app/store/orders/OrdersActions";
import { translate } from "app/helpers/LanguageHelper/index";
import { orderStatuses } from "./components/OrdersStatus";
import { orderTypes } from "./components/OrdersType";
import UpgradeModal from "../common/UpgradeModal";
import moment from "moment";
import { orderListHeaders } from "../user/components/data";
import OrderFilter from "../common/OrderFilter";
import { ExportModal } from "../common/ExportModal";
import { debounce } from "app/helpers/utilsHelper";

class OrderList extends Component {
  state = {
    orderList: [],
    orderStatus: "0",
    orderType: "0",
    orderTypesValues: [],
    order: {},
    page: 0,
    rowsPerPage: 10,
    open: false,
    showModal: false,
    orderExportList: [],
  };

  static getDerivedStateFromProps(props, state) {
    const { userPermissions, languageStrings } = props;
    if (userPermissions) {
      let orderTypesValues = orderTypes.filter((type) => {
        if (userPermissions.hasCollection && type.id === 1) {
          return type;
        }

        if (userPermissions.hasDelivery && type.id === 2) {
          return type;
        }

        if (userPermissions.hasOrderFromTable && type.id === 3) {
          return type;
        }

        if (userPermissions.hasFutureOrder && type.id === 4) {
          return type;
        }
      });

      orderTypesValues = orderTypesValues.map((orderType) => ({
        ...orderType,
        name: languageStrings[orderType.name],
      }));
      return {
        ...state,
        orderTypesValues,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.orderList.length !== this.props.orderList.length) {
      this.setState({
        orderList: this.props.orderList.map((order) => ({
          ...order,
          totalPrice: order.price + order.deliveryPrice,
        })),
      });
    }
    const { user } = this.props;

    if (this.state.open === false && user.accessLevel == "1") {
      this.setState({
        open: true,
      });
    }
  }

  componentDidMount() {
    this.props.getOrderList({
      searchText: "",
      page: 0,
      limit: 10,
      order: {},
      status: "",
      type: "",
    });
  }

  handleClick = (item) => {
    history.push("/order/" + item._id);
  };

  handleRowsPerPageChange = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () =>
      this.filterOrderList()
    );
  };

  handlePageChange = (event, page) => {
    this.setState({ page }, () => this.filterOrderList());
  };

  handleSearch = (searchText) => {
    this.props.setOrderSearchText(searchText);
    this.setState({ page: 0 }, () => {
      const debouncedFunc = debounce(this.filterOrderList, 500);
      return debouncedFunc();
    });
  };

  handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (
      this.state.order.id === property &&
      this.state.order.direction === "desc"
    ) {
      direction = "asc";
    }

    this.setState({
      order: {
        direction,
        id,
      },
    });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterByStatus = (ev) => {
    let { value } = ev.target;

    this.setState({ orderStatus: value });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterByType = (ev) => {
    let { value } = ev.target;

    this.setState({ orderType: value });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterOrderList = () => {
    let { orderList } = this.props;
    let { orderStatus, orderType, order, page, rowsPerPage } = this.state;
    let filteredOrderList = orderList;

    this.props.getOrderList({
      searchText: this.props.searchText,
      page,
      limit: rowsPerPage,
      order,
      status: orderStatus,
      type: orderType,
    });

    this.setState({
      orderList: filteredOrderList.map((order) => ({
        ...order,
        totalPrice: order.price + order.deliveryPrice,
      })),
    });
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "referenceNo",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.REF"],
          sort: true,
          type: "text",
        },
        {
          id: "venueTitle",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.VENUE"],
          sort: true,
          type: "text",
        },
        {
          id: "type",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.ORDER_TYPE"],
          sort: true,
          type: "orderType",
        },
        {
          id: ["totalPrice", "discountedPrice"],
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.PRICE"],
          sort: true,
          type: "discountCell",
        },
        {
          id: "status",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.STATUS"],
          sort: true,
          type: "orderStatus",
        },
        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "time",
        },
      ],
      checkbox: false,
      clickable: true,
      locationSearch: false,
      searchBy: ["referenceNo"],
    };

    const orderStatusValues = orderStatuses.map((status) => {
      return {
        ...status,
        name: languageStrings[status.name],
      };
    });
    const exportFiles = () => {
      const usersData = [];
      this.state.orderList.forEach((ord) => {
        const orderStausText = orderStatuses.filter(
          (item) => item.id === Number(ord.orderStatus)
        )[0].name;
        const orderTypeText = orderTypes.filter(
          (item) => item.id === Number(ord.type)
        )[0].name;
        const usersObj = {};
        usersObj["referenceNo"] = ord.referenceNo;
        usersObj["venueTitle"] = ord.venueTitle;
        usersObj["orderType"] = languageStrings[orderTypeText];
        usersObj["totalPrice"] = Number(ord.totalPrice).toFixed(2);
        usersObj["orderStatus"] = languageStrings[orderStausText];
        usersObj["createdDate"] = moment(ord.createdAt).format("L");
        usersObj["createdTime"] = moment(ord.createdAt).format("LT");

        usersData.push(usersObj);
      });
      return usersData;
    };

    return (
      <>
        <UpgradeModal
          open={this.state.open}
          setOpen={() => {
            history.goBack();
            this.setState({
              open: false,
            });
          }}
        />
        <LoadingOverlay
          active={this.props.loading}
          spinner
          styles={{
            wrapper: {
              width: "100%",
              height: "100%",
              overflow: this.props.loading ? "hidden" : "scroll",
            },
          }}
          text="Loading..."
        >
          <div
            style={{
              flex: 1,
              filter: this.state.open ? "blur(3px)" : "none",
              marginTop: 30,
            }}
          >
            <FusePageCarded
              classes={{
                content: "flex",
                header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
              }}
              header={
                <div
                  className={
                    "flex w-full items-center flex-row justify-between"
                  }
                >
                  <ListHeader
                    title={languageStrings["ORDER_LIST_PAGE.TITLE"]}
                    setSearchText={this.handleSearch}
                    searchType={"order"}
                    selectors={[
                      {
                        onChange: this.filterByStatus,
                        values: orderStatusValues,
                        keys: ["id", "name"],
                        title:
                          languageStrings["ORDER_LIST_PAGE.STATUS_PLACEHOLDER"],
                        firstOption:
                          languageStrings["ORDER_LIST_PAGE.ALL_STATUS"],
                      },
                      {
                        onChange: this.filterByType,
                        values: this.state.orderTypesValues,
                        keys: ["id", "name"],
                        title:
                          languageStrings["ORDER_LIST_PAGE.TYPES_PLACEHOLDER"],
                        firstOption:
                          languageStrings["ORDER_LIST_PAGE.ALL_TYPES"],
                      },
                    ]}
                  />
                  <div className={"mt-12"}>
                    <ExportModal
                      onShow={() => {
                        this.setState({ showModal: true });
                      }}
                      fileName={"order-list"}
                      data={exportFiles()}
                      header={orderListHeaders}
                      {...this.props}
                    />
                  </div>
                </div>
              }
              content={
                <ListTablePaginated
                  handleClick={this.handleClick}
                  headerRows={headerRows}
                  data={this.state.orderList.map((order) => {
                    if (order.totalPrice) {
                      let obj = {
                        ...order,
                        totalPrice: Number(order.totalPrice).toFixed(2),
                      };
                      if (order.discountVoucherAmount) {
                        obj = {
                          ...obj,
                          discountedPrice: (
                            Number(order.totalPrice) -
                            Number(order.discountVoucherAmount)
                          ).toFixed(2),
                        };
                      }
                      return obj;
                    }
                    return order;
                  })}
                  totalData={this.props.totalOrders}
                  searchText={this.props.searchText}
                  order={this.state.order}
                  handleRequestSort={this.handleRequestSort}
                  page={this.state.page}
                  rowsPerPage={this.state.rowsPerPage}
                  handleRowsPerPageChange={this.handleRowsPerPageChange}
                  handlePageChange={this.handlePageChange}
                />
              }
              innerScroll
            />
          </div>
          <OrderFilter
            onCloseModal={() => {
              this.setState({ showModal: false });
            }}
            open={this.state.showModal}
            {...this.props}
          />
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.order.loading,
    orderList: state.banzzu.order.orderList,
    totalOrders: state.banzzu.order.totalOrders,
    searchText: state.banzzu.order.searchText,
    userPermissions: state.banzzu.auth.user.permission,
    user: state.banzzu.auth.user,
    orderExportList: state.banzzu.order.orderExportList
  };
};
const mapDispatchToProps = {
  getOrderList,
  setOrderSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(OrderList));
