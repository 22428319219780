import React from "react";
import { TableRow } from "@material-ui/core";

import ImageCell from "./ImageCell";
import CheckBoxCell from "./CheckBoxCell";
import SuccessAndDangerCell from "./SuccessAndDangerCell";
import PriceReduceCell from "./PriceReduceCell";
import DiscountedAmountCell from "./DiscountedAmountCell";
import IconCell from "./IconCell";
import IconCtaCell from "./IconCtaCell";
import DateTimeCell from "./DateTimeCell";
import DateCell from "./DateCell";
import MenuTypeCell from "./MenuTypeCell";
import OrderStatusCell from "./OrderStatusCell";
import OrderTypeCell from "./OrderTypeCell";
import DateComparison from "./DateComparison"

const RowComponent = ({
  isSelected,
  headerRows,
  handleCheck,
  handleClick,
  n,
}) => {
  return (
    <TableRow
      className={headerRows.clickable ? "h-64 cursor-pointer" : "h-64"}
      hover={headerRows.clickable}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={n._id}
      selected={isSelected}
      onClick={(event) => headerRows.clickable && handleClick(n)}
    >
      {headerRows.checkbox && (
        <CheckBoxCell
          isSelected={isSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleCheck(event, n._id)}
        />
      )}
      {headerRows.data.map((obj) => {
        switch (obj.type) {
          case "image":
            return <ImageCell key={n._id} src={n.image} alt={n.title} />;

          case "text":
            return <SuccessAndDangerCell key={n._id} n={n} obj={obj} />;

          case "date":
            return <DateCell key={n._id} date={n[obj.id]} />;

          case "time":
            return <DateTimeCell key={n._id} date={n[obj.id]} />;

          case "dateComparison":
            return <DateComparison key={n._id} data={n} />;

          case "reducedPrice":
            return <PriceReduceCell key={n._id} n={n} />;

          case "discountCell":
            return <DiscountedAmountCell key={n._id} data={n} dataTypes={obj.id} />;

          case "orderType":
            return <OrderTypeCell key={n._id} orderType={n[obj.id]} />;

          case "orderStatus":
            return <OrderStatusCell key={n._id} orderStatus={n.orderStatus} />;

          case "icon":
            return <IconCell key={n._id} platform={n.platform} />;

          case "bool":
            return <MenuTypeCell key={n._id} n={n.isPdf} />;

          case "iconCta":
            return <IconCtaCell key={n._id} icon={obj.icon} onClick={e => obj.onClick(e, n._id)} />;

          default:
            return null;
        }
      })}
    </TableRow>
  );
};

export default RowComponent;
