export const paymentTypeMenu = [
  {
    type: "Redsys",
    name: "rd1",
    imagePath: `assets/images/redsys_logo.png`,
    advantages: {
      title: "GENERAL.ADV_TITLE",
      data: [
        "GENERAL.REDSYS_ADV1",
        "GENERAL.REDSYS_ADV2",
        "GENERAL.REDSYS_ADV3",
        "GENERAL.REDSYS_ADV4",
      ],
    },
    disAdvantages: {
      title: "GENERAL.DIS_ADV_TITLE",
      data: ["GENERAL.REDSYS_DIS_ADV1", "GENERAL.REDSYS_DIS_ADV2"],
    },
    inputs: [
      {
        required: true,
        label: "GENERAL.REDSYS_KEY",
        id: "secretKey",
        name: "secretKey",
        valueCheck: "",
        variant: "outlined",
        errorMessage: "ERRORS.REDSYS_SECRET_KEY",
        fullWidth: true,
        inputProps: {
          maxLength: 200,
        },
      },
      {
        required: true,
        label: "GENERAL.MERCHENT_CODE",
        id: "merchantCode",
        name: "merchantCode",
        errorMessage: "ERRORS.REDSYS_MERCHANT_CODE",
        variant: "outlined",
        valueCheck: "",
        fullWidth: true,
        inputProps: {
          maxLength: 200,
        },
      },
      {
        required: true,
        label: "GENERAL.MERCHENT_NAME",
        id: "merchantName",
        name: "merchantName",
        errorMessage: "ERRORS.REDSYS_MERCHANT_NAME",
        variant: "outlined",
        valueCheck: "",
        fullWidth: true,
        inputProps: {
          maxLength: 200,
        },
      },
    ],
  },
  {
    type: "Stripe",
    name: "rd2",
    imagePath: `assets/images/stripe/stripe_logo.png`,
    advantages: {
      title: "GENERAL.ADV_TITLE",
      data: [
        "GENERAL.STRIPE_ADV1",
        "GENERAL.STRIPE_ADV2",
        "GENERAL.STRIPE_ADV3",
        "GENERAL.STRIPE_ADV4",
      ],
    },
    disAdvantages: {
      title: "GENERAL.DIS_ADV_TITLE",
      data: ["GENERAL.STRIPE_DIS_ADV1"],
    },
    inputs: [
      {
        required: true,
        label: "GENERAL.STRIPE_KEY",
        id: "stripeId",
        name: "stripeId",
        errorMessage: "ERRORS.STRIPE_ID_INVALID",
        valueCheck: "sk_live",
        devValueCheck: "sk_test",
        variant: "outlined",
        fullWidth: true,
        inputProps: {
          maxLength: 200,
        },
      },
      {
        required: true,
        label: "GENERAL.STRIPE_PUB_KEY",
        id: "stripePublicKey",
        errorMessage: "ERRORS.STRIPE_KEY_INVALID",
        valueCheck: "pk_live",
        devValueCheck: "pk_test",
        name: "stripePublicKey",
        variant: "outlined",
        fullWidth: true,
        inputProps: {
          maxLength: 200,
        },
      },
    ],
  },
];
