import React, { useState, useEffect } from "react";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import { useDispatch } from "react-redux";
import reducer from "app/store/venues/VenuesReducer";
import * as Actions from "app/store/actions";
import withReducer from "app/store/withReducer";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { makeStyles } from "@material-ui/styles";
import history from "@history";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 30,
    boxShadow: theme.shadows[5],
    padding: "24px",
  },
}));

function UpgradeModal({ open, setOpen, closeable }) {
  const classes = useStyles();

  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
  }, []);

  const isMobile = width <= 600;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        if (closeable) {
          setOpen(false);
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={["md:w-2/5 w-11/12", classes.paper].join(" ")}>
          <h1
            id="transition-modal-title"
            className="pb-32 text-center md:text-4xl text-2xl"
            style={{ color: "#fa7a0d" }}
          >
            {languageStrings["UPGRADE_MODAL.YOUR_PLAN_IS_TOO_SMALL"]}
          </h1>
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-3/5 pt-12 md:pl-56 pl-8">
              <h5 style={{ fontSize: isMobile ? 13.5 : 13 }}>
                {languageStrings["UPGRADE_MODAL.ITS_SEEMS"]}
              </h5>

              <p
                className={"md:mt-16 mt-16"}
                style={{ fontSize: isMobile ? 13.5 : 13 }}
              >
                {languageStrings["UPGRADE_MODAL.INCREASE_PLAN"]}
              </p>
              {!isMobile && (
                <>
                  <button
                    className="mt-10 w-4/5 h-28 text-white font-semibold  py-2 px-4 border"
                    style={{
                      fontSize: 13,
                      borderRadius: "2.4rem",
                      backgroundColor: "#fa7a0d",
                      borderColor: "#fa7a0d",
                    }}
                    onClick={() => history.push("/pricing")}
                  >
                    {languageStrings["UPGRADE_MODAL.UPGRADE_MY_PLAN"]}
                  </button>
                  <button
                    className={
                      "mt-8 w-4/5 h-28 text-white font-semibold  py-2 px-4 border"
                    }
                    style={{
                      color: hover ? "white" : "#fa7a0d",
                      backgroundColor: hover ? "#fa7a0d" : "transparent",
                      borderColor: "#fa7a0d",
                      fontSize: 13,
                      borderRadius: "2.4rem",
                    }}
                    onClick={() => {
                      setOpen(false);
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {languageStrings["UPGRADE_MODAL.I_STICK"]}
                  </button>
                </>
              )}
            </div>
            <div className={"w-2/5"}>
              <img
                src={"assets/images/animacion.gif"}
                style={{ height: "auto", width: "auto" }}
              />
            </div>
          </div>
          {isMobile && (
            <div
              className={"flex flex-row justify-between w-full pl-2 pr-2 mt-5"}
            >
              <button
                className="mt-8  h-32 text-white font-semibold  py-2 px-4 border"
                style={{
                  fontSize: 13,
                  borderRadius: "2.4rem",
                  backgroundColor: "#fa7a0d",
                  borderColor: "#fa7a0d",
                  width: "49%",
                }}
                onClick={() => history.push("/pricing")}
              >
                {languageStrings["UPGRADE_MODAL.UPGRADE_MY_PLAN"]}
              </button>
              <button
                className={"mt-6 w-full font-semibold  py-2 px-4 border"}
                style={{
                  color: hover ? "white" : "#fa7a0d",
                  backgroundColor: hover ? "#fa7a0d" : "transparent",
                  borderColor: "#fa7a0d",
                  fontSize: 13,
                  borderRadius: "2.4rem",
                  width: "49%",
                }}
                onClick={() => {
                  setOpen(false);
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {languageStrings["UPGRADE_MODAL.I_STICK"]}
              </button>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

export default withReducer("BookingReducer", reducer)(UpgradeModal);
