import { MerchantActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";
import _ from "@lodash";

const INITIAL_STATE: MerchantState = {
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const MerchantReducer = (
  state: MerchantState = INITIAL_STATE,
  action: Action
): MerchantState => {
  switch (action.type) {
    case MerchantActionTypes.PURCHASE_PRODUCT_START:
      return updateObject(state, { loading: true });

    case MerchantActionTypes.PURCHASE_PRODUCT_SUCCESS:
      return updateObject(state, {
        loading: false,
      });
    case MerchantActionTypes.PURCHASE_PRODUCT_FAIL:
      return updateObject(state, { loading: false });

    case MerchantActionTypes.SEND_UP_SELL_EMAIL_START:
      return updateObject(state, { loading: true });

    case MerchantActionTypes.SEND_UP_SELL_EMAIL_FAIL:
      return updateObject(state, { loading: false });

    case MerchantActionTypes.SEND_UP_SELL_EMAIL_SUCCESS:
      return updateObject(state, {
        loading: false,
      });

    default:
      return state;
  }
};

export default MerchantReducer;
