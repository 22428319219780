import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Icon,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import {
  getMenuItemDetail,
  getMenuItemAllergenes,
  createMenuItem,
  editMenuItem,
} from "app/store/menu/MenuActions";
import _ from "@lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import { formatImageUrl } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const allergenesTranslation = {
  Cereals: "ALLERGENE.CEREALS",
  Celery: "ALLERGENE.CELERY",
  Crustaceans: "ALLERGENE.CRUSTACEANS",
  Eggs: "ALLERGENE.EGGS",
  Fish: "ALLERGENE.FISH",
  Lupin: "ALLERGENE.LUPIN",
  Milk: "ALLERGENE.MILK",
  Molluscs: "ALLERGENE.MOLLUSCS",
  Mustard: "ALLERGENE.MUSTARD",
  Nuts: "ALLERGENE.NUTS",
  Peanuts: "ALLERGENE.PEANUTS",
  "Sesame Seeds": "ALLERGENE.SESAME_SEEDS",
  "Sulphur dioxide": "ALLERGENE.SULPHER_DIOXIDE",
  Allergenes: "ALLERGENE.TITLE",
  Soya: "ALLERGENE.SOYA",
};

function MenuItemDetailModal(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemCategory
  );
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );

  let menu = useSelector(({ banzzu }) => banzzu.menu.menuItemDetail);
  const { languageStrings } = useLanguageHelper();
  const { productId, categoryId, menuId, onClose } = props;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getMenuItemDetail(productId, menuId));
    }
  }, [dispatch, productId, menuId]);

  useEffect(() => {
    dispatch(getMenuItemAllergenes());
  }, [dispatch]);

  if (productId === "new") {
    menu = {
      id: "0",
      image: "",
      title: "",
      price: "",
      description: "",
      allergenes: "",
      idMenu: menuId,
      categoryId: categoryId,
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };
  const [dirty, setDirty] = useState(dirtyInitial);
  const { form, handleChange, setForm } = useForm(null);

  if (form != null && form.categoryId === "0" && categoryList[0] != null) {
    setForm((form) => ({
      ...form,
      categoryId: categoryList[0]._id,
    }));
  }

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      setForm(menu);
    }
  }, [form, menu, setForm]);

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.price !== "";
  }

  function hasNoErrors() {
    return !dirty.title && !dirty.description && !dirty.price;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];

    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleAllergenesChange(event) {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  }

  const deleteMenuItemImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      imageFile: null,
      deleteImage: true,
    }));
  };

  if (!form) {
    return <div />;
  }

  return (
    <div className="p-16 sm:p-24 max-w-xl">
      <div className="flex justify-center sm:justify-start flex-wrap">
        <input
          accept="image/jpg, image/jpeg, image/png, image/gif"
          className="hidden"
          id={`button-file-${productId}`}
          type="file"
          onChange={handleUploadChange}
        />
        <label
          htmlFor={`button-file-${productId}`}
          className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
        >
          <Icon fontSize="large" color="action">
            cloud_upload
          </Icon>
        </label>
        {
          <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
            <img
              className="max-w-none w-auto h-full"
              alt="whatson"
              src={
                form.image
                  ? formatImageUrl(form.image)
                  : "assets/images/avatars/noimage.png"
              }
            />
            {form.image && (
              <Icon
                onClick={deleteMenuItemImage}
                className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                style={{
                  color: "#f4516c",
                  backgroundColor: "#fff",
                }}
              >
                delete
              </Icon>
            )}
          </div>
        }
      </div>
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8"
          required
          label={languageStrings["GENERAL.TITLE"]}
          id="title"
          name="title"
          value={form.title || ""}
          onValueChange={handleChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) =>
            setDirty((prevState) => {
              return { ...prevState, title: error };
            })
          }
          minLength={3}
        />

        <TextField
          className="mt-8 ml-8 mb-16"
          label={languageStrings["GENERAL.PRICE"]}
          required
          id="price"
          name="price"
          type={"number"}
          value={form.price || ""}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          //error
        />
      </div>

      <CustomInput
        className="mt-8 mb-16 mr-8"
        label={languageStrings["GENERAL.DESCRIPTION"]}
        id="description"
        name="description"
        maxLength={1000}
        value={form.description || ""}
        onValueChange={handleChange}
        errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
        hasError={(error) => error}
      />

      <div className="mt-8 ml-8 mb-8 font-bold">
        {languageStrings["ALLERGENE.TITLE"]}
      </div>

      <FormGroup className="ml-8" row>
        {allergenesList &&
          allergenesList.map((item) => (
            <FormControlLabel
              key={item.title}
              control={
                <Checkbox
                  checked={
                    form.allergenes
                      ? form.allergenes.includes(`,${item._id},`)
                      : false
                  }
                  onChange={handleAllergenesChange}
                  value={item._id}
                />
              }
              label={languageStrings[allergenesTranslation[item.title]]}
            />
          ))}
      </FormGroup>
      <Button
        className="whitespace-no-wrap mt-16"
        variant="contained"
        disabled={!canBeSubmitted()}
        onClick={() => {
          form.id !== "0"
            ? dispatch(editMenuItem(form))
            : dispatch(createMenuItem(form));
          onClose();
        }}
      >
        <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>save</Icon>
        {languageStrings["GENERAL.SAVE"]}
      </Button>
    </div>
  );
}

export default withReducer("MenuReducer", reducer)(MenuItemDetailModal);
