import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import moment from "moment";
import clsx from "clsx";
import CalendarHeader from "./CalendarHeader";
import { getReservationList } from "app/store/reservation/ReservationActions";
import history from "@history";
import { getVenueList } from "app/store/venues/VenuesActions";
import {
  InputLabel,
  Select,
  OutlinedInput,
  FormControl
} from "@material-ui/core";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

const localizer = BigCalendar.momentLocalizer(moment);

let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

const useStyles = makeStyles(theme => ({
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 14
    },
    "& .rbc-label": {
      padding: "8px 6px"
    },
    "& .rbc-today": {
      backgroundColor: "transparent"
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important"
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16
      },
      ...theme.mixins.border(0)
    },
    "& .rbc-agenda-view table": {
      ...theme.mixins.border(1),
      "& thead > tr > th": {
        ...theme.mixins.borderBottom(0)
      },
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {
          ...theme.mixins.borderLeft(1)
        }
      }
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {
        ...theme.mixins.border(1)
      },
      "& .rbc-time-content": {
        flex: "0 1 auto",
        ...theme.mixins.border(1)
      }
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {
        ...theme.mixins.border(1)
      },
      "& .rbc-month-row": {
        ...theme.mixins.border(1),
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128

      },
      "& .rbc-header + .rbc-header": {
        ...theme.mixins.borderLeft(1)
      },
      "& .rbc-header": {
        ...theme.mixins.borderBottom(0)
      },
      "& .rbc-day-bg + .rbc-day-bg": {
        ...theme.mixins.borderLeft(1)
      }
    },
    "& .rbc-day-slot .rbc-time-slot": {
      ...theme.mixins.borderTop(1),
      opacity: 0.5
    },
    "& .rbc-time-header > .rbc-row > * + *": {
      ...theme.mixins.borderLeft(1)
    },
    "& .rbc-time-content > * + * > *": {
      ...theme.mixins.borderLeft(1)
    },
    "& .rbc-day-bg + .rbc-day-bg": {
      ...theme.mixins.borderLeft(1)
    },
    "& .rbc-time-header > .rbc-row:first-child": {
      ...theme.mixins.borderBottom(1)
    },
    "& .rbc-timeslot-group": {
      minHeight: 64,
      ...theme.mixins.borderBottom(1)
    },
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit"
      }
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2]
      }
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px"
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light" ? "rgba(0,0,0,0.03)" : "rgba(0,0,0,0.16)"
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent"
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static"
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child": {
      left: 0,
      top: 0,
      bottom: 0,
      height: "auto"
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child": {
      right: 0,
      top: 0,
      bottom: 0,
      height: "auto"
    }
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99
  }
}));

function CalendarApp(props) {
  const dispatch = useDispatch();
  const events = useSelector(
    ({ banzzu }) => banzzu.reservation.reservationList
  );
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { languageStrings } = useLanguageHelper()
  const [venue, setVenue] = useState("0");
  const [reservation, setReservation] = useState(events);

  useEffect(() => {
    setReservation(
      events.length === 0 || venue === "0"
        ? events
        : _.filter(events, item => item.venueId === venue)
    );
  }, [events, venue]);

  const eventsFormatted = reservation.map(item => {
    let dateTime = moment(item.date+ " "+item.time,'DD/MM/YYYY HH:mm')
    item.start = new Date(dateTime);
    item.end = new Date(new Date(dateTime).getTime() + 60 * 60000);
    item.title = item.time + " - " + item.userFirstName + " " +item.userLastName
    return item;  
  });

  const classes = useStyles(props);
  const headerEl = useRef(null);

  useEffect(() => {
    dispatch(getReservationList());
    dispatch(getVenueList());
  }, [dispatch]);

  function handleVenueChange(event) {
    setVenue(event.target.value);
  }
  function eventStyleGetter(event, start, end, isSelected) {
    let backgroundColor;

    switch (event.status) {
      case "1":
        backgroundColor = "#34bfa3";
        break;

      case "2":
        backgroundColor = "#f4516c";
        break;

      default:
        backgroundColor = "#6495ED";
        break;
    }

    var style = {
      backgroundColor: backgroundColor
    };
    return {
      style: style
    };
  }

  return (
    <div className={clsx(classes.root, "flex flex-col flex-auto relative")}>
      <div ref={headerEl} />

      <div className="flex justify-center sm:justify-end flex-wrap">
        <FormControl variant="outlined" className="mt-10 mb-8 mr-10">
          <InputLabel htmlFor="outlined-venue-native-simple">
            {languageStrings["CALENDER_APP_PAGE.SELECT_VENUE"]}
          </InputLabel>
          <Select
            className="mt-8 mb-16 ml-8"
            native
            disabled={user.permission && !user.permission.isMaster}
            onChange={handleVenueChange}
            value={venue}
            input={
              <OutlinedInput
                name="venueId"
                labelWidth={100}
                id="outlined-venue-native-simple"
              />
            }
          >
            {user.permission && !user.permission.isMaster ? (
              <option value={user.id}>{user.username}</option>
            ) : (
              <option value={0}>{languageStrings["GENERAL.ALL_VENUES"]}</option>
            )}
            {venueList &&
              venueList.map(item => (
                <option value={item.id}>{item.title}</option>
              ))}
          </Select>
        </FormControl>
      </div>

      <BigCalendar
        className="flex flex-1 container"
        selectable
        localizer={localizer}
        events={eventsFormatted}
        defaultView={BigCalendar.Views.MONTH}
        startAccessor="start"
        endAccessor="end"
        views={allViews}
        step={60}
        eventPropGetter={eventStyleGetter}
        showMultiDayTimes
        components={{
          toolbar: props => {
            return <CalendarHeader {...props} />;
          }
        }}
        // onNavigate={handleNavigate}
        onSelectEvent={event => {
          history.push("/bookingDetail/" + event._id);
        }}
      />
    </div>
  );
}

export default CalendarApp;
