import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";

import { FusePageCarded } from "@fuse";
import { Tab, Tabs } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import OrderFromTableHeader from "./components/OrderFromTableHeader";
import OrderFromTableList from "./components/OrderFromTableList";

const OrderFromTable = ({ match }) => {
  const [tabValue, setTabValue] = useState(0);

  let loading = useSelector(({ banzzu }) => banzzu.menu.loading);

  const { languageStrings } = useLanguageHelper();

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <>
            <OrderFromTableHeader />
          </>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["ORDER_FROM_TABLE.LIST"]}
            />
          </Tabs>
        }
        content={
          <>
            <OrderFromTableList />
          </>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default OrderFromTable;
