import React from "react";
import {
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  Input,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const EstCard = ({
  title,
  image,
  name,
  onChange,
  checked = false,
  onSwitchChange,
  value = 30,
}) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div style={{ minWidth: "48%" }}>
      <div className={"flex items-center justify-center w-9/12"}>
        <img
          src={image}
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          }}
        />
      </div>

      <div className={"flex flex-row justify-center items-center w-11/12 "}>
        <Typography className="text-16 mr-12 truncate">
          {languageStrings[title]}
        </Typography>

        <FormGroup>
          <FormControlLabel
            label={""}
            control={
              <Switch checked={checked} onChange={onSwitchChange} name={name} />
            }
          />
        </FormGroup>
      </div>
      <div className={"flex flex-1 w-full mt-20 bg-grey-lightest"}>
        {checked && (
          <Card
            className={
              "h-160 w-full flex flex-col justify-evenly  bg-grey-lightest"
            }
          >
            <h3 className={"ml-20	"}>{languageStrings["GENERAL.ENTER_TIME"]}</h3>

            <div className={"flex flex-row w-full items-center justify-evenly"}>
              <Input
                disableUnderline
                className={"border-2 w-9/12  rounded-12 p-4 border-red-400"}
                fullWidth
                type="number"
                name="Delivery-Time"
                value={value}
                inputProps={{
                  "aria-label": "Search",
                  min: 0,
                }}
                onChange={onChange}
              />

              <h3>{languageStrings["GENERAL.MIN"]}</h3>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default EstCard;
