import React, { FC } from "react";

interface Props {
  title: string;
}

const AlertBox: FC<Props> = (props) => {
  const { title } = props;
  return (
    <div
      className={"flex h-52 justify-center items-center rounded-lg bg-orange"}
    >
      <span>{title}</span>
    </div>
  );
};

export default AlertBox;
