import React from "react";
import _ from "@lodash";
import history from "@history";
import { Card, Typography, Icon } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListTable from "app/main/common/List/ListTable";

const OrderListCard = ({ orderList }) => {
  const { languageStrings } = useLanguageHelper();
  const headerRows = {
    data: [
      {
        id: "referenceNo",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.REF"],
        sort: true,
        type: "text",
      },
      {
        id: "venueTitle",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.VENUE"],
        sort: true,
        type: "text",
      },
      {
        id: "type",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ORDER_TYPE"],
        sort: true,
        type: "orderType",
      },
      {
        id: "price",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE"],
        sort: true,
        type: "time",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
  };
  const handleClick = (item) => {
    history.push("/order/" + item._id);
  };
  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Icon className="mr-16" color="action">
            shopping_cart
          </Icon>
          <Typography className="h2" color="textSecondary">
            {languageStrings["ORDER_LIST_PAGE.TITLE"]}
          </Typography>
        </div>
        <ListTable
          handleClick={handleClick}
          headerRows={headerRows}
          data={orderList}
          searchText={""}
        />
      </Card>
    </div>
  );
};

export default OrderListCard;
