import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import moment from "moment";
import clsx from "clsx";
import CalendarHeader from "./CalendarHeader";
import {
  getDeliveryAvailability,
  addDeliveryAvailability,
  deleteDeliveryAvailability,
} from "app/store/venues/VenuesActions";
import {
  Button,
  Icon,
  TextField,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useForm } from "@fuse/hooks";
import { createDate } from "app/helpers/validators";
import AlertDialog from "../../common/AlertDialog";
import * as Actions from "app/store/actions";

const localizer = BigCalendar.momentLocalizer(moment);

let allViews = Object.keys(BigCalendar.Views).map((k) => BigCalendar.Views[k]);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 14,
    },
    "& .rbc-label": {
      padding: "8px 6px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important",
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
      ...theme.mixins.border(0),
    },
    "& .rbc-agenda-view table": {
      ...theme.mixins.border(1),
      "& thead > tr > th": {
        ...theme.mixins.borderBottom(0),
      },
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {
          ...theme.mixins.borderLeft(1),
        },
      },
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {
        ...theme.mixins.border(1),
      },
      "& .rbc-time-content": {
        flex: "0 1 auto",
        ...theme.mixins.border(1),
      },
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {
        ...theme.mixins.border(1),
      },
      "& .rbc-month-row": {
        ...theme.mixins.border(1),
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128,
      },
      "& .rbc-header + .rbc-header": {
        ...theme.mixins.borderLeft(1),
      },
      "& .rbc-header": {
        ...theme.mixins.borderBottom(0),
      },
      "& .rbc-day-bg + .rbc-day-bg": {
        ...theme.mixins.borderLeft(1),
      },
    },
    "& .rbc-day-slot .rbc-time-slot": {
      ...theme.mixins.borderTop(1),
      opacity: 0.5,
    },
    "& .rbc-time-header > .rbc-row > * + *": {
      ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-content > * + * > *": {
      ...theme.mixins.borderLeft(1),
    },
    "& .rbc-day-bg + .rbc-day-bg": {
      ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-header > .rbc-row:first-child": {
      ...theme.mixins.borderBottom(1),
    },
    "& .rbc-timeslot-group": {
      minHeight: 64,
      ...theme.mixins.borderBottom(1),
    },
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit",
      },
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2],
      },
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px",
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0,0,0,0.03)"
          : "rgba(0,0,0,0.16)",
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child":
      {
        left: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child":
      {
        right: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99,
  },
}));

function DeliverCalendar(props) {
  const dispatch = useDispatch();
  const availabilities = useSelector(
    ({ banzzu }) => banzzu.venue.deliveryAvailability
  );
  const { languageStrings } = useLanguageHelper();

  const { form, handleChange, setForm } = useForm(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const availability = {
    startDate: new Date(),
    endDate: new Date(),
    initialTime: "",
    endTime: "",
  };

  useEffect(() => {
    if (availability && !form) {
      setForm(availability);
    }
  }, [form, availability, setForm]);

  const eventsFormatted = availabilities.map((item) => {
    item.start = createDate(item.date, item.initialTime);
    item.end = createDate(item.date, item.endTime);

    item.title = item.initialTime + " - " + item.endTime;

    return item;
  });

  const [dirty, setDirty] = useState({
    startDateError: "",
    endDateError: "",
  });

  const classes = useStyles(props);
  const headerEl = useRef(null);

  useEffect(() => {
    if (props.venue && props.venue._id) {
      dispatch(getDeliveryAvailability(props.venue._id));
    }
  }, [dispatch, props.venue]);

  function eventStyleGetter(event, start, end, isSelected) {
    var style = {
      backgroundColor: "#34bfa3",
    };
    return {
      style: style,
    };
  }

  function hasNoErrors() {
    return dirty.startDateError === "" && dirty.endDateError === "";
  }

  function hasNoEmpty() {
    return (
      form.startDate !== "" &&
      form.endDate !== "" &&
      form.initialTime !== "" &&
      form.endTime !== ""
    );
  }

  const checkDayCount = (startDate, endDate) => {
    let start = moment(startDate);
    let end = moment(endDate);

    let error = {
      startDateError: "",
      endDateError: "",
    };

    if (end.diff(start, "days") > 30) {
      error.startDateError = languageStrings["VENUE_DETAIL_PAGE.DATE_RANGE"];
      error.endDateError = languageStrings["VENUE_DETAIL_PAGE.DATE_RANGE"];
    }

    setDirty((prevState) => ({
      ...prevState,
      ...error,
    }));
  };

  const greaterStartDate = (startDate, endDate) => {
    if (startDate > endDate) {
      setDirty((prevState) => ({
        ...prevState,
        startDateError:
          languageStrings["VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS"],
        endDateError:
          languageStrings["VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER"],
      }));
    } else {
      checkDayCount(startDate, endDate);
    }
  };

  const smallerEndDate = (startDate, endDate) => {
    if (startDate > endDate) {
      setDirty((prevState) => ({
        ...prevState,
        endDateError:
          languageStrings["VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER"],
        startDateError:
          languageStrings["VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS"],
      }));
    } else {
      checkDayCount(startDate, endDate);
    }
  };

  function handleFromDateChange(startDate) {
    greaterStartDate(startDate, form.endDate);
    setForm((form) => ({
      ...form,
      startDate,
    }));
  }

  function handleToDateChange(endDate) {
    smallerEndDate(form.startDate, endDate);
    setForm((form) => ({
      ...form,
      endDate,
    }));
  }

  const createSlot = (id, strtDate, enDate, strtTime, enTime) => {
    dispatch(addDeliveryAvailability(id, strtDate, enDate, strtTime, enTime));
  };

  return (
    <div className={clsx(classes.root, "flex flex-col flex-auto relative")}>
      <div ref={headerEl} />

      <div className="flex mb-16 flex-1 w-full items-center">
        <Button
          className="whitespace-no-wrap"
          variant="contained"
          onClick={() => {
            handleOpen();
          }}
        >
          <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>add</Icon>
          {languageStrings["VENUE_DETAIL_PAGE.ALERT_TIME.TITLE"]}
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              {languageStrings["VENUE_DETAIL_PAGE.ALERT_TIME.TITLE"]}
            </h2>
            <p id="transition-modal-description">
              {form && (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="flex">
                      <DatePicker
                        error={dirty.startDateError !== ""}
                        className="mt-32 mb-32"
                        margin="normal"
                        fullWidth
                        id="startDate"
                        name="startDate"
                        label={languageStrings["GENERAL.DATE"]}
                        value={form.startDate}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleFromDateChange}
                        helperText={
                          dirty.startDateError !== ""
                            ? dirty.startDateError
                            : ""
                        }
                      />

                      <label className="mt-52 mb-32 mx-2">
                        {languageStrings["GENERAL.TO"]}
                      </label>

                      <DatePicker
                        error={dirty.endDateError !== ""}
                        className="mt-32 mb-32"
                        margin="normal"
                        fullWidth
                        id="endDate"
                        name="endDate"
                        label={languageStrings["GENERAL.DATE"]}
                        value={form.endDate}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleToDateChange}
                        helperText={
                          dirty.endDateError !== "" ? dirty.endDateError : ""
                        }
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <div className="flex">
                    <TextField
                      id="initialTime"
                      name="initialTime"
                      label={
                        languageStrings[
                          "VENUE_DETAIL_PAGE.ALERT_TIME.INITIAL_TIME"
                        ]
                      }
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={form.initialTime}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        step: 900, // 5 min
                      }}
                    />
                    <TextField
                      id="endTime"
                      name="endTime"
                      label={
                        languageStrings["VENUE_DETAIL_PAGE.ALERT_TIME.END_TIME"]
                      }
                      type="time"
                      className="ml-10"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={form.endTime}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        step: 900, // 5 min
                      }}
                    />
                  </div>
                  <div className="flex mt-64 justify-end">
                    <Button
                      className="whitespace-no-wrap"
                      style={{
                        backgroundColor: "#34bfa3",
                      }}
                      variant="contained"
                      disabled={!hasNoEmpty()}
                      onClick={() => {
                        const [endHour, endMin] = form.endTime.split(":");
                        const [iniHour, iniMin] = form.initialTime.split(":");
                        const startTimeObject = new Date().setHours(
                          iniHour,
                          iniMin
                        );
                        const endTimeObject = new Date().setHours(
                          endHour,
                          endMin
                        );

                        if (startTimeObject > endTimeObject) {
                          const tomorrow = new Date(
                            form.startDate.getTime() + 24 * 60 * 60 * 1000
                          );

                          if (form.endDate > form.startDate) {
                            let nextToEndData = new Date(
                              form.endDate.getTime() + 24 * 60 * 60 * 1000
                            );
                            let d = form.startDate;
                            while (d <= nextToEndData) {
                              const nextDayDate = new Date(
                                d.getTime() + 24 * 60 * 60 * 1000
                              );
                              createSlot(
                                props.venue._id,
                                d,
                                d,
                                form.initialTime,
                                "23:59"
                              );
                              createSlot(
                                props.venue._id,
                                nextDayDate,
                                nextDayDate,
                                "00:00",
                                form.endTime
                              );

                              d = nextDayDate;
                            }
                          } else {
                            createSlot(
                              props.venue._id,
                              form.startDate,
                              form.endDate,
                              form.initialTime,
                              "23:59"
                            );
                            createSlot(
                              props.venue._id,
                              tomorrow,
                              tomorrow,
                              "00:00",
                              form.endTime
                            );
                          }
                        } else {
                          createSlot(
                            props.venue._id,
                            form.startDate,
                            form.endDate,
                            form.initialTime,
                            form.endTime
                          );
                        }

                        setForm({
                          startDate: new Date(),
                          endDate: new Date(),
                          initialTime: "",
                          endTime: "",
                        });
                        handleClose();
                      }}
                    >
                      {languageStrings["GENERAL.CREATE"]}
                    </Button>
                    <Button
                      className="whitespace-no-wrap ml-32"
                      style={{
                        backgroundColor: "#f4516c",
                      }}
                      variant="contained"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {languageStrings["GENERAL.DENY_BUTTON"]}
                    </Button>
                  </div>
                </>
              )}
            </p>
          </div>
        </Fade>
      </Modal>
      <BigCalendar
        className="flex flex-1 container"
        selectable
        localizer={localizer}
        events={eventsFormatted}
        defaultView={BigCalendar.Views.MONTH}
        startAccessor="start"
        endAccessor="end"
        views={allViews}
        step={60}
        eventPropGetter={eventStyleGetter}
        showMultiDayTimes
        components={{
          toolbar: (props) => {
            return <CalendarHeader {...props} />;
          },
        }}
        onSelectEvent={(event) => {
          dispatch(
            Actions.openDialog({
              children: (
                <AlertDialog
                  onSuccess={() =>
                    dispatch(
                      deleteDeliveryAvailability(event._id, props.venue._id)
                    )
                  }
                  title={
                    languageStrings[
                      "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_TITLE"
                    ]
                  }
                  message={
                    languageStrings[
                      "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_SUBTITLE"
                    ]
                  }
                />
              ),
            })
          );
        }}
      />
    </div>
  );
}

export default DeliverCalendar;
