import { Reservation } from "app/models/Reservation";
import { ReservationActionTypes } from "../redux/actionTypes";
import { axiosInstance as axios} from "app/api/axios";
import * as Actions from "app/store/actions";
import {
  getCreateReservationUrl,
  getEditReservationUrl,
  getUserId,
  RESERVATION
} from "app/api/Endpoint";
import History from "@history";
import { successAlert } from "app/helpers/alerts";
export function setReservationSearchText(event) {
  return {
    type: ReservationActionTypes.SET_RESERVATION_SEARCH_TEXT,
    payload: {
      searchText: event.target.value
    }
  };
}
export const getReservationList = () => {
  return dispatch => {
    dispatch({
      type: ReservationActionTypes.RESERVATION_LIST_START
    });

    let params = { userId: getUserId() }
    axios
      .get(RESERVATION, { params })
      .then(res => {
        if (res.data.statusCode === 200) {
          getReservationListSuccess(dispatch, res.data);
        } else {
          getReservationListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getReservationListFail(dispatch, "There was an error connection");
      });
  };
};

const getReservationListFail = (dispatch, errorMessage) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_LIST_FAIL,
    payload: {
      errorMessage
    }
  });
};

const getReservationListSuccess = (dispatch, data) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_LIST_SUCCESS,
    payload: data
  });
};

export const getReservationDetail = (id: string) => {
  return dispatch => {
    dispatch({
      type: ReservationActionTypes.RESERVATION_DETAIL_START
    });

    axios
      .get(RESERVATION + "/" + id)
      .then(res => {
        if (res.data.statusCode === 200) {
          getReservationDetailSuccess(dispatch, res.data);
        } else {
          getReservationDetailFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getReservationDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getReservationDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_DETAIL_FAIL,
    payload: {
      errorMessage
    }
  });
};

const getReservationDetailSuccess = (dispatch, data) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_DETAIL_SUCCESS,
    payload: data
  });
};

export const createReservation = (reservation: Reservation) => {
  return dispatch => {
    dispatch({
      type: ReservationActionTypes.RESERVATION_CREATE_START
    });

    const url = getCreateReservationUrl(reservation);

    axios
      .get(url)
      .then(res => {
        if (res.data.response === "OK") {
          createReservationSuccess(dispatch, res.data);
        } else {
          createReservationFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        createReservationFail(dispatch, "There was an error connection");
      });
  };
};

const createReservationFail = (dispatch, errorMessage) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_CREATE_FAIL,
    payload: {
      errorMessage
    }
  });
};

const createReservationSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message.description);
  dispatch({
    type: ReservationActionTypes.RESERVATION_CREATE_SUCCESS,
    payload: data
  });
  History.push({
    pathname: "/reservation/"
  });
};

export const editReservation = (reservation: Reservation) => {
  return dispatch => {
    dispatch({
      type: ReservationActionTypes.RESERVATION_EDIT_START
    });

    const url = getEditReservationUrl(reservation);

    axios
      .get(url)
      .then(res => {
        if (res.data.response === "OK") {
          editReservationSuccess(dispatch, res.data);
        } else {
          editReservationFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        editReservationFail(dispatch, "There was an error connection");
      });
  };
};

const editReservationFail = (dispatch, errorMessage) => {
  dispatch({
    type: ReservationActionTypes.RESERVATION_EDIT_FAIL,
    payload: {
      errorMessage
    }
  });
};

const editReservationSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message.description);
  dispatch({
    type: ReservationActionTypes.RESERVATION_EDIT_SUCCESS,
    payload: data
  });

  History.push({
    pathname: "/reservation/"
  });
};

export const confirmDenyReservation = (id: string, action: string) => {
  return dispatch => {
    dispatch({
      type: ReservationActionTypes.CHANGE_RESERVATION_START
    });

    let params = {
      id,
      action
    }
    axios
      .patch(RESERVATION, params)
      .then(res => {
        dispatch(Actions.closeDialog());
        if (res.data.statusCode === 200) {
          ConfirmDenyReservationSuccess(dispatch, res.data);
        } else {
          ConfirmDenyReservationFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        editReservationFail(dispatch, "There was an error connection");
      });
  };
};

const ConfirmDenyReservationFail = (dispatch, errorMessage) => {
  dispatch({
    type: ReservationActionTypes.CHANGE_RESERVATION_FAIL,
    payload: {
      errorMessage
    }
  });
};

const ConfirmDenyReservationSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: ReservationActionTypes.CHANGE_RESERVATION_SUCCESS,
    payload: data
  });

  History.push({
    pathname: "/bookingConfirmation/"
  });
};
