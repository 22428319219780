import React, { useEffect } from "react";
import {
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";

const ExtraCategories = ({
  menu,
  categoryForm,
  handleCategoryChange,
  setCategoryForm,
  setInitialForm,
}) => {
  const { languageStrings } = useLanguageHelper();
  let menuExtras = useSelector(({ banzzu }) => banzzu.menu.menuExtraCategories);
  let { permission } = useSelector(({ banzzu }) => banzzu.auth.user);
  useEffect(() => {
    if (
      (menu && !categoryForm) ||
      (menu && categoryForm && menu._id !== categoryForm._id)
    ) {
      setCategoryForm(menu);
      setInitialForm(menu);
    }
  }, [categoryForm, menu, setCategoryForm, setInitialForm]);

  if (!categoryForm) return null;

  return (
    categoryForm && (
      <>
        <div className="flex" style={{ marginTop: "5rem" }}>
          <CustomInput
            className="mt-8 mb-16 mr-8"
            required
            label={languageStrings["GENERAL.TITLE"]}
            id="title"
            name="title"
            value={categoryForm.title}
            onValueChange={handleCategoryChange}
            errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
            hasError={(error) => error}
            minLength={3}
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-venue-native-simple">
              {languageStrings["EXTRA_CATEGORIES.CATEGORY_TYPES"]}
            </InputLabel>
            <Select
              className="mt-8 mb-16 mr-8"
              native
              fullWidth
              value={categoryForm.type}
              onChange={handleCategoryChange}
              input={
                <OutlinedInput
                  name="type"
                  labelWidth={100}
                  id="outlined-venue-native-simple"
                />
              }
            >
              <option value={"uniSelect"}>Uni Select</option>
              <option value={"multiSelect"}>Multi Select</option>
            </Select>
          </FormControl>

          {permission &&
            permission.hasConnectedCategories &&
            menuExtras.length > 1 && (
              <FormControl fullWidth variant="outlined" className="ml-8">
                <InputLabel htmlFor="outlined-venue-native-simple">
                  {languageStrings["EXTRA_CATEGORIES.CONNECT_CATEGORY"]}
                </InputLabel>
                <Select
                  className="mt-8 mb-16"
                  native
                  fullWidth
                  value={categoryForm.connectedCategory}
                  onChange={handleCategoryChange}
                  input={
                    <OutlinedInput
                      name="connectedCategory"
                      labelWidth={100}
                      id="outlined-venue-native-simple"
                    />
                  }
                >
                  <option value=""></option>
                  {menuExtras.map(
                    (extra) =>
                      extra._id !== menu._id && (
                        <option value={extra._id}>{extra.title}</option>
                      )
                  )}
                </Select>
              </FormControl>
            )}
        </div>
        {categoryForm.type === "multiSelect" && (
          <div className="flex">
            <FormControlLabel
              className="mr-32 min-w-128"
              control={
                <Checkbox
                  defaultChecked={categoryForm.hasMaxMin}
                  value={categoryForm.hasMaxMin}
                  onChange={handleCategoryChange}
                  id="hasMaxMin"
                  name="hasMaxMin"
                  color="primary"
                />
              }
              label={languageStrings["EXTRA_CATEGORIES.ENABLE_QUANTITY"]}
            />

            <CustomInput
              className="mt-8 mb-16 mr-8"
              required
              label={languageStrings["EXTRA_CATEGORIES.MINIMUM_QUANTITY"]}
              id="minQuantity"
              name="minQuantity"
              type={"number"}
              disabled={!categoryForm.hasMaxMin}
              value={categoryForm.minQuantity}
              onValueChange={handleCategoryChange}
              errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
              hasError={(error) => error}
              minLength={1}
            />

            <CustomInput
              className="mt-8 mb-16 mr-8"
              required
              label={languageStrings["EXTRA_CATEGORIES.MAXIMUM_QUANTITY"]}
              disabled={!categoryForm.hasMaxMin}
              id="maxQuantity"
              type={"number"}
              name="maxQuantity"
              value={categoryForm.maxQuantity}
              onValueChange={handleCategoryChange}
              errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
              hasError={(error) => error}
              minLength={1}
            />
          </div>
        )}

        <CustomInput
          className="mt-8 mb-16 mr-8"
          label={languageStrings["GENERAL.DESCRIPTION"]}
          id="description"
          name="description"
          value={categoryForm.description}
          onValueChange={handleCategoryChange}
          hasError={(error) => error}
        />
      </>
    )
  );
};

export default ExtraCategories;
