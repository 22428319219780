import BookingList from "./BookingList";
import BookingDetail from "./BookingDetail";
import CalendarApp from "./calendar/CalendarApp";

export const ReservationConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/bookingConfirmation",
      component: BookingList
    },
    {
      path: "/bookingDetail/:productId",
      component: BookingDetail
    },
    {
      path: "/calendar",
      component: CalendarApp
    }
  ]
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
