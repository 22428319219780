import React from 'react'
import { TableCell, Icon } from "@material-ui/core";

const IconCell = ({ platform }) => {
  return (
    <TableCell>
      {platform == 1 ? (
        <Icon
          className="list-item-icon text-16 flex-shrink-0 mr-16"
          color="action"
        >
          android
         </Icon>
      ) : (
          <Icon
            className="list-item-icon text-16 flex-shrink-0 mr-16"
            color="action"
          >
            phone_iphone
          </Icon>
        )}
    </TableCell>
  )
}

export default IconCell