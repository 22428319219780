import React from "react";
import { axiosInstance as axios} from "app/api/axios";

import history from "@history";

const DirectLogin = (props) => {
  const { accessToken,userId,masterId } = props.match.params;
  if (accessToken&&userId) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("idUser", userId);
    localStorage.setItem("masterId", masterId ? masterId : 0);
    history.replace({
      pathname: "/home",
    });
  }

  return <></>;
};

export default DirectLogin;
