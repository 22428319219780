/* eslint-disable no-undef */
import React, {
  useState,
  useRef,
  useCallback,
  useDispatch,
  useEffect,
} from "react";
const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
} = require("react-google-maps");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

export const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM&v=3.exp&libraries=drawing",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [path, setPath] = useState(props.initialPaths);
  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  useEffect(() => {
    setPath(props.initialPaths);
  }, [props.initialPaths]);
  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly.getPath().getArray();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      setPath(paths);
      props.onPathChanged(paths, props.mapIndex);
      poly.setMap(null);
    },
    [props.mapIndex]
  );

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ["polygon"],
    },
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
      props.onPathChanged(nextPath, props.mapIndex);
      //point(nextPath);
    }
  }, [setPath, props.mapIndex]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <GoogleMap
      mapContainerClassName="App-map"
      defaultCenter={new google.maps.LatLng(props.latitude, props.longitude)}
      center={new google.maps.LatLng(props.latitude, props.longitude)}
      zoom={11}
      version="weekly"
      options={{ streetViewControl: false, mapTypeControl: false }}
    >
      {props.isCreatingNewArea ? (
        <DrawingManager
          drawingMode={state.drawingMode}
          options={options}
          onPolygonComplete={onPolygonComplete}
          editable={props.isCreatingNewArea}
          // Event used when manipulating and adding points
          onMouseUp={onEdit}
          // Event used when dragging the whole Polygon
          onDragEnd={onEdit}
        />
      ) : (
        <Polygon
          ref={polygonRef}
          options={{
            fillColor: `#2196F3`,
            strokeColor: `#2196F3`,
            fillOpacity: 0.5,
            strokeWeight: 2,
          }}
          // Make the Polygon editable / draggable
          editable
          path={path}
          // Event used when manipulating and adding points
          onMouseUp={onEdit}
          // Event used when dragging the whole Polygon
          onDragEnd={onEdit}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      )}
    </GoogleMap>
  );
});
