import { GalleryActionTypes } from "./../redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";

import { GALLERY, getUserId } from "app/api/Endpoint";
import { successAlert } from "app/helpers/alerts";
import { Gallery } from "app/models/Gallery";

export const getGalleryList = () => {
  return (dispatch) => {
    dispatch({
      type: GalleryActionTypes.GALLERY_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(GALLERY, {
        params,
      })
      .then((res) => {
        getGalleryListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getGalleryListFail(dispatch, "There was an error connection");
      });
  };
};

const getGalleryListFail = (dispatch, errorMessage) => {
  dispatch({
    type: GalleryActionTypes.GALLERY_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getGalleryListSuccess = (dispatch, data) => {
  dispatch({
    type: GalleryActionTypes.GALLERY_LIST_SUCCESS,
    payload: data,
  });
};

export const createGallery = (gallery: Gallery) => {
  return (dispatch) => {
    dispatch({
      type: GalleryActionTypes.GALLERY_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in gallery) {
      if (key == "imageFile") {
        formData.append(key, gallery[key], gallery[key].name);
      } else if (key != "id") {
        formData.append(key, gallery[key]);
      }
    }

    axios
      .post(GALLERY, formData)
      .then((res) => {
        createGallerySuccess(dispatch, res.data);
      })
      .catch(() => {
        createGalleryFail(dispatch, "There was an error connection");
      });
  };
};

const createGalleryFail = (dispatch, errorMessage) => {
  dispatch({
    type: GalleryActionTypes.GALLERY_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createGallerySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: GalleryActionTypes.GALLERY_CREATE_SUCCESS,
    payload: data,
  });
  dispatch(getGalleryList());
};

export const deleteGallery = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: GalleryActionTypes.GALLERY_DELETE_START,
    });

    const request = {
      data: {
        galleryId: id,
      },
    };

    axios
      .delete(GALLERY, request)
      .then((res) => {
        deleteGallerySuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteGalleryFail(dispatch, "There was an error connection");
      });
  };
};

const deleteGalleryFail = (dispatch, errorMessage) => {
  dispatch({
    type: GalleryActionTypes.GALLERY_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteGallerySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getGalleryList());
  dispatch({
    type: GalleryActionTypes.GALLERY_DELETE_SUCCESS,
    payload: data,
  });
};
