import { NotificationActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: NotificationState = {
  notificationList: [],
  loading: false,
  searchText: "",
  notificationDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const NotificationReducer = (
  state: NotificationState = INITIAL_STATE,
  action: Action
): NotificationState => {
  switch (action.type) {
    case NotificationActionTypes.NOTIFICATION_LIST_START: {
      return { ...state, loading: true, notificationList: [] };
    }
    case NotificationActionTypes.NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        notificationList: action.payload,
        loading: false,
      };
    }
    case NotificationActionTypes.NOTIFICATION_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case NotificationActionTypes.SET_NOTIFICATION_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case NotificationActionTypes.NOTIFICATION_CREATE_START: {
      return { ...state, loading: true };
    }
    case NotificationActionTypes.NOTIFICATION_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case NotificationActionTypes.NOTIFICATION_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default NotificationReducer;
