import React, { useState, useEffect } from "react";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import { useDispatch } from "react-redux";
import reducer from "app/store/menu/MenuReducer";
import * as Actions from "app/store/actions";
import withReducer from "app/store/withReducer";
import { makeStyles } from "@material-ui/styles";
import history from "@history";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function MenuInformationModal({
  open,
  setOpen,
  closeable,
  updateUserGoals,
  user,
}) {

  const [images] = useState([
    "assets/images/info1.jpg",
    "assets/images/info2.png",
    "assets/images/info3.png",
    "assets/images/info4.png",
  ]);
  const [counter, setCounter] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
  }, []);

  const isMobile = width <= 600;

  const dispatch = useDispatch();
  const handleNextClick = () => {
    if (counter == 3) {
      setOpen(false);
      setCounter(0);
      if (user && user.goals && user.goals.firstView == false) {
        dispatch(updateUserGoals(user._id));
      }
      return;
    }
    if (open)
      setCounter(counter + 1);
  };
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: isMobile ? "0px" : "4rem",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        if (closeable) {
          setOpen(false);
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            className={"flex md:flex-row flex-col bg-white"}
            style={{
              width: isMobile ? "91%" : "70%",
            }}
          >
            <img
              src={images[counter]}
              style={{ width: "auto", height: "auto" }}
            />
            <div
              className={
                "flex justify-center items-center md:w-1/12 w-full cursor-pointer"
              }
              style={{
                backgroundColor: "#fa7a0d",
              }}
              onClick={handleNextClick}
            >
              <ArrowForwardIcon
                style={{
                  fontSize: isMobile ? "40px" : "55px",
                  color: "white",
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default withReducer("BookingReducer", reducer)(MenuInformationModal);
