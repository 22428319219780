import React,{useState,useEffect} from "react";
import {
    FormControlLabel,
    FormGroup,
    Checkbox,
  } from "@material-ui/core";
  import Card from "@material-ui/core/Card";

  import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
  
  const MenuCheckboxes = ({ user,form,onChange,name }) => {
    const { languageStrings } = useLanguageHelper();
    const [list, setList] = useState([])
    useEffect(() => {
      setList(form.menuList)
   }, [form,name])
  return (
    <>
     {list&&list.length > 0 && (
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <React.Fragment>
                  <div className="font-bold">
                    {`${languageStrings["PAYMENT_SETTINGS_PAGE.MENU_AVAILABILITY"]}`}
                  </div>
                  <FormGroup row>
                    {list.map((item) => (
                      <FormControlLabel
                        key={item.title}
                        control={
                          <Checkbox
                            disabled={!form[name]}
                            checked={name=="hasDelivery"?item.availableOnDelivery:name=="hasCollection"?item.availableOnCollection:item.availableOnOrderFromTable}
                            onChange={onChange}
                            value={item._id}
                            name={name=="hasDelivery"?"delivery":name=="hasCollection"?"collection":"orderFromTable"}
                          />
                        }
                        label={item.title}
                      />
                    ))}
                  </FormGroup>
                </React.Fragment>
              </Card>
            )}
    </>
  );
};

export default MenuCheckboxes;
