import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index"
import history from './../../../@history';
import DeleteSubscription from "./components/DeleteSubscription"
import CancelSubscription from "./components/CancelSubscription"
const AccountPage = () => {
    const dispatch = useDispatch();
    const { languageStrings } = useLanguageHelper();
    const user = useSelector(({ banzzu }) => banzzu.auth.user);
    useEffect(() => {
        if (user && (user.accessLevel != "1" && user.accessLevel != "2")) {
            history.push("/home")
        }
    }, [user])
    return (
        <div className={"flex flex-col w-full h-screen items-center px-28"}>
            {user && user.accessLevel == "1" && <DeleteSubscription title={languageStrings["ACCOUNT.YOUR_CURRENT_SUBSCRIPTION"]} />}
            {user && user.accessLevel == "2" && <CancelSubscription title={languageStrings["ACCOUNT.CURRENT_SUBSCRIPTION_IS_ADVANCED"]} />}
        </div>
    );
};

export default AccountPage;
