import React from "react";

import { Button } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export interface headerProps {
  id?: string;
  title?: string;
}
export interface ExportFilesProps {
  data: Array<String>;
  header: Array<headerProps>;
  fileName: String;
  onShow?: () => void;
}

export const ExportModal = (props: ExportFilesProps) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div>
      <>
        <Button
          onClick={props.onShow}
          className={"whitespace-no-wrap"}
          variant="contained"
        >
          <span>{languageStrings["GENERAL.EXPORT"]}</span>
        </Button>
      </>
    </div>
  );
};
