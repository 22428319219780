import React, { useState, useEffect } from "react";
import { Icon, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { FuseUtils } from "@fuse";
import {
  createMenuExtraCatItems,
  editMenuExtraCatItems,
  deleteMenuExtraCatItems,
  sortExtraItems,
} from "app/store/menu/MenuActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";

const ExtraCategoriesTable = ({
  items,
  connectedCategoryItem,
  category,
  connectedCategoryTitle,
  onAddClick,
}) => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "image",
      title: "",
      editComponent: (props) => {
        let { image: itemImage, _id } = props.rowData;

        return (
          <div className="flex justify-center sm:justify-start flex-wrap">
            <input
              accept="image/jpg, image/jpeg, image/png, image/gif"
              className="hidden"
              id={`button-file-${_id}`}
              type="file"
              onChange={(e) => {
                FuseUtils.lighterImage(e.target.files[0]).then((file) => {
                  const reader = new FileReader();
                  reader.readAsBinaryString(file);

                  reader.onload = () => {
                    props.onChange({
                      image: `data:${file.type};base64,${btoa(
                        reader.result.toString()
                      )}`,
                      imageFile: file,
                      delete: false,
                    });
                  };

                  reader.onerror = function () {
                    console.log("error on load image");
                  };
                });
              }}
            />

            <label
              htmlFor={`button-file-${_id}`}
              className="flex items-center justify-center relative w-48 h-48 rounded-4 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>

            {
              <div className="flex items-center w-48 h-48 justify-center relative rounded-4 overflow-hidden shadow-1 ml-6">
                <img
                  className="max-w-none w-auto h-full"
                  alt="item"
                  src={
                    itemImage
                      ? itemImage.image
                        ? itemImage.image
                        : typeof itemImage == "string"
                        ? itemImage
                        : "assets/images/default-image-placeholder.png"
                      : "assets/images/default-image-placeholder.png"
                  }
                />
                {itemImage && (
                  <div
                    className="mb-2 cursor-pointer absolute bottom-0"
                    style={{ height: 22, width: 24 }}
                    onClick={() => {
                      props.onChange({ image: null, delete: true });
                    }}
                  >
                    <Icon
                      onClick={() => {}}
                      className="rounded-4 shadow-5 mb-2 text-2xl "
                      style={{
                        color: "#f4516c",
                        backgroundColor: "#fff",
                        objectFit: "cover",
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      clear
                    </Icon>
                  </div>
                )}
              </div>
            }
          </div>
        );
      },
      render: (rowData) => (
        <>
          {rowData.image && (
            <div className={"w-40 h-40"}>
              <img
                src={formatImageUrl(rowData.image)}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: languageStrings["GENERAL.TITLE"],
      field: "title",
      focus: true,
    },
    {
      title: languageStrings["EXTRA_CATEGORIES.EXTRA_PRICE"],
      field: "price",
      // type: "numeric",
    },
    {
      title: languageStrings["EXTRA_CATEGORIES.SELECTED"],
      field: "selected",
      lookup: { true: languageStrings["GENERAL.YES"], false: "No" },
      render: (rowData) => (
        <input checked={rowData.selected} disabled type="checkbox" />
      ),
    },
  ];
  const [state, seState] = useState({ columns });
  useEffect(() => {
    if (items.length > 0) {
      let positions = items.reduce((arr, data) => {
        return [...arr, parseInt(data.position)];
      }, []);

      setMaxPosition(Math.max(...positions));
      setMinPosition(Math.min(...positions));
    }
  }, [items]);

  let [maxPosition, setMaxPosition] = useState(0);
  let [minPosition, setMinPosition] = useState(0);
  let ref;

  return (
    <div
      ref={(r?: any) => {
        ref = r;
      }}
    >
      {items && (
        <MaterialTable
          title={
            !connectedCategoryTitle
              ? languageStrings["GENERAL.ITEMS"]
              : connectedCategoryTitle
          }
          columns={state.columns}
          localization={{
            pagination: {
              labelDisplayedRows:
                languageStrings["MATERIAL_TABLE.DISPLAYED_ROWS"],
              labelRowsSelect: languageStrings["MATERIAL_TABLE.ROWS"],
            },
            toolbar: {
              nRowsSelected: languageStrings["MATERIAL_TABLE.ROW_SELECTED"],
            },
            header: {
              actions: languageStrings["GENERAL.ACTIONS"],
            },
            body: {
              emptyDataSourceMessage:
                languageStrings["MATERIAL_TABLE.NO_RECORDS"],
              filterRow: {
                filterTooltip: "Filter",
              },
              editRow: {
                deleteText: languageStrings["MATERIAL_TABLE.DELETE"],
              },
            },
          }}
          data={items.map((item) => {
            return {
              ...item,
              price: item.price
                ? parseFloat(item.price).toFixed(2)
                : item.price,
                image:item.image || ""
            };
          })}
          options={{
            search: false,
            pageSize: 5,
            doubleHorizontalScroll: true,
          }}
          icons={{
            Add: () => {
              return (
                <div
                  onClick={() => {
                    onAddClick(ref);
                  }}
                >
                  <Typography
                    color="secondary"
                    className="normal-case flex items-center sm:mb-12"
                  >
                    <Icon color="secondary" className="text-32 mr-8">
                      add_circle_outline
                    </Icon>
                    {languageStrings["EXTRA_CATEGORIES.ADD"]}
                  </Typography>
                </div>
              );
            },
            Delete: () => (
              <Icon color="error" className="text-22">
                delete_outline
              </Icon>
            ),
            Edit: () => (
              <Icon color="secondary" className="text-22">
                edit
              </Icon>
            ),
            Check: () => <Icon className="text-22 text-green">check</Icon>,
            Clear: () => (
              <div>
                <Icon color="error" className="text-22">
                  close
                </Icon>
              </div>
            ),
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                let request = {
                  ...newData,
                  extra_category: category,
                  connectedCategoryItem: connectedCategoryItem
                    ? connectedCategoryItem
                    : undefined,
                  image: newData.image ? newData.image.image : null,
                  imageFile: newData.image ? newData.image.imageFile : null,
                };

                dispatch(createMenuExtraCatItems(request));
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                let request = {
                  ...newData,
                  extra_category: category,
                  image: newData.image.image,
                  imageFile: newData.image.imageFile,
                  deleteImage: newData.image.delete,
                };

                dispatch(editMenuExtraCatItems(request));
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                dispatch(deleteMenuExtraCatItems(oldData._id, category));
                resolve();
              }),
          }}
          actions={[
            (rowData) => ({
              icon: () => (
                <Icon color="primary" className="text-14">
                  arrow_upward
                </Icon>
              ),
              tooltip: "Move Up",
              onClick: (event, rowData) => {
                const { _id } = rowData;
                let sortDetails = {
                  id: _id,
                  categoryId: category,
                  action: "decrease",
                  connectedCategoryItem,
                };
                dispatch(sortExtraItems(sortDetails));
              },
              disabled: minPosition === parseInt(rowData.position),
            }),
            (rowData) => ({
              icon: () => (
                <Icon color="primary" className="text-14">
                  arrow_downward
                </Icon>
              ),
              tooltip: "Move Down",
              onClick: (event, rowData) => {
                const { _id } = rowData;
                let sortDetails = {
                  id: _id,
                  categoryId: category,
                  action: "increase",
                  connectedCategoryItem,
                };
                dispatch(sortExtraItems(sortDetails));
              },
              disabled: maxPosition === parseInt(rowData.position),
            }),
          ]}
        />
      )}
    </div>
  );
};

export default ExtraCategoriesTable;
