import React from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper";

export const DescribeCard = ({ ...rest }) => {
  const { data = [], title = "" } = rest || {};

  const StatusCard = () => {
    const { languageStrings } = useLanguageHelper();
    return (
      <div
        class="box-border md:box-content rounded-lg my-4 ..."
        style={{
          backgroundColor: "rgba(239, 239, 239)",
          paddingBottom: 30,
          paddingRight: 10,
        }}
      >
        <h4 class="pl-10 pt-10 pb-5">{languageStrings[title]}</h4>

        {data.map((i) => (
          <ul class="pb-5">
            <li>{languageStrings[i]}</li>
          </ul>
        ))}
      </div>
    );
  };

  return StatusCard();
};
