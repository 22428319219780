import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { MainConfig } from "app/MainConfig";
import { LoginPageConfig } from "app/main/login/LoginPageConfig";
import { RegisterPageConfig } from "app/main/register/RegisterPageConfig";
import { VenueListConfig } from "app/main/venue/VenueListConfig";
import { UserListConfig } from "app/main/user/UserListConfig";
import { StaffListConfig } from "app/main/staff/StaffListConfig";
import { OfferListConfig } from "app/main/offer/OfferListConfig";
import { WhatsOnListConfig } from "app/main/whatson/WhatsOnListConfig";
import { GalleryConfig } from "app/main/gallery/GalleryConfig";
import { MenuConfig } from "app/main/menu/MenuConfig";
import { LoyaltyConfig } from "app/main/loyalty/LoyaltyConfig";
import { ReservationConfig } from "app/main/reservation/ReservationConfig";
import { NoficationListConfig } from "app/main/notification/NoficationListConfig";
import { DrawListConfig } from "app/main/darws/DrawsListConfig";
import { VoucherListConfig } from "app/main/voucher/VoucherListConfig";
import { OrderConfig } from "app/main/orders/OrderConfig";
import { OrderFromTableConfig } from "app/main/order-from-table/OrderFromTableConfig";
import { BrandingConfig } from "app/main/branding/BrandingConfig";
import { PricingConfig } from "app/main/pricing/PricingConfig";
import { DirectLoginConfig } from "app/main/directLogin/DirectLoginConfig";
import { PaymentConfig } from "app/main/payment/PaymentConfig";
import { AnalyticsConfig } from "app/main/analytics/AnalyticsConfig";
import { ContactSupportConfig } from "app/main/contactSupport/ContactSupportConfig";
import { ForgotPasswordPageConfig } from "app/main/forgotPassword/ForgotPasswordPageConfig"
import { MerchandisingConfig } from "app/main/merchandising/MerchandisingConfig"
import { AccountConfig } from "app/main/account/AccountConfig"

const routeConfigs = [
  LoginPageConfig,
  RegisterPageConfig,
  MainConfig,
  VenueListConfig,
  UserListConfig,
  OfferListConfig,
  WhatsOnListConfig,
  GalleryConfig,
  MenuConfig,
  LoyaltyConfig,
  ReservationConfig,
  PricingConfig,
  NoficationListConfig,
  DrawListConfig,
  VoucherListConfig,
  OrderConfig,
  OrderFromTableConfig,
  DirectLoginConfig,
  BrandingConfig,
  PaymentConfig,
  AnalyticsConfig,
  ContactSupportConfig,
  StaffListConfig,
  ForgotPasswordPageConfig,
  MerchandisingConfig,
  AccountConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/login" />,
  },
];
export default routes;
