import { VoucherActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: VoucherState = {
  voucherList: [],
  voucherDetail: {},
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const VoucherReducer = (
  state: VoucherState = INITIAL_STATE,
  action: Action
): VoucherState => {
  switch (action.type) {
    case VoucherActionTypes.VOUCHER_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case VoucherActionTypes.VOUCHER_LIST_SUCCESS: {
      return {
        ...state,
        voucherList: action.payload,
        loading: false,
      };
    }
    case VoucherActionTypes.VOUCHER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VoucherActionTypes.SORT_VOUCHER_LIST_START: {
      return {
        ...state,
        loading: true,
        voucherList: action.payload,
      };
    }
    case VoucherActionTypes.SORT_VOUCHER_LIST_SUCCESS: {
      return {
        ...state,
        voucherList: action.payload.content,
        loading: false,
      };
    }
    case VoucherActionTypes.SORT_VOUCHER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VoucherActionTypes.VOUCHER_DETAIL_START: {
      return { ...state, loading: true, voucherDetail: {} };
    }
    case VoucherActionTypes.VOUCHER_DETAIL_SUCCESS: {
      return {
        ...state,
        voucherDetail: action.payload,
        loading: false,
      };
    }
    case VoucherActionTypes.VOUCHER_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VoucherActionTypes.VOUCHER_CREATE_START: {
      return { ...state, loading: true };
    }
    case VoucherActionTypes.VOUCHER_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VoucherActionTypes.VOUCHER_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VoucherActionTypes.VOUCHER_EDIT_START: {
      return { ...state, loading: true };
    }
    case VoucherActionTypes.VOUCHER_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VoucherActionTypes.VOUCHER_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default VoucherReducer;
