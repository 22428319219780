import React, { useState } from "react";
import Cards from "react-credit-cards";
import TextField from "@material-ui/core/TextField";
import "react-credit-cards/es/styles-compiled.css";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  Select,
  Input,
  Button,
  Typography,
  Modal,
  Backdrop,
} from "@material-ui/core";
import clsx from "clsx";

import CloseButton from "app/main/common/CloseButton";
import { saveCard } from "app/store/payment/PaymentActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/useLanguageHelper";

interface StyleProps {
  monthError?: boolean;
  yearError?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "& .Mui-focused": {
      color: "#ff8330",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ff8330",
    },
  }),
  rootMonth: (props) => ({
    margin: "0.4rem",
    "& .Mui-focused": {
      color: "#ff8330",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.monthError ? "red" : "#ff8330",
    },
  }),
  rootYear: (props) => ({
    margin: "0.4rem",
    "& .Mui-focused": {
      color: "#ff8330",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.yearError ? "red" : "#ff8330",
    },
  }),
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const AddCardModal = ({ onCloseModal, showModal }) => {
  const { languageStrings } = useLanguageHelper();
  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    month: "01",
    year: "20",
  });

  const [focus, setFocus] = useState("");
  const [monthError, setMonthError] = useState(true);
  const [numberError, setnumberError] = useState(true);
  const [yearError, setYearError] = useState(true);
  const [cvcError, setCvcError] = useState(true);
  const [nameError, setNameError] = useState(true);

  const styleProps: StyleProps = { monthError, yearError };
  const Classes = useStyles(styleProps);
  const dispatch = useDispatch();

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    if (e.target.name === "number") {
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, 16);
      if (e.target.value.length == 16) {
        setnumberError(false);
      } else {
        setnumberError(true);
      }
    }

    if (e.target.name === "cvc") {
      e.target.value = e.target.value.toString().slice(0, 3);

      if (e.target.value.length !== 3) {
        setCvcError(true);
      } else {
        setCvcError(false);
      }
    }

    if (e.target.name === "month") {
      setMonthError(false);
    }

    if (e.target.name === "year") {
      setYearError(false);
    }

    if (e.target.name === "name") {
      if (e.target.value.length > 5) {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }

    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const canBeSubmited = () => {
    return !numberError && !cvcError && !nameError;
  };

  const saveCardDetails = () => {
    const cardDetails = {
      cardNumber: state.number,
      expMonth: state.month,
      expYear: state.year,
      cvc: state.cvc,
    };

    dispatch(saveCard(cardDetails));
    resetValues();
    closeModal();
  };

  const closeModal = () => {
    resetValues();
    onCloseModal();
  };

  const resetValues = () => {
    setFocus("name");
    setMonthError(true);
    setnumberError(true);
    setYearError(true);
    setCvcError(true);
    setNameError(true);
    setState({
      cvc: "",
      expiry: "",
      name: "",
      number: "",
      month: "01",
      year: "20",
    });
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={showModal}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="mx-6 bg-white flex flex-col rounded-8 items-center justify-center p-24 relative">
        <CloseButton onClick={closeModal} color={"rgb(105, 105, 105)"} />
        <Cards
          cvc={state.cvc}
          expiry={state.expiry}
          focused={focus}
          name={state.name}
          number={state.number}
        />
        <div className="flex flex-col items-center w-full mt-6 mb-12">
          <TextField
            label={<Typography>{languageStrings["GENERAL.NAME"]}</Typography>}
            name="name"
            className={clsx(Classes.root, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
          />

          <TextField
            label={<Typography>{languageStrings["GENERAL.NUMBER"]}</Typography>}
            name="number"
            type="number"
            value={state.number}
            className={clsx(Classes.root, Classes.number, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            inputProps={{ maxLength: 16 }}
          />

          <div className="flex justify-between w-full">
            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"month"}>
                {"MM"}
              </InputLabel>
              <Select
                name="month"
                native
                value={state.month}
                onChange={handleInputChange}
                input={<Input id="month" />}
              >
                {[
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                ].map((month) => {
                  return (
                    <option className="text-center h-8" value={month}>
                      {month}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"year"}>
                {"YY"}
              </InputLabel>
              <Select
                name="year"
                native
                value={state.year}
                onChange={handleInputChange}
                input={<Input id="year" />}
              >
                {[...Array(21).keys()].map((year) => {
                  return (
                    <option className="text-center h-8" value={20 + year}>
                      {20 + year}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label={<Typography>CVC</Typography>}
              name="cvc"
              type="number"
              className={clsx(Classes.root, "w-1/4")}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <Button
          className={
            "flex items-center justify-center self-center w-full text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
          }
          style={{
            borderRadius: "25px",
            fontSize: "14px",
            backgroundColor: !canBeSubmited() ? "lightgray" : "#f5aa00",
            color: !canBeSubmited() ? "black" : "white",
          }}
          disabled={!canBeSubmited()}
          onClick={saveCardDetails}
        >
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </div>
    </Modal>
  );
};

export default AddCardModal;
