import firebase from 'firebase';

export const initializeFirebase = () => {

    let firebaseConfig = {
        apiKey: "AIzaSyDw-IsrQQ7_kL1SqYaF_weE7CmT6K4s9pM",
        authDomain: "banzzu.firebaseapp.com",
        databaseURL: "https://banzzu.firebaseio.com",
        projectId: "banzzu",
        storageBucket: "banzzu.appspot.com",
        messagingSenderId: "573257472764",
        appId: "1:573257472764:web:05f7c0c396b33cd85ed579",
        measurementId: "G-2TFKTXJFRK"
    }

    firebase.initializeApp(firebaseConfig);

}

export const askForPermissionToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();

        return token;
    } catch (error) {
        console.error(error);
    }
}