import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import GetAppIcon from "@material-ui/icons/GetApp";
import BuildIcon from "@material-ui/icons/Build";
import Card from "@material-ui/core/Card";
import {
  Modal,
  Fade,
  Backdrop,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { updatePermaLink, downloadedQr } from 'app/store/user/UserActions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const QrCode = ({ user, permaLinks }) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();

  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(user.settings.permalink);
  const [links, setLinks] = useState([]);
  const [error, setError] = useState("")

  useEffect(() => {
    user.settings.permalink && setLink(user.settings.permalink);
  }, [user]);
  useEffect(() => {
    if (!open) {
      setLink(user.settings.permalink)
    }
  }, [open]);
  useEffect(() => {
    if (user.settings.permalink && permaLinks && permaLinks.length > 0) {
      const newLinks = permaLinks.filter(
        (link) => link != user.settings.permalink
      );
      setLinks(newLinks);
    }
  }, [permaLinks, user]);

  useEffect(() => {
    if (permaLinks && permaLinks.length > 0) {
      const exist = links.find((item) => item == link);
      if (exist) {
        setError(languageStrings["HOME_PAGE.URL_TAKEN"])
      }
      else {
        setError("")
      }
    }
  }, [link])
  const downloadQR = (title, id) => {
    const canvas = document.getElementById(id);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${title}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch(downloadedQr())
    }
  };
  const handleChange = (event) => {
    setLink(event.target.value);
  };
  const handleSave = () => {
    if (error) {
      return
    }
    else {
      dispatch(updatePermaLink(user._id, link))
      setOpen(false);
    }
  };

  const webUrl = process.env.REACT_APP_ENVIRONMENT === "production"
    ? `https://web.banzzu.com/${user.settings.permalink}`
    : process.env.REACT_APP_ENVIRONMENT === "staging" ?
      `https://webstaging.banzzu.com/${user.settings.permalink}` :
      `http://localhost:3001/${user.settings.permalink}`

  return (
    <>
      {user.settings.permalink && (
        <Card className={"flex md:flex-row flex-col md:p-28 p-10"}>
          <div className={"flex flex-col items-center"}>
            <h2 className={"mb-8"}>{languageStrings["HOME_PAGE.YOUR_CODE"]}</h2>
            <QRCode id={user._id} value={webUrl} />
          </div>
          <div
            className={
              "flex flex-col md:items-start items-center md:pl-40 pl-4 md:mt-0 mt-10"
            }
          >
            <h2>{languageStrings["HOME_PAGE.RESTAURANT"]}</h2>
            <h2 className={"md:mt-8 mt-2"}>{user.title}</h2>
            <a
              className={"mt-2 cursor-pointer"}
              style={{ color: user.settings.typography }}
              onClick={() => window.open(webUrl, "_blank")}
            >{process.env.REACT_APP_ENVIRONMENT === "production" ? `web.banzzu.com/${link}` : `webstaging.banzzu.com/${link}`}</a>
            <div className={"flex flex-row items-center md:mt-48 mt-8"}>
              <button
                class="flex flex-row items-center bg-blue hover:bg-blue text-white font-bold py-2 px-6 rounded"
                style={{
                  backgroundColor: user.settings.typography,
                }}
                onClick={() => {
                  downloadQR(user.settings.permalink, user._id);
                }}
              >
                <GetAppIcon
                  style={{ color: "white", fontSize: 18, margin: 2 }}
                />
                {languageStrings["HOME_PAGE.DOWNLOAD"]}
              </button>
              {user.permission.isMaster && (
                <button
                  class="flex flex-row items-center bg-blue hover:bg-blue text-white font-bold py-2 px-6 rounded ml-5"
                  style={{
                    backgroundColor: user.settings.typography,
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <BuildIcon
                    style={{ color: "white", fontSize: 18, margin: 2 }}
                  />
                  {languageStrings["HOME_PAGE.CHANGE_URL"]}
                </button>
              )}
              {/* <button class="flex flex-row items-center bg-blue hover:bg-blue text-white font-bold py-2 px-4 rounded ml-10"
            style={{width:"10.5rem",backgroundColor:user.settings.typography}}
            >
            <PrintIcon style={{color:"white",fontSize:18,margin:4}}/>
             {languageStrings["HOME_PAGE.PRINT"]}
            </button> */}
            </div>
          </div>
        </Card>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className="pb-32">
              {languageStrings["HOME_PAGE.CHANGE_URL"]}
            </h2>
            <p id="transition-modal-description">
              <TextField
                className="mt-8 mb-16 mr-8"
                required
                id="link"
                name="link"
                value={link}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={error}
                helperText={error ? error : ""}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </p>
            <Button
              className="whitespace-no-wrap"
              variant="contained"
              onClick={() => {
                handleSave();
              }}
              disabled={error || link.length < 3}
            >
              {languageStrings["GENERAL.SAVE"]}
            </Button>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default QrCode;
