import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  InputLabel,
  Typography,
  makeStyles,
  Select,
  OutlinedInput,
  FormControl,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import ListTable from "app/main/common/List/ListTable";

import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/voucher/VouchersReducer";
import { FuseUtils } from "@fuse";
import { useDebounce } from "@fuse/hooks";
import {
  createVoucher,
  editVoucher,
  getVoucherDetail,
} from "app/store/voucher/VouchersActions";
import LoadingOverlay from "react-loading-overlay";
import clsx from "clsx";
import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";

const headerRows = {
  data: [
    {
      id: "name",
      align: "left",
      disablePadding: false,
      label: "Name",
      sort: true,
      type: "text",
    },
    {
      id: "points",
      align: "left",
      disablePadding: false,
      label: "Voucher",
      sort: true,
      type: "text",
    },
  ],
  checkbox: false,
  clickable: false,
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
function VoucherDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(({ VouchersReducer }) => VouchersReducer.loading);
  let voucher = useSelector(({ banzzu }) => banzzu.voucher.voucherDetail);
  const { languageStrings } = useLanguageHelper();

  let userPermissions = useSelector(
    ({ banzzu }) => banzzu.auth.user.permission
  );

  const params = props.match.params;
  const { productId } = params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getVoucherDetail(productId));
    }
  }, [dispatch, productId]);

  if (productId === "new") {
    voucher = {
      id: "0",
      title: "",
      description: "",
      number: "",
      passcode: "",
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
    passcode: false,
  };

  const errorInitial = {
    title: "",
    description: "",
    number: "0",
    passcode: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if ((voucher && !form) || (voucher && form && voucher._id !== form._id)) {
      let newVoucher = {
        ...voucher,
        isMaster: userPermissions && userPermissions.isMaster,
      };
      setForm(newVoucher);
      setInitialForm(newVoucher);
    }
  }, [form, voucher, setForm, setInitialForm, userPermissions]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.description !== "" && form.number !== "";
  }

  function hasNoErrors() {
    return !errorName() && !errorDescription() && !errorImage();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return {
          ...prevState,
          description: languageStrings["ERRORS.MINIMUM_10_CHAR"],
        };
      });
    }
  }, 500);

  function errorImage() {
    return !form.image;
  }

  function errorDescription() {
    return dirty.description && form.description.length < 10;
  }

  function goBack() {
    History.push({
      pathname: `/voucherList`,
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["VOUCHER_DETAIL_PAGE.BUTTON_BACK"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["VOUCHER_DETAIL_PAGE.NEW_VOUCHER"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["VOUCHER_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form.id !== "0"
                      ? dispatch(editVoucher(form))
                      : dispatch(createVoucher(form));
                  }}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className={
                userPermissions && userPermissions.isMaster
                  ? "h-64 normal-case"
                  : "hidden"
              }
              label={languageStrings["GENERAL.DETAIL"]}
            />

            <Tab
              className={productId === "new" ? "hidden" : "h-64 normal-case"}
              label={languageStrings["GENERAL.USER_LIST"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <input
                    accept="image/jpg, image/jpeg, image/png, image/gif"
                    className="hidden"
                    id="button-file"
                    type="file"
                    onChange={handleUploadChange}
                  />
                  <div className="flex justify-center sm:justify-start flex-wrap">
                    <label
                      htmlFor="button-file"
                      className={
                        "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 " +
                        clsx(
                          voucher.onlyRead ? classes.enable : classes.disabled
                        )
                      }
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          alt="whatson"
                          src={
                            form.image
                              ? formatImageUrl(form.image)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                      </div>
                    }
                    {errorImage() && (
                      <div className="text-red">
                        {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
                      </div>
                    )}
                  </div>
                  <div className="flex">
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      error={errorName()}
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title}
                      onChange={handleTitleChange}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={errorName() ? error.title : ""}
                    />
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-voucher-native-simple">
                        {languageStrings["VOUCHER_DETAIL_PAGE.VOUCHER_TYPE"]}
                      </InputLabel>
                      <Select
                        className="flex mt-8 mb-16 min-w-128  required"
                        native
                        required
                        value={form.number}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="number"
                            labelWidth={100}
                            id="outlined-voucher-native-simple"
                          />
                        }
                      >
                        <option></option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </Select>
                    </FormControl>
                  </div>
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorDescription()}
                    required
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    multiline
                    rows={8}
                    id="description"
                    name="description"
                    value={form.description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 4000,
                    }}
                    helperText={errorDescription() ? error.description : ""}
                  />
                </div>
              )}
              {tabValue === 1 && (
                <ListTable
                  headerRows={headerRows}
                  data={voucher.users}
                  searchText={""}
                />
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("VouchersReducer", reducer)(VoucherDetail);
