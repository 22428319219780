import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext"

export const useLanguageHelper = () => {
  const { changeLocale, messages } = useContext(LanguageContext);

  const changeLanguage = (locale,changeOnFrontend) => changeLocale(locale,changeOnFrontend);

  return {
    languageStrings: messages,
    changeLanguage
  };
};
