import React, { FC } from "react";
import LocationOn from "@material-ui/icons/LocationOn";
import Restaurant from "@material-ui/icons/Restaurant";
import RoomService from "@material-ui/icons/RoomService";
import Info from "@material-ui/icons/Info";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";

interface Props {
  active: boolean;
  completed: boolean;
  icon: any;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const StepIcon: FC<Props> = ({ active, completed, icon }) => {
  const classes = useStyles({});

  const icons = {
    1: <Restaurant />,
    2: <LocationOn />,
    3: <RoomService />,
    4: <Info />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
};

export default StepIcon;
