import React from 'react'
import {TableCell} from "@material-ui/core";
import { formatDateTime } from '../../../../helpers/validators';

const  DateTimeCell = ({date})=> {
    return (
        <TableCell>
            {formatDateTime(date)}
        </TableCell>
    )
}

export default DateTimeCell