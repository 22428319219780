import React, { useState } from "react";
import { TextField } from "@material-ui/core";

import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

interface Props {
  handleChange: (e) => void;
  form: any;
}

const RestaurantAdditionalDetails = ({ handleChange, form }: Props) => {
  const { languageStrings } = useLanguageHelper();

  const [webError, setWebError] = useState(false);
  const [twitterError, setTwitterError] = useState(false);
  const [instaError, setInstaError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);

  const changeUrl = (e) => {
    const value = e.target.value;
    const error = value && value.length && !FuseUtils.validURL(value);

    switch (e.target.name) {
      case "website": {
        if (error) {
          setWebError(true);
        } else {
          setWebError(false);
        }
        break;
      }
      case "twitter": {
        if (error) {
          setTwitterError(true);
        } else {
          setTwitterError(false);
        }
        break;
      }
      case "instagram": {
        if (error) {
          setInstaError(true);
        } else {
          setInstaError(false);
        }
        break;
      }
      case "facebook": {
        if (error) {
          setFacebookError(true);
        } else {
          setFacebookError(false);
        }
        break;
      }
    }
    handleChange(e);
  };

  return (
    <>
      <div className="flex">
        <TextField
          className="mt-8 mb-16 mx-8"
          label={languageStrings["GENERAL.WEBSITE"]}
          id="website"
          name="website"
          value={form.website}
          onChange={changeUrl}
          variant="outlined"
          fullWidth
          error={webError}
          helperText={webError ? languageStrings["ERRORS.INVALID_URL"] : ""}
        />
        <TextField
          className="mt-8 mb-16 mx-8"
          label={"Twitter"}
          id="twitter"
          name="twitter"
          value={form.twitter}
          onChange={changeUrl}
          variant="outlined"
          fullWidth
          error={twitterError}
          helperText={twitterError ? languageStrings["ERRORS.INVALID_URL"] : ""}
        />
      </div>
      <div className="flex">
        <TextField
          className="mt-8 mb-16 mx-8"
          label={"Instagram"}
          id="instagram"
          name="instagram"
          value={form.instagram}
          onChange={changeUrl}
          variant="outlined"
          fullWidth
          error={instaError}
          helperText={instaError ? languageStrings["ERRORS.INVALID_URL"] : ""}
        />
        <TextField
          className="mt-8 mb-16 mx-8"
          label={"Facebook"}
          id="facebook"
          name="facebook"
          value={form.facebook}
          onChange={changeUrl}
          variant="outlined"
          fullWidth
          error={facebookError}
          helperText={
            facebookError ? languageStrings["ERRORS.INVALID_URL"] : ""
          }
        />
      </div>
    </>
  );
};

export default RestaurantAdditionalDetails;
