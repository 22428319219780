import { UsersActionTypes } from '../redux/actionTypes';

export interface UserState {
  userAppList: any[];
  totalUsers: number;
  loading: boolean;
  searchText: string;
  androidUsers: any;
  iosUsers: any;
  userRegistered: any[];
  permaLinks: any[];
  userDetails: any;
}

const INITIAL_STATE: UserState = {
  userAppList: [],
  totalUsers: 0,
  loading: false,
  searchText: '',
  androidUsers: '',
  iosUsers: '',
  userRegistered: [],
  permaLinks: [],
  userDetails: {},
};

interface Action {
  payload: any;
  type: string;
}

const UserReducer = (
  state: UserState = INITIAL_STATE,
  action: Action,
): UserState => {
  switch (action.type) {
    case UsersActionTypes.USER_LIST_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.USER_LIST_SUCCESS: {
      return {
        ...state,
        userAppList: action.payload.users,
        totalUsers: action.payload.totalUsers,
        loading: false,
      };
    }
    case UsersActionTypes.USER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.USER_DETAILS_START: {
      return { ...state, loading: true, userDetails: {} };
    }
    case UsersActionTypes.USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    }
    case UsersActionTypes.USER_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.SET_BRANDING_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.SET_BRANDING_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.SET_BRANDING_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.USER_PLATFORM_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.USER_PLATFORM_SUCCESS: {
      return {
        ...state,
        androidUsers: action.payload.data.android,
        iosUsers: action.payload.data.ios,
        userRegistered: action.payload.data.usersRegistered,
        loading: false,
      };
    }
    case UsersActionTypes.USER_PLATFORM_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.SET_USER_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.PERMA_LINK_LIST_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.PERMA_LINK_LIST_SUCCESS: {
      return {
        ...state,
        permaLinks: action.payload,
        loading: false,
      };
    }
    case UsersActionTypes.PERMA_LINK_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.UPDATE_LINK_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.UPDATE_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.UPDATE_LINK_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UsersActionTypes.ADD_LAST_INTERACTION_START: {
      return { ...state, loading: true };
    }
    case UsersActionTypes.ADD_LAST_INTERACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UsersActionTypes.ADD_LAST_INTERACTION_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default UserReducer;
