export const userListHeaders = [
  { id: "platform", title: "Plataforma" },
  { id: "name", title: "Nombre" },
  { id: "email", title: "Email" },
  { id: "points", title: "Puntos" },
  { id: "createdAt", title: "Fecha" },
  { id: "createdTime", title: "Hora" },
];
export const orderListHeaders = [
  { id: "orderId", title: "Order Id" },
  { id: "restaurant", title: "Restaurant" },
  { id: "orderType", title: "Order Type" },
  { id: "totalPrice", title: "Total Price" },
];
