const navigationConfig = [
  {
    id: "Menu",
    title: "SIDEBAR_MENUE.TITLE",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "Home",
        title: "SIDEBAR_MENUE.HOME",
        type: "item",
        icon: "whatshot",
        url: "/home",
      },
      {
        id: "Analytics",
        title: "SIDEBAR_MENUE.ANALYTICS",
        type: "item",
        icon: "whatshot",
        url: "/analytics",
        group: "analytics",
      },
      {
        id: "configuration",
        title: "SIDEBAR_MENUE.CONFIGURATION",
        type: "item",
        icon: "settings",
        url: "",
        group: "configuration",
      },
      {
        id: "Venues",
        title: "SIDEBAR_MENUE.VENUE",
        type: "collapse",
        icon: "store",
        group: "venue",
        children: [
          {
            id: "VenueList",
            title: "SIDEBAR_MENUE.VENUE_LIST",
            type: "item",
            url: "/venueList",
            group: "venue",
            exact: true,
          },
          {
            id: "CreateVenue",
            title: "SIDEBAR_MENUE.VENUE_DETAILS",
            type: "item",
            url: "/create-venue",
            group: "venue",
          },
        ],
      },
      {
        id: "Whatson",
        title: "SIDEBAR_MENUE.WHATSON",
        type: "collapse",
        icon: "whatshot",
        group: "whatson",
        children: [
          {
            id: "WhatsonList",
            title: "SIDEBAR_MENUE.WHATSON_lIST",
            type: "item",
            url: "/whatsonList",
            group: "whatson",
          },
          {
            id: "CreateWhatson",
            title: "SIDEBAR_MENUE.CREATE_WHATSON",
            type: "item",
            url: "/whatsonDetail/new",
            group: "whatson",
          },
        ],
      },

      {
        id: "Gallery",
        title: "SIDEBAR_MENUE.GALLERY",
        type: "item",
        icon: "collections",
        url: "/gallery",
        group: "gallery",
      },
      {
        id: "Notification",
        title: "SIDEBAR_MENUE.NOTIFICATIONS",
        type: "collapse",
        icon: "notifications_active",
        group: "notification",
        children: [
          {
            id: "WhatsonList",
            title: "SIDEBAR_MENUE.NOTIFICATIONS_PUSH",
            type: "item",
            url: "/notificationList",
            group: "notification",
          },
          {
            id: "CreateNotification",
            title: "SIDEBAR_MENUE.NOTIFICATIONS_SEND",
            type: "item",
            url: "/notificationDetail/new",
            group: "notification",
          },
        ],
      },
      {
        id: "menu",
        title: "SIDEBAR_MENUE.MENUE_TITLE",
        type: "item",
        icon: "restaurant_menu",
        group: "menu",
        url: "/menuList",
      },
      {
        id: "Offers",
        title: "SIDEBAR_MENUE.OFFERS",
        type: "collapse",
        icon: "local_offer",
        group: "offers",
        children: [
          {
            id: "OfferList",
            title: "SIDEBAR_MENUE.OFFER_LIST",
            type: "item",
            url: "/offerList",
            group: "offers",
          },
          {
            id: "CreateOffer",
            title: "SIDEBAR_MENUE.CREATE_OFFER",
            type: "item",
            url: "/offerDetail/new",
            group: "offers",
          },
        ],
      },
      {
        id: "Voucher",
        title: "SIDEBAR_MENUE.VOUCHER",
        type: "collapse",
        icon: "store",
        group: "voucher",
        children: [
          {
            id: "VoucherList",
            title: "SIDEBAR_MENUE.VOUCHER_LIST",
            type: "item",
            url: "/voucherList",
            group: "voucher",
            exact: true,
          },
          {
            id: "CreateVoucher",
            title: "SIDEBAR_MENUE.VOUCHER_CREATE_VOUCHER",
            type: "item",
            url: "/voucherDetail/new",
            group: "voucher",
          },
        ],
      },
      {
        id: "draw",
        title: "SIDEBAR_MENUE.DRAWS",
        type: "collapse",
        group: "draw",
        icon: "local_offer",
        children: [
          {
            id: "DrawList",
            title: "SIDEBAR_MENUE.DRAWS_LIST",
            group: "draw",
            type: "item",
            url: "/drawList",
          },
          {
            id: "Create Draw",
            title: "SIDEBAR_MENUE.DRAWS_CREATE",
            group: "draw",
            type: "item",
            url: "/drawDetail/new",
          },
        ],
      },
      {
        id: "Reservation",
        title: "SIDEBAR_MENUE.RESERVATION",
        type: "collapse",
        icon: "local_dining",
        group: "reservation",
        children: [
          {
            id: "bookingConfirmation",
            title: "SIDEBAR_MENUE.RESERVATION_CONFIRM",
            type: "item",
            url: "/bookingConfirmation",
            group: "reservation",
          },
          {
            id: "Booking",
            title: "SIDEBAR_MENUE.RESERVATION_BOOKING",
            type: "item",
            url: "/calendar",
            group: "reservation",
          } /*,
          {
            id: "CreateReservation",
            title: "Create Reservation",
            type: "item",
            url: "/bookingDetail/new"
          }*/,
        ],
      },
      {
        id: "Loyalty",
        title: "SIDEBAR_MENUE.LOYALTY_PROGRAM",
        type: "collapse",
        icon: "card_giftcard",
        group: "rewards",
        children: [
          {
            id: "TransactionList",
            title: "SIDEBAR_MENUE.TRANSACTION",
            type: "item",
            url: "/transactionList",
            group: "rewards",
          },
          {
            id: "RewardList",
            title: "SIDEBAR_MENUE.REWARDS_LIST",
            type: "item",
            url: "/rewardList",
            group: "rewards",
          },
          {
            id: "CreateReward",
            title: "SIDEBAR_MENUE.REWARDS_CREATE",
            type: "item",
            url: "/rewardDetail/new",
            group: "rewards",
          },
        ],
      },
      {
        id: "Branding",
        title: "SIDEBAR_MENUE.BRANDING",
        type: "item",
        icon: "brush",
        url: "/branding",
        group: "branding",
      },
      {
        id: "User",
        title: "SIDEBAR_MENUE.USER_LIST",
        type: "item",
        icon: "people",
        url: "/userList",
        group: "user",
      },
      {
        id: "staff",
        title: "SIDEBAR_MENUE.STAFF",
        type: "collapse",
        icon: "people",
        url: "/staff-list",
        group: "staff",
        children: [
          {
            id: "staffList",
            title: "SIDEBAR_MENUE.STAFF_LIST",
            type: "item",
            url: "/staff-list",
            group: "staff",
          },
          {
            id: "createStaff",
            title: "SIDEBAR_MENUE.CREATE_STAFF",
            type: "item",
            url: "/staff-detail/new",
            group: "staff",
          },
        ],
      },
      {
        id: "OrdersList",
        title: "SIDEBAR_MENUE.ORDERS",
        type: "item",
        icon: "shopping_cart",
        url: "/orders",
        group: "orders",
      },
      {
        id: "Order-from-table",
        title: "SIDEBAR_MENUE.ORDER_FROM_TABLE",
        type: "collapse",
        icon: "whatshot",
        group: "orderFromTable",
        children: [
          {
            id: "OrderFromTable",
            title: "SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST",
            type: "item",
            url: "/orderFromTable",
            group: "orderFromTable",
          },
        ],
      },
      // {
      //   id: "Profile",
      //   title: "SIDEBAR_MENUE.PROFILE",
      //   type: "item",
      //   icon: "collections",
      //   url: "/profile",
      //   group: "profile",
      // },
      {
        id: "Payment",
        title: "SIDEBAR_MENUE.ONLINE_ORDERS",
        type: "item",
        icon: "payment",
        url: "/payment-settings",
        group: "payment",
      },
      {
        id: "merchandising",
        title: "SIDEBAR_MENUE.MERCHANDISING",
        type: "item",
        icon: "storefront",
        url: "/merchandising",
        group: "merchandising",
      },
      /*,
    {
      id: "Profile",
      title: "Profile",
      type: "collapse",
      icon: "person",
      children: [
        {
          id: "EditProfile",
          title: "Edit Profile",
          type: "item",
          url: "/editprofile"
        },
        {
          id: "OpeningHours",
          title: "Opening Hours",
          type: "item",
          url: "/openinghours"
        }
      ]
    }*/
    ],
  },
];

export default navigationConfig;
