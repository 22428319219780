import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { LanguageContext } from "./LanguageContext";

export const translate = () => {
  return WrappedComponent => {
    const _translate = props => (
      <LanguageContext.Consumer>
        {language => (
          <WrappedComponent {...props} languageStrings={language.messages} />
        )}
      </LanguageContext.Consumer>
    );

    return hoistNonReactStatics(_translate, WrappedComponent);
  };
};
