import { axiosInstance as axios } from "app/api/axios";

import { SUBSCRIPTION, CARDS, getUserId } from "app/api/Endpoint";
import { PaymentActionTypes } from "../redux/actionTypes";
import { Card } from "app/models/Card";
import { errorAlert } from "app/helpers/alerts";
import History from "@history";

export const createSubscription = (
  cardDetails: Card,
  venueQuantity: number
) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.CREATE_SUBSCRIPTION_START,
    });
    let params = {
      ...cardDetails,
      venueQuantity,
      userId: getUserId(),
    };

    axios
      .post(SUBSCRIPTION, params)
      .then((res) => {
        createSubscriptionSuccess(dispatch);
      })
      .catch((error) => {
        createSubscriptionFail(dispatch, "There was an error connection");
      });
  };
};

const createSubscriptionFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createSubscriptionSuccess = (dispatch) => {
  dispatch({
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
  });
  History.push("/home");
  setTimeout(() => dispatch(resetPaymentStatus()), 15000);
};

export const resetPaymentStatus = () => ({
  type: PaymentActionTypes.RESET_SUBSCRIPTION_SUCCESS,
});

export const getCardList = () => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.GET_CARD_LIST_START,
    });

    axios
      .get(CARDS)
      .then((res) => {
        getCardListSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        getCardListFail(dispatch, "There was an error connection");
      });
  };
};

const getCardListFail = (dispatch, errorMessage) => {
  dispatch({
    type: PaymentActionTypes.GET_CARD_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getCardListSuccess = (dispatch, data) => {
  dispatch({
    type: PaymentActionTypes.GET_CARD_LIST_SUCCESS,
    payload: data,
  });
};

export const saveCard = (carDetails: Card) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.SAVE_CARD_DETAILS_START,
    });

    axios
      .post(CARDS, carDetails)
      .then((res) => {
        saveCardSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        saveCardFail(dispatch, error.response.data.message);
      });
  };
};

const saveCardFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: PaymentActionTypes.SAVE_CARD_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const saveCardSuccess = (dispatch, data) => {
  dispatch(getCardList());
  dispatch({
    type: PaymentActionTypes.SAVE_CARD_DETAILS_SUCCESS,
    payload: data,
  });
};

export const deleteCard = (cardNumber: string) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.DELETE_CARD_DETAILS_START,
    });

    axios
      .delete(CARDS, { data: { cardNumber } })
      .then((res) => {
        deleteCardSuccess(dispatch);
      })
      .catch((error) => {
        deleteCardFail(dispatch, error);
      });
  };
};

const deleteCardFail = (dispatch, errorMessage) => {
  dispatch({
    type: PaymentActionTypes.DELETE_CARD_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteCardSuccess = (dispatch) => {
  dispatch(getCardList());
  dispatch({
    type: PaymentActionTypes.DELETE_CARD_DETAILS_SUCCESS,
  });
};

export const setDefaultCard = (cardNumber: string) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.SET_DEFAULT_CARD_START,
    });

    axios
      .put(CARDS, { cardNumber })
      .then((res) => {
        setDefaultCardSuccess(dispatch);
      })
      .catch((error) => {
        setDefaultCardFail(dispatch, error);
      });
  };
};

const setDefaultCardFail = (dispatch, errorMessage) => {
  dispatch({
    type: PaymentActionTypes.SET_DEFAULT_CARD_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const setDefaultCardSuccess = (dispatch) => {
  dispatch(getCardList());
  dispatch({
    type: PaymentActionTypes.SET_DEFAULT_CARD_SUCCESS,
  });
};
