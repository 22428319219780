import React from "react";
import { TableRow } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";

import ImageCell from "../../List/components/ImageCell";
import CheckBoxCell from "../../List/components/CheckBoxCell";
import SuccessAndDangerCell from "../../List/components/SuccessAndDangerCell";
import PriceReduceCell from "../../List/components/PriceReduceCell";
import IconCell from "../../List/components/IconCell";
import IconCtaCell from "../../List/components/IconCtaCell";
import DateTimeCell from "../../List/components/DateTimeCell";
import MenuTypeCell from "../../List/components/MenuTypeCell";

const ListRow = ({
  isSelected,
  headerRows,
  handleCheck,
  handleClick,
  n,
  index,
}) => {
  return (
    <Draggable draggableId={n._id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          className={
            headerRows.clickable
              ? `h-64 cursor-pointer ${
                  snapshot.isDragging ? "bg-gray-200" : ""
                }`
              : "h-64"
          }
          hover={headerRows.clickable}
          role="checkbox"
          aria-checked={isSelected}
          tabIndex={-1}
          key={n._id}
          selected={isSelected}
          onClick={(event) => headerRows.clickable && handleClick(n)}
        >
          {headerRows.checkbox && (
            <CheckBoxCell
              isSelected={isSelected}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => handleCheck(event, n._id)}
            />
          )}
          {headerRows.data.map((obj) => {
            switch (obj.type) {
              case "image":
                return (
                  <ImageCell key={n._id} src={n.image} alt={n.title} />
                );

              case "text":
                return (
                  <SuccessAndDangerCell key={n._id} n={n} obj={obj} />
                );

              case "date":
                return <DateTimeCell key={n._id} date={n[obj.id]} />;

              case "reducedPrice":
                return <PriceReduceCell key={n._id} n={n} />;

              case "icon":
                return <IconCell key={n._id} platform={n.platform} />;

              case "bool":
                return <MenuTypeCell key={n._id} n={n.isPdf} />;

              case "iconCta":
                  return <IconCtaCell key={n._id} icon={obj.icon} onClick={()=>obj.onClick(n._id)} />;

              default:
                return null;
            }
          })}
        </TableRow>
      )}
    </Draggable>
  );
};

export default ListRow;
