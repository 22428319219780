import React from "react";
import { Card, Typography, makeStyles, Button } from "@material-ui/core";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "2rem",
  },
  description: {
    color: "#656565",
  },
}));

export default function ItemsCard({
  title,
  image,
  description,
  onClick,
  width,
  height,
}) {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  return (
    <Card
      className={[
        "mx-40 mt-28 p-5 flex flex-col items-center justify-around shadow-lg md:w-1/4 w-full",
        classes.container,
      ].join(" ")}
      style={{ borderRadius: "2rem" }}
    >
      <img
        src={image}
        width={width ? width : "200"}
        height={height ? height : "200"}
        className="object-cover"
      />
      <div className="flex flex-col">
        <Typography
          variant="h5"
          className="font-bold text-center"
          style={{ color: "#f5aa00" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={["text-center mt-5  px-4", classes.description].join(" ")}
        >
          {description}
        </Typography>
      </div>
      <Button
        variant="contained"
        className="w-220 mx-auto mt-16"
        aria-label="SEND"
        type="submit"
        style={{ backgroundColor: "#faad63", color: "white" }}
        onClick={onClick}
      >
        {languageStrings["MERCHANDISE.WANT_IT"]}
      </Button>
    </Card>
  );
}
