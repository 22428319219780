import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createMenuItemExtraCategories,
  editMenuItemExtraCategories,
} from "app/store/menu/MenuActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import { useForm, useDebounce } from "@fuse/hooks";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Icon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const ExtraCategoriesModal = ({ menuItem, onClose, category }) => {
  const classes = useStyles();

  const { languageStrings } = useLanguageHelper();
  let menuExtras = useSelector(({ banzzu }) => banzzu.menu.menuExtraCategories);
  let { permission } = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();

  let initialCategory;

  if (category._id === "new") {
    initialCategory = {
      menuItem: menuItem._id,
      title: "",
      type: "uniSelect",
      minQuantity: "",
      maxQuantity: "",
      description: "",
      hasMaxMin: true,
      connectedCategory: "",
    };
  } else {
    initialCategory = { ...category, menuItem: menuItem._id };
  }

  const {
    form: categoryForm,
    handleChange: handleCategoryChange,
    setForm: setCategoryForm,
  } = useForm(initialCategory);

  if (!categoryForm) return null;

  return (
    categoryForm && (
      <div className="w-auto">
        <div className="flex">
          <CustomInput
            className="mt-8 mb-16 mr-8 min-w-64"
            required
            label={languageStrings["GENERAL.TITLE"]}
            id="title"
            name="title"
            value={categoryForm.title}
            onValueChange={handleCategoryChange}
            errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
            hasError={(error) => error}
            minLength={3}
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-venue-native-simple">
              {languageStrings["EXTRA_CATEGORIES.CATEGORY_TYPES"]}
            </InputLabel>
            <Select
              className="mt-8 mb-16 mr-8"
              native
              fullWidth
              value={categoryForm.type}
              onChange={handleCategoryChange}
              input={
                <OutlinedInput
                  name="type"
                  labelWidth={100}
                  id="outlined-venue-native-simple"
                />
              }
            >
              <option value={"uniSelect"}>Uni Select</option>
              <option value={"multiSelect"}>Multi Select</option>
            </Select>
          </FormControl>

          {permission &&
            permission.hasConnectedCategories &&
            menuItem.extraCategories &&
            menuItem.extraCategories.length > 1 && (
              <FormControl fullWidth variant="outlined" className="ml-8">
                <InputLabel htmlFor="outlined-venue-native-simple">
                  {languageStrings["EXTRA_CATEGORIES.CONNECT_CATEGORY"]}
                </InputLabel>
                <Select
                  className="mt-8 mb-16"
                  native
                  fullWidth
                  value={categoryForm.connectedCategory}
                  onChange={handleCategoryChange}
                  input={
                    <OutlinedInput
                      name="connectedCategory"
                      labelWidth={100}
                      id="outlined-venue-native-simple"
                    />
                  }
                >
                  <option value=""></option>
                  {menuItem.extraCategories.map(
                    (extra) =>
                      extra._id !== categoryForm._id && (
                        <option value={extra._id}>{extra.title}</option>
                      )
                  )}
                </Select>
              </FormControl>
            )}
        </div>
        {categoryForm.type === "multiSelect" && (
          <div className="flex">
            <FormControlLabel
              className="mr-32 min-w-128"
              control={
                <Checkbox
                  defaultChecked={categoryForm.hasMaxMin}
                  value={categoryForm.hasMaxMin}
                  onChange={handleCategoryChange}
                  id="hasMaxMin"
                  name="hasMaxMin"
                  color="primary"
                />
              }
              label={languageStrings["EXTRA_CATEGORIES.ENABLE_QUANTITY"]}
            />

            <CustomInput
              className="mt-8 mb-16 mr-8"
              required
              label={languageStrings["EXTRA_CATEGORIES.MINIMUM_QUANTITY"]}
              id="minQuantity"
              name="minQuantity"
              type={"number"}
              disabled={!categoryForm.hasMaxMin}
              value={categoryForm.minQuantity}
              onValueChange={handleCategoryChange}
              inputProps={{ min: "0" }}
              errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
              hasError={(error) => error}
              minLength={1}
            />

            <CustomInput
              className="mt-8 mb-16 mr-8"
              required
              label={languageStrings["EXTRA_CATEGORIES.MAXIMUM_QUANTITY"]}
              disabled={!categoryForm.hasMaxMin}
              id="maxQuantity"
              type={"number"}
              name="maxQuantity"
              inputProps={{ min: "0" }}
              value={categoryForm.maxQuantity}
              onValueChange={handleCategoryChange}
              errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
              hasError={(error) => error}
              minLength={1}
            />
          </div>
        )}

        <CustomInput
          className="mt-8 mb-16 mr-8"
          fullWidth
          label={languageStrings["GENERAL.DESCRIPTION"]}
          id="description"
          name="description"
          value={categoryForm.description}
          onValueChange={handleCategoryChange}
          hasError={(error) => error}
        />
        <Button
          className="whitespace-no-wrap mt-16 mb-16"
          variant="contained"
          //disabled={!canBeSubmitted()}
          onClick={() => {
            if (category._id === "new") {
              dispatch(
                createMenuItemExtraCategories(categoryForm, menuItem.menuId)
              );
            } else {
              dispatch(
                editMenuItemExtraCategories(
                  categoryForm,
                  menuItem._id,
                  menuItem.menuId
                )
              );
            }

            onClose();
          }}
        >
          <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
            save
          </Icon>
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </div>
    )
  );
};

export default ExtraCategoriesModal;
