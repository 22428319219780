import React, { Component, useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect, useSelector } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
import { userListHeaders } from "./components/data";
import { getUserList, setUserSearchText } from "app/store/user/UserActions";
import { translate, useLanguageHelper } from "app/helpers/LanguageHelper/index";
import UpgradeModal from "../common/UpgradeModal";
import history from "@history";
import { ExportFiles } from "../common/FileExport";
import moment from "moment";
import { debounce } from "app/helpers/utilsHelper";

function UserList(props) {
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState({});

  useEffect(() => {
    if (user.accessLevel == "1") {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, order]);
  const getUsers = () => {
    props.getUserList({
      searchText: props.searchText,
      page,
      limit: rowsPerPage,
      order,
    });
  };
  const setSearchText = (ev) => {
    props.setUserSearchText(ev);
    const debouncedFunc = debounce(getUsers, 500);
    debouncedFunc();
  };
  const handleClick = (item) => {
    history.push("/userDetail/" + item._id);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const { languageStrings } = useLanguageHelper();

  const headerRows = {
    data: [
      {
        id: "platform",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.PLATFORM"],
        sort: true,
        type: "icon",
      },
      {
        id: ["firstName", "lastName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "text",
      },
      {
        id: "totalPoints",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.POINTS"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: ["firstName", "lastName", "email"],
  };

  const handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    const isEqual =
      (Array.isArray(order.id) && order.id[0] === property[0]) ||
      order.id === property;

    if (isEqual && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  };

  const exportFiles = () => {
    const usersData = [];
    props.userList.forEach((user) => {
      const usersObj = {};

      const name = `${user.firstName || ""} ${user.lastName || ""}`;
      const platform = user && user.platform === 1 ? "android" : "iphone";

      usersObj["platform"] = platform;
      usersObj["name"] = name;
      usersObj["email"] = user.email;
      usersObj["points"] = user.totalPoints;
      usersObj["createdAt"] = moment(user.createdAt).format("L");
      usersObj["createdTime"] = moment(user.createdAt).format("LT");

      usersData.push(usersObj);
    });
    return usersData;
  };
  return (
    <>
      <UpgradeModal
        open={open}
        setOpen={() => {
          history.goBack();
          setOpen(false);
        }}
      />
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div
          style={{
            flex: 1,
            filter: open ? "blur(3px)" : "none",
          }}
        >
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["USER_LIST_PAGE.TITLE"]}
                  setSearchText={setSearchText}
                  searchType={"user"}
                />

                {/* <ExportFiles
                  fileName={"user-list"}
                  data={exportFiles()}
                  header={userListHeaders}
                /> */}
              </div>
            }
            content={
              <ListTablePaginated
                handleClick={handleClick}
                headerRows={headerRows}
                data={props.userList}
                totalData={props.totalUsers}
                searchText={props.searchText}
                order={order}
                handleRequestSort={handleRequestSort}
                page={page}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.user.loading,
    userList: state.banzzu.user.userAppList,
    totalUsers: state.banzzu.user.totalUsers,
    searchText: state.banzzu.user.searchText,
  };
};
const mapDispatchToProps = {
  getUserList,
  setUserSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(UserList));
