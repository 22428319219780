import { getUserId } from "app/api/Endpoint";
import { axiosInstance as axios } from "app/api/axios";

import { OffersActionTypes } from "../redux/actionTypes";
import {
  getOfferDetailUrl,
  OFFER,
  OFFERS_LIST_SORT
} from "app/api/Endpoint";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import { Offer } from "app/models/Offer";
import _ from "@lodash";

export function setOfferSearchText(event) {
  return {
    type: OffersActionTypes.SET_OFFERS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getOfferList = () => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.OFFERS_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(OFFER, {
        params,
      })
      .then((res) => {
        getOfferListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getOfferListFail(dispatch, "There was an error connection");
      });
  };
};

const getOfferListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OffersActionTypes.OFFERS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOfferListSuccess = (dispatch, data) => {
  dispatch({
    type: OffersActionTypes.OFFERS_LIST_SUCCESS,
    payload: data,
  });
};

export const getOfferDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.OFFERS_DETAIL_START,
    });

    const url = getOfferDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getOfferDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getOfferDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getOfferDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: OffersActionTypes.OFFERS_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/offerList`,
  });
};

const getOfferDetailSuccess = (dispatch, data) => {
  dispatch({
    type: OffersActionTypes.OFFERS_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createOffer = (offer: Offer) => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.OFFERS_CREATE_START,
    });

    let formData = new FormData()
    formData.append("userId", getUserId())

    for (let key in offer) {
      if (key == "imageFile") {
        formData.append(key, offer[key], offer[key].name);
      } else if (key != "id") {
        formData.append(key, offer[key])
      }
    }

    axios
      .post(OFFER, formData)
      .then((res) => {
        const data = res.data;
        createOfferSuccess(dispatch, data);
      })
      .catch(() => {
        createOfferFail(dispatch, "There was an error connection");
      });
  };
};

const createOfferFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: OffersActionTypes.OFFERS_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createOfferSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: OffersActionTypes.OFFERS_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/offerList/",
  });
};

export const editOffer = (offer: Offer) => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.OFFERS_EDIT_START,
    });

    let formData = new FormData()
    formData.append("userId", getUserId())

    for (let key in offer) {
      if (key == "_id") {
        formData.append("offerId", offer[key])
      } else if (key == "imageFile") {
        formData.append(key, offer[key], offer[key].name);
      } else {
        formData.append(key, offer[key])
      }
    }

    axios
      .put(OFFER, formData)
      .then((res) => {
        const data = res.data;
        editOfferSuccess(dispatch, data);
      })
      .catch(() => {
        editOfferFail(dispatch, "There was an error connection");
      });
  };
};

const editOfferFail = (dispatch, errorMessage) => {
  dispatch({
    type: OffersActionTypes.OFFERS_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editOfferSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: OffersActionTypes.OFFERS_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/offerList/",
  });
};

export const deleteOffer = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.OFFERS_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(OFFER, { data: { offerId: id } })
        .then((res) => {
          deleteOfferSuccess(dispatch, res.data);
        })
        .catch(() => {
          deleteOfferFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteOfferFail = (dispatch, errorMessage) => {
  dispatch({
    type: OffersActionTypes.OFFERS_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteOfferSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getOfferList());
  dispatch({
    type: OffersActionTypes.OFFERS_DELETE_SUCCESS,
    payload: data,
  });
};

export const sortOfferList = (sortOrder, sortedOffers) => {
  return (dispatch) => {
    dispatch({
      type: OffersActionTypes.SORT_OFFER_LIST_START,
      payload: sortedOffers,
    });

    const request = {
      ...sortOrder,
      userId: getUserId()
    };

    axios
      .post(OFFERS_LIST_SORT, request)
      .then((res) => {
        sortOfferListSuccess(dispatch);
      })
      .catch((err) => {
        sortOfferListFail(dispatch, err.response.data.message);
      });
  };
};

const sortOfferListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OffersActionTypes.SORT_OFFER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortOfferListSuccess = (dispatch) => {
  dispatch({
    type: OffersActionTypes.SORT_OFFER_LIST_SUCCESS,
  });
};
