import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded, FuseUtils } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { createNotification } from "app/store/notification/NotificationActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";

function NotificationDetail(props) {
  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.notification.loading);
  let notification = useSelector(
    ({ banzzu }) => banzzu.notification.notificationDetail
  );
  const { languageStrings } = useLanguageHelper();

  const { productId } = props.match.params;

  useEffect(() => {
    if (productId !== "new") {
    }
  }, [dispatch, productId]);

  if (productId === "new") {
    notification = {
      id: "0",
      title: "",
      description: "",
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if (
      (notification && !form) ||
      (notification && form && notification.id !== form.id)
    ) {
      setForm(notification);
      setInitialForm(notification);
    }
  }, [form, notification, setInitialForm, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.description !== "";
  }

  function hasNoErrors() {
    return !errorDescription() && !errorTitle();
  }

  function errorTitle() {
    return dirty.title && form.title.length < 3;
  }

  function errorDescription() {
    return dirty.description && form.description.length < 10;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return { ...prevState, title: "Minimum size 3 characters" };
      });
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return { ...prevState, description: "Minimum size 10 characters" };
      });
    }
  }, 500);

  function goBack() {
    History.push({
      pathname: `/notificationList`,
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["NOTIFICATION_DETAIL_PAGE.BUTTON_BACK"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["NOTIFICATION_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    dispatch(createNotification(form));
                  }}
                >
                  {languageStrings["GENERAL.SEND"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <div className="flex">
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      error={errorTitle()}
                      required
                      label={languageStrings["GENERAL.NAME"]}
                      id="title"
                      name="title"
                      value={form.title}
                      onChange={handleTitleChange}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={errorTitle() ? error.title : ""}
                    />
                  </div>

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorDescription()}
                    required
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    multiline
                    rows={8}
                    id="description"
                    name="description"
                    value={form.description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 4000,
                    }}
                    helperText={errorDescription() ? error.description : ""}
                  />
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("NotificationReducer", reducer)(NotificationDetail);
