import React, { useState, useEffect } from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Typography, makeStyles, Card } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import clsx from "clsx"
import QRCode from "qrcode.react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { useForm } from "@fuse/hooks";
import { FuseAnimate } from "@fuse";
import OptionsCard from "app/main/common/payment/MerchantOptionsCard"
import PaymentCard from "app/main/common/payment/MerchantPaymentCard";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"
import { getCardList } from "app/store/payment/PaymentActions";
import QRCard from "./components/QRCard"
import StickerQuantity from "./components/StickerQuantity"
import AddressCard from "./components/AddressCard"
import { purchaseProduct } from "app/store/merchant/MerchantActions"
import { saveCard } from "app/store/payment/PaymentActions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const useStyles = makeStyles((theme) => ({
  header: {
    height: 400,
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      theme.palette.primary.main +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

export default function MerchantProductDetail() {
  const classes = useStyles();

  const cards = useSelector(({ banzzu }) => banzzu.payment.cards);
  const loading = useSelector(({ banzzu }) => banzzu.merchant.loading)
  const venueLoading = useSelector(({ banzzu }) => banzzu.venue.loading)
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();

  const { languageStrings } = useLanguageHelper();

  const { form, handleChange, setForm } = useForm({
    color: "#f5aa00",
    title: languageStrings["MERCHANDISE.ITEM.QR_TEXT"]
  });

  const [quantityHidden, setQuantityHidden] = useState(true);
  const [configHidden, setConfigHidden] = useState(false);
  const [previous, setPrevious] = useState("quantity");
  const [cardsHidden, setCardsHidden] = useState(true);
  const [addCardHidden, setAddCardHidden] = useState(true);
  const [addressCardHidden, setAddressCardHidden] = useState(true)
  const [selectedCard, setSelectedCard] = useState("");
  const [quantity, setQuantity] = useState(15)
  const [price, setPrice] = useState(12);

  useEffect(() => {
    dispatch(getCardList());
  }, []);

  useEffect(() => {
    if (cards.length) {
      cards.map((card) => {
        if (card.default) {
          setSelectedCard(card.id);
        }
      });
    }
  }, [cards]);

  const changeQuantity = (data) => {
    setQuantityHidden(true);
    setAddressCardHidden(false)
  };

  const onAddressCardSubmit = (data) => {
    setForm(prevForm => ({ ...prevForm, ...data }))
    setPrevious("quantity");
    setAddressCardHidden(true)
    if (cards.length) {
      setCardsHidden(false);
    } else {
      setAddCardHidden(false);
    }
  }

  const addressCardPrevHandler = () => {
    setQuantityHidden(false);
    setAddressCardHidden(true)
  }

  const decreaseQuantity = () => {
    if (quantity > 15) {
      setQuantity(quantity - 15);
      setPrice(parseFloat((price - 12).toFixed(2)));
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 15);
    setPrice(parseFloat((price + 12).toFixed(2)));
  };

  const onCardSelected = (stripe) => {
    dispatch(purchaseProduct({ ...form, payment_method: selectedCard,stripe, quantity }));
  };

  const onBackPayment = () => {
    if (previous === "cards") {
      setCardsHidden(false);
      setAddCardHidden(true);
      setPrevious("quantity");
    }

    if (previous === "quantity") {
      setQuantityHidden(false);
      setAddCardHidden(true);
      setCardsHidden(true);
    }
  };

  const createCard = (cardDetails) => {
    dispatch(saveCard(cardDetails));
    setAddCardHidden(true)
    setCardsHidden(false)
  };

  const addNewCard = () => {
    setPrevious("cards");
    setAddCardHidden(false);
    setCardsHidden(true);
  };

  const onBackCardSelection = () => {
    setAddressCardHidden(false);
    setCardsHidden(true);
  };

  const onConfigSubmit = (data) => {
    setForm(prevForm => ({ ...prevForm, ...data }))
    setConfigHidden(true)
    setQuantityHidden(false);
    setPrevious("quantity");
  }

  const stickerQuantityPrevHandler = () => {
    setConfigHidden(false)
    setQuantityHidden(true);
  }

  return (
    <LoadingOverlay
      active={loading || venueLoading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <div className={clsx(classes.header, "flex")}>
        <div className="p-24 w-full max-w-2xl mx-auto">
          <div className="text-center my-32 mx-24">
            <FuseAnimate
              animation="transition.slideUpIn"
              duration={400}
              delay={100}
            >
              <>
                <Typography variant="h2" color="inherit" className="font-light">
                  {languageStrings["MERCHANDISE.ITEM.QR_TITLE"]}
                </Typography>
              </>
            </FuseAnimate>
          </div>
        </div>
      </div>

      <div className="-mt-192">
        <div className="w-full flex justify-center flex-wrap">

          <Card
            className="mx-24 mt-12 p-24 py-64 flex flex-col items-center justify-around shadow-lg md:w-1/3 w-full"
            style={{
              borderRadius: "2rem",
              background: `url(assets/images/backgrounds/table.jpg) no-repeat center`,
              backgroundSize: "cover"
            }}
          >
            <Card className="bg-white p-5 flex flex-col items-center w-224 h-224">
              <Typography
                variant="subtitle1"
                className="mb-12 text-center font-bold"
                style={{ color: form.color, height: "6rem" }}
              >
                {form.title}
              </Typography>
              <QRCode id={user._id} value={"table"} />
              <img className="mt-12" src="assets/images/logos/logo-hori.png" width="50" />
            </Card>
          </Card>
          {stripePromise && 
            <Elements stripe={stripePromise}>
              {!addCardHidden && (
                  <PaymentCard
                    onBack={onBackPayment}
                    onSubmit={createCard}
                  />
              )}

              {!cardsHidden && (
                <OptionsCard
                  title={"Elige metodo de pago"}
                  addTitle={"Añadir tarjeta"}
                  innerCards={
                    cards && cards.length > 0
                      ? cards.map((card) => {
                        return {
                          ...card,
                          _id: card.id,
                          title: `**** **** **** ${card.last4}`,
                        };
                      })
                      : []
                  }
                  onBack={onBackCardSelection}
                  addClickHandler={addNewCard}
                  selectedCard={selectedCard}
                  onSelect={(id) => setSelectedCard(id)}
                  onSubmit={onCardSelected}
                />
              )}
            </Elements>
          }

          {!configHidden && (
            <QRCard
              setForm={setForm}
              form={form}
              handleChange={handleChange}
              onSubmit={onConfigSubmit}
              title={languageStrings["MERCHANDISE.ITEM.QR_CUSTOMIZE"]}
            />
          )}

          {!quantityHidden && (
            <StickerQuantity
              onBack={stickerQuantityPrevHandler}
              quantity={quantity}
              price={price}
              onSubmit={changeQuantity}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              title={languageStrings["MERCHANDISE.ITEM.QR_QUANTITY_TITLE"]}
            />
          )}

          {!addressCardHidden && (
            <AddressCard onSubmit={onAddressCardSubmit} onBack={addressCardPrevHandler} />
          )}

        </div>
      </div>
    </LoadingOverlay>
  )
}
