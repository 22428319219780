export default {
  "ALLERGENE.CELERY": "Celery",
  "ALLERGENE.CEREALS": "Cereals",
  "ALLERGENE.CRUSTACEANS": "Crustaceans",
  "ALLERGENE.EGGS": "Eggs",
  "ALLERGENE.FISH": "Fish",
  "ALLERGENE.LUPIN": "Lupin",
  "ALLERGENE.MILK": "Milk",
  "ALLERGENE.MOLLUSCS": "Molluscs",
  "ALLERGENE.MUSTARD": "Mustard",
  "ALLERGENE.NUTS": "Nuts",
  "ALLERGENE.PEANUTS": "Peanuts",
  "ALLERGENE.SESAME_SEEDS": "Sesame Seeds",
  "ALLERGENE.SULPHER_DIOXIDE": "Sulphur dioxide",
  "ALLERGENE.TITLE": "Allergenes",
  "ALLERGENE.SOYA": "Soya",
  "HOME_PAGE.YOUR_CODE": "Your QR code",
  "HOME_PAGE.RESTAURANT": "Restaurant",
  "HOME_PAGE.PRINT": "Print",
  "HOME_PAGE.DOWNLOAD": "Download",
  "HOME_PAGE.CHANGE_URL": "Change URL",
  "HOME_PAGE.URL_TAKEN": "Url already taken",
  "HOME_PAGE.ANDROID_USER": "Android Users",
  "HOME_PAGE.IOS_USER": "iOS Users",
  "HOME_PAGE.ANALYTICS": "Analytics",
  "HOME_PAGE.USER_REGISTERED": "Users Registered",
  "HOME_PAGE.ORDERS_AMOUNT": "Revenue Online Orders",
  "HOME_PAGE.PENDING_ORDERS": "Pending Orders",
  "HOME_PAGE.PROGRESS_TITLE": "Your account, Your possibilities",
  "HOME_PAGE.PROGRESS_DESCRIPTION":
    "We love to see your progress, that's why we show you all the features that you have yet to discover... and the ones that you have already discovered!",
  "HOME_PAGE.HIDE_TEXT": "Hide completed goals",
  "HOME_PAGE.SHOW_TEXT": "Show completed goals",
  "HOME_PAGE_GOALS.RESTAURANT_LOGO": "Upload your restaurant logo",
  "HOME_PAGE_GOALS.CUSTOMIZE_COLOR": "Customize the colors of your website",
  "HOME_PAGE_GOALS.CUSTOMIZE_HEADER": "Choose the header for your website",
  "HOME_PAGE_GOALS.PREVIEW_WEB": "Preview your website",
  "HOME_PAGE_GOALS.CREATE_FIRST_CATEGORY":
    "Create the first category of your menu",
  "HOME_PAGE_GOALS.UPLOAD_FIRST_MENU": "Upload the first course of your menu",
  "HOME_PAGE_GOALS.ADD_ALLERGENS": "Add allergens to the menu!",
  "HOME_PAGE_GOALS.UPLOAD_PRODUCT_PHOTOS": "Upload photos of your products",
  "HOME_PAGE_GOALS.ADD_EXTRA_OPTIONS": "Add extra options to your dishes",
  "HOME_PAGE_GOALS.CREATE_SECOND_CATEGORY": "Create a second category",

  "HOME_PAGE_GOALS.ADD_STRIPE_KEY":
    "Add your Stripe Key to receive online payments",
  "HOME_PAGE_GOALS.ACTIVATE_ORDER_FROM_TABLE": "Activate orders from the table",
  "HOME_PAGE_GOALS.ACTIVATE_DELIVERY": "Activate home delivery",
  "HOME_PAGE_GOALS.ACTIVATE_PICK_UP": "Activate local Pick up",
  "HOME_PAGE_GOALS.CREATE_FIRST_TABLE": "Create your first table",
  "HOME_PAGE_GOALS.RECEIVE_FIRST_ORDER": "Receive your first order",
  "HOME_PAGE_GOALS.ADD_PICKUP_AVAILABILITY":
    "Create your first availability for orders to pick up",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AVAILABILITY":
    "Create your first availability for home orders",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AREA": "Configure your delivery area",
  "HOME_PAGE.SELECT_YEAR": "Select Year",

  "BOOKING_DETAIL_PAGE.BACK_BUTTON": "Booking List",
  "BOOKING_DETAIL_PAGE.N_PEOPLE": "N. People",
  "BOOKING_DETAIL_PAGE.SELECT_VENUE": "Select Venue",
  "BOOKING_DETAIL_PAGE.TITLE": "Booking Detail",
  "BOOKING_LIST_HEADER_PAGE.TITLE": "Confirmation List",
  "BRANDING_HEADER.TITLE": "Customize you website",
  "BRANDING.UPLOAD_LOGO": "Upload your logo",
  "BRANDING.UPLOAD_HEADER": "Upload your header",
  "BRANDING.CHOOSE_COLOR": "Choose your corporate color",
  "CALENDER_APP_PAGE.SELECT_VENUE": "Select Venue",
  "CALENDER_HEADER_PAGE.TITLE": "Calender",
  "CHANGE_PASSWORD_PAGE.TITLE": "Change Password",
  "DRAW_DETAIL_PAGE.BACK_BUTTON": "Draw List",
  "DRAW_DETAIL_PAGE.EVENT_DURATIONL": "Event Duration",
  "DRAW_DETAIL_PAGE.NEW_DRAW": "New Draw",
  "DRAW_DETAIL_PAGE.SELECT_DRAW": "Select Draw",
  "DRAW_DETAIL_PAGE.TITLE": "  Draw Detail",
  "DRAW_LIST_HEADER_PAGE.TITLE": "draw",
  "DRAW_LIST_PAGE.BUTTON_TEXT": "Create Draw",
  "DRAW_LIST_PAGE.TITLE": "Draw's",

  "EXTRA_CATEGORIES.CATEGORY_TYPES": "Category Type",
  "EXTRA_CATEGORIES.MINIMUM_QUANTITY": "Minimum Quantity",
  "EXTRA_CATEGORIES.MAXIMUM_QUANTITY": "Maximum Quantity",
  "EXTRA_CATEGORIES.ENABLE_QUANTITY": "Enable Limit",
  "EXTRA_CATEGORIES.EXTRA_PRICE": "Extra Price",
  "EXTRA_CATEGORIES.ADD": "Add Item Category",
  "EXTRA_CATEGORIES.SELECTED": "Preselected",
  "EXTRA_CATEGORIES.CONNECT_CATEGORY": "Linked Category",

  "ERRORS.STRIPE_ID_INVALID": "The stripe key introduced is invalid",
  "ERRORS.STRIPE_KEY_INVALID": "The stripe public key introduced is invalid",
  "ERRORS.REDSYS_SECRET_KEY": "The redsys secret key introduced is invalid",
  "ERRORS.REDSYS_MERCHANT_CODE":
    "The redsys merchant code introduced is invalid",
  "ERRORS.REDSYS_MERCHANT_NAME":
    "The redsys merchant name introduced is invalid",
  "ERRORS.IMAGE_IS_REQUIRED": "Image is Required",
  "ERRORS.FIELD_IS_REQUIRED": "Field Required",
  "ERRORS.INVALID_EMAIL": "This email is invalid",
  "ERRORS.EMAIL_TAKEN": "Email already taken",
  "ERRORS.INVALID_PHONE": "This phone number is invalid",
  "ERRORS.INVALID_LOGIN": "Invalid Login. Please try again",
  "ERRORS.INVALID_URL": "Invalid URL",
  "ERRORS.MINIMUM_10_CHAR": "Minimum size 10 characters",
  "ERRORS.MINIMUM_3_CHAR": "Minimum size 3 characters",
  "ERRORS.MINIMUM_1_CHAR": "Minimum size 1 character",
  "ERRORS.MINIMUM_4_CHAR": "Minimum size 4 characters",
  "ERRORS.MINIMUM_6_CHAR": "Minimum size 6 characters",
  "ERRORS.MUST_BE_NUMBER": "Must be a number",
  "ERRORS.ORIGINAL_PRICE_SMALLER":
    "Original price must be greater then reduced price",
  "ERRORS.REDUCED_PRICE_GREATER":
    "Reduced price must be smaller then original price",
  "ERRORS.SELECT_ONE": "Select at least one",
  "ERRORS.PASSWORD_MUST_BE_7": "Password must be longer than 6 characters",
  "GALLERY_DETAIL_PAGE.SELECT_VENUE": "Select Venue",
  "GALLERY_DETAIL_PAGE.TITLE": "Gallery",
  "GENERAL.CITY": "City",
  "GENERAL.DUPLICATE": "Duplicate",
  "GENERAL.CREATE": "Create",
  "GENERAL.JANUARY": "January",
  "GENERAL.FEBRUARY": "February",
  "GENERAL.MARCH": "March",
  "GENERAL.APRIL": "April",
  "GENERAL.MAY": "May",
  "GENERAL.JUNE": "June",
  "GENERAL.JULY": "July",
  "GENERAL.AUGUST": "August",
  "GENERAL.SEPTEMBER": "September",
  "GENERAL.OCTOBER": "October",
  "GENERAL.NOVEMBER": "November",
  "GENERAL.DECEMBER": "December",
  "GENERAL.ADDRESS": "Address",
  "GENERAL.ADD_BUTTON": "Add",
  "GENERAL.BASIC_INFO": "Basic Info",
  "GENERAL.CANCEL_BUTTON": "Cancel",
  "GENERAL.CLIENT": "Client",
  "GENERAL.COMMENTS": "Comment",
  "GENERAL.CONFIRM_BUTTON": "Confirm",
  "GENERAL.CONTACT_INFO": "Contact Info",
  "GENERAL.DATE": "Date",
  "GENERAL.EXPIRY_DATE": "Expiration Date",
  "GENERAL.DELETE": "Delete",
  "GENERAL.DENY_BUTTON": "Deny",
  "GENERAL.DESCRIPTION": "Description",
  "GENERAL.DETAIL": "Detail",
  "GENERAL.EMAIL": "Email",
  "GENERAL.FRIDAY": "Friday",
  "GENERAL.IMAGE": "Image",
  "GENERAL.MONDAY": "Monday",
  "GENERAL.NAME": "Name",
  "GENERAL.NUMBER": "Number",
  "GENERAL.OPENING_HOURS": "Opening Hours",
  "GENERAL.DELIVERY_INFO": "Delivery Info",
  "GENERAL.ORIGINAL_PRICE": "Original Price",
  "GENERAL.DISCOUNTED_PRICE": "Discounted Price",
  "GENERAL.PASSWORD": "Password",
  "GENERAL.PHONE": "Phone",
  "GENERAL.NO_ORDER_LIST": "There is no orders selected on that range",

  "GENERAL.EXPORT": "Export",
  "GENERAL.EXPORT_CSV": "Export CSV",
  "GENERAL.EXPORT_XLS": "Export XLS",
  "GENERAL.POINTS": "Points",
  "GENERAL.POSTCODE": "Postcode",
  "GENERAL.PRICE": "Price",
  "GENERAL.REDUCED_PRICE": "Reduced Price",
  "GENERAL.ROWS_PER_PAGE": "Rows per page",
  "GENERAL.SATURDAY": "Saturday",
  "GENERAL.SAVE": "Save",
  "GENERAL.SAVE_PREVIEW": "Save & Preview",
  "GENERAL.SAVE_BUTTON": "Save",
  "GENERAL.SEARCH": "Search",
  "GENERAL.SEND": "Send",
  "GENERAL.SUNDAY": "Sunday",
  "GENERAL.THURSDAY": "Thursday",
  "GENERAL.TIME": "Time",
  "GENERAL.TITLE": "Title",
  "GENERAL.TUESDAY": "Tuesday",
  "GENERAL.TYPE": "Type",
  "GENERAL.USER_LIST": "User List",
  "GENERAL.WINNERS_LIST": "Winners List",
  "GENERAL.VENUE": "Venue",
  "GENERAL.STATUS": "Status",
  "GENERAL.PAYMENT_METHOD": "Payment Method",
  "GENERAL.WEBSITE": "Website",
  "GENERAL.WEDNESDAY": "Wednesday",
  "GENERAL.CATEGORY": "Category",
  "GENERAL.ITEMS": "Items",
  "GENERAL.ALL_VENUES": "All Venues",
  "GENERAL.ACTIONS": "Actions",
  "GENERAL.REF": "Ref #",
  "GENERAL.ORDER_TYPE": "Order Type",
  "GENERAL.ALERT_TITLE": "Redirection Confirmation",
  "GENERAL.ALERT_DESC":
    "Are you sure you want to go back without saving changes?",
  "GENERAL.AREA_REMOVE_ALERT_DESC": "Are you sure you want to Remove the Area?",
  "GENERAL.DELIVERY_TIME": "Timetable Delivery",
  "GENERAL.COLLECTION_TIME": "Timetable Collection",
  "GENERAL.LATITUDE": "Latitude",
  "GENERAL.LONGITUDE": "Longitude",
  "GENERAL.MIN_DELIVERY_PRICE": "Delivery price",
  "GENERAL.MIN_ORDER_PRICE": "Minimum order price",
  "GENERAL.REMOVE": "Remove",
  "GENERAL.NO_OF_PEOPLE": "No of People",
  "GENERAL.PLATFORM": "Platform",
  "GENERAL.STRIPE_KEY": "Stripe Secret Key",
  "GENERAL.REDSYS_KEY": "Secret Key",
  "GENERAL.STRIPE_PUB_KEY": "Stripe Publishable Key",
  "GENERAL.MERCHENT_CODE": "Merchent Code",
  "GENERAL.MERCHENT_NAME": "Merchent Name",
  "GENERAL.REDSYS_IS_TEST_MODE": "Test Mode",
  "GENERAL.MIN": "min",
  "GENERAL.ENTER_TIME": "Enter Default Time",
  "GENERAL.ESTIMATED_TIME": "Estimated Time",

  "GENERAL.ADV_TITLE": "Advatages",
  "GENERAL.DIS_ADV_TITLE": "Disadvatages",
  "GENERAL.USER_GUIDE": "User Guide",
  "GENERAL.REDSYS_ADV1":
    "Low Commisions: Between 0,15-0,3% depending of the deal got it.",
  "GENERAL.REDSYS_ADV2": "with your bank.",
  "GENERAL.REDSYS_ADV3":
    "Working with the majority of the bank: as Banco Sabadell, ING Direct.",
  "GENERAL.REDSYS_ADV4": "BBVA, Caixabank, Santander, Banco Popular.",
  "GENERAL.REDSYS_DIS_ADV1":
    "Payment card not stored: Your clients need to introduce his payment method in every purchase.",
  "GENERAL.REDSYS_DIS_ADV2":
    "Long review processes to get accepted and you have to request it to your current bank or your request can be declined.",

  "GENERAL.STRIPE_ADV1":
    "Quick and easy to setup to start receiving your online orders.",
  "GENERAL.STRIPE_ADV2":
    "Intuitive and modern dashboard where manage all your money transactions.",
  "GENERAL.STRIPE_ADV3":
    "Work qith all the banks you will receive the money in your account few days after the transaction is made.",
  "GENERAL.STRIPE_ADV4":
    "Payment cards securely stored: so your user need to introduce his card only the first time the purchase.",
  "GENERAL.STRIPE_DIS_ADV1": "Higher Commisions: 1.4%+ .",

  "GENERAL.TO": "To",
  "GENERAL.YES": "Yes",
  "GENERAL.FIRST_NAME": "First Name",
  "GENERAL.LAST_NAME": "Last Name",
  "GENERAL.YOUR_ARE_THERE": "You are almost there!",
  "GENERAL.ONLY_THE_LAST":
    "Only the last ingredient is missing to fully enjoy Banzzu",
  "GENERAL.MAKE_DEFAULT": "Make Default",
  "GENERAL.DEFAULT": "Default",
  "GENERAL.CONTINUE": "Continue",
  "GENERAL.DELIVERY_COST": "Delivery Cost",
  "GENERAL.BACK": "Back",
  "GENERAL.PAY": "Pay",
  "GENERAL.FULL_NAME": "Full Name",
  "GENERAL.ADDRESS_LIST": "Address List",
  "GENERAL.AVERAGE_ORDER": "Average Order",
  "GENERAL.TOP_FIVE_DISHES": "Top 5 dishes",
  "GENERAL.ICOME_PER_DAY": "Income per day",
  "GENERAL.CODE": "Code",
  "GENERAL.PERCENTAGE": "Percentage",
  "GENERAL.QUANTITY": "Quantity",
  "GENERAL.EXPIREABLE": "Expireable",
  "GENERAL.AMOUNT": "Amount",
  "GENERAL.USED": "Used",
  "GENERAL.MINIMUM_QUANTITY": "Minimum Quantity",
  "GENERAL.MINIMUM_AMOUNT": "Minimum Amount",
  "GENERAL.ACTIVATE": "Activate",
  "GENERAL.DISOCUNT_CODE": "Discount Code",
  "GENERAL.USABILITY": "Usability",
  "GENERAL.SINGLE_USE": "Single Use",
  "GENERAL.MULTIPLE_USE": "Mutiple Uses",
  "GENERAL.CONFIRMATION_TITLE": "Confirmation",
  "GENERAL.CONFIRMATION_SUBTITLE":
    "Do you want to request information about this Upsell?",
  "GENERAL.COLLECTION_ORRDER_CONFIRMATION_TITLE": "Estimated collection time",
  "GENERAL.DELIVERY_ORRDER_CONFIRMATION_TITLE": "Estimated delivery time",

  "LOGIN_PAGE.LOGIN_BUTTON": "Login",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "LOGIN TO YOUR ACCOUNT",
  "LOGIN_PAGE.REGISTER_LINK": "Don't have an account? Register",
  "LOGIN_PAGE.FORGOT_PASSWORD": "Recover Password?",
  "LOGIN_PAGE.ARE_YOU_SURE": "Are you sure you want to recover your password",
  "LOGIN_PAGE.CONFIRM": "Confirm",
  "LOGIN_PAGE.CANCEL": "Cancel",
  "LOGIN_PAGE.ENTER_VALID_EMAIL": "Please enter a valid email",
  "LOGIN_PAGE_.EMAIL_EXIST": "This email does not exist",
  "LOGIN_PAGE_.CHECK_EMAIL": "Check your email",
  "LOGIN_PAGE_.REMEMBER_ME": "Remember Me",

  "FORGOT_PASSWORD_PAGE.RESET_PASSWORD": "Reset Password",
  "FORGOT_PASSWORD_PAGE.SAVE_BUTTON": "Save",
  "FORGOT_PASSWORD_PAGE.PASSWORD": "New Password",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD": "Confirm password",
  FORGOT_PASSWORD_PAGE_TOKEN_EXPIRED: "The token has expired",
  "FORGOT_PASSWORD_PAGE.SUCCESS": "Password changed successfully",
  "FORGOT_PASSWORD_PAGE.PASSWORD_MUST_BE_7":
    "Password must be longer than 6 characters",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD_MUST_BE_7":
    "Confirm Password must be longer than 6 characters",
  "FORGOT_PASSWORD_PAGE.PASSWORD_SAME":
    "Password and confirm the password should be the same",

  "MENU_CATEGORY_PAGE.ADD_CATEGORY": "Add Category",
  "MENU_CATEGORY_PAGE.PRODUCT_LIST": "Product List",

  "MENU_DETAIL_PAGE.BACK_BUTTON": "Menu List",
  "MENU_DETAIL_PAGE.MENU_TYPE": "Menu Type",
  "MENU_DETAIL_PAGE.NEW_MENUE": "New Menu",
  "MENU_DETAIL_PAGE.NEW_PRODUCT": "New Product",
  "MENU_DETAIL_PAGE.PDF_IS_REQUIRED": "PDF is Required",
  "MENU_DETAIL_PAGE.TITLE": "Menu Detail",
  "MENU_DETAIL_PAGE.HELP": "You need help",
  "MENU_DETAIL_PAGE.MENU_SHARED":
    "This menu is shared with others and it can't be edited",
  "MENU_DETAIL_PAGE.VENUES": "Venues",
  "MENU_DETAIL_PAGE.VIEW_PDF": "View PDF",
  "MENU_DETAIL_PAGE.CREATE_CATEGORY": "Create Category",
  "MENU_DETAIL_PAGE.UPDATE_CATEGORY": "Update Category",
  "MENU_DETAIL_PAGE.VIRTUAL_MENU_PRODUCTS": "Virtual Menu Products",
  "MENU_DETAIL_PAGE.DELETE_CATEGORY": "Delete Category",
  "MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY": "Create extra category",
  "MENU_DETAIL_PAGE.MENU_AVAILABILITY": "Menu Available on:",
  "MENU_DETAIL_PAGE.FUTURE_ORDERS": "Future Orders",
  "MENU_DETAIL_PAGE.DELIVERY": "Delivery",
  "MENU_DETAIL_PAGE.COLLECTION": "Collection",
  "MENU_DETAIL_PAGE.ORDER_FROM_TABLE": "Order from Table",
  "MENU_DETAIL_PAGE.ALLERGENES": "Allergenes PDF (Optional)",
  "MENU_NEW_MENU_CREATED": "Your menu has been created successfully",

  "MENU_ITEM_DETAIL_PAGE.PRODUCT_DETAIL": "Product Detail",
  "MENU_ITEM_DETAIL_PAGE.NEW_PRODUCT": "New Product",
  "MENU_ITEM_DETAIL_PAGE.PRODUCT_LIST": "Product List",
  "MENU_ITEM_DETAIL_PAGE.SELECT_CATEGORY": "Select Category",
  "MENU_ITEM_LIST_HEADER_PAGE.SELECT_CATEGORY": "Select Category",
  "MENU_ITEM_LIST_HEADER_PAGE.CREATE_PRODUCT": "Create Product",
  "MENU_ITEM_LIST_HEADER_PAGE.PRODUCT": "Product",
  "MENU_LIST_CATEGORY_PAGE.TITLE": "Menu Categories",
  "MENU_ITEM_LIST_PAGE.PRODUCT_LIST": "Product List",
  "MENU_ITEM_LIST_PAGE.CATEGORIES": "Categories",
  "MENU_LIST_PAGE.BUTTON_TEXT": "Create Menu",
  "MENU_LIST_PAGE.TITLE": "Menu List",
  "NAVBAR.MASTER_ACCOUNT": "Master Account",
  "NOTIFICATION_DETAIL_PAGE.BUTTON_BACK": "Notification Detail",
  "NOTIFICATION_DETAIL_PAGE.TITLE": "Send Notification",
  "NOTIFICATION_LIST_PAGE.TITLE": "Notification Push",
  "OFFER_DETAIL_PAGE.BACK_BUTTON": " Offer List",
  "OFFER_DETAIL_PAGE.DISABLE_PRICE": "Disable Price",
  "OFFER_DETAIL_PAGE.NEW_OFFER": "Offer Detail",
  "OFFER_DETAIL_PAGE.SELECT_VENUE": "Select Venue",
  "OFFER_DETAIL_PAGE.TITLE": "Offer Detail",
  "OFFER_LIST_HEADER_PAGE.BUTTON_TEXT": "Create Offer",
  "OFFER_LIST_HEADER_PAGE.TITLE": "Offer List",
  "ORDER_LIST_PAGE.TITLE": "Orders",
  "ORDER_LIST_PAGE.ALL_STATUS": "All statuses",
  "ORDER_LIST_PAGE.ALL_TYPES": "All types",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Select Order Status",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Select Order Type",
  "ORDER_PAGE.CUSTOMER": "Customer",
  "ORDER_PAGE.EMAIL": "Email",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Name",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Confirm Order",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Cancel",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Preparing",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "COLLECT",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "COLLECTED",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Start Delivery",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Delivered",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Order",
  "ORDER_PAGE.ORDERS": "Orders",
  "ORDER_PAGE.ORDER_DETAIL": "Order Detail",
  "ORDER_PAGE.PHONE": "Phone",
  "ORDER_PAGE.DELIVERY": "Delivery Address",
  "ORDER_PAGE.COMMENTS": "Comments",
  "ORDER_PAGE.PRODUCTS": "Products",
  "ORDER_PAGE.QUANTITY": "Quantity",
  "REWARD_DETAIL_PAGE.BACK_BUTTON": "Rewards List",
  "REWARD_DETAIL_PAGE.NEW_REWARD": "New Reward",
  "REWARD_DETAIL_PAGE.TITLE": "Reward Detail",
  "REWARD_LIST_PAGE.BUTTON_TEXT": "Create Reward",
  "REWARD_LIST_PAGE.TITLE": "Rewards",
  "SIDEBAR_MENUE.CREATE_MENUE": "Create Menu",
  "SIDEBAR_MENUE.CREATE_OFFER": "Create Offer",
  "SIDEBAR_MENUE.CREATE_WHATSON": "Create What's on",
  "SIDEBAR_MENUE.HOME": "Home",
  "SIDEBAR_MENUE.ANALYTICS": "Analytics",
  "SIDEBAR_MENUE.DRAWS": "Draws",
  "SIDEBAR_MENUE.DRAWS_CREATE": "Create Draw",
  "SIDEBAR_MENUE.DRAWS_LIST": "Draw List",
  "SIDEBAR_MENUE.GALLERY": "Gallery",
  "SIDEBAR_MENUE.LOYALTY_PROGRAM": "Loyalty Program",
  "SIDEBAR_MENUE.MENUE_LIST": "Menu List",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menu",
  "SIDEBAR_MENUE.NOTIFICATIONS": "Notifications",
  "SIDEBAR_MENUE.NOTIFICATIONS_PUSH": "Push History",
  "SIDEBAR_MENUE.NOTIFICATIONS_SEND": "Send Push",
  "SIDEBAR_MENUE.OFFERS": "Offers",
  "SIDEBAR_MENUE.OFFER_LIST": "Offer List",
  "SIDEBAR_MENUE.ORDERS": "Orders",
  "SIDEBAR_MENUE.ORDERS_LIST": "Orders List",
  "SIDEBAR_MENUE.PROFILE": "Profile",
  "SIDEBAR_MENUE.BRANDING": "Branding",
  "SIDEBAR_MENUE.RESERVATION": "Reservation",
  "SIDEBAR_MENUE.RESERVATION_BOOKING": "Booking Calendar",
  "SIDEBAR_MENUE.RESERVATION_CONFIRM": "Confirm Reservations",
  "SIDEBAR_MENUE.REWARDS_CREATE": "Create Reward",
  "SIDEBAR_MENUE.REWARDS_LIST": "Rewards List",
  "SIDEBAR_MENUE.TITLE": "Menu",
  "SIDEBAR_MENUE.TRANSACTION": "Transactions List",
  "SIDEBAR_MENUE.USER_LIST": "User",
  "SIDEBAR_MENUE.VENUE": "Venue",
  "SIDEBAR_MENUE.VENUE_DETAILS": "Create Venue",
  "SIDEBAR_MENUE.VENUE_LIST": "Venue List",
  "SIDEBAR_MENUE.VOUCHER": "Voucher",
  "SIDEBAR_MENUE.VOUCHER_CREATE_VOUCHER": "Create Voucher",
  "SIDEBAR_MENUE.VOUCHER_LIST": "Voucher List",
  "SIDEBAR_MENUE.WHATSON": "What's on",
  "SIDEBAR_MENUE.WHATSON_lIST": "What's on List",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE": "Order from Table",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST": "Table List",
  "SIDEBAR_MENUE.CONFIGURATION": "Initial configuration",
  "SIDEBAR_MENUE.ONLINE_ORDERS": "Online Orders",
  "SIDEBAR_MENUE.STAFF": "Staff",
  "SIDEBAR_MENUE.STAFF_LIST": "Staff List",
  "SIDEBAR_MENUE.CREATE_STAFF": "Create staff",
  "SIDEBAR_MENUE.MERCHANDISING": "Additional Services",

  "STAFF_DETAIL.BASIC_INFO": "Basic Info",
  "STAFF_DETAIL.PERMISSIONS": "Permissions and Roles",
  "STAFF_DETAIL.BACK_LIST": "Staff List",
  "STAFF_DETAIL.CREATE_STAFF": "Create Staff",
  "STAFF_DETAIL.ASSIGNED_VENUES": "Assigned Venues",

  "ORDER_FROM_TABLE.LIST": "List",
  "ORDER_FROM_TABLE.CREATE_TABLE": "Create table",
  "ORDER_FROM_TABLE.DOWNLOAD_QR": "Download QR",
  "ORDER_FROM_TABLE.VENUES": "Venues",

  "TRANSACTION_LIST_PAGE.TITLE": "Transaction",
  "USER_LIST_PAGE.TITLE": "User List",
  "VENUE_DETAIL_PAGE.BUTTON_BACK": "Venue List",
  "VENUE_DETAIL_PAGE.MAIN_TITLE": "New Venue",
  "VENUE_DETAIL_PAGE.ALERT_TIME.TITLE": "Add Availability",
  "VENUE_DETAIL_PAGE.ALERT_TIME.INITIAL_TIME": "Initial Time",
  "VENUE_DETAIL_PAGE.ALERT_TIME.END_TIME": "End Time",
  "VENUE_DETAIL_PAGE.DELIVERY_ACTIVATION_ALERT":
    "Please activate delivery and collection feature from online orders to explore this section fully",
  "VENUE_DETAIL_PAGE.PASSWORD":
    "Password (Fill this value only if you want to change the password)",
  "VENUE_DETAIL_PAGE.TITLE": "Venue Detail",
  "VENUE_LIST_HEADER_PAGE.CREATE_NEW_VENUE": "Create Venue",
  "VENUE_LIST_HEADER_PAGE.NEW": "New",
  "VENUE_DETAIL_PAGE.REMOVE_AREA": "Remove Delivery Area",
  "VENUE_DETAIL_PAGE.DLIVERY_AREA_TITLE": "Delivery Areas",
  "VENUE_DETAIL_PAGE.AREA": "Area",
  "VENUE_DETAIL_PAGE.CREATE_AREA": "Create Delivery Area",
  "VENUE_DETAIL_PAGE.CLEAR_AREA": "Clear Delivery Area",
  "VENUE_LIST_HEADER_PAGE.TITLE": "Venue List",
  "VOUCHER_DETAIL_PAGE.BUTTON_BACK": "Voucher List",
  "VOUCHER_DETAIL_PAGE.NEW_VOUCHER": "NEW Voucher",
  "VOUCHER_DETAIL_PAGE.TITLE": "Voucher Detail",
  "VOUCHER_DETAIL_PAGE.VOUCHER_TYPE": "Select Nº Stamps",
  "VOUCHER_LIST_HEADER_PAGE.BUTTON_TEXT": "Create Voucher",
  "VOUCHER_LIST_HEADER_PAGE.NEW": "New",
  "VOUCHER_LIST_HEADER_PAGE.TITLE": "Voucher List",
  "WHATS_ON_LIST_HEADER_PAGE.BUTTON_TEXT": "Create What's On",
  "WHATS_ON_LIST_HEADER_PAGE.MAIN_TITLE": "New What´s On",
  "WHATS_ON_LIST_HEADER_PAGE.TITLE": "What's On",
  "WHAT_ON_DETAIL_PAGE.BACK_BUTTON": "What´s On List",
  "WHAT_ON_DETAIL_PAGE.DATE_FROM": "Date from",
  "WHAT_ON_DETAIL_PAGE.DATE_TO": "Date to",
  "WHAT_ON_DETAIL_PAGE.EVENT_DURATIONL": "Event Duration",
  "WHAT_ON_DETAIL_PAGE.SELECT_VENUE": "Select Venue",
  "WHAT_ON_DETAIL_PAGE.TITLE": "What´s On Detail",
  "WHAT_ON_DETAIL_PAGE.ONE_DAY": "1 Day",
  "WHAT_ON_DETAIL_PAGE.MULTIPLE_DAYS": "Multiple Days",

  "ORDER_TYPE.DELIVERY": "Delivery",
  "ORDER_TYPE.COLLECT": "Collect",
  "ORDER_TYPE.FUTURE_ORDER": "Future Order",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Order From Table",

  "ORDER_STATUS.UPDATED_AT": "Updated on",
  "ORDER_STATUS.ORDER_NUMBER": "Order Number",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Pay by Cash",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Payment accepted",
  "ORDER_STATUS.ORDER_ACCEPTED": "Order accepted",
  "ORDER_STATUS.PREPARING": "Preparing the order",
  "ORDER_STATUS.ON_DELIVERY": "On Delivery",
  "ORDER_STATUS.DELIVERED": "Delivered",
  "ORDER_STATUS.CANCELED": "Cancel",
  "ORDER_STATUS.COLLECT": "Ready To Collect",
  "ORDER_STATUS.COLLECTED": "Collected",
  "ORDER_STATUS.ORDER_TIME": "Order Time",
  "ORDER_STATUS.ORDER_TABLE": "Order Table",

  "USER_DETAIL.BACK_LIST": "User List",
  "USER_DETAIL.ORDER_LIST": "Order List",
  "USER_DETAIL.REWARD_LIST": "Reward List",
  "USER_DETAIL.LOYALTY_LIST": "Fidelity Program",
  "USER_DETAIL.BOOKING_LIST": "Reservations",
  "USER_DETAIL.DRAW_LIST": "Draw List",
  "USER_DETAIL.ADDRESS_LIST": "Address List",

  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_TITLE": "Delete Availability",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_SUBTITLE":
    "Are you sure you want to delete this availability",
  "VENUE_DETAIL_PAGE.VALID_URL": "Please enter valid url",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "Start date should be smaller then end date",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "End date should be greater then start date",
  "VENUE_DETAIL_PAGE.DATE_RANGE": "Date range should be less then 30 days",

  "VISIBILITY.ALWAYS_VISIBLE": "Always Available",
  "VISIBILITY.DISABLED": "Disabled",
  "VISIBILITY.SHOW_ONLY": "Show only",
  "VISIBILITY.PARTIALY_DISABLED": "Partially Disabled",
  "VISIBILITY.TITLE": "Visibility",
  "VISIBILITY.TIME_RANGE": "Time Range",

  "VENUE.ADD_LONGITUDE_ALERT":
    "Please add latitude and longitude on Contact Info to set delivery area",
  "MENU.SHARED": "This menu is shared with other venues, you can’t edit it",
  "TABLE_HEAD.CONFIRMATION_TITLE": "Remove Confirmation",
  "TABLE_HEAD.CONFIRMATION_DESCRIPTION":
    "Are you sure you want to delete this item?",
  "PRICE_REDUCE_CELL.DISABLED_PRICE": "Price disabled",

  "MATERIAL_TABLE.DELETE": "Are you sure delete this row?",
  "MATERIAL_TABLE.NO_RECORDS": "No records to display",
  "MATERIAL_TABLE.ROW_SELECTED": "{0} row(s) selected",
  "MATERIAL_TABLE.ROWS": "Rows",
  "MATERIAL_TABLE.DISPLAYED_ROWS": "{from}-{to} of {count}",

  "REGISTER_PAGE.TITLE": "Sign Up for free",
  "REGISTER_PAGE.SUBTITLE":
    "Start offering commission-free online ordering, build customer loyalty and increase your revenue.",
  "REGISTER_PAGE.FIRST_NAME": "First Name",
  "REGISTER_PAGE.LAST_NAME": "Last Name",
  "REGISTER_PAGE.PHONE_NUMBER": "Phone number",
  "REGISTER_PAGE.EMAIL": "Email",
  "REGISTER_PAGE.TELEPHONE": "Phone Number",
  "REGISTER_PAGE.PASSWORD": "Password",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Terms and Conditions",
  "REGISTER_PAGE.PRIVACY_POLICY": "Privacy Policy",
  "REGISTER_PAGE.ACCEPT": "Accept",
  "REGISTER_PAGE.BUTTON_TEXT": "Continue",
  "REGISTER_PAGE.LOGIN_LINK": "Already have an account?",
  "REGISTER_PAGE.LOGIN": "Login",
  "REGISTER_PAGE.NEW_IN_BANZZU": "New in Banzzu?",
  "REGISTER_PAGE.SUB_TITLE_1":
    "Conquer the digital universe of your restaurant,",
  "REGISTER_PAGE.SUB_TITLE_2": "and discover all your possibilities",

  "REGISTER_STEPS.REGISTER_RESTAURANT": "Register your restaurant",
  "REGISTER_STEPS.LOCATION": "Location",
  "REGISTER_STEPS.SELECT_SERVICES": "Select your services",
  "REGISTER_STEPS.ADDITIONAL_INFORMATION": "Additional Information",
  "REGISTER_STEPS.RESTAURANT_NAME": "Restaurant Name",
  "REGISTER_STEPS.TELEPHONE": "Phone Number",
  "REGISTER_STEPS.ADDRESS": "Address",
  "REGISTER_STEPS.POSTAL_CODE": "Postal code",
  "REGISTER_STEPS.MAP_TITLE": "Do you want to change your restaurant address?",
  "REGISTER_STEPS.MOVE_PIN": "Move the pin on the map",
  "REGISTER_STEPS.SERVICES_TITLE": "Which services do you offer?",
  "REGISTER_STEPS.PICK_UP_SERVICE": "Takeaway",
  "REGISTER_STEPS.DELIVERY_SERVICE": "Delivery",
  "REGISTER_STEPS.CONGRATULATION": "Congratulations!",
  "REGISTER_STEPS.CONGRATULATION_DESCRIPTION":
    "Your restaurant has been registered successfully",
  "REGISTER_STEPS.PROCEED": "Proceed",
  "REGISTER_STEPS.SELECT_COUNTRY": "Select Country",
  "REGISTER_STEPS.BACK": "BACK",
  "REGISTER_STEPS.NEXT": "NEXT",
  "REGISTER_STEPS.SUBMIT": "SUBMIT",

  "PAYMENT_SETTINGS_PAGE.SETTINGS": "Online Orders",
  "PAYMENT_SETTINGS_PAGE.TITLE": "Settings",
  "PAYMENT_SETTINGS_PAGE.ORDER_SETTINGS": "Online Orders",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_INTEGRATION": "Payment integration",
  "PAYMENT_SETTINGS_PAGE.DISCOUNT_VOUCHERS": "Discount Vouchers",
  "PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER": "Create Discount Voucher",
  "PAYMENT_SETTINGS_PAGE.EDIT_DISCOUNT_VOUCHER": "Edit Discount Voucher",
  "PAYMENT_SETTINGS_PAGE.HELPER_TEXT":
    "By enabling above options. These order types will become available for your customers to place order.",
  "PAYMENT_SETTINGS_PAGE.ONLINE_ORDERS_TITLE":
    "Enable or disable online order types",
  "PAYMENT_SETTINGS_PAGE.ALERT_HELPER_TEXT":
    "Please add stripe id in payment integration tab first to activate the features",
  "PAYMENT_SETTINGS_PAGE.ALERT_STRIPE":
    "Get Secret key from your stripe account and double check to get the payments correctly",
  "PAYMENT_SETTINGS_PAGE.DELIVERY": "Delivery",
  "PAYMENT_SETTINGS_PAGE.COLLECTION": "Collection",
  "PAYMENT_SETTINGS_PAGE.ORDER_FROM_TABLE": "Order from Table",
  "PAYMENT_SETTINGS_PAGE.MENU_AVAILABILITY":
    "Available on the following menus:",
  "PAYMENT_SETTINGS_PAGE.STRIP_KEY_ERROR":
    "The online orders can't be activated until the stripe key is not correctly setup. Click here to setup your key",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CASH": "Pay by cash",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CARD": "Pay by card",
  TIME_SETTINGS_DELIVERY: "Delivery",
  TIME_SETTINGS_COLLECTION: "Collection",
  "PAYMENT_SETTINGS_PAGE.ONE_ACTIVE_PAYMENT": "One option must be active",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_METHODS": "Payment Methods",
  "PAYMENT_SETTINGS_PAGE.ADD_STRIPE_KEY":
    "You have to add a valid stripe key to activate this option",

  "PAYMENT_PAGE.VENUE": "Venues",
  "PAYMENT_PAGE.MONTH": "Month",
  "PAYMENT_PAGE.RESTAURANT": "Restaurant",
  "PAYMENT_PAGE.IVA": "Tax not included",
  "PAYMENT_PAGE.EXTRA_RESTAURANT": "Extra Restaurant",
  "PAYMENT.CHOOSE_PAYMENT_METHOD": "Choose payment method",
  "PAYMENT.ADD_CARD": "Add Card",
  "PAYMENT.HOW_MANY_RESTAURANTS": "How many restaurants do you have?",
  "PAYMENT.HOW_MANY_RESTAURANTS_DO_YOU_WANT_TO_ADD":
    "How many restaurants do you want to add?",

  "HELP_MENU.CONTACT_SUPPORT": "Contact Support",
  "HELP_MENU.PAYMENTS": "Payment methods",
  "HELP_MENU.CHANGE_PASSWORD": "Change password",
  "HELP_MENU.LOGOUT": "Logout",

  "CONTACT_SUPPORT.TITLE": "Send us a message",
  "CONTACT_SUPPORT.SUBJECT": "Subject",
  "CONTACT_SUPPORT.MESSAGE": "Message",
  "CONTACT_SUPPORT.SEND": "Send",
  "CONTACT_SUPPORT.SUB_TITLE": "Use the form bellow, or email us at ",
  "CONTACT_SUPPORT.SUCCESS_MESSAGE": "You message has been sent successfully",

  "PRICING.UPGRADE": "Upgrade to more venues",
  "PRICING.PLANES": "Plans",
  "PRICING.THE_MOST_ADVANCED":
    "The most advanced and sophisticated software for your restaurant with clear and transparent prices.",
  "PRICING.YOU_PLANE": "YOUR PLAN",
  "PRICING.PER_MONTH": "PER MONTH",
  "PRICING.STANDARD": "Standard",
  "PRICING.BASIC_ANALYTICS": "Basic Analytics",
  "PRICING.PAGE": "Page",
  "PRICING.CUSTOM_WEB": "custom web",
  "PRICING.FOR_YOUR_BUSINESS": "for your business",
  "PRICING.ACCOMMODATION": "and",
  "PRICING.FREE_HOSTING": "free hosting",
  "PRICING.YOUR_OWN_INTERACTIVE_MENU": "Your own interactive menu",
  "PRICING.ADD_IMAGES_OF_YOUR_PRODUCT":
    "Add images of your products, descriptions, prices, ingredients and allergens of each of your dishes.",
  "PRICING.CUSTOM_QR_CODE": "Custom QR code",
  "PRICING.WITH_YOUR_MENU": "with your menu",
  "PRICING.24th_SUPPORT": "24th support ",
  "PRICING.BY_CHAT_AND_EMAIL": "by chat and email",
  "PRICING.ACCESS_ALL_RESTAURANT": "Access to all restaurant data:",
  "PRICING.ADDRESS_PHONE":
    "address, phone numbers, social networks, website...",
  "PRICING.INCLUDE_ONE_VENUE": "Includes one venue",
  "PRICING.YOUR_PLAN": "Your plan",
  "PRICING.ADVANCED": "Advanced",
  "PRICING.BEFORE": "Antes",
  "PRICING.EVERYTHING_INCLUDE": "Everything included in the Free plan",
  "PRICING.PROP_ANALYTICS": "Pro Analitycs",
  "PRICING.USER_CREATION_REGISTRATION": "User creation and registration",
  "PRICING.TO_RECIVE": "To recive",
  "PRICING.ORDER_TO_COLLECT": " Orders to collect",
  "PRICING.ORDER_FROM_TABLE": "Order from the table",
  "PRICING.LET_YOUR_IMGINATION":
    "Let your imagination fly and create unlimited menus",
  "PRICING.SLEEP_PACE_FULLY": "Sleep peacefully with the security of our",
  "PRICING.ANTI_FRAUD_SYSTEM": " anti-fraud system",
  "PRICING.UNLIMITED_VENUES": " unlimited venues",
  "PRICING.PER_VENUE": "per venue",
  "PRICING.RECIVE_ORDERES_WITH_PAYMENT": "Receive orders with payment in",
  "PRICING.CASH_CARD": "cash/card",
  "PRICING.INTEGRATION": "Payment Gateway Integration",
  "PRICING.PRINTING_ORDER_BY_PRINTER": "Printing orders by bluetooth printer",
  "PRICING.UPGRADE_": "Upgrade",
  "PRICING.14_DAY_FREE_TRAIL": "14 days Free trial",
  "PRICING.GET_THIS_PLAN": "Get this plan",
  "PRICING.START_FREE_TRAIL": "Start Free trial",
  "PRICING.YOU_WILL_NOT_BE":
    "You will not be charged on this trial period, you can cancel your subscription at any time.",
  "PRICING.SINCE": "Since",
  "PRICING.EVERY_THING_INCLUDED": "Everything included in the Advanced plan",
  "PRICING.WE_ARE_CREATE": "we create your app fully optimized for",
  "PRICING.IOS_AND_ANDROID": "IOs and Android",
  "PRICING.DONT_MISS":
    "Don’t miss anything thanks to the push notification system on your smartphone",
  "PRICING.PUBLICATION_IN": "Publication in",
  "PRICING.APP_STORE": "App Store ",
  "PRICING.AND": "and ",
  "PRICING.INCLUDE": " included",
  "PRICING.ORGANIZE": " Organize",
  "PRICING.EXCLUSIVE_CONTESTS": "exclusive contests",
  "PRICING.FOR_ALL_APP_USERS": "for all App users.",
  "PRICING.MAINTAIN_A_CLOSE_RELATIONSHIP":
    " Maintain a close relationship with your clients through ",
  "PRICING.LOYALTY_PROGRAM": "loyalty program",
  "PRICING.EXCLUSIVE_OFFERS": "exclusive offers",
  "PRICING.MANAGE_YOUR_RESTAURANTS_BOOKINGS":
    "Manage your restaurant bookings from your smartphone",
  "PRICING.AND_MUCH_MORE": "And much more ...",
  "PRICING.REQUEST_INFORMATION": "Request Information",
  "PRICING.FREQUENT_QUESTIONS": "Frequent questions",
  "PRICING.HOW_CAN_I_UPGRADE_MY_PLAN": " How can I upgrade my plan?",
  "PRICING.IF_YOU_WANT_TO_UNLOCK":
    "If you want to unlock the order’s functionalities -from anywhere, unlimited menus and orders from device, simply go above and choose any between the Advanced or Premium plans, according to your needs.",
  "PRICING.WHAT_ARE_AVAILABLE_PAYMENT_METHOD":
    "what are the available payment methods?",
  "PRICING.YOU_CAN_PAY":
    " You can pay by credit card from all countries, if you have problems with the payment, contact us and we will solve it as soon as possible",
  "PRICING.WHO_CAN_ACCESS_MY_QR_CODE": "Who can access my QR code?",
  "PRICING.YOUR_CODE_CAN":
    "Your code can be scanned by anyone. Which means that the more you distribute and publish your code, the more people will see your restaurant and your menu.",
  "PRICING.HOW_CURRENT_ACCURATE":
    "How current and accurate is the data collected?",
  "PRICING.ALL_DATA_IS_COLLECTED":
    "All data is collected in real time, so we generate statistics daily. We strive every day to provide you with the most up-to-date and real information for your business.",
  "PRICING.CAN_I_EDIT": "Can I edit the menu myself?",
  "PRICING.YES_YOU_WILL_HAVE_ACCESS":
    "Yes! You will have access to a very simple dashboard, where you can change dishes, photos, prices, descriptions and much more ...",
  "PRICING.WHAT_ARE_THE_FEES": "What are the fees?",
  "PRICING.AT_BANZZU_WE_DO_NOT":
    "At Banzzu we do not apply any type of commission, what you see is what you pay",
  "PRICING.IF_I_AM_OTHER":
    "If I am with other delivery companies, can I work with Banzzu?",
  "PRICING.YES_OUR_RECOMMENDATION":
    "Yes! Our recommendation is to continue making yourself known in this way and pass all new clients to Banzzu, knowing that with us you will not pay commissions.",
  "PRICING.CAN_THE_CUSTOMER":
    " Can the customer order from his device? and pay from it?",
  "PRICING.YES_THANK_TO_BANZZU":
    "Yes, thanks to Banzzu all your customers will be able to place their orders from their own smartphone, including paying for them.",
  "PRICING.WHAT_TYPE_OF_RESTAURANT":
    "What type of restaurant is the best to work with Banzzu?",
  "PRICING.BANZZU_IS_SOFTWARE":
    " Banzzu is software designed for all types of restaurants. Digitize your restaurant and apply new technological improvements, no matter what type of restaurant you have, start optimizing your business with Banzzu.",
  "PRICING.CAN_I_CREATE": "Can I create multiple menus?",
  "PRICING.YES_ONE_OF":
    "Yes! One of the benefits of our Advanced and Premium plans are the unlimited menus. Let your imagination fly and create day and night or holiday menus. Everything is possible with Banzzu.",

  "UPGRADE_MODAL.YOUR_PLAN_IS_TOO_SMALL": "Your plan is too small for you",
  "UPGRADE_MODAL.ITS_SEEMS":
    "Whoops! It seems that these features are not included in your current plan.",
  "UPGRADE_MODAL.INCREASE_PLAN":
    "Increase your plan and enjoy all the possibilities that Banzzu offers you.",
  "UPGRADE_MODAL.UPGRADE_MY_PLAN": "Upgrade my plan",
  "UPGRADE_MODAL.I_STICK": " I stick with my plan",

  "TOOLBAR.VIEW_WEB": "View your website",
  "TOOLBAR.IMPROVE_PLAN": "Improve your plan",

  "MERCHANDISE.ITEM.QR_TITLE": "Your Personalized QR Code",
  "MERCHANDISE.ITEM.QR_TEXT": "Scan to start ordering",
  "MERCHANDISE.ITEM.QR_SUB_TITLE":
    "Can you imagine having your QR code in all your tables? Now it's possible!",
  "MERCHANDISE.ITEM.QR_DESCRIPTION":
    "Get your QR code on customizable stickers. We send them to you ready for you to just stick them on and have your customers scan them.",
  "MERCHANDISE.ITEM.QR_CUSTOMIZE": "Customize your QR stickers",
  "MERCHANDISE.ITEM.QR_QUANTITY_TITLE":
    "How many QR Stickers do you want to print?",
  "MERCHANDISE.ITEM.ADDRESS_TITLE": "Introduce delivery address",
  "MERCHANDISE.ITEM.SEND_TO_RESTAURANT": "Send to restaurant address",
  "MERCHANDISE.ITEM.CREATE_MENUE": "We Create Your Menu",
  "MERCHANDISE.ITEM.CREATE_MENUE_DESCRIPTION":
    "We help you to have your perfect menu online without you having to worry about anything.",
  "MERCHANDISE.ITEM.DELIVERY_FLEET": "Your Delivery Fleet",
  "MERCHANDISE.ITEM.DELIVERY_FLEET_DESCRIPTION":
    "Now it is possible to have your own fleet hired by hours. We provide you with as many riders and as many hours as you require to meet your needs.",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF": "Introduce Yourself Online",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF_DESCRIPTION":
    "You will be able to be listed in a marketplace price comparator platform called Delitbee",

  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE":
    "We Create Your Corporate Website",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE_DESCRIPTION":
    "We are sure that apart from the order section that you create with Banzzu, you will want a corporate website where you can show the unique charm of your restaurant.",

  "MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE":
    "Info requested successfully, we will be in contact with you.",
  "MERCHANDISE.WANT_IT": "I want it",

  "ACCOUNT.ARE_YOU_SURE":
    "Are you sure you want to delete your account, you will lose all the menus created?",
  "ACCOUNT.DELETE_ACCOUNT": "Delete account",
  "ACCOUNT.YOUR_CURRENT_SUBSCRIPTION": "Your current subscription is Free",
  "ACCOUNT.YOUR_SUBSCRIPTION": "Your Subscription",
  "ACCOUNT.CURRENT_SUBSCRIPTION_IS_ADVANCED":
    "Your current subscription is Advanced",
  "ACCOUNT.CANCEL_SUBSCRIPTION": "Cancel Subscription",
  "ACCOUNT.ARE_YOU_SURE_TO_CANCEL_SUBSCRIPTION":
    "Are you sure you want to cancel your current subscription and come back to the free plan?",

  "SETUP_ACCOUNT.ACCOUNT_ALREADY_SETUPED": "Account already setuped",
  "SETUP_ACCOUNT.TITLE":
    "Getting started has never been easier, choose the password for your account",
  "SETUP_ACCOUNT.PASSWORD": "Password",
  "SETUP_ACCOUNT.CONFIRM_PASSWORD": "Confirm Password",
  "SETUP_ACCOUNT.SAVE_BUTTON": "Continue",

  "LANGUAGE.SPANISH": "Spanish",
  "LANGUAGE.ENGLISH": "English",
  "LANGUAGE.ITALIAN": "Italian",
  "LANGUAGE.FRENCH": "French",
  "LANGUAGE.GERMAN": "German",
  "LANGUAGE.CATALAN": "Catalan",
};
