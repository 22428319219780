import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";

import history from "@history";
import { FusePageSimple } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import UserNumberCard from "./components/UserNumberCard";
import UserRegisteredCard from "./components/UserRegisteredCard";
import OrdersAnalyticsCard from "./components/OrdersAnalyticsCard";
import {
  getUserNumber,
  previewWeb,
  addLastInteractionWithAnalytics,
} from "app/store/user/UserActions";
import { registerToken, getUserInfo } from "app/store/auth/LoginActions";
import { connect } from "react-redux";
import { askForPermissionToReceiveNotifications } from "app/helpers/push-notifications";
import { getMenuList } from "app/store/menu/MenuActions";
import TopDishesCard from "./components/TopDishesCard";
import IncomeAnalyticsCard from "./components/IncomeAnalyticsCard";
import HomeHeader from "./components/HomeHeader";

import { orderTypes } from "app/main/orders/components/OrdersType";

import { getOrderAnalytics } from "app/store/orders/OrdersActions";
import AverageOrderCard from "./components/AverageOrderCard";

const styles = (theme) => ({
  layoutRoot: {},
});

const AnalyticsPage = (props) => {
  const {
    classes,
    iosUsers,
    androidUsers,
    getUserNumber,
    registerToken,
    getUserInfo,
    previewWeb,
    getMenuList,
    menuList,
    getOrderAnalytics,
    user,
    userPermissions,
    addLastInteractionWithAnalytics,
  } = props;
  const { languageStrings } = useLanguageHelper();
  const loading = useSelector(({ banzzu }) => banzzu.auth.loading);
  const { orderAnalytics } = useSelector(({ banzzu }) => banzzu.order);
  const venueloading = useSelector(({ banzzu }) => banzzu.venue.loading);

  const [orderType, setOrderType] = useState("0");
  const [orderTypesValues, setOrderTypesValues] = useState([]);
  const [yearsList, setYearList] = useState([{ id: "1", name: 2021 }]);
  const [selectedYear, setSelectedYear] = useState(2021);

  useEffect(() => {
    let getToken = async () => {
      let token = await askForPermissionToReceiveNotifications();
      registerToken(token);
    };
    getToken();
    getUserNumber();
    getUserInfo();
    getMenuList();
    getOrderAnalytics();
    addLastInteractionWithAnalytics();
  }, []);

  useEffect(() => {
    setAnalyticsYearList();
  }, [orderAnalytics]);
  useEffect(() => {
    if (userPermissions && languageStrings) {
      let orderTypesValues = orderTypes.filter((type) => {
        if (userPermissions.hasCollection && type.id === 1) {
          return type;
        }

        if (userPermissions.hasDelivery && type.id === 2) {
          return type;
        }

        if (userPermissions.hasOrderFromTable && type.id === 3) {
          return type;
        }

        if (userPermissions.hasFutureOrder && type.id === 4) {
          return type;
        }
      });

      orderTypesValues = orderTypesValues.map((orderType) => ({
        ...orderType,
        name: languageStrings[orderType.name],
      }));

      setOrderTypesValues(orderTypesValues);
    }
  }, [languageStrings, userPermissions]);
  const filterByType = (ev) => {
    let { value } = ev.target;

    setOrderType(value);
  };
  const filterByYear = (ev) => {
    let { value } = ev.target;
    const _selectedYear = yearsList.find((item) => item.id === value);

    setSelectedYear(_selectedYear.name);
  };
  const setAnalyticsYearList = () => {
    if (
      orderAnalytics &&
      orderAnalytics.orderAvg &&
      orderAnalytics.orderAvg.length
    ) {
      const data = orderAnalytics.orderAvg.map((item, index) => {
        return { id: `${index}`, name: item._id.year };
      });
      data.sort((a, b) => b.name - a.name);
      data.length && setSelectedYear(data[0].name);

      setYearList(data);
    } else {
      return;
    }
  };
  const getFirstOption = () => {
    let firstOption = 2021;
    if (yearsList && yearsList.length) {
      firstOption = yearsList[0].name;
    }
    return firstOption;
  };

  return (
    <LoadingOverlay
      active={loading || venueloading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageSimple
        classes={{ root: classes.layoutRoot }}
        content={
          <>
            {user.settings ? (
              <div className="p-24">
                <br />
                <HomeHeader
                  title={languageStrings["HOME_PAGE.ANALYTICS"]}
                  setSearchText={""}
                  searchType={"order"}
                  selectors={[
                    {
                      onChange: filterByYear,
                      values: yearsList,
                      keys: ["id", "name"],
                      title: languageStrings["HOME_PAGE.SELECT_YEAR"],
                      firstOption: getFirstOption(),
                    },
                    {
                      onChange: filterByType,
                      values: orderTypesValues,
                      keys: ["id", "name"],
                      title:
                        languageStrings["ORDER_LIST_PAGE.TYPES_PLACEHOLDER"],
                      firstOption: languageStrings["ORDER_LIST_PAGE.ALL_TYPES"],
                    },
                  ]}
                />

                <br />
                {user.permission.hasApp && (
                  <div className="flex flex-col sm:flex sm:flex-row pb-32">
                    <UserNumberCard
                      title={languageStrings["HOME_PAGE.ANDROID_USER"]}
                      number={androidUsers}
                      textColor={"text-green"}
                    />
                    <UserNumberCard
                      title={languageStrings["HOME_PAGE.IOS_USER"]}
                      number={iosUsers}
                      textColor={"text-pink"}
                    />
                  </div>
                )}
                <div className="flex flex-col sm:flex sm:flex-row pb-32">
                  <UserRegisteredCard selectedYear={selectedYear} />
                  <OrdersAnalyticsCard
                    orderType={orderType}
                    selectedYear={selectedYear}
                  />
                </div>
                <div className="flex flex-col sm:flex sm:flex-row pb-32">
                  <AverageOrderCard
                    title={languageStrings["GENERAL.AVERAGE_ORDER"]}
                    textColor={"red"}
                    orderType={orderType}
                    selectedYear={selectedYear}
                  />
                  <TopDishesCard
                    title={languageStrings["GENERAL.TOP_FIVE_DISHES"]}
                    textColor={"red"}
                    orderType={orderType}
                    selectedYear={selectedYear}
                  />
                  <IncomeAnalyticsCard
                    orderType={orderType}
                    selectedYear={selectedYear}
                  />
                </div>
              </div>
            ) : null}
          </>
        }
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ banzzu }) => {
  const { androidUsers, iosUsers } = banzzu.user;
  const { menuList } = banzzu.menu;
  const userPermissions = banzzu.auth.user.permission;

  return {
    androidUsers,
    iosUsers,
    user: banzzu.auth.user,
    menuList,
    userPermissions,
  };
};
const mapDispatchToProps = {
  getUserNumber,
  registerToken,
  previewWeb,
  getUserInfo,
  getMenuList,
  getOrderAnalytics,
  addLastInteractionWithAnalytics,
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage)
);
