import { LoyaltyActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: LoyaltyState = {
  rewardList: [],
  transactionList: [],
  rewardDetail: {},
  loading: false,
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
}

const LoyaltyReducer = (
  state: LoyaltyState = INITIAL_STATE,
  action: Action
): LoyaltyState => {
  switch (action.type) {
    case LoyaltyActionTypes.TRANSACTION_LIST_START: {
      return {
        ...state,
        transactionList: [],
        loading: true,
      };
    }
    case LoyaltyActionTypes.TRANSACTION_LIST_SUCCESS: {
      return {
        ...state,
        transactionList: action.payload.data,
        loading: false,
      };
    }
    case LoyaltyActionTypes.TRANSACTION_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LoyaltyActionTypes.LOYALTY_LIST_START: {
      return {
        ...state,
        rewardList: [],
        loading: true,
      };
    }
    case LoyaltyActionTypes.LOYALTY_LIST_SUCCESS: {
      return {
        ...state,
        rewardList: action.payload,
        loading: false,
      };
    }
    case LoyaltyActionTypes.LOYALTY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LoyaltyActionTypes.SET_TRANSACTION_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_START: {
      return { ...state, loading: true, rewardDetail: {} };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_SUCCESS: {
      return {
        ...state,
        rewardDetail: action.payload,
        loading: false,
      };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LoyaltyActionTypes.LOYALTY_REWARD_CREATE_START: {
      return { ...state, loading: true };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LoyaltyActionTypes.LOYALTY_REWARD_EDIT_START: {
      return { ...state, loading: true };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LoyaltyActionTypes.LOYALTY_REWARD_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default LoyaltyReducer;
