import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { useDispatch } from "react-redux";
import { FC } from "react";
import CustomModal from "app/main/common/CustomModal";

interface IProps {
  onSuccess: (payload: any) => void;
  value?: number | string;
  handleChange: (payload: string) => void;
  onCloseModal: () => void;
  title: string;
  showModal: boolean;
}

export const AlertInputDialog: FC<IProps> = ({
  onSuccess,
  value,
  handleChange,
  title,
  showModal,
  onCloseModal,
}) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
      <div className="bg-white h-200 w-360 rounded-4">
        <DialogTitle id="alert-dialog-title">
          {languageStrings[title]}
        </DialogTitle>
        <DialogContent>
          <div className={"flex flex-row my-16 justify-center items-center"}>
            <Input
              disableUnderline
              className={"border-2 rounded-12 w-192 p-4 border-grey-light"}
              style={{ color: "black" }}
              fullWidth
              type="number"
              name="D-Time"
              value={value}
              inputProps={{
                min: 0,
              }}
              onChange={(event) => {
                handleChange(event.target.value);
              }}
            />

            <h3 style={{ marginLeft: 5, marginTop: 13 }}>
              {languageStrings["GENERAL.MIN"]}
            </h3>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onSuccess(true);
            }}
            color="secondary"
          >
            {languageStrings["GENERAL.CONFIRM_BUTTON"]}
          </Button>
          <Button onClick={onCloseModal} color="primary" autoFocus>
            {languageStrings["GENERAL.CANCEL_BUTTON"]}
          </Button>
        </DialogActions>
      </div>
    </CustomModal>
  );
};
