import React from "react";
import {
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  Divider,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import MenuCheckboxes from "./MenuCheckboxes";

const OrderCard = ({
  title,
  image,
  name,
  user,
  onChange,
  form,
  onMenuChange,
  onCashPayChange,
  onCardPayChange,
  paymentType,
}) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <Card
      className={
        "flex flex-col items-center justify-center pl-8 pr-8 md:m-6 mt-5"
      }
      style={{ minWidth: "25%" }}
    >
      <div className={"flex items-center justify-center w-9/12"}>
        <img
          src={image}
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        className={"flex flex-col justify-between items-center w-11/12 mt-5"}
      >
        <div
          className={"flex flex-row justify-between items-center w-11/12 mt-5"}
        >
          <Typography className="text-16 truncate">
            {languageStrings[title]}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={form[name]} onChange={onChange} name={name} />
              }
            />
          </FormGroup>
        </div>
        <Divider className={"bg-grey w-full"} />

        <FormGroup
          className={"flex flex-row justify-evenly items-center w-full  mt-5"}
        >
          <FormControlLabel
            control={
              <div className={"flex flex-row items-center"}>
                <Typography className="text-13 truncate ">
                  {languageStrings["PAYMENT_SETTINGS_PAGE.PAY_BY_CASH"]}
                </Typography>
                <Switch
                  checked={form[paymentType].payByCash}
                  onChange={onCashPayChange}
                  name={"payByCash"}
                  disabled={!form[name]}
                />
              </div>
            }
          />
          <FormControlLabel
            control={
              <div className={"flex flex-row items-center"}>
                <Typography className="text-13 truncate">
                  {languageStrings["PAYMENT_SETTINGS_PAGE.PAY_BY_CARD"]}
                </Typography>
                <Switch
                  disabled={!form[name]}
                  checked={form[paymentType].payByCard}
                  onChange={onCardPayChange}
                  name={"payByCard"}
                />
              </div>
            }
          />
        </FormGroup>
      </div>
      <MenuCheckboxes
        user={user}
        form={form}
        onChange={onMenuChange}
        name={name}
      />
    </Card>
  );
};

export default OrderCard;
