import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography, Select, OutlinedInput } from "@material-ui/core";
import clsx from "clsx";
import { Line } from "react-chartjs-2";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { connect } from "react-redux";

interface OwnProps {
  orderType: string;
  selectedYear: number;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const styles = (theme) => ({
  layoutRoot: {},
});

const IncomeAnalyticsCard = ({
  orderAnalytics,
  orderType,
  selectedYear,
}: Props) => {
  const { languageStrings } = useLanguageHelper();

  const [orderIncomPerDayFiltered, setOrderIcomePerDayFiltered] =
    useState(null);
  useEffect(() => {
    let data = [0, 0, 0, 0, 0, 0, 0];
    let i = 1;

    if (
      orderType == "0" &&
      orderAnalytics &&
      orderAnalytics.perDayIncome &&
      orderAnalytics.perDayIncome.length > 0
    ) {
      for (i = 1; i <= 7; i++) {
        orderAnalytics.perDayIncome.map((item) => {
          if (item._id.year == selectedYear && item._id.days == i) {
            data[i - 1] = item.total.toFixed(2);
          }
        });
      }
    } else if (
      orderAnalytics &&
      orderAnalytics.perDayIncomeWithTypes &&
      orderAnalytics.perDayIncomeWithTypes.length > 0
    ) {
      for (i = 1; i <= 7; i++) {
        orderAnalytics.perDayIncomeWithTypes.map((item) => {
          if (
            item._id.days == i &&
            item._id.type == orderType &&
            item._id.year == selectedYear
          ) {
            data[i - 1] = item.total.toFixed(2);
          }
        });
      }
    }

    setOrderIcomePerDayFiltered({
      labels: [
        languageStrings["GENERAL.SUNDAY"],
        languageStrings["GENERAL.MONDAY"],
        languageStrings["GENERAL.TUESDAY"],
        languageStrings["GENERAL.WEDNESDAY"],
        languageStrings["GENERAL.THURSDAY"],
        languageStrings["GENERAL.FRIDAY"],
        languageStrings["GENERAL.SATURDAY"],
      ],
      datasets: [
        {
          label: "Revenue ",
          backgroundColor: "rgba(0,153,0,0.2)",
          borderColor: "rgba(0,153,0,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(0,153,0,0.4)",
          hoverBorderColor: "rgba(0,153,0,1)",
          data,
        },
      ],
    });
  }, [orderAnalytics, orderType, selectedYear, languageStrings]);

  return (
    <Card className="w-full md:w-6/12 rounded-8 shadow-none border-1 p-16  md:mt-0 mt-16 ">
      <div className={"flex w-full justify-between items-center"}>
        <Typography className="h2 pb-16" color="primary">
          {languageStrings["GENERAL.ICOME_PER_DAY"]}
        </Typography>
      </div>
      <Line
        data={orderIncomPerDayFiltered}
        height={120}
        legend={{ display: false }}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    orderAnalytics: state.banzzu.order.orderAnalytics,
  };
};
const mapDispatchToProps = {};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(IncomeAnalyticsCard)
);
