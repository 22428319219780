import { axiosInstance as axios } from "app/api/axios";

import History from "@history";
import _ from "@lodash";
import { NotificationActionTypes } from "../redux/actionTypes";
import { NOTIFICATION, getUserId } from "app/api/Endpoint";
import { successAlert, errorAlert } from "app/helpers/alerts";
import { Notification } from "../../models/Notification";

export function setNotificationSearchText(event) {
  return {
    type: NotificationActionTypes.SET_NOTIFICATION_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getNotificationList = () => {
  return (dispatch) => {
    dispatch({
      type: NotificationActionTypes.NOTIFICATION_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(NOTIFICATION, { params })
      .then((res) => {
        getNotificationListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getNotificationListFail(dispatch, "There was an error connection");
      });
  };
};

const getNotificationListFail = (dispatch, errorMessage) => {
  dispatch({
    type: NotificationActionTypes.NOTIFICATION_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getNotificationListSuccess = (dispatch, data) => {
  dispatch({
    type: NotificationActionTypes.NOTIFICATION_LIST_SUCCESS,
    payload: data,
  });
};

export const createNotification = (notification: Notification) => {
  return (dispatch) => {
    dispatch({
      type: NotificationActionTypes.NOTIFICATION_CREATE_START,
    });

    const payload = {
      title: notification.title,
      description: notification.description,
      clientId: getUserId(),
    };

    axios
      .post(NOTIFICATION, payload)
      .then((res) => {
        createNotificationSuccess(dispatch, res.data);
      })
      .catch(() => {
        createNotificationFail(dispatch, "There was an error connection");
      });
  };
};

const createNotificationFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: NotificationActionTypes.NOTIFICATION_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createNotificationSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: NotificationActionTypes.NOTIFICATION_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/NotificationList/",
  });
};
