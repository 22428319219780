import React from "react";
import _ from "@lodash";
import { Typography, Icon, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const AddressListCard = ({ addressList }) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Icon className="mr-16" color="action">
            local_shipping
          </Icon>
          <Typography className="h2" color="textSecondary">
            {languageStrings["GENERAL.ADDRESS_LIST"]}
          </Typography>
        </div>
        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.ADDRESS"]}</th>
                <th>{languageStrings["GENERAL.POSTCODE"]}</th>
                <th>{languageStrings["GENERAL.CITY"]}</th>
                <th>{languageStrings["GENERAL.DETAIL"]}</th>
              </tr>
            </thead>
            <tbody>
              {addressList &&
                addressList.length > 0 &&
                addressList.map((address) => (
                  <tr>
                    <td>
                      <span className="truncate">{address.address}</span>
                    </td>
                    <td>
                      <span className="truncate">{address.postcode}</span>
                    </td>
                    <td>
                      <span className="truncate">{address.city}</span>
                    </td>
                    <td>
                      <span className="truncate">{address.details}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default AddressListCard;
