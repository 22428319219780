import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { Theme, Typography, Card, Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { FuseUtils } from "@fuse";
import { makeStyles } from "@material-ui/core/styles";
import { deleteCard, setDefaultCard } from "app/store/payment/PaymentActions";

interface Props {
  title?: string;
  brand?: string;
  selectedCard: string;
  id: string;
  onSelect: (value: string) => void;
}

interface StyleProps {
  isSelected?: boolean;
}

const EditItemCard: FC<Props> = ({
  selectedCard,
  title,
  id,
  brand,
  onSelect,
}) => {
  const isSelected = selectedCard === id;
  const styleProps: StyleProps = {
    isSelected,
  };

  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: ({ isSelected }) => ({
      boxShadow: !isSelected
        ? "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)"
        : "rgba(255, 131, 48,0.6) 0px 0px 15px 0px, rgba(250, 122, 13, 0) 0px 0px 0px 0px",
    }),
  }));

  const Classes = useStyles(styleProps);

  return (
    <Card
      className={[
        "flex flex-col m-6 cursor-pointer p-32 mt-12 relative",
        Classes.root,
      ].join(" ")}
      key={title}
      onClick={() => onSelect(id)}
    >
      <div className={"flex flex-col w-full items-start"}>
        <div className="flex flex-row self-center">
          <div className="flex items-center">
            <img
              className="mr-1"
              width="50"
              src={FuseUtils.getCardImage(brand)}
            ></img>
            <Typography className="whitespace-no-wrap">{title}</Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EditItemCard;
