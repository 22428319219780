import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import reducer from "app/store/venues/VenuesReducer";
import * as Actions from "app/store/actions";
import withReducer from "app/store/withReducer";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

function AlertDialog(props) {
  const { languageStrings } = useLanguageHelper()
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(Actions.closeDialog());
            props.onSuccess(true);
          }}
          color="secondary"
        >
          {languageStrings["GENERAL.CONFIRM_BUTTON"]}
        </Button>
        <Button
          onClick={() => dispatch(Actions.closeDialog())}
          color="primary"
          autoFocus
        >
          {languageStrings["GENERAL.CANCEL_BUTTON"]}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

export default withReducer("BookingReducer", reducer)(AlertDialog);
