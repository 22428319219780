import React from "react";
import { TableCell } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  red: {
    color: "red",
    fontWeight: "bold",
  },
}));

const DiscountedAmountCell = ({ data, dataTypes }) => {

  const classes = useStyles();
  return (
    <TableCell className="truncate" component="th" scope="row">

      <font>
        {data[dataTypes[1]] && (
          <font >{data[dataTypes[1]]}</font>
        )}{" "}
        {data[dataTypes[0]] && !data[dataTypes[1]] && <div>{data[dataTypes[0]]}</div>}
        {data[dataTypes[0]] && data[dataTypes[1]] && <strike className={clsx(classes.red)}>{data[dataTypes[0]]}</strike>}
      </font>
    </TableCell>
  );
};

export default DiscountedAmountCell;
