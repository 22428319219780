import React from "react";
import { TableCell, Icon, Button } from "@material-ui/core";

const IconCtaCell = ({ icon, onClick }) => {
  return (
    <TableCell>
      <Button
        component="a"
        rel="noreferrer noopener"
        role="button"
        className="normal-case"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        <Icon className="list-item-icon text-white text-16 flex-shrink-0 mr-16">
          {icon}
        </Icon>
      </Button>
    </TableCell>
  );
};

export default IconCtaCell;
