import React, { useEffect, useState } from "react";
import {
  Icon,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Button,
  TextField,
} from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { FuseAnimate, FusePageCarded } from "@fuse";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useForm, useDebounce } from "@fuse/hooks";
import MenuInformationModal from "../common/MenuInformationModal";
import { updateUserGoals } from "app/store/auth/LoginActions";

import {
  getMenuItemCategory,
  getMenuDetail,
  getMenuItemList,
  createMenuCategory,
  editMenuCategory,
  sortMenuListCategory,
} from "app/store/menu/MenuActions";
import CategoryItem from "./menuDetailPro/CategoryItem";
import MenuDetailForm from "./menuDetailPro/MenuDetailForm";
import AlertDialog from "../common/AlertDialog";
import * as Actions from "app/store/actions";
import History from "@history";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function MenuDetailPro({ match }) {
  const classes = useStyles();

  let loading = useSelector(({ banzzu }) => banzzu.menu.loading);
  let { user } = useSelector(({ banzzu }) => banzzu.auth);
  const { goals } = user;
  const { languageStrings } = useLanguageHelper();
  const [open, setOpen] = useState(false);
  const [newCreated, setNewCreated] = useState(false);
  const [openInformationModal, setOpenInformationModal] = useState(false);

  const { form, handleChange, setForm } = useForm({ title: "" });
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemCategory
  );
  const menuDetail = useSelector(({ banzzu }) => banzzu.menu.menuDetail);
  const [onlyRead, setOnlyRead] = useState(menuDetail.onlyRead);
  const [list, setList] = useState(categoryList);
  const dispatch = useDispatch();
  const { productId } = match.params;

  useEffect(() => {
    setList(categoryList);
  }, [categoryList]);

  useEffect(() => {
    if (goals && goals.firstView == false) {
      setOpenInformationModal(true);
    }
  }, [goals]);
  useEffect(() => {
    setOnlyRead(menuDetail.onlyRead);
  }, [menuDetail]);
  useEffect(() => {
    if (productId != "new") {
      dispatch(getMenuItemCategory(productId));
      dispatch(getMenuItemList(productId));
      dispatch(getMenuDetail(productId));
    }
  }, [dispatch, productId]);

  const dirtyInitial = {
    title: false,
    // description: false,
  };

  const errorInitial = {
    title: "",
    // description: "",
  };

  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return { ...prevState, title: "Minimum size 3 characters" };
      });
    }
  }, 500);

  function previousPage() {
    History.push({
      pathname: `/menuList`,
    });
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
      menuId: productId,
    };

    const { from, to } = sortOrder;

    const sortedCategoryList = [...list];
    let movedMenu = sortedCategoryList.splice(from, 1);
    sortedCategoryList.splice(to, 0, _.first(movedMenu));
    setList(sortedCategoryList);

    dispatch(sortMenuListCategory(sortOrder));
  };

  return (
    <>
      <MenuInformationModal
        open={openInformationModal}
        setOpen={() => {
          setOpenInformationModal(false);
        }}
        updateUserGoals={updateUserGoals}
        user={user}
      />
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            zIndex: 9999,
          },
          overlay: {
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            display: "flex",
            textAlign: "center",
            fontSize: "1.2em",
            color: "#FFF",
            background: "rgba(0,0,0,0.7)",
            zIndex: "1400",
            transition: "opacity 500ms ease-in",
            opacity: 1,
            zIndex: 9999,
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={() => previousPage()}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["MENU_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["MENU_DETAIL_PAGE.NEW_MENUE"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["MENU_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              {onlyRead && (
                <Typography variant="caption" variant="h5">
                  {languageStrings["MENU_DETAIL_PAGE.MENU_SHARED"]}
                </Typography>
              )}
              <Typography
                className={"cursor-pointer"}
                variant="caption"
                onClick={() => {
                  setOpenInformationModal(true);
                }}
              >
                {languageStrings["MENU_DETAIL_PAGE.HELP"]}
              </Typography>
            </div>
          }
          content={
            <div className="p-16 sm:p-24 w-full">
              <div className="justify-center sm:justify-start">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <h2 id="transition-modal-title" className="pb-32">
                        {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
                      </h2>
                      <p id="transition-modal-description">
                        <TextField
                          className="mt-8 mb-16 mr-8"
                          required
                          label={languageStrings["GENERAL.NAME"]}
                          id="title"
                          name="title"
                          value={form.title}
                          onlyRead={onlyRead}
                          onChange={handleTitleChange}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </p>
                      <Button
                        className="whitespace-no-wrap"
                        variant="contained"
                        hidden={onlyRead ? true : false}
                        onClick={() => {
                          if (form._id === undefined) {
                            dispatch(createMenuCategory(productId, form.title));
                            setNewCreated(true);
                          } else {
                            dispatch(
                              editMenuCategory(form._id, form.title, productId)
                            );
                          }
                          setForm({ title: "" });
                          setOpen(false);
                        }}
                      >
                        {languageStrings["GENERAL.SAVE"]}
                      </Button>
                    </div>
                  </Fade>
                </Modal>
                <MenuDetailForm menuId={productId} />

                {productId !== "new" && (
                  <>
                    <Typography variant="h5" component="h4" className="mb-16">
                      Carta
                    </Typography>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <DropeableCategory
                        form={form}
                        productId={productId}
                        categoryList={list}
                        setOpen={setOpen}
                        onlyRead={onlyRead}
                        newCreated={newCreated}
                      />
                    </DragDropContext>

                    <Button
                      className="mt-16 bg-grey-lightest"
                      variant="contained"
                      onClick={() => {
                        setOpen(true);
                      }}
                      hidden={onlyRead ? true : false}
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        save
                      </Icon>
                      {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
                    </Button>
                  </>
                )}
              </div>
            </div>
          }
          innerScroll
        />
      </LoadingOverlay>
    </>
  );
}

const DropeableCategory = ({
  categoryList,
  productId,
  form,
  setOpen,
  onlyRead,
  newCreated,
}) => {
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {categoryList.map((category, index) => {
            if (categoryList.length == index + 1) {
              // setOpen(true);
            }
            return (
              <CategoryItem
                key={category._id}
                index={index}
                category={category}
                productId={productId}
                onlyRead={onlyRead}
                defaultExpanded={
                  newCreated && categoryList.length == index + 1 ? true : false
                }
                onClick={() => {
                  form._id = category._id;
                  form.title = category.title;
                  setOpen(true);
                }}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default withReducer("GalleryReducer", reducer)(MenuDetailPro);
