import React, { useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import { useLanguageHelper } from 'app/helpers/LanguageHelper/index';
import { connect } from 'react-redux';
import {
  getOrderList,
  setOrderSearchText,
} from 'app/store/orders/OrdersActions';
import ListHeader from 'app/main/common/List/ListHeader';
import ListTable from 'app/main/common/List/ListTable';
import History from '@history';
import { orderStatuses } from 'app/main/orders/components/OrdersStatus';
import { orderTypes } from 'app/main/orders/components/OrdersType';

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const OrdersNotCompletedCard = ({
  orderList,
  getOrderList,
  setOrderSearchText,
  searchText,
  userPermissions,
}: Props) => {
  const { languageStrings } = useLanguageHelper();

  const [orderListFiltered, setOrderListFiltered] =
    useState(null);
  const [orderStatusValues, setOrderStatusValues] =
    useState([]);
  const [orderTypesValues, setOrderTypesValues] = useState(
    [],
  );
  const [orderType, setOrderType] = useState('0');

  const handleClick = item => {
    History.push('/order/' + item._id);
  };

  const headerRows = {
    data: [
      {
        id: 'referenceNo',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.REF'],
        sort: true,
        type: 'text',
      },
      {
        id: 'venueTitle',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.VENUE'],
        sort: true,
        type: 'text',
      },
      {
        id: 'type',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.ORDER_TYPE'],
        sort: true,
        type: 'orderType',
      },
      {
        id: 'price',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.PRICE'],
        sort: true,
        type: 'text',
      },
      {
        id: 'orderStatus',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.STATUS'],
        sort: true,
        type: 'orderStatus',
      },
      {
        id: 'createdAt',
        align: 'left',
        disablePadding: false,
        label: languageStrings['GENERAL.DATE'],
        sort: true,
        type: 'time',
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: ['referenceNo'],
  };

  const filterByType = ev => {
    let { value } = ev.target;
    setOrderType(value);
  };

  const filterOrderList = () => {
    let filteredOrderList = orderList;

    if (orderType !== '0') {
      filteredOrderList = filteredOrderList.filter(
        order =>
          order.type == orderType &&
          order.orderStatus != 5 &&
          order.orderStatus != 6 &&
          order.orderStatus != 8,
      );
    }

    if (orderType === '0') {
      filteredOrderList = filteredOrderList.filter(
        order =>
          order.orderStatus != 5 &&
          order.orderStatus != 6 &&
          order.orderStatus != 8,
      );
    }

    setOrderListFiltered(filteredOrderList);
  };

  useEffect(() => {
    getOrderList({
      status: '0',
      type: '0',
      order: {},
      searchText: '',
    });
  }, []);

  useEffect(() => {
    if (userPermissions && languageStrings) {
      let orderTypesValues = orderTypes.filter(type => {
        if (
          userPermissions.hasCollection &&
          type.id === 1
        ) {
          return type;
        }

        if (userPermissions.hasDelivery && type.id === 2) {
          return type;
        }

        if (
          userPermissions.hasOrderFromTable &&
          type.id === 3
        ) {
          return type;
        }

        if (
          userPermissions.hasFutureOrder &&
          type.id === 4
        ) {
          return type;
        }
      });

      orderTypesValues = orderTypesValues.map(
        orderType => ({
          ...orderType,
          name: languageStrings[orderType.name],
        }),
      );

      setOrderTypesValues(orderTypesValues);
    }
  }, [languageStrings, userPermissions]);

  useEffect(() => {
    filterOrderList();
  }, [orderType]);

  useEffect(() => {
    setOrderListFiltered(
      orderList.filter(
        order =>
          order.orderStatus != 5 &&
          order.orderStatus != 6 &&
          order.orderStatus != 8,
      ),
    );
  }, [orderList]);

  return userPermissions ? (
    <Card className='w-full rounded-8 shadow-none border-1 p-16'>
      <ListHeader
        title={languageStrings['HOME_PAGE.PENDING_ORDERS']}
        setSearchText={setOrderSearchText}
        searchType={'order'}
        selectors={[
          {
            onChange: filterByType,
            values: orderTypesValues,
            keys: ['id', 'name'],
            title:
              languageStrings[
                'ORDER_LIST_PAGE.TYPES_PLACEHOLDER'
              ],
            firstOption:
              languageStrings['ORDER_LIST_PAGE.ALL_TYPES'],
          },
        ]}
      />
      <ListTable
        handleClick={handleClick}
        headerRows={headerRows}
        data={orderListFiltered}
        searchText={searchText}
      />
    </Card>
  ) : null;
};

const mapStateToProps = state => {
  return {
    orderList: state.banzzu.order.orderList,
    searchText: state.banzzu.order.searchText,
    userPermissions: state.banzzu.auth.user.permission,
  };
};
const mapDispatchToProps = {
  getOrderList,
  setOrderSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersNotCompletedCard);
