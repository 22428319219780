import React from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate } from "@fuse";
import { useForm } from "@fuse/hooks";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { sendContactSupportEmail } from "app/store/user/UserActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
}));

function ContactSupport() {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();

  const { form, handleChange } = useForm({
    subject: "",
    message: "",
  });

  function isFormValid() {
    return form && form.subject != "" && form.message != "";
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    dispatch(
      sendContactSupportEmail(
        { message: form.message, subject: form.subject, clientId: user._id },
        languageStrings
      )
    );
  }

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-10"
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <FuseAnimate animation="transition.expandIn">
          <Card className=" md:w-8/12 w-full">
            <CardContent className="flex flex-col p-28">
              <Typography variant="h6" className="mt-8 mb-32 flex self-center">
                {languageStrings["CONTACT_SUPPORT.TITLE"]}
              </Typography>
              <Typography
                variant="h6"
                className="mt-8 mb-8 md:text-left text-center"
                style={{ fontSize: "16px" }}
              >
                {languageStrings["CONTACT_SUPPORT.SUB_TITLE"]}
                <a href="mailto:support@banzzu.com">support@banzzu.com</a>
              </Typography>
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit}
              >
                <TextField
                  className="mb-16"
                  label={languageStrings["CONTACT_SUPPORT.SUBJECT"]}
                  type="text"
                  name="subject"
                  value={form.subject}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  fullWidth
                />
                <TextField
                  className="mb-16"
                  label={languageStrings["CONTACT_SUPPORT.MESSAGE"]}
                  name="message"
                  multiline
                  rows={6}
                  value={form.message}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    maxLength: 4000,
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="w-224 mx-auto mt-16"
                  aria-label="SEND"
                  disabled={!isFormValid()}
                  type="submit"
                >
                  {languageStrings["CONTACT_SUPPORT.SEND"]}
                </Button>
              </form>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default ContactSupport;
