import MenuList from "./MenuList";
import MenuDetail from "./MenuDetail";
import MenuItemList from "./menuItemList/MenuItemList";
import MenuItemDetail from "./menuItem/MenuItemDetail";
import MenuCategory from "./menuCategory/MenuCategory";
import MenuDetailPro from "./MenuDetailPro";

export const MenuConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/menuList",
      component: MenuList,
    },
    {
      path: "/menuDetail/:productId",
      component: MenuDetail,
    },
    {
      path: "/menuDetailPro/:productId",
      component: MenuDetailPro,
    },
    {
      path: "/menuItemList/:productId",
      component: MenuItemList,
    },
    {
      path: "/menuCategory/:productId",
      component: MenuCategory,
    },
    {
      path: "/menuItemDetail/:menuId/:productId",
      component: MenuItemDetail,
    },
    {
      path: "/menuItemDetail/:productId",
      component: MenuItemDetail,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
