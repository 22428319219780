import OrderFromTable from './OrderFromTable'

export const OrderFromTableConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/orderFromTable',
      component: OrderFromTable
    },
  // {
  //   path: "/orderFromTable/:productId",
  //   component: WhatsOnDetail
  // }
  ]
}

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
