import DirectLogin  from "./DirectLogin";

export const DirectLoginConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/directLogin/:accessToken/:userId/:masterId",
      component: DirectLogin
    }
  ]
};