import React from "react";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

import OrderButtons from "./OrderButtons";

interface Props {
  orderId: string;
  orderStatus: string;
  orderType: string;
}

const OrderButtonsWrapper = ({ orderId, orderStatus, orderType }: Props) => {
  const { languageStrings } = useLanguageHelper();

  console.log(orderStatus);

  let ready = (
    <OrderButtons
      orderType={orderType}
      orderStatus={orderStatus}
      orderId={orderId}
      textPrimary={languageStrings["ORDER_PAGE.STATUS_BUTTON.START_DELIVERY"]}
      iconPrimary={"check"}
      statusPrimary={"4"}
      textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
      iconSecondary={"close"}
      statusSecondary={"6"}
    />
  );

  if (orderStatus == "3" && orderType == "1") {
    ready = (
      <OrderButtons
        orderStatus={orderStatus}
        orderType={orderType}
        orderId={orderId}
        textPrimary={languageStrings["ORDER_PAGE.STATUS_BUTTON.COLLECT"]}
        iconPrimary={"check"}
        statusPrimary={"7"}
        textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
        iconSecondary={"close"}
        statusSecondary={"6"}
      />
    );
  }

  if ((orderStatus == "0" || orderStatus == "1") && orderType == "3") {
    return (
      <OrderButtons
        orderStatus={orderStatus}
        orderType={orderType}
        orderId={orderId}
        textPrimary={
          languageStrings["ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED"]
        }
        iconPrimary={"check"}
        statusPrimary={"5"}
        textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
        iconSecondary={"close"}
        statusSecondary={"6"}
      />
    );
  }

  return (
    <>
      {orderStatus == "0" && (
        <OrderButtons
          orderStatus={orderStatus}
          orderType={orderType}
          orderId={orderId}
          textPrimary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CONFIRM"]}
          iconPrimary={"check"}
          statusPrimary={"2"}
          textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
          iconSecondary={"close"}
          statusSecondary={"6"}
        />
      )}
      {orderStatus == "1" && (
        <OrderButtons
          orderStatus={orderStatus}
          orderType={orderType}
          orderId={orderId}
          textPrimary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CONFIRM"]}
          iconPrimary={"check"}
          statusPrimary={"2"}
          textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
          iconSecondary={"close"}
          statusSecondary={"6"}
        />
      )}
      {orderStatus == "2" && (
        <OrderButtons
          orderStatus={orderStatus}
          orderType={orderType}
          orderId={orderId}
          textPrimary={
            languageStrings["ORDER_PAGE.STATUS_BUTTON.START_PREPARING"]
          }
          iconPrimary={"check"}
          statusPrimary={"3"}
          textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
          iconSecondary={"close"}
          statusSecondary={"6"}
        />
      )}
      {orderStatus == "3" && ready}
      {orderStatus == "4" && (
        <OrderButtons
          orderStatus={orderStatus}
          orderType={orderType}
          orderId={orderId}
          textPrimary={
            languageStrings["ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED"]
          }
          iconPrimary={"check"}
          statusPrimary={"5"}
          textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
          iconSecondary={"close"}
          statusSecondary={"6"}
        />
      )}
      {orderStatus == "7" && (
        <OrderButtons
          orderStatus={orderStatus}
          orderType={orderType}
          orderId={orderId}
          textPrimary={languageStrings["ORDER_PAGE.STATUS_BUTTON.COLLECTD"]}
          iconPrimary={"check"}
          statusPrimary={"8"}
          textSecondary={languageStrings["ORDER_PAGE.STATUS_BUTTON.CANCEL"]}
          iconSecondary={"close"}
          statusSecondary={"6"}
        />
      )}
    </>
  );
};

export default OrderButtonsWrapper;
