import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography, Select, OutlinedInput } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { connect } from "react-redux";
import { database } from "firebase";

interface OwnProps {
  title: string;
  number: string;
  textColor: string;
  selectedYear: number;
}
const styles = (theme) => ({
  layoutRoot: {},
});

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const UserRegisteredCard = ({ userRegistered, selectedYear }: Props) => {
  const { languageStrings } = useLanguageHelper();
  const [userRegisteredFiltered, setUserRegisteredFiltered] = useState(null);

  useEffect(() => {
    let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let i = 1;

    for (i = 1; i <= 12; i++) {
      userRegistered.map((item) => {
        if (item.month === i && item.year === selectedYear) {
          data[i - 1] = item.count;
        }
      });
    }
    console.log(data);

    setUserRegisteredFiltered({
      labels: [
        languageStrings["GENERAL.JANUARY"],
        languageStrings["GENERAL.FEBRUARY"],
        languageStrings["GENERAL.MARCH"],
        languageStrings["GENERAL.APRIL"],
        languageStrings["GENERAL.MAY"],
        languageStrings["GENERAL.JUNE"],
        languageStrings["GENERAL.JULY"],
        languageStrings["GENERAL.AUGUST"],
        languageStrings["GENERAL.SEPTEMBER"],
        languageStrings["GENERAL.OCTOBER"],
        languageStrings["GENERAL.NOVEMBER"],
        languageStrings["GENERAL.DECEMBER"],
      ],
      datasets: [
        {
          label: "Registered Users ",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data,
        },
      ],
    });
  }, [userRegistered, languageStrings, selectedYear]);

  return (
    <Card className="w-full rounded-8 shadow-none border-1 p-16 mr-16">
      <div className={"flex w-full justify-between items-center"}>
        <Typography className="h2 pb-16" color="primary">
          {languageStrings["HOME_PAGE.USER_REGISTERED"]}
        </Typography>
      </div>
      <Line
        data={userRegisteredFiltered}
        height={120}
        legend={{ display: true }}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userRegistered: state.banzzu.user.userRegistered,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(UserRegisteredCard)
);
