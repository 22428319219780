import { getUserId } from "./Endpoint";

export const ORDERS = "orders";
export const ORDERS_DASHBOARD = "/orders/dashboard";
export const ORDER_REVENUE = "/orders/revenue";
export const ORDER_EXPORT = "/orders/export";
export const ORDER_TABLE = "order/table";
export const ORDER_ANALYTICS = "/orders/ordersAnalytics";

export const getOrderListUrl = (): string => {
  return encodeURI(ORDERS + "?userId=" + getUserId());
};
export const getOrderListUrl2 = (): string => {
  return encodeURI(ORDERS_DASHBOARD + "?userId=" + getUserId());
};

export const getExportList = (): string => {
  return encodeURI(ORDER_EXPORT + "?userId=" + getUserId());
};

export const getOrderDetailUrl = (id: string): string => {
  return encodeURI(ORDERS + "/" + id);
};

export const getOrderRevenueUrl = (): string => {
  return encodeURI(ORDER_REVENUE + "?userId=" + getUserId());
};
export const getOrderAnalyticsUrl = (): string => {
  return encodeURI(ORDER_ANALYTICS + "?userId=" + getUserId());
};
