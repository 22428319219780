import Branding from "./Branding";

export const BrandingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/branding",
      component: Branding,
    },
    // {
    //   path: "/orderFromTable/:productId",
    //   component: WhatsOnDetail
    // }
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
