import { axiosInstance as axios } from "app/api/axios";

import { DrawActionTypes } from "app/store/redux/actionTypes";
import { getDrawDetailUrl, getUserId, DRAW } from "app/api/Endpoint";
import { Draw } from "app/models/Draw";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";

import _ from "@lodash";

export function setDrawSearchText(event) {
  return {
    type: DrawActionTypes.SET_DRAW_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getDrawList = () => {
  return (dispatch) => {
    dispatch({
      type: DrawActionTypes.DRAW_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(DRAW, {
        params,
      })
      .then((res) => {
        getDrawListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getDrawListFail(dispatch, "There was an error connection");
      });
  };
};

const getDrawListFail = (dispatch, errorMessage) => {
  dispatch({
    type: DrawActionTypes.DRAW_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getDrawListSuccess = (dispatch, data) => {
  dispatch({
    type: DrawActionTypes.DRAW_LIST_SUCCESS,
    payload: data,
  });
};

export const getDrawDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: DrawActionTypes.DRAW_DETAIL_START,
    });

    const url = getDrawDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getDrawDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getDrawDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getDrawDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: DrawActionTypes.DRAW_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/drawList`,
  });
};

const getDrawDetailSuccess = (dispatch, data) => {
  dispatch({
    type: DrawActionTypes.DRAW_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createDraw = (draw: Draw) => {
  return (dispatch) => {
    dispatch({
      type: DrawActionTypes.DRAW_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in draw) {
      if (key == "imageFile") {
        formData.append(key, draw[key], draw[key].name);
      } else if (key != "id") {
        formData.append(key, draw[key]);
      }
    }

    axios
      .post(DRAW, formData)
      .then((res) => {
        const data = res.data;

        if (res.data.statusCode === 200) {
          createDrawSuccess(dispatch, data);
        } else {
          createDrawFail(dispatch, res.data.message.description);
        }
      })
      .catch(() => {
        createDrawFail(dispatch, "There was an error connection");
      });
  };
};

const createDrawFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: DrawActionTypes.DRAW_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createDrawSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: DrawActionTypes.DRAW_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/drawList/",
  });
};

export const editDraw = (draw: Draw) => {
  return (dispatch) => {
    dispatch({
      type: DrawActionTypes.DRAW_EDIT_START,
    });

    let formData = new FormData();

    formData.append("userId", getUserId());

    for (let key in draw) {
      if (key == "_id") {
        formData.append("drawId", draw[key]);
      } else if (key == "imageFile") {
        formData.append(key, draw[key], draw[key].name);
      } else {
        formData.append(key, draw[key]);
      }
    }

    axios
      .put(DRAW, formData)
      .then((res) => {
        editDrawSuccess(dispatch, res.data);
      })
      .catch(() => {
        editDrawFail(dispatch, "There was an error connection");
      });
  };
};

const editDrawFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: DrawActionTypes.DRAW_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editDrawSuccess = (dispatch, data) => {
  dispatch({
    type: DrawActionTypes.DRAW_EDIT_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, data.message);

  History.push({
    pathname: "/drawList/",
  });
};

export const deleteDraw = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: DrawActionTypes.DRAW_DELETE_START,
    });

    let request = {
      data: {
        ids: idArray,
      },
    };

    axios
      .delete(DRAW, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          deleteDrawSuccess(dispatch, res.data);
        } else {
          deleteDrawFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        deleteDrawFail(dispatch, "There was an error connection");
      });
  };
};

const deleteDrawFail = (dispatch, errorMessage) => {
  dispatch({
    type: DrawActionTypes.DRAW_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteDrawSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getDrawList());
  dispatch({
    type: DrawActionTypes.DRAW_DELETE_SUCCESS,
    payload: data,
  });
};
