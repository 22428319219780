import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Icon,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
} from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import { getVenueList } from "app/store/venues/VenuesActions";
import {
  getMenuDetail,
  createMenu,
  editMenu,
} from "app/store/menu/MenuActions";
import _ from "@lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import History from "@history";
import { FuseUtils } from "@fuse";
import * as Actions from "app/store/actions";
import AlertDialog from "../../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getMenuList } from "app/store/menu/MenuActions";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { LanguagesList } from "./components/LanguagesList";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  resize: {
    fontSize: 30,
  },
}));

function MenuDetailForm({ menuId }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  let menu = useSelector(({ banzzu }) => banzzu.menu.menuDetail);
  let menuList = useSelector(({ banzzu }) => banzzu.menu.menuList);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();
  const { selectedLanguages } = menu || {};
  useEffect(() => {
    if (menuId !== "new") {
      dispatch(getMenuDetail(menuId));
    }
  }, [dispatch, menuId]);
  useEffect(() => {
    dispatch(getVenueList());
    dispatch(getMenuList());
  }, [dispatch]);

  if (menuId === "new") {
    menu = {
      id: "0",
      title: "",
      isPdf: "0",
      venues: [],
      // type: "",
      // maxCount: "",
      // minCount: "",
      availableOnFutureOrders: false,
      availableOnDelivery: false,
      availableOnCollection: false,
      availableOnOrderFromTable: false,
      onlyRead: false,
      selectedLanguages: ["Spanish"],
    };
  }
  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      let _languages = ["Spanish"];
      if (selectedLanguages && selectedLanguages.length > 0)
        _languages = selectedLanguages;
      setForm({ ...menu, selectedLanguages: _languages });
    }
  }, [form, menu, setForm, user, menuList, selectedLanguages]);

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !menu.onlyRead;
  }

  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.title != undefined &&
      form.venues &&
      form.venues.length > 0
    );
  }

  function hasNoErrors() {
    return !errorName();
  }

  function errorName() {
    return dirty.title && form.title && form.title.length < 3;
  }

  function errorImage() {
    return !form.image;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handlePDFUploadChange(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      setForm((form) => ({
        ...form,
        pdf: `data:${file.type};base64,${btoa(reader.result)}`,
        pdfFile: file,
      }));
    };

    reader.onerror = function () {
      console.log("error on load image");
    };
  }

  function handleAllergenesPDFUploadChange(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      setForm((form) => ({
        ...form,
        allergenesPdf: `data:${file.type};base64,${btoa(reader.result)}`,
        allergenesPdfFile: file,
      }));
    };

    reader.onerror = function () {
      console.log("error on load image");
    };
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  function handleVenueChange(event) {
    let venuesFormatted = form.venues;

    if (event.target.checked) {
      venuesFormatted.push(event.target.value);
    } else {
      venuesFormatted.splice(venuesFormatted.indexOf(event.target.value), 1);
    }

    setForm((form) => ({
      ...form,
      venues: venuesFormatted,
    }));
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function previousPage() {
    History.push({
      pathname: `/menuList`,
    });
  }

  const onLanguageSelect = (lang) => {
    const array = form.selectedLanguages;
    let index = array.findIndex((l) => l === lang);
    if (index === -1) array.push(lang);
    else array.splice(index, 1);

    setForm({
      ...form,
      selectedLanguages: array,
    });
  };

  return (
    <>
      {form && (
        <div>
          <input
            accept="image/jpg, image/jpeg, image/png, image/gif"
            className="hidden"
            id="button-file"
            type="file"
            onChange={handleUploadChange}
          />
          <div className="flex justify-center sm:justify-start flex-wrap">
            <label
              htmlFor="button-file"
              className={
                "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 " +
                clsx(menu.onlyRead ? classes.enable : classes.disabled)
              }
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>
            {
              <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                <img
                  className="max-w-none w-auto h-full"
                  alt="whatson"
                  src={
                    form.image
                      ? formatImageUrl(form.image)
                      : "assets/images/avatars/noimage.png"
                  }
                />
              </div>
            }
            {/* {errorImage() && (
              <div className="text-red">
                {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
              </div>
            )} */}
            <TextField
              className="mt-8 mb-16 min-w-2/5"
              error={errorName()}
              required
              label={languageStrings["GENERAL.TITLE"]}
              id="title"
              size="medium"
              name="title"
              disabled={menu.onlyRead}
              value={form.title || ""}
              onChange={handleTitleChange}
              variant="outlined"
              inputProps={{
                maxLength: 100,
                size: 30,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              helperText={errorName() ? error.title : ""}
            />
          </div>
          <div className="flex md:flex-row md:justify-evenly flex-col">
            {venueList.length > 0 && user.accessLevel != "1" && (
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <React.Fragment>
                  <div className="font-bold">
                    {`${languageStrings["MENU_DETAIL_PAGE.VENUES"]} *`}
                  </div>
                  <FormGroup row>
                    {venueList.map((item) => {
                      return (
                        <FormControlLabel
                          key={item.title}
                          control={
                            <Checkbox
                              checked={
                                form.venues && form.venues.length > 0
                                  ? form.venues.includes(item._id)
                                  : false
                              }
                              onChange={handleVenueChange}
                              value={item._id}
                            />
                          }
                          label={item.title}
                        />
                      );
                    })}
                  </FormGroup>
                </React.Fragment>
              </Card>
            )}
            {user.permission &&
            (user.permission.hasFutureOrder ||
              user.permission.hasDelivery ||
              user.permission.hasCollection ||
              user.permission.hasOrderFromTable) ? (
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <React.Fragment>
                  <div className="font-bold">
                    {languageStrings["MENU_DETAIL_PAGE.MENU_AVAILABILITY"]}
                  </div>
                  <FormGroup row>
                    {user.permission.hasFutureOrder && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnFutureOrders"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnFutureOrders}
                          />
                        }
                        label={
                          languageStrings["MENU_DETAIL_PAGE.FUTURE_ORDERS"]
                        }
                      />
                    )}

                    {user.permission.hasDelivery && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnDelivery"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnDelivery ? true : false}
                          />
                        }
                        label={languageStrings["MENU_DETAIL_PAGE.DELIVERY"]}
                      />
                    )}

                    {user.permission.hasCollection && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnCollection"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnCollection ? true : false}
                          />
                        }
                        label={languageStrings["MENU_DETAIL_PAGE.COLLECTION"]}
                      />
                    )}

                    {user.permission.hasOrderFromTable && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnOrderFromTable"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={
                              form.availableOnOrderFromTable ? true : false
                            }
                          />
                        }
                        label={
                          languageStrings["MENU_DETAIL_PAGE.ORDER_FROM_TABLE"]
                        }
                      />
                    )}
                  </FormGroup>
                </React.Fragment>
              </Card>
            ) : null}
            {/* {form && (
              <LanguagesList form={form} handleChange={onLanguageSelect} />
            )} */}
          </div>
          <div className="w-full flex justify-end">
            <Button
              className="mt-16 mb-16 bg-grey-lightest"
              variant="contained"
              disabled={!canBeSubmitted()}
              onClick={() => {
                const _successMessage = languageStrings["MENU_NEW_MENU_CREATED"]

                form.id !== "0"
                  ? dispatch(editMenu(form))
                  : dispatch(createMenu(form,_successMessage));
              }}
            >
              <Icon className="mr-8">save</Icon>
              {`Guardar Cambios`}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default withReducer("MenuReducer", reducer)(MenuDetailForm);
