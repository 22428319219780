import React from "react";
import { Button } from "@material-ui/core";

const FCMNotification = ({ title, url }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: 18 }}>{title}</div>
      <Button
        component="a"
        href={url}
        rel="noreferrer noopener"
        role="button"
        className="normal-case"
        variant="contained"
        color="primary"
      >
        <span>Check now</span>
      </Button>
    </div>
  );
};

export default FCMNotification;
