import { axiosInstance as axios } from "app/api/axios";

import { VoucherActionTypes } from "app/store/redux/actionTypes";
import {
  getVoucherDetailUrl,
  getUserId,
  VOUCHER_URL,
  SORT_VOUCHER,
} from "app/api/Endpoint";
import { Voucher } from "app/models/Voucher";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";

export const getVoucherList = () => {
  return (dispatch) => {
    dispatch({
      type: VoucherActionTypes.VOUCHER_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(VOUCHER_URL, {
        params,
      })
      .then((res) => {
        getVoucherListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getVoucherListFail(dispatch, "There was an error connection");
      });
  };
};

const getVoucherListFail = (dispatch, errorMessage) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getVoucherListSuccess = (dispatch, data) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_LIST_SUCCESS,
    payload: data,
  });
};

export const sortVoucherList = (sortOrder, voucherList) => {
  return (dispatch) => {
    const { from, to } = sortOrder;

    const sortedVoucher = [...voucherList];
    let movedVoucher = sortedVoucher.splice(from, 1);
    sortedVoucher.splice(to, 0, _.first(movedVoucher));

    dispatch({
      type: VoucherActionTypes.SORT_VOUCHER_LIST_START,
      payload: sortedVoucher,
    });

    const request = {
      ...sortOrder,
      userId: getUserId()
    };

    axios
      .post(SORT_VOUCHER, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          sortVoucherListSuccess(dispatch, res.data);
        } else {
          sortVoucherListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        sortVoucherListFail(dispatch, "There was an error connection");
      });
  };
};

const sortVoucherListFail = (dispatch, errorMessage) => {
  dispatch({
    type: VoucherActionTypes.SORT_VOUCHER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortVoucherListSuccess = (dispatch, data) => {
  dispatch(getVoucherList());
};

export const getVoucherDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: VoucherActionTypes.VOUCHER_DETAIL_START,
    });

    const url = getVoucherDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getVoucherDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getVoucherDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getVoucherDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/voucherList`,
  });
};

const getVoucherDetailSuccess = (dispatch, data) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createVoucher = (voucher: Voucher) => {
  return (dispatch) => {
    dispatch({
      type: VoucherActionTypes.VOUCHER_CREATE_START,
    });

    let formData = new FormData()
    formData.append("userId", getUserId())

    for (let key in voucher) {
      if (key == "imageFile") {
        formData.append(key, voucher[key], voucher[key].name);
      } else if (key != "id") {
        formData.append(key, voucher[key])
      }
    }

    axios
      .post(VOUCHER_URL, formData)
      .then((res) => {
        createVoucherSuccess(dispatch, res.data);
      })
      .catch((error) => {
        createVoucherFail(dispatch, "There was an error connection");
      });
  };
};

const createVoucherFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VoucherActionTypes.VOUCHER_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createVoucherSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: VoucherActionTypes.VOUCHER_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/voucherList/",
  });
};

export const editVoucher = (voucher: Voucher) => {
  return (dispatch) => {
    dispatch({
      type: VoucherActionTypes.VOUCHER_EDIT_START,
    });

    let formData = new FormData()

    formData.append("userId", getUserId())

    for (let key in voucher) {
      if (key == "_id") {
        formData.append("voucherId", voucher[key])
      } else if (key == "imageFile") {
        formData.append(key, voucher[key], voucher[key].name);
      } else {
        formData.append(key, voucher[key])
      }
    }

    axios
      .put(VOUCHER_URL, formData)
      .then((res) => {
        editVoucherSuccess(dispatch, res.data);
      })
      .catch(() => {
        editVoucherFail(dispatch, "There was an error connection");
      });
  };
};

const editVoucherFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VoucherActionTypes.VOUCHER_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editVoucherSuccess = (dispatch, data) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_EDIT_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, data.message);

  History.push({
    pathname: "/voucherList/",
  });
};

export const deleteVoucher = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: VoucherActionTypes.VOUCHER_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(VOUCHER_URL, { data: { voucherId: id } })
        .then((res) => {
          deleteVoucherSuccess(dispatch, res.data);
        })
        .catch(() => {
          deleteVoucherFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteVoucherFail = (dispatch, errorMessage) => {
  dispatch({
    type: VoucherActionTypes.VOUCHER_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteVoucherSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getVoucherList());
  dispatch({
    type: VoucherActionTypes.VOUCHER_DELETE_SUCCESS,
    payload: data,
  });
};
