import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";

import { FusePageSimple } from "@fuse";
import ItemsCard from "./components/ItemsCard";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { clickedQr, sendUpSellEmail } from "app/store/merchant/MerchantActions";
import history from "@history";
import AlertDialog from "../common/AlertDialog";
import * as Actions from "app/store/actions";

export default function MerchandisingPage() {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.merchant.loading);

  const onItemClicked = () => {
    history.push("product-detail");
    dispatch(clickedQr());
  };

  const setModalAndEmailData = (upselldata = null, message = null) => {
    if (upselldata && message) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={() => {
                dispatch(sendUpSellEmail(upselldata, message));
              }}
              title={languageStrings["GENERAL.CONFIRMATION_TITLE"]}
              message={languageStrings["GENERAL.CONFIRMATION_SUBTITLE"]}
            />
          ),
        })
      );
    }
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageSimple
          header={
            <div className="p-24">
              <h2>{languageStrings["SIDEBAR_MENUE.MERCHANDISING"]}</h2>
            </div>
          }
          content={
            <div className="px-2 py-32 flex flex-row flex-wrap">
              <ItemsCard
                onClick={onItemClicked}
                title={languageStrings["MERCHANDISE.ITEM.QR_TITLE"]}
                description={languageStrings["MERCHANDISE.ITEM.QR_DESCRIPTION"]}
                width={205}
                image="assets/images/merchandise/qr.png"
              />
              <ItemsCard
                onClick={() => {
                  setModalAndEmailData(
                    languageStrings["MERCHANDISE.ITEM.CREATE_MENUE"],
                    languageStrings["MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE"]
                  );
                }}
                title={languageStrings["MERCHANDISE.ITEM.CREATE_MENUE"]}
                description={
                  languageStrings["MERCHANDISE.ITEM.CREATE_MENUE_DESCRIPTION"]
                }
                image="assets/images/merchandise/menuupsell.png"
              />
              <ItemsCard
                onClick={() => {
                  setModalAndEmailData(
                    languageStrings["MERCHANDISE.ITEM.DELIVERY_FLEET"],
                    languageStrings["MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE"]
                  );
                }}
                title={languageStrings["MERCHANDISE.ITEM.DELIVERY_FLEET"]}
                description={
                  languageStrings["MERCHANDISE.ITEM.DELIVERY_FLEET_DESCRIPTION"]
                }
                image="assets/images/merchandise/deliveryupsell.png"
              />
              <ItemsCard
                onClick={() => {
                  setModalAndEmailData(
                    languageStrings["MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF"],
                    languageStrings["MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE"]
                  );
                }}
                title={languageStrings["MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF"]}
                description={
                  languageStrings[
                    "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF_DESCRIPTION"
                  ]
                }
                image="assets/images/merchandise/delitbeeupsell.png"
              />
              <ItemsCard
                onClick={() => {
                  setModalAndEmailData(
                    languageStrings[
                      "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE"
                    ],
                    languageStrings["MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE"]
                  );
                }}
                title={
                  languageStrings[
                    "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE"
                  ]
                }
                description={
                  languageStrings[
                    "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE_DESCRIPTION"
                  ]
                }
                image="assets/images/merchandise/upsellweb.png"
              />
            </div>
          }
        />
      </LoadingOverlay>
    </>
  );
}
