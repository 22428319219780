import React from "react"
import { makeStyles, Theme } from "@material-ui/core"

interface Props {
  color?: string
  onClick: () => void
  margin?: boolean
  style?: any
}

interface StyleProps {
  color?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  close: ({ color }) => ({
    position: "absolute",
    right: "6px",
    top: "6px",
    width: "24px",
    height: "24px",
    opacity: 1,
    zIndex: 9,
    cursor: "pointer",
    "&::hover": {
      opacity: 1,
    },
    "&::before, &&::after": {
      position: "absolute",
      left: "10px",
      content: '" "',
      height: "24px",
      width: "2px",
      backgroundColor: color ? color : "white",
    },
    "&::before": {
      transform: "rotate(45deg)",
    },
    "&::after": {
      transform: "rotate(-45deg)",
    },
  }),
}))

const CloseButton = ({ color, onClick, style }: Props) => {
  const styleProps: StyleProps = { color }
  const classes = useStyles(styleProps)
  return <div style={style} onClick={onClick} className={classes.close}></div>
}

export default CloseButton
