import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import Confetti from "react-confetti";

import history from "@history";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  getVenueList,
  deleteVenue,
  setVenueSearchText,
} from "app/store/venues/VenuesActions";
import { translate } from "app/helpers/LanguageHelper/index";

class VenueList extends Component {
  componentDidMount() {
    this.props.getVenueList();
  }
  onHandleDelete = (selected) => {
    this.props.deleteVenue(selected);
  };
  handleClick = (item) => {
    history.push("/venueDetail/" + item._id);
  };

  setSearchText = (ev) => {
    this.props.setVenueSearchText(ev);
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "title",
          align: "left",
          disablePadding: true,
          label: languageStrings["GENERAL.NAME"],
          sort: true,
          type: "text",
        },
      ],
      checkbox: true,
      clickable: true,
      locationSearch: false,
      searchBy: ["title", "email"],
    };

    const { user } = this.props;

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        {this.props.success && (
          <Confetti
            style={{ position: "absolute" }}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        )}
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <ListHeader
              title={languageStrings["VENUE_LIST_HEADER_PAGE.TITLE"]}
              setSearchText={this.setSearchText}
              createUrl={"/create-venue"}
              searchType={"venue"}
              createButton={user && user.permission && user.permission.isMaster}
              buttonText={
                languageStrings["VENUE_LIST_HEADER_PAGE.CREATE_NEW_VENUE"]
              }
              limited
            />
          }
          content={
            <ListTable
              handleClick={this.handleClick}
              handleDelete={this.onHandleDelete}
              headerRows={headerRows}
              data={this.props.venueList}
              searchText={this.props.searchText}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = ({ banzzu }) => {
  const { loading, venueList, searchText, success } = banzzu.venue;
  const { user } = banzzu.auth;
  return {
    loading,
    success,
    venueList,
    searchText,
    user,
  };
};

const mapDispatchToProps = {
  getVenueList,
  deleteVenue,
  setVenueSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(VenueList));
