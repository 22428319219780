import React from "react";
import { Typography, makeStyles, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "::hover": {
      boxShadow: "2px 15px #cc3c3",
    },
  },
}));

const GoalButton = ({ title, active, onClick }) => {
  const classes = useStyles({});
  return (
    <div
      className={["relative cursor-pointer", classes.root].join(" ")}
      onClick={() => !active && onClick()}
    >
      <Hidden smUp>
        <img
          width="135"
          className="mx-12 mb-12 mt-12"
          src={
            active
              ? "assets/images/backgrounds/active-goal.png"
              : "assets/images/backgrounds/inactive-goal.png"
          }
        />
        <Typography
          className={[
            "absolute z-10 pr-12",
            active ? "text-white" : "text-black",
          ].join(" ")}
          style={{
            top: "2rem",
            left: "5rem",
            lineHeight: "1rem",
            fontSize: "1rem",
          }}
          variant="body1"
        >
          {title}
        </Typography>
      </Hidden>
      <Hidden xsDown>
        <img
          width="250"
          className="mx-12 mb-12 mt-12"
          src={
            active
              ? "assets/images/backgrounds/active-goal.png"
              : "assets/images/backgrounds/inactive-goal.png"
          }
        />
        <Typography
          className={[
            "absolute z-10 pr-12",
            active ? "text-white" : "text-black",
          ].join(" ")}
          style={{
            top: "3rem",
            left: "7rem",
            lineHeight: "1.5rem",
          }}
          variant="body1"
        >
          {title}
        </Typography>
      </Hidden>
    </div>
  );
};

export default GoalButton;
