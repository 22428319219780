import React, { useEffect, useState } from "react";
import {
  Icon,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import { getVenueList } from "app/store/venues/VenuesActions";
import {
  getGalleryList,
  createGallery,
  deleteGallery,
} from "app/store/gallery/GalleryActions";
import _ from "@lodash";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from 'app/helpers/utilsHelper';

function GalleryDetail(props) {
  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const loading = useSelector(({ banzzu }) => banzzu.gallery.loading);
  let galleryList = useSelector(({ banzzu }) => banzzu.gallery.galleryList);
  const { languageStrings } = useLanguageHelper();

  let user = useSelector(({ banzzu }) => banzzu.auth.user);

  const [venue, setVenue] = useState("0");
  const [gallery, setGallery] = useState(galleryList);

  useEffect(() => {
    dispatch(getGalleryList());
  }, [dispatch, venue]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  useEffect(() => {
    setGallery(
      galleryList.length === 0 || venue === "0"
        ? galleryList
        : _.filter(galleryList, (item) => item.venueId === venue)
    );
  }, [galleryList, venue]);

  if (gallery.length === 0) {
    setGallery([
      {
        id: "0",
        image: "assets/images/avatars/noimage.png",
      },
    ]);
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        const galleryItem = {
          imageFile: file,
          venueId: venue,
        };

        dispatch(createGallery(galleryItem));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleVenueChange(event) {
    setVenue(event.target.value);
  }

  function deleteImage(imageId) {
    dispatch(deleteGallery(imageId));
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex items-center">
              <FuseAnimate animation="transition.expandIn" delay={300}>
                <Icon className="text-32 mr-0 sm:mr-12">collections</Icon>
              </FuseAnimate>
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h6">
                  {languageStrings["GALLERY_DETAIL_PAGE.TITLE"]}
                </Typography>
              </FuseAnimate>
            </div>
          </div>
        }
        content={
          <div className="p-16 sm:p-24 max-w-2xl">
            <div>
              <input
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                className="hidden"
                id="button-file"
                type="file"
                onChange={handleUploadChange}
              />
              <div className="flex justify-center sm:justify-start flex-wrap">
                <label
                  htmlFor="button-file"
                  className="flex items-center justify-center relative w-200 w-200 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                >
                  <Icon fontSize="large" color="action">
                    cloud_upload
                  </Icon>
                </label>
                {gallery &&
                  gallery.map((item) => (
                    <div className="flex flex-col relative items-center crop-center rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                      <img
                        className="max-w-none w-auto h-200"
                        alt={item.id}
                        key={item.id}
                        src={formatImageUrl(item.image)}
                      />
                      {item.id !== "0" && (
                        <>
                          <IconButton
                            onClick={() => deleteImage(item._id)}
                            aria-label="delete"
                          >
                            <Icon color="error" className="text-22">
                              delete
                            </Icon>
                          </IconButton>
                          <div className="flex h-32 items-center justify-center mr-8 ml-8">
                            {item.venueFormatted}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("GalleryReducer", reducer)(GalleryDetail);
