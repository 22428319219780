import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  getLoyaltyList,
  setTransactionSearchText
} from "app/store/loyalty/LoyaltyActions";
import { translate } from "app/helpers/LanguageHelper/index"

class TransactionList extends Component {
  componentDidMount() {
    this.props.getLoyaltyList();
  }
  setSearchText = ev => {
    this.props.setTransactionSearchText(ev);
  };

  render() {
    const { languageStrings } = this.props
    const headerRows = {
      data: [
        {
          id: "userName",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.NAME"],
          sort: true,
          type: "text"
        },
        {
          id: "rewardTitle",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.TITLE"],
          sort: true,
          type: "text"
        },
        {
          id: "points",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.POINTS"],
          showSuccessAndDanger:true,
          sort: true,
          type: "text"
        },
        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "time"
        }
      ],
      checkbox: false,
      clickable: false,
      locationSearch: false,
      searchBy: ["userName","points"]
    };
    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll"
          }
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
          }}
          header={
            <ListHeader
              title={languageStrings["TRANSACTION_LIST_PAGE.TITLE"]}
              setSearchText={this.setSearchText}
              searchType={"loyalty"}
            />
          }
          content={
            <ListTable
              headerRows={headerRows}
              data={this.props.transactionList}
              searchText={this.props.searchText}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.banzzu.loyalty.loading,
    transactionList: state.banzzu.loyalty.transactionList,
    searchText: state.banzzu.loyalty.searchText
  };
};
const mapDispatchToProps = {
  getLoyaltyList,
  setTransactionSearchText
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TransactionList));
