import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import history from "@history";
import ListHeader from "app/main/common/List/ListHeader";
import DraggableListTable from "app/main/common/DraggableList/DraggableListTable";
import {
  getVoucherList,
  deleteVoucher,
  sortVoucherList,
} from "app/store/voucher/VouchersActions";
import { translate } from "app/helpers/LanguageHelper/index";

class VoucherList extends Component {
  componentDidMount() {
    this.props.getVoucherList();
  }
  onHandleDelete = (selected) => {
    this.props.deleteVoucher(selected);
  };
  handleClick = (item) => {
    history.push("/voucherDetail/" + item._id);
  };

  sortVoucherList = (sortOrder) => {
    const { voucherList, sortVoucherList } = this.props;

    sortVoucherList(sortOrder, voucherList);
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "image",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.IMAGE"],
          sort: true,
          type: "image",
        },
        {
          id: "title",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.TITLE"],
          sort: true,
          type: "text",
        },
      ],
      checkbox: true,
      clickable: true,
      locationSearch: false,
      searchBy: ["title"],
    };

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <ListHeader
              title={languageStrings["VOUCHER_LIST_HEADER_PAGE.TITLE"]}
              createUrl={"/voucherDetail/new"}
              searchType={"voucher"}
              createButton
              buttonText={
                languageStrings["VOUCHER_LIST_HEADER_PAGE.BUTTON_TEXT"]
              }
            />
          }
          content={
            <DraggableListTable
              handleClick={this.handleClick}
              handleDelete={this.onHandleDelete}
              headerRows={headerRows}
              data={this.props.voucherList}
              searchText={this.props.searchText}
              sortList={this.sortVoucherList}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.voucher.loading,
    voucherList: state.banzzu.voucher.voucherList,
    searchText: state.banzzu.voucher.searchText,
  };
};
const mapDispatchToProps = {
  getVoucherList,
  deleteVoucher,
  sortVoucherList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(VoucherList));
