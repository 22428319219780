import React, { Component, useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect, useSelector } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  setStaffSearchText,
  getStaffList,
  deleteStaff,
} from "app/store/staff/StaffActions";
import { translate, useLanguageHelper } from "app/helpers/LanguageHelper/index";
import UpgradeModal from "../common/UpgradeModal";
import history from "@history";

function StaffList(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.getStaffList();
  }, []);

  const setSearchText = (ev) => {
    props.setStaffSearchText(ev);
  };

  const { languageStrings } = useLanguageHelper();

  const headerRows = {
    data: [
      {
        id: ["firstName", "lastName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["firstName", "lastName", "email"],
  };

  const onHandleDelete = (selected) => {
    props.deleteStaff(selected);
  };

  return (
    <>
      <UpgradeModal
        open={open}
        setOpen={() => {
          history.goBack();
          setOpen(false);
        }}
      />
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div style={{ flex: 1, filter: open ? "blur(3px)" : "none" }}>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <ListHeader
                title={languageStrings["USER_LIST_PAGE.TITLE"]}
                setSearchText={setSearchText}
                searchType={"user"}
                createUrl={"/staff-detail/new"}
                createButton
                buttonText={languageStrings["STAFF_DETAIL.CREATE_STAFF"]}
              />
            }
            content={
              <ListTable
                handleDelete={onHandleDelete}
                headerRows={headerRows}
                data={props.staffList}
                searchText={props.searchText}
                handleClick={(item) => {
                  history.push("/staff-detail/" + item._id);
                }}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.user.loading,
    searchText: state.banzzu.user.searchText,
    staffList: state.banzzu.staff.staffList,
  };
};
const mapDispatchToProps = {
  setStaffSearchText,
  getStaffList,
  deleteStaff,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(StaffList));
