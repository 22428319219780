import AnalyticsPage from "./AnalyticsPage";

export const AnalyticsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/analytics",
      component: AnalyticsPage,
    },
  ],
};

