import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    boxShadow: "5px 0px 15px #adadad",
    backgroundColor: "white",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ff8330",
  },
}))(LinearProgress);

export default function LinearDeterminate({ progress }) {
  let image = "assets/images/backgrounds/burger1.png";
  if (progress >= 10) {
    image = "assets/images/backgrounds/burger2.png";
  }

  if (progress >= 40) {
    image = "assets/images/backgrounds/burger3.png";
  }

  if (progress >= 80) {
    image = "assets/images/backgrounds/burger4.png";
  }

  if (progress == 100) {
    image = "assets/images/backgrounds/burger5.png";
  }

  return (
    <>
      <img
        width="100"
        style={{
          marginLeft: `calc(${progress}% - 52px)`,
          marginBottom: -22,
          marginTop: -44,
          transition: "margin 700ms",
        }}
        src={image}
      ></img>
      <BorderLinearProgress variant="determinate" value={progress} />
    </>
  );
}
