import React, { useState, useEffect } from "react";
import _ from "@lodash";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Icon,
  Backdrop,
  Fade,
  IconButton,
  Modal,
} from "@material-ui/core";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import ProductItem from "./ProductItem";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import MenuItemDetailModal from "./MenuItemDetailModal";
import { deleteMenuCategory, sortMenuList } from "app/store/menu/MenuActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  accordion: {
    backgroundColor: "#edf2f7",
  },
}));

const CategoryItem = ({
  category,
  productId,
  onClick,
  index,
  onlyRead,
  defaultExpanded,
}) => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  const menuList = useSelector(({ banzzu }) => banzzu.menu.menuItemList);

  const menuListFiltered = menuList
    .filter((menuItem) => menuItem.categoryId == category._id)
    .sort((a, b) => a.position - b.position);

  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
      categoryId: menuListFiltered.find((menu) => menu._id === draggableId)
        .categoryId,
    };

    dispatch(sortMenuList(sortOrder, productId));
  };

  return (
    <Draggable
      key={category._id}
      draggableId={category._id}
      index={index}
      isDragDisabled={onlyRead || expanded}
    >
      {(provided, snapshot) => (
        <div
          className="mb-8"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => {
              console.log("closing");
              setOpen(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
              >
                <h2 id="transition-modal-title" className="pb-32">
                  {languageStrings["MENU_DETAIL_PAGE.NEW_PRODUCT"]}
                </h2>
                <MenuItemDetailModal
                  productId={"new"}
                  categoryId={category._id}
                  menuId={category.menuId}
                  onClose={() => {
                    setOpen(false);
                  }}
                />
              </div>
            </Fade>
          </Modal>
          <Accordion
            defaultExpanded={defaultExpanded}
            onChange={(e, expanded) => {
              console.log("expanded :>> ", expanded);
              setExpanded(expanded);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="flex items-center">
                <div className="justify-start">
                  <Typography variant="subtitle2" component="h3">
                    {category.title}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => onClick()}
                  hidden={onlyRead ? true : false}
                >
                  <Icon className="pr-8 text-blue">edit</Icon>
                </IconButton>
                <IconButton
                  onClick={() =>
                    dispatch(
                      deleteMenuCategory([category._id], category.menuId)
                    )
                  }
                  hidden={onlyRead ? true : false}
                >
                  <Icon className="pr-16 text-red">delete</Icon>
                </IconButton>
              </div>
            </AccordionSummary>
            <AccordionDetails className="bg-grey-lighter">
              <div className="w-full">
                <DragDropContext onDragEnd={onDragEnd}>
                  <DropableProductItem
                    list={menuListFiltered}
                    onlyRead={onlyRead}
                  />
                </DragDropContext>

                <Button
                  className="mt-16 ml-8 bg-white"
                  variant="contained"
                  onClick={() => {
                    setOpen(true);
                  }}
                  hidden={onlyRead ? true : false}
                >
                  <Icon className="mr-8">save</Icon>
                  {`Añadir Producto en ${category.title}`}
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Draggable>
  );
};

const DropableProductItem = ({ list, onlyRead }) => {
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {list.map((menuItem, index) => {
            return (
              <ProductItem
                key={menuItem._id}
                extraCategories={menuItem.extraCategories}
                menuItem={menuItem}
                index={index}
                onlyRead={onlyRead}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default withReducer("GalleryReducer", reducer)(CategoryItem);
