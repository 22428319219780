import React from "react";
import { Box, Typography, Card, Button } from "@material-ui/core";

import EditItemCard from "./EditItemCard";
import AddNewItemCard from "./AddNewItemCard";
import { useLanguageHelper } from "app/helpers/LanguageHelper/useLanguageHelper";

interface InnerCard {
  _id: string;
  title: string;
  description: string;
  brand?: string;
  address?: boolean;
  isValidAddress?: boolean;
  default?: boolean;
}

interface Props {
  title: string;
  innerCards: InnerCard[];
  addClickHandler: () => void;
  addTitle: string;
  onSelect: (value: string) => {};
  onSubmit: () => {};
  onBack: () => {};
  selectedCard: string;
}

const OptionsCard = ({
  innerCards,
  addClickHandler,
  title,
  addTitle,
  onSelect,
  onSubmit,
  onBack,
  selectedCard,
}: Props) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <Card
      className="mx-24 mt-12 p-12 flex flex-col items-center justify-around shadow-lg md:w-1/2 w-full"
      style={{ borderRadius: "2rem" }}
    >
      {/* <Typography variant="body1" className="text-center mt-12 mb-6 px-32">
        {languageStrings["PRICING.YOU_WILL_NOT_BE"]}
      </Typography> */}
      <Box className={"flex flex-col items-center mb-12 pt-6"}>
        <Typography
          variant="h5"
          style={{ color: "rgb(105, 105, 105)", marginTop: "0.8rem" }}
          className={"m-0"}
        >
          {title}
        </Typography>
      </Box>

      <Box className={"flex flex-wrap w-full justify-center pb-12"}>
        {innerCards.map((item) => {
          return (
            <EditItemCard
              selectedCard={selectedCard}
              title={item.title}
              key={item._id}
              id={item._id}
              brand={item.brand}
              onSelect={onSelect}
            />
          );
        })}

        <AddNewItemCard title={addTitle} onClick={addClickHandler} />
      </Box>
      <div className="flex flex-row justify-center w-full">
        <Button variant="contained" onClick={onBack}>
          {languageStrings["GENERAL.BACK"]}
        </Button>
        <Button
          className="ml-12"
          variant="contained"
          style={{ backgroundColor: "#f5aa00", color: "white" }}
          onClick={onSubmit}
        >
          {languageStrings["GENERAL.PAY"]}
        </Button>
      </div>
    </Card>
  );
};

export default OptionsCard;
