import React from "react";
import { Box, Typography } from "@material-ui/core";

import EditItemCard from "./EditItemCard";
import AddNewItemCard from "./AddNewItemCard";

interface InnerCard {
  _id: string;
  title: string;
  description: string;
  brand?: string;
  address?: boolean;
  isValidAddress?: boolean;
  default?: boolean;
}

interface Props {
  title: string;
  innerCards: InnerCard[];
  addClickHandler: () => void;
  addTitle: string;
}

const OptionsCard = ({
  innerCards,
  addClickHandler,
  title,
  addTitle,
}: Props) => {
  return (
    <div className="bg-white p-32">
      <Box className={"flex flex-col items-center md:pl-16 mb-12 pt-6"}>
        <Typography
          variant="h5"
          style={{ color: "rgb(105, 105, 105)", marginTop: "0.8rem" }}
          className={"m-0"}
        >
          {title}
        </Typography>
      </Box>

      <Box className={"flex flex-wrap w-full justify-center md:pl-12 pb-12"}>
        {innerCards.map((item) => {
          return (
            <EditItemCard
              isSelected={item.default}
              title={item.title}
              key={item._id}
              id={item._id}
              brand={item.brand}
            />
          );
        })}

        <AddNewItemCard title={addTitle} onClick={addClickHandler} />
      </Box>
    </div>
  );
};

export default OptionsCard;
