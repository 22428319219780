import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";

interface Props {
  title: string;
  number: string;
  textColor: string;
}
const styles = (theme) => ({
  layoutRoot: {
    display: "inline",
    padding: " 0.4rem",
    backgroundColor: "#E3342F",
    color: "white",
  },
});

const UserNumberCard = ({ title, number, textColor }: Props) => {
  return (
    <div className="widget flex w-full sm:w-1/3 p-16">
      <Card className="w-full rounded-8 shadow-none border-1 h-160">
        <div className="p-16 pb-0 flex flex-row flex-wrap items-end">
          <div className="pr-16">
            <Typography className="h2" color="primary">
              {title}
            </Typography>
            <Typography
              style={{ fontSize: "5.4rem" }}
              className={`font-300 leading-none mt-8 ${textColor}`}
            >
              {number}
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(UserNumberCard);
