import VenueList from "./VenueList";
import VenueDetail from "./VenueDetail";
import VenueStepsPage from "./components/VenueStepsPage";

export const VenueListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/venueList",
      component: VenueList,
    },
    {
      path: "/venueDetail/:productId/:tab?",
      component: VenueDetail,
    },
    {
      path: "/create-venue",
      component: VenueStepsPage,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
