import React, { useEffect } from 'react'
import { Card, TextField, Button, Typography } from "@material-ui/core"
import { ChromePicker } from "react-color";
import { useSelector } from "react-redux"

import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

export default function QRCard({ onSubmit, title, setForm, handleChange, form }) {

    const { languageStrings } = useLanguageHelper();
    const user = useSelector(({ banzzu }) => banzzu.auth.user);

    useEffect(() => {
        if (user && user.settings && !form) {
            setForm({
                color: user.settings.typography,
                title: ""
            });
        }
    }, [form, user]);

    const submitForm = ()=>{
        onSubmit(form)
    }

    return (
        form ? (
            <Card
                className="mx-24 mt-12 p-24 flex flex-col items-center justify-around shadow-lg md:w-1/3 w-full"
                style={{ borderRadius: "2rem" }}
            >
                <Typography
                    variant="h6"
                    className="font-bold text-center"
                    style={{ color: "#f5aa00" }}
                >
                    {title}
                </Typography>
                <TextField
                    className="mt-8 mb-16"
                    required
                    label={languageStrings["GENERAL.TITLE"]}
                    id="title"
                    name="title"
                    value={form.title}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                        maxLength: 30
                    }}
                />
                <ChromePicker
                    color={form.color}
                    disableAlpha
                    onChange={(color) => {
                        setForm((form) => ({
                            ...form,
                            color: color.hex,
                        }));
                    }}
                />
                <Button
                    variant="contained"
                    className={"mt-24"}
                    style={{ backgroundColor: "#f5aa00", color: "white" }}
                    onClick={submitForm}
                >
                    {languageStrings["GENERAL.CONTINUE"]}
                </Button>
            </Card>
        )
            : null
    )
}
