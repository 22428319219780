import React from "react";
import { TableCell } from "@material-ui/core";
import OrdersType from "app/main/orders/components/OrdersType";

const OrderTypeCell = (props) => {
  const { orderType } = props;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <OrdersType orderType={orderType} />
    </TableCell>
  );
};

export default OrderTypeCell;
