import { AuthActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: AuthState = {
  user: {},
  id: "",
  isValidToken: false,
  success: true,
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const AuthReducer = (
  state: AuthState = INITIAL_STATE,
  action: Action
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.USER_INFO_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case AuthActionTypes.USER_INFO_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        success: true,
      };
    }

    case AuthActionTypes.USER_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case AuthActionTypes.LOGIN_USER_FAIL: {
      return {
        ...state,
        success: false,
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CREATE_USER_START: {
      return { ...state, loading: true };
    }

    case AuthActionTypes.CREATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        success: false,
      };
    }

    case AuthActionTypes.CREATE_USER_FAIL: {
      return { ...state, loading: false };
    }

    case AuthActionTypes.UPDATE_USER_GOALS_START: {
      return { ...state, loading: true };
    }

    case AuthActionTypes.UPDATE_USER_GOALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.UPDATE_USER_GOALS_FAIL: {
      return { ...state, loading: false };
    }
    case AuthActionTypes.FORGOT_PASSWORD_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CHECK_VALID_TOKEN_START: {
      return { ...state, loading: true, isValidToken: false, id: "" };
    }
    case AuthActionTypes.CHECK_VALID_TOKEN_SUCCESS: {
      return {
        ...state,
        id: action.payload.id,
        isValidToken: action.payload.isValidToken,
        loading: false,
      };
    }
    case AuthActionTypes.CHECK_VALID_TOKEN_FAIL: {
      return {
        ...state,
        isValidToken: false,
        loading: false,
      };
    }

    case AuthActionTypes.VALIDATE_CREATE_ACCOUNT_START: {
      return { ...state, loading: true, isValidToken: false, id: "" };
    }
    case AuthActionTypes.VALIDATE_CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        id: action.payload.id,
        isValidToken: action.payload.isValidToken,
        loading: false,
      };
    }
    case AuthActionTypes.VALIDATE_CREATE_ACCOUNT_FAIL: {
      return {
        ...state,
        isValidToken: false,
        loading: false,
      };
    }

    case AuthActionTypes.DELETE_CLIENT_START: {
      return { ...state, loading: true};
    }
    case AuthActionTypes.DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.DELETE_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_START: {
      return { ...state, loading: true};
    }
    case AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
