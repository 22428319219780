import NotificationDetail from "./NotificationDetail";
import NotificationList from "./NotificationList";

export const NoficationListConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/notificationList",
      component: NotificationList
    },
    {
      path: "/notificationDetail/:productId",
      component: NotificationDetail
    }
  ]
};
