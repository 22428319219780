import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { FuseUtils } from "@fuse";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Card, Hidden } from "@material-ui/core";
import { useLanguageHelper } from "../../../../app/helpers/LanguageHelper/index";
import RestaurantDetails from "app/main/register/components/RestaurantDetails";
import StepIcon from "app/main/register/components/StepIcon";
import RestaurantServices from "app/main/register/components/RestaurantServices";
import RestaurantAdditionalDetails from "app/main/register/components/RestaurantAdditionalDetails";
import { createClientVenue } from "app/store/venues/VenuesActions";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
    fontSize: 11,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  alternativeLabel: {
    fontSize: 11,
    textAlign: "center",
  },
}));

function getSteps(languageStrings) {
  return [
    languageStrings["REGISTER_STEPS.REGISTER_RESTAURANT"],
    languageStrings["REGISTER_STEPS.LOCATION"],
    // languageStrings["REGISTER_STEPS.SELECT_SERVICES"],
    // languageStrings["REGISTER_STEPS.ADDITIONAL_INFORMATION"],
  ];
}

export default function CustomSteppers({
  form,
  handleChange,
  setForm,
  activeStep,
  setActiveStep,
  setZoom,
}) {
  const classes = useStyles({});
  const { languageStrings } = useLanguageHelper();
  const steps = getSteps(languageStrings);
  const [apiCalled, setApiCalled] = useState(false);

  const dispatch = useDispatch();

  const isFirstStepValid = () => {
    return (
      form.title.length >= 3 &&
      form.phone.length > 3 &&
      form.country.length > 3 &&
      form.city.length > 3 &&
      form.postcode.length > 3 &&
      form.address.length > 3
    );
  };

  const isThirdStepValid = () => {
    let valid = true;

    if (
      form.facebook &&
      form.facebook.length &&
      !FuseUtils.validURL(form.facebook)
    ) {
      valid = false;
    }

    if (
      form.twitter &&
      form.twitter.length &&
      !FuseUtils.validURL(form.twitter)
    ) {
      valid = false;
    }

    if (
      form.website &&
      form.website.length &&
      !FuseUtils.validURL(form.website)
    ) {
      valid = false;
    }

    if (
      form.instagram &&
      form.instagram.length &&
      !FuseUtils.validURL(form.instagram)
    ) {
      valid = false;
    }

    return valid;
  };

  const checkValid = () => {
    if (apiCalled) {
      return false;
    }

    if (activeStep === 0) {
      return isFirstStepValid();
    }
    if (activeStep === 2) {
      return isThirdStepValid();
    }
    return true;
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RestaurantDetails
            setForm={setForm}
            handleChange={handleChange}
            form={form}
            setZoom={setZoom}
          />
        );
      case 1:
        return (
          <div className="text-center">
            <Typography
              className={clsx(classes.instructions, "m-8")}
              variant="subtitle1"
              gutterBottom
            >
              {languageStrings["REGISTER_STEPS.MAP_TITLE"]}
            </Typography>
            <Typography className="m-8" variant="subtitle2" gutterBottom>
              {languageStrings["REGISTER_STEPS.MOVE_PIN"]}
            </Typography>
          </div>
        );
      // case 2:
      //   return (
      //     <RestaurantServices
      //       handleChange={handleChange}
      //       form={form}
      //       valid={isThirdStepValid()}
      //     />
      //   );
      case 2:
        return (
          <RestaurantAdditionalDetails
            handleChange={handleChange}
            form={form}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setApiCalled(true);
      dispatch(createClientVenue(form));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={"flex justify-between self-center"}>
      <div className={classes.root}>
        {activeStep !== 1 && (
          <div className="widget flex w-full p-16 justify-center">
            <Card className="rounded-8 shadow-none border-1 z-10">
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      classes={{
                        alternativeLabel: classes.alternativeLabel,
                      }}
                      StepIconComponent={StepIcon}
                    >
                      <Hidden smDown>{label}</Hidden>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Card>
          </div>
        )}
        <div className="widget flex w-full p-16 justify-center">
          <Card className="rounded-8 p-16 shadow-none border-1 z-10">
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <>
                {getStepContent(activeStep)}

                <div className="flex justify-center mb-4">
                  {activeStep !== 0 && (
                    <Button
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      {languageStrings["REGISTER_STEPS.BACK"]}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!checkValid()}
                  >
                    {activeStep === steps.length - 1
                      ? languageStrings["REGISTER_STEPS.SUBMIT"]
                      : languageStrings["REGISTER_STEPS.NEXT"]}
                  </Button>
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
