import { UserSettings } from "./UserSettings";
import { UserPermissions } from "./UserPermissions";
export interface User {
  id?: string;
  masterId?: string;
  isMaster?: boolean;
  email?: string;
  username?: string;
  password?: string;
  appLogo?: string;
  name?: string;
  phone?: number;
  countryCode?: number;
  address?: string;
  postcode?: string;
  website?: string;
  facebook?: string;
  instagram?: string;
  google?: string;
  twitter?: string;
  image?: string;
  pushKey?: string;
  locale?: string;
  currency?: string;
  permission?: UserPermissions;
  settings?: UserSettings;
  stripeId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  referralCode?:string
}

export enum SUBSCRIPTION {
  BASIC = "1",
  PRO = "2",
  PREMIUM = "3",
}
