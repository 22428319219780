import { orderStatuses } from "app/main/orders/components/OrdersStatus";
import moment from "moment";
import _ from "@lodash";

export function formatImageUrl(imageUrl) {
  return imageUrl.replace(
    "http://server.banzzu.com:8003/",
    "https://server.banzzu.com:8009/"
  );
}

export const getOrderType = (orderType: string): string => {
  switch (orderType) {
    case "0":
      return "ORDER_LIST_PAGE.ALL_TYPES";
    case "1":
      return "ORDER_TYPE.COLLECT";
    case "2":
      return "ORDER_TYPE.DELIVERY";
    case "3":
      return "MENU_DETAIL_PAGE.ORDER_FROM_TABLE";
    default:
      return "";
  }
};
export const filterOrderList = (
  venue = null,
  orderList = []
) => {
  let _data = [];

  if (venue && venue !== "All Venues") {
    _data = orderList.filter((item) => item.venueTitle === venue);
  } else _data = orderList
  return _data;
};

export const getOrderStatus = (orderStatus= '1')=>{
const _status = _.find(orderStatuses, { id: Number(orderStatus)});
return _status.name
}
export function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  }
}
