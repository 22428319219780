import { Reservation } from "app/models/Reservation";

import {
  getVoucherDetailUrl,
  VOUCHER_URL,
  SORT_VOUCHER,
  DISCOUNT_VOUCHER_URL,
} from "./VoucherEndpoints";

import {
  getVenueListUrl,
  getVenueDetailUrl,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  COLLECTION_AVAILABILITY,
  DELIVERY_AVAILABILITY,
  VENUE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
} from "./VenueEndpoints";

import {
  MENU_ITEM,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_CATEGORY_SORT,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_DUPLICATE,
  MENU_DUPLICATE_ITEM,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  MENU_ITEM_UPDATE_TIME,
} from "./MenuEndpoints";

import {
  getOrderListUrl,
  getOrderDetailUrl,
  getOrderRevenueUrl,
  ORDERS,
  ORDER_TABLE,
  getOrderAnalyticsUrl,
} from "./OrderEndpoints";

//NEW ENDPOINTS
export const LOGIN = "auth/login";
export const CLIENT = "auth/client";
export const CLIENT_WEB = "auth/client/web";
export const OFFER = "offer";
export const OFFERS_LIST_SORT = "offer/sort";
export const WHATSON = "whatson";
export const DRAW = "draw";
export const CHANGE_PASSWORD = "auth/changePassword";
export const LOYALTY = "reward";
export const REWARD_TRANSACTIONS = "reward/transactions";
export const GALLERY = "gallery";
export const MENU = "menu";
export const MENU_CATEGORY = "menuCategory";
export const ALLERGEN = "allergenes";
export const USERS = "users";
export const USERS_DETAILS = "userDetails";
export const RESERVATION = "bookings";
export const USER_NUMBER = "analytics";
export const NOTIFICATION = "push";
export const USER = "user";
export const USER_BRANDING = "user/branding";
export const PREVIEW_WEB = "user/preview";
export const DOWNLOAD_QR = "user/download-qr";
export const SUBSCRIPTION = "subscription";
export const SEND_PREMIUM_LEAD_EMAIL = "user/sendPremiumLeadEmail";
export const SEND_CONTACT_SUPPORT_EMAIL = "user/sendContactSupportEmail";
export const ADD_LEAD_VISITING_PRICING = "user/addLeadVisitPricingKlaviyo";
export const CARDS = "cards";
export const STAFF = "auth/staff";
export const FORGOT_PASSWORD = "auth/forgotPassword";
export const RESET_PASSWORD = "auth/resetPassword";
export const CREATE_PASSWORD = "auth/create-password";
export const CHECK_TOKEN = "auth/validateToken";
export const CHECK_VALID_ACCOUNT_SETUP = "auth/validate-setup-account";
export const CLICKED_QR = "merchant/clicked-qr";
export const SECRET = "merchant/secret";
//OLD ENDPOINTS
export const INDEX =
  "index.php?apikey=67a74306b06d0c01624fe0d0249a570f4d093747&";
export const MERCHANT = "merchant/purchase";
export const MERCHANT_UP_SELL_EMAIL = "merchant/sendUpSellEmail";

export const APP_CODE = "1";

export const getUserId = (): string => {
  return localStorage.getItem("idUser");
};

export const getMasterId = (): string => {
  return localStorage.getItem("masterId");
};

export const loginUserUrl = (): string => {
  return LOGIN;
};

export const getUserInfoUrl = (): string => {
  return CLIENT + "/" + getUserId();
};

export const getOfferDetailUrl = (id: string): string => {
  return OFFER + "/" + id;
};

export const getWhatsOnDetailUrl = (id: string): string => {
  return WHATSON + "/" + id;
};

export const getDrawDetailUrl = (id: string): string => {
  return DRAW + "/" + id;
};

export const getCreateReservationUrl = (reservation: Reservation): string => {
  return encodeURI("");
};

export const getEditReservationUrl = (reservation: Reservation): string => {
  return encodeURI("");
};

export const getUserNumberUrl = (): string => {
  return encodeURI(USER_NUMBER + "/" + getUserId());
};

export const getNotificationListUrl = (): string => {
  return NOTIFICATION + "client=" + getUserId();
};
export const getCreateNotificationUrl = (): string => {
  return encodeURI(NOTIFICATION);
};

export const getTableListUrl = (venueId: string): string => {
  return encodeURI(ORDER_TABLE + "?venueId=" + venueId);
};

export {
  getVoucherDetailUrl,
  getVenueListUrl,
  getVenueDetailUrl,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  getOrderListUrl,
  getOrderDetailUrl,
  getOrderRevenueUrl,
  getOrderAnalyticsUrl,
  MENU_ITEM,
  VENUE,
  ORDERS,
  VOUCHER_URL,
  SORT_VOUCHER,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_CATEGORY_SORT,
  COLLECTION_AVAILABILITY,
  DELIVERY_AVAILABILITY,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_DUPLICATE,
  MENU_DUPLICATE_ITEM,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  ORDER_TABLE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
  MENU_ITEM_UPDATE_TIME,
  DISCOUNT_VOUCHER_URL,
};
