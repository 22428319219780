import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import OptionsCard from "./components/OptionsCard";
import AddCardModal from "./components/AddCardModal";
import { getCardList } from "app/store/payment/PaymentActions";
import LoadingOverlay from "react-loading-overlay";
import { useLanguageHelper } from 'app/helpers/LanguageHelper/useLanguageHelper';

const PaymentMethods = () => {
  const cards = useSelector(({ banzzu }) => banzzu.payment.cards);
  const loading = useSelector(({ banzzu }) => banzzu.payment.loading);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    dispatch(getCardList());
  }, []);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <OptionsCard
        title={languageStrings["PAYMENT.CHOOSE_PAYMENT_METHOD"]}
        addTitle={languageStrings["PAYMENT.ADD_CARD"]}
        innerCards={
          cards && cards.length > 0
            ? cards.map((card) => ({
              ...card,
              _id: card.id,
              title: `**** **** **** ${card.last4}`,
            }))
            : []
        }
        addClickHandler={() => setShowModal(true)}
      />
      <AddCardModal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </LoadingOverlay>
  );
};

export default PaymentMethods;
