import venue from "app/store/venues/VenuesReducer";
import draw from "app/store/draws/DrawReducer";
import { combineReducers } from "redux";
import auth from "app/store/auth/AuthReducer";
import user from "app/store/user/UserReducer";
import offer from "app/store/offers/OffersReducer";
import whatson from "app/store/whatson/WhatsonReducer";
import gallery from "app/store/gallery/GalleryReducer";
import menu from "app/store/menu/MenuReducer";
import loyalty from "app/store/loyalty/LoyaltyReducer";
import reservation from "app/store/reservation/ReservationReducer";
import notification from "app/store/notification/NotificationReducer";
import voucher from "app/store/voucher/VouchersReducer";
import discountVoucher from "app/store/dicountVoucher/DiscountVoucherReducer";
import order from "app/store/orders/OrdersReducer";
import payment from "app/store/payment/PaymentReducer";
import staff from "app/store/staff/StaffReducer";
import merchant from "app/store/merchant/MerchantReducer";

const banzzuReducers = combineReducers({
  auth,
  venue,
  user,
  offer,
  whatson,
  gallery,
  menu,
  loyalty,
  reservation,
  notification,
  draw,
  voucher,
  order,
  payment,
  staff,
  merchant,
  discountVoucher,
});

export default banzzuReducers;
