import React from 'react'
import { Card, Button, Typography } from "@material-ui/core"
import { RemoveCircle, AddCircle } from "@material-ui/icons"
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

export default function StickerQuantity({   
    onSubmit,
    onBack,
    increaseQuantity,
    decreaseQuantity,
    quantity,
    price,
    title,
}) {

    const { languageStrings } = useLanguageHelper();

    return (

        <Card
            className="mx-24 mt-12 p-24 flex flex-col items-center justify-around shadow-lg md:w-1/3 w-full"
            style={{ borderRadius: "2rem" }}
        >
            <Typography
                variant="h6"
                className="font-bold text-center"
                style={{ color: "#f5aa00" }}
            >
                {title}
            </Typography>
            <div className="flex flex-row items-center justify-around w-full">
                <RemoveCircle
                    className="cursor-pointer"
                    style={{ color: "#f5aa00" }}
                    onClick={decreaseQuantity}
                />
                <Typography variant="h5">{quantity}</Typography>
                <AddCircle
                    className="cursor-pointer"
                    style={{ color: "#f5aa00" }}
                    onClick={increaseQuantity}
                />
            </div>

            <div className="px-12 w-full">
                <div className="flex flex-row items-center justify-between">
                    <Typography className="text-left" variant="subtitle1" style={{ color: "#f5aa00" }}>
                        Total
                    </Typography>
                    <Typography variant="h6">{price} €</Typography>
                </div>

                <div className="flex flex-row items-center justify-between">
                    <Typography className="text-left" variant="subtitle1" style={{ color: "#f5aa00" }}>
                    {languageStrings["GENERAL.DELIVERY_COST"]}
                </Typography>
                    <Typography variant="h6">6 €</Typography>
                </div>
            </div>

            <div className="flex flex-row justify-around mt-24 w-full">
                <Button
                    variant="contained"
                    onClick={onBack}
                >
                    {languageStrings["GENERAL.BACK"]}
                </Button>
                <Button
                    variant="contained"
                    style={{ backgroundColor: "#f5aa00", color: "white" }}
                    onClick={onSubmit}
                >
                    {languageStrings["GENERAL.CONTINUE"]}
                </Button>
            </div>
        </Card>
    )
}
