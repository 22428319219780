import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  makeStyles,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded, FuseUtils } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import { isEmailValid } from "app/helpers/validators";
import {
  createVenue,
  editVenue,
  getVenueDetail,
  updatePoints,
} from "app/store/venues/VenuesActions";
import LoadingOverlay from "react-loading-overlay";
import clsx from "clsx";

import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { MyMapComponent } from "./MyMapComponent";
import DeliverCalendar from "./calendar/DeliverCalendar";
import CollectionCalendar from "./calendar/CollectionCalendar";
import AlertBox from "app/main/common/AlertBox";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function VenueDetail(props) {
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(({ VenuesReducer }) => VenuesReducer.loading);
  let venue = useSelector(({ banzzu }) => banzzu.venue.venueDetail);
  let { deliveryInfo = [] } = venue || {};
  let venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  let userPermissions = useSelector(
    ({ banzzu }) => banzzu.auth.user.permission
  );
  const currency = useSelector(({ banzzu }) => banzzu.auth.user.currency);

  const { languageStrings } = useLanguageHelper();

  const params = props.match.params;
  const { productId, tab: focusTab } = params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getVenueDetail(productId));
      setForm(null);
      setInitialForm(null);
    }
  }, [dispatch, productId]);

  if (productId === "new") {
    venue = {
      id: "0",
      title: "",
      email: "",
      password: "",
    };
  }

  const dirtyInitial = {
    title: false,
    email: false,
    password: false,
  };

  const errorInitial = {
    title: "",
    email: "",
    password: "",
  };

  const [tabValue, setTabValue] = useState();
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);
  const [showPassword, setShowPassword] = useState(false);
  const [areaDetail, setAreaDetail] = useState({ index: 0, isCreating: false });
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);
  useEffect(() => {
    if ((venue && !form) || (venue && form && venue._id !== form._id)) {
      let areaLatitude = 0;
      let areaLongitude = 0;

      let deliveryInfoData = [
        { deliveryPrice: 0, minOrderPrice: 0, deliveryPoints: [] },
      ];
      if (deliveryInfo.length > 0) {
        if (
          deliveryInfo &&
          deliveryInfo.length === 1 &&
          deliveryInfo[0] &&
          deliveryInfo[0].deliveryPoints &&
          deliveryInfo[0].deliveryPoints.length <= 0
        ) {
          setAreaDetail({ index: 0, isCreating: true });
        } else {
          deliveryInfoData = deliveryInfo;
          setAreaDetail({ index: 0, isCreating: false });
        }
      } else {
        setAreaDetail({ index: 0, isCreating: true });
      }
      let form = {
        ...venue,
        deliveryInfo: deliveryInfoData,
      };

      if (userPermissions && Object.keys(userPermissions).length > 0) {
        form.isMaster = userPermissions.isMaster;
      }
      const data = {
        ...form,
        ...form.contactInfo,
        ...form.orderInfo,
        ...form.openingHours,
      };

      setForm(data);
      setInitialForm(data);
      if (
        deliveryInfo &&
        deliveryInfo[0] &&
        deliveryInfo[0].deliveryPoints &&
        deliveryInfo[0].deliveryPoints[0]
      ) {
        const { lat, lng } = deliveryInfo[0].deliveryPoints[0];
        areaLatitude = parseFloat(lat);
        areaLongitude = parseFloat(lng);
      } else if (
        data &&
        data.contactInfo &&
        data.contactInfo.latitude &&
        data.contactInfo.longitude
      ) {
        const { latitude, longitude } = data.contactInfo;
        areaLatitude = parseFloat(latitude);
        areaLongitude = parseFloat(longitude);
      }
      setLocation({ latitude: areaLatitude, longitude: areaLongitude });
    }
  }, [form, venue, setForm, setInitialForm, userPermissions]);

  useEffect(() => {
    let tab = 1;
    if (userPermissions && userPermissions.isMaster) {
      tab = 0;
    }

    if (focusTab) {
      tab = parseInt(focusTab);
    }

    setTabValue(tab);
  }, [userPermissions, productId]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }
  function handlePostionChange(tabValue = 0) {
    const { latitude = 0, longitude = 0 } = form.contactInfo;
    let lat = latitude;
    let long = longitude;
    if (
      form &&
      form.deliveryInfo &&
      form.deliveryInfo[tabValue] &&
      form.deliveryInfo[tabValue].deliveryPoints[0] &&
      form.deliveryInfo[tabValue].deliveryPoints[0].lat
    ) {
      lat = form.deliveryInfo[tabValue].deliveryPoints[0].lat;
    }
    if (
      form &&
      form.deliveryInfo &&
      form.deliveryInfo[tabValue] &&
      form.deliveryInfo[tabValue].deliveryPoints[0] &&
      form.deliveryInfo[tabValue].deliveryPoints[0].lng
    ) {
      long = form.deliveryInfo[tabValue].deliveryPoints[tabValue].lng;
    }
    setLocation({
      latitude: lat,
      longitude: long,
    });
  }
  function handleChangeInnerTab(event, tabValue) {
    handlePostionChange(tabValue);
    setForm((prevForm) => ({
      ...prevForm,
      minOrderPrice: form.deliveryInfo[tabValue].minOrderPrice,
      minDeliveryPrice: form.deliveryInfo[tabValue].minDeliveryPrice,
    }));
    const _isCreating =
      !!form.deliveryInfo[tabValue].deliveryPoints.length <= 0;

    setAreaDetail({ isCreating: _isCreating, index: tabValue });
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }
  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.email !== "" &&
      (form._id !== "0" || form.password !== "")
    );
  }

  function hasNoErrors() {
    let error = !errorName() && !errorPassword() && !errorEmail();
    return error;
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorPassword() {
    return dirty.password && form._id === "0" && form.password.length < 6;
  }

  function errorEmail() {
    return dirty.email && !isEmailValid(form.email);
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);
  function handleEmailChange(event) {
    handleChange(event);
    debouncedEmail(event.target.value);
  }

  function handleClickShowPassword(event) {
    setShowPassword(!showPassword);
  }

  const debouncedEmail = useDebounce((email) => {
    setDirty((prevState) => {
      return { ...prevState, email: true };
    });
    if (!isEmailValid(email)) {
      setError((prevState) => {
        return { ...prevState, email: languageStrings["ERRORS.INVALID_EMAIL"] };
      });
    }
  }, 500);

  function handlePasswordChange(event) {
    handleChange(event);
    debouncedPassword(form.password);
  }
  const debouncedPassword = useDebounce((password) => {
    setDirty((prevState) => {
      return { ...prevState, password: true };
    });
    if (password && password.length <= 6) {
      setError((prevState) => {
        return {
          ...prevState,
          password: languageStrings["ERRORS.MINIMUM_6_CHAR"],
        };
      });
    }
  }, 500);

  const locationExists = () => {
    let exists =
      form.latitude &&
      form.longitude &&
      form.latitude !== "" &&
      form.longitude !== "";
    return exists;
  };

  const goBack = () => {
    History.push({
      pathname: `/venueList`,
    });
  };

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  const deletAreaConfirmation = () => {
    dispatch(
      Actions.openDialog({
        children: (
          <AlertDialog
            onSuccess={() => {
              const prevData = form.deliveryInfo;
              prevData.splice(areaDetail.index, 1);
              dispatch(updatePoints(prevData));
              setForm((prevForm) => ({
                ...prevForm,
                deliveryInfo: prevData,
                deliveryPoints: [],
              }));
              const { lat = 0, lng = 0 } = prevData[0].deliveryPoints[0] || {};

              setLocation({ latitude: lat, longitude: lng });
              setAreaDetail({ ...areaDetail, index: 0 });
            }}
            title={languageStrings["GENERAL.CONFIRMATION_TITLE"]}
            message={languageStrings["GENERAL.AREA_REMOVE_ALERT_DESC"]}
          />
        ),
      })
    );
  };
  const onCreateArea = () => {
    const nextItemIndex = form.deliveryInfo.length;

    const mapData = {
      deliveryPrice: 0,
      minOrderPrice: 0,
      deliveryPoints: [],
    };
    const prevDeliveryData = form.deliveryInfo;
    prevDeliveryData[nextItemIndex] = mapData;
    setForm((prevForm) => ({
      ...prevForm,
      deliveryInfo: prevDeliveryData,
    }));

    setAreaDetail({
      index: nextItemIndex,
      isCreating: true,
    });
    handlePostionChange(nextItemIndex);
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div style={{ flex: 1, filter: "none" }}>
          <FusePageCarded
            classes={{
              toolbar: "p-0",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              form && (
                <div className="flex flex-1 w-full items-center justify-between">
                  <div className="flex flex-col items-start max-w-full">
                    <FuseAnimate
                      animation="transition.slideRightIn"
                      delay={300}
                    >
                      <Button onClick={previousPage}>
                        <Icon className="mr-4 text-20">arrow_back</Icon>
                        {languageStrings["VENUE_DETAIL_PAGE.BUTTON_BACK"]}
                      </Button>
                    </FuseAnimate>

                    <div className="flex items-center max-w-full">
                      <div className="flex flex-col min-w-0">
                        <FuseAnimate
                          animation="transition.slideLeftIn"
                          delay={300}
                        >
                          <Typography className="text-16 sm:text-20 truncate">
                            {form.id !== "0"
                              ? form.title
                              : languageStrings["VENUE_DETAIL_PAGE.MAIN_TITLE"]}
                          </Typography>
                        </FuseAnimate>
                        <FuseAnimate
                          animation="transition.slideLeftIn"
                          delay={300}
                        >
                          <Typography variant="caption">
                            {languageStrings["VENUE_DETAIL_PAGE.TITLE"]}
                          </Typography>
                        </FuseAnimate>
                      </div>
                    </div>
                  </div>
                  <FuseAnimate animation="transition.slideRightIn" delay={300}>
                    <Button
                      className="whitespace-no-wrap"
                      variant="contained"
                      disabled={!canBeSubmitted()}
                      onClick={() => {
                        form.id !== "0"
                          ? dispatch(
                              editVenue(
                                form,
                                venue.deliveryPoints,
                                form.deliveryInfo
                              )
                            )
                          : dispatch(createVenue(form));
                      }}
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        save
                      </Icon>
                      {languageStrings["GENERAL.SAVE"]}
                    </Button>
                  </FuseAnimate>
                </div>
              )
            }
            contentToolbar={
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                classes={{ root: "w-full h-64" }}
              >
                <Tab
                  className={
                    userPermissions && userPermissions.isMaster
                      ? "h-64 normal-case"
                      : "hidden"
                  }
                  label={languageStrings["GENERAL.BASIC_INFO"]}
                />

                <Tab
                  className={
                    productId === "new" ? "hidden" : "h-64 normal-case"
                  }
                  label={languageStrings["GENERAL.OPENING_HOURS"]}
                />
                <Tab
                  className={
                    productId === "new" ||
                    (userPermissions && !userPermissions.hasDelivery)
                      ? "hidden"
                      : "h-64 normal-case"
                  }
                  label={languageStrings["GENERAL.DELIVERY_INFO"]}
                />
                <Tab
                  className={
                    productId === "new" ||
                    (userPermissions && !userPermissions.hasDelivery)
                      ? "hidden"
                      : "h-64 normal-case"
                  }
                  label={languageStrings["GENERAL.DELIVERY_TIME"]}
                />
                <Tab
                  className={
                    productId === "new" ||
                    (userPermissions && !userPermissions.hasCollection)
                      ? "hidden"
                      : "h-64 normal-case"
                  }
                  label={languageStrings["GENERAL.COLLECTION_TIME"]}
                />
              </Tabs>
            }
            content={
              form &&
              userPermissions && (
                <>
                  {(!userPermissions.hasDelivery ||
                    !userPermissions.hasCollection) &&
                    user.accessLevel !== "1" && (
                      <div className={`p16 sm:p-24 max-w-2xl`}>
                        <AlertBox
                          title={
                            languageStrings[
                              "VENUE_DETAIL_PAGE.DELIVERY_ACTIVATION_ALERT"
                            ]
                          }
                        />
                      </div>
                    )}
                  <div
                    className={`p16 sm:p-24 ${
                      tabValue === 2 ? "w-full" : "max-w-2xl"
                    }`}
                  >
                    {tabValue === 0 && (
                      <div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16"
                            error={errorName()}
                            required
                            label={languageStrings["GENERAL.NAME"]}
                            id="title"
                            name="title"
                            value={form.title}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleTitleChange}
                            variant="outlined"
                            fullWidth
                            helperText={errorName() ? error.title : ""}
                          />
                        </div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.ADDRESS"]}
                            id="address"
                            name="address"
                            value={form.address || ""}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.POSTCODE"]}
                            id="postcode"
                            name="postcode"
                            value={form.postcode}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.PHONE"]}
                            id="phone"
                            name="phone"
                            value={form.phone || ""}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.WEBSITE"]}
                            id="website"
                            name="website"
                            value={form.website || ""}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>

                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.LATITUDE"]}
                            id="latitude"
                            name="latitude"
                            type={"number"}
                            value={form.latitude}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.LONGITUDE"]}
                            id="longitude"
                            name="longitude"
                            type={"number"}
                            value={form.longitude}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>

                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={"Facebook"}
                            id="facebook"
                            name="facebook"
                            value={form.facebook || ""}
                            InputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8 mr-8"
                            label={"Instagram"}
                            id="instagram"
                            name="instagram"
                            value={form.instagram || ""}
                            InputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={"Twitter"}
                            id="twitter"
                            name="twitter"
                            value={form.twitter || ""}
                            InputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                    )}
                    {tabValue === 1 && (
                      <div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.MONDAY"]}
                            id="monday"
                            name="monday"
                            value={form.monday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.TUESDAY"]}
                            id="tuesday"
                            name="tuesday"
                            value={form.tuesday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.WEDNESDAY"]}
                            id="wednesday"
                            name="wednesday"
                            value={form.wednesday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.THURSDAY"]}
                            id="thursday"
                            name="thursday"
                            value={form.thursday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleTitleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                        <div className="flex">
                          <TextField
                            className="mt-8 mb-16 mr-8"
                            label={languageStrings["GENERAL.FRIDAY"]}
                            id="friday"
                            name="friday"
                            value={form.friday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8 mr-8"
                            label={languageStrings["GENERAL.SATURDAY"]}
                            id="saturday"
                            name="saturday"
                            value={form.saturday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                          <TextField
                            className="mt-8 mb-16 ml-8"
                            label={languageStrings["GENERAL.SUNDAY"]}
                            id="sunday"
                            name="sunday"
                            value={form.sunday}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                    )}
                    {tabValue === 2 && (
                      <>
                        <div className={"w-full border-b-1 mb-20"}>
                          <Button
                            className={"mb-20 whitespace-no-wrap"}
                            variant="contained"
                            onClick={onCreateArea}
                          >
                            {languageStrings["VENUE_DETAIL_PAGE.CREATE_AREA"]}
                          </Button>
                          <Tabs
                            value={areaDetail.index}
                            onChange={handleChangeInnerTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                          >
                            {form.deliveryInfo.map((item, index) => (
                              <Tab
                                value={index}
                                label={`${
                                  languageStrings["VENUE_DETAIL_PAGE.AREA"]
                                } ${index + 1} `}
                              />
                            ))}
                          </Tabs>
                        </div>
                        <>
                          <div className="flex">
                            <TextField
                              className="mt-8 mb-16 mr-8"
                              label={languageStrings["GENERAL.MIN_ORDER_PRICE"]}
                              id="minOrderPrice"
                              name="minOrderPrice"
                              type="number"
                              value={
                                form.deliveryInfo[areaDetail.index]
                                  .minOrderPrice
                              }
                              InputProps={{
                                maxLength: 15,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currency}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(event) => {
                                const arr = form.deliveryInfo;
                                const newData =
                                  form.deliveryInfo[areaDetail.index];
                                newData.minOrderPrice = parseFloat(
                                  event.target.value
                                );
                                arr[areaDetail.index] = newData;

                                setForm((prevForm) => ({
                                  ...prevForm,
                                  deliveryInfo: arr,
                                }));

                                handleChange(event);
                              }}
                              variant="outlined"
                              fullWidth
                            />
                            <TextField
                              className="mt-8 mb-16 ml-8"
                              label={
                                languageStrings["GENERAL.MIN_DELIVERY_PRICE"]
                              }
                              id="minDeliveryPrice"
                              name="minDeliveryPrice"
                              type="number"
                              value={
                                form.deliveryInfo[areaDetail.index]
                                  .deliveryPrice
                              }
                              InputProps={{
                                maxLength: 15,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currency}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(event) => {
                                const arr = form.deliveryInfo;
                                const newData =
                                  form.deliveryInfo[areaDetail.index];
                                newData.deliveryPrice = parseFloat(
                                  event.target.value
                                );
                                arr[areaDetail.index] = newData;

                                setForm((prevForm) => ({
                                  ...prevForm,
                                  deliveryInfo: arr,
                                }));

                                handleChange(event);
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                          {!locationExists() ? (
                            <AlertBox
                              title={
                                languageStrings["VENUE.ADD_LONGITUDE_ALERT"]
                              }
                            />
                          ) : (
                            <>
                              <div className="flex mb-16 flex-1 w-full items-center">
                                {deliveryInfo.length <= 1 ||
                                form.deliveryInfo.length <= 1 ? (
                                  <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={() => {
                                      setForm((prevForm) => ({
                                        ...prevForm,
                                        deliveryInfo: [
                                          {
                                            minOrderPrice: 0,
                                            deliveryPrice: 0,
                                            deliveryPoints: [],
                                          },
                                        ],
                                        deliveryPoints: [],
                                      }));
                                      setAreaDetail({
                                        index: 0,
                                        isCreating: true,
                                      });
                                    }}
                                  >
                                    <Icon
                                      className={clsx(
                                        classes.leftIcon,
                                        classes.iconSmall
                                      )}
                                    >
                                      delete
                                    </Icon>
                                    {
                                      languageStrings[
                                        "VENUE_DETAIL_PAGE.CLEAR_AREA"
                                      ]
                                    }
                                  </Button>
                                ) : (
                                  <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={deletAreaConfirmation}
                                  >
                                    <Icon
                                      className={clsx(
                                        classes.leftIcon,
                                        classes.iconSmall
                                      )}
                                    >
                                      delete
                                    </Icon>
                                    {
                                      languageStrings[
                                        "VENUE_DETAIL_PAGE.REMOVE_AREA"
                                      ]
                                    }
                                  </Button>
                                )}
                              </div>

                              <div className={" mt-20 w-full"}>
                                <MyMapComponent
                                  mapIndex={areaDetail.index}
                                  isCreatingNewArea={areaDetail.isCreating}
                                  {...location}
                                  initialPaths={
                                    form.deliveryInfo[areaDetail.index]
                                      .deliveryPoints
                                  }
                                  onPathChanged={(nextPath, mapIndex) => {
                                    let prevDeliveryData = form.deliveryInfo;

                                    prevDeliveryData[mapIndex] = {
                                      ...prevDeliveryData[mapIndex],
                                      deliveryPoints: nextPath,
                                    };
                                    setForm((prevForm) => ({
                                      ...prevForm,
                                      deliveryInfo: prevDeliveryData,
                                    }));

                                    let payload = {
                                      minOrderPrice: parseFloat(
                                        form.minOrderPrice || 0
                                      ),
                                      deliveryPrice: parseFloat(
                                        form.minDeliveryPrice || 0
                                      ),
                                      deliveryPoints: nextPath,
                                    };
                                    setAreaDetail({
                                      index: mapIndex,
                                      isCreating: false,
                                    });
                                    dispatch(updatePoints(payload));
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </>
                      </>
                    )}

                    {userPermissions &&
                      userPermissions.hasDelivery &&
                      tabValue === 3 && <DeliverCalendar venue={venue} />}
                    {userPermissions &&
                      userPermissions.hasCollection &&
                      tabValue === 4 && <CollectionCalendar venue={venue} />}
                  </div>
                </>
              )
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

export default withReducer("VenuesReducer", reducer)(VenueDetail);
