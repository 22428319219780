import { OffersActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: OfferState = {
  offerList: [],
  loading: false,
  searchText: "",
  offerDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const OffersReducer = (
  state: OfferState = INITIAL_STATE,
  action: Action
): OfferState => {
  switch (action.type) {
    case OffersActionTypes.OFFERS_LIST_START:
      return updateObject(state, { loading: true, offerList: [] });

    case OffersActionTypes.OFFERS_LIST_SUCCESS:
      return updateObject(state, { offerList: action.payload, loading: false, });

    case OffersActionTypes.OFFERS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case OffersActionTypes.SET_OFFERS_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText });

    case OffersActionTypes.OFFERS_DETAIL_START:
      return updateObject(state, { loading: true, offerDetail: {} });

    case OffersActionTypes.OFFERS_DETAIL_SUCCESS:
      return updateObject(state, { offerDetail: action.payload, loading: false });

    case OffersActionTypes.OFFERS_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_CREATE_START:
      return updateObject(state, { loading: true });

    case OffersActionTypes.OFFERS_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_EDIT_START:
      return updateObject(state, { loading: true });

    case OffersActionTypes.OFFERS_EDIT_SUCCESS:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_DELETE_START:
      return updateObject(state, { loading: true });

    case OffersActionTypes.OFFERS_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case OffersActionTypes.OFFERS_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case OffersActionTypes.SORT_OFFER_LIST_START:
      return updateObject(state, { loading: true, offerList: action.payload });

    case OffersActionTypes.SORT_OFFER_LIST_SUCCESS:
      return updateObject(state, { loading: false });

    case OffersActionTypes.SORT_OFFER_LIST_FAIL:
      return updateObject(state, { loading: false });


    default: {
      return state;
    }
  }
};

export default OffersReducer;
