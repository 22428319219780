import React, { useEffect, useState } from "react";
import {
  Icon,
  Typography,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import MaterialTable from "material-table";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getVenueList } from "app/store/venues/VenuesActions";
import {
  getTableList,
  editTable,
  deleteTable,
  createTable,
} from "app/store/orders/OrdersActions";
import QRCode from "qrcode.react";
import history from "@history";

const OrderFromTableList = (props) => {
  const { languageStrings } = useLanguageHelper();

  const dispatch = useDispatch();
  const tableList = useSelector(({ banzzu }) => banzzu.order.tableList);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { settings, permission, accessLevel } = useSelector(
    ({ banzzu }) => banzzu.auth.user
  );
  const [venueSelected, setVenueSelected] = useState("0");

  const [state, setState] = useState({});
  useEffect(() => {
    if (accessLevel && permission && Object.keys(permission).length > 0) {
      const check =
        permission.hasOrderFromTable === true &&
          (accessLevel === "2" || accessLevel === "3")
          ? true
          : false;

      if (!check) {
        history.replace({
          pathname: "/home",
        });
      }
    }
  }, [permission, accessLevel]);
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0 && settings.permalink) {

      const webUrl = process.env.REACT_APP_ENVIRONMENT === "production"
        ? `https://web.banzzu.com/${settings.permalink}`
        : process.env.REACT_APP_ENVIRONMENT === "staging" ?
          `https://webstaging.banzzu.com/${settings.permalink}` :
          `http://localhost:3001/${settings.permalink}`

      setState({
        columns: [
          { title: languageStrings["GENERAL.TITLE"], field: "title" },
          {
            field: "url",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <QRCode
                    id={rowData._id}
                    value={`${webUrl}/${rowData._id}`}
                  />
                )
              );
            },
          },
          {
            field: "code",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <div>{`${webUrl}/${rowData._id}`}</div>
                )
              );
            },
          },
          {
            field: "download",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <NavLink onClick={() => downloadQR(rowData.title.replace(/ /g, ''), rowData._id)}>
                    {languageStrings["ORDER_FROM_TABLE.DOWNLOAD_QR"]}
                  </NavLink>
                )
              );
            },
          },
        ],
      });
    }
  }, [settings, venueList]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  useEffect(() => {
    if (venueList.length > 0 && venueSelected === "0") {
      setVenueSelected(venueList[0]._id);
    }
  }, [venueList]);

  useEffect(() => {
    if (venueSelected !== "0") {
      dispatch(getTableList(venueSelected));
    }
  }, [dispatch, venueSelected]);

  const downloadQR = (venueTitle, id) => {
    const canvas = document.getElementById(id);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${venueTitle}-${id}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className="w-full px-20">
      <FormControl fullWidth variant="outlined">
        <InputLabel className="mt-10" htmlFor="outlined-venue-native-simple">
          {languageStrings["ORDER_FROM_TABLE.VENUES"]}
        </InputLabel>
        <Select
          className="mt-10 mb-16 mr-8"
          native
          required
          onChange={(e) => setVenueSelected(e.target.value)}
          input={
            <OutlinedInput
              name="venues"
              labelWidth={100}
              id="outlined-venue-native-simple"
            />
          }
        >
          {venueList.map((venue) => (
            <option value={venue._id}>{venue.title}</option>
          ))}
        </Select>
      </FormControl>

      <MaterialTable
        title={""}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: languageStrings["GENERAL.ACTIONS"],
          },
          body: {
            emptyDataSourceMessage: languageStrings["MATERIAL_TABLE.NO_RECORDS"],
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
        columns={state.columns}
        data={tableList}
        options={{ search: false, pageSize: 5 }}
        icons={{
          Add: () => (
            <Typography
              color="secondary"
              className="normal-case flex items-center sm:mb-12"
            >
              <Icon color="secondary" className="text-32 mr-8">
                add_circle_outline
              </Icon>
              {languageStrings["ORDER_FROM_TABLE.CREATE_TABLE"]}
            </Typography>
          ),
          Delete: () => (
            <Icon color="error" className="text-22">
              delete_outline
            </Icon>
          ),
          Edit: () => (
            <Icon color="secondary" className="text-22">
              edit
            </Icon>
          ),
          Check: () => <Icon className="text-22 text-green">check</Icon>,
          Clear: () => (
            <Icon color="error" className="text-22">
              close
            </Icon>
          ),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              dispatch(createTable(newData, venueSelected));
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              dispatch(editTable(newData.title, newData._id, venueSelected));
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              dispatch(deleteTable(oldData._id, venueSelected));
              resolve();
            }),
        }}
      />
    </div>
  );
};

export default withReducer("MenuReducer", reducer)(OrderFromTableList);
