import { MenuActionTypes } from "../redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import _ from "@lodash";

import {
  MENU,
  ALLERGEN,
  MENU_ITEM,
  MENU_CATEGORY,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_CATEGORY_SORT,
  MENU_DUPLICATE,
  MENU_DUPLICATE_ITEM,
  getUserId,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  MENU_ITEM_UPDATE_TIME,
} from "app/api/Endpoint";

import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import { Menu, MenuItem } from "app/models/Menu";
import { ExtrasCategory } from "app/models/ExtrasCatergory";
import { ExtrasCategoryItem } from "app/models/ExtrasCategoryItem";

export function setMenuSearchText(event) {
  return {
    type: MenuActionTypes.SET_MENU_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export function setMenuItemCategory(category) {
  return {
    type: MenuActionTypes.SET_MENU_ITEM_SEARCH_CATEGORY,
    payload: category,
  };
}

export const getMenuList = () => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(MENU, {
        params,
      })
      .then((res) => {
        getMenuListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuListFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_LIST_SUCCESS,
    payload: data,
  });
};

export const getMenuDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_DETAIL_START,
    });

    const url = MENU + "/" + id;

    axios
      .get(url)
      .then((res) => {
        getMenuDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/menuList`,
  });
};

const getMenuDetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createMenu = (menu: Menu,sucessMessage?:string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());
    for (let key in menu) {
      if (key == "imageFile") {
        formData.append(key, menu[key], menu[key].name);
      } else if (key != "id") {
        formData.append(key, menu[key]);
      }
    }

    axios
      .post(MENU, formData)
      .then((res) => {
        createMenuSuccess(dispatch, res.data,sucessMessage);
      })
      .catch(() => {
        createMenuFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuSuccess = (dispatch, data,sucessMessage?:string) => {
  if (sucessMessage) {
    
    successAlert(dispatch, sucessMessage||'');
  }
  dispatch({
    type: MenuActionTypes.MENU_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/menuDetailPro/" + data.data._id,
  });
};

export const editMenu = (menu: Menu) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EDIT_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in menu) {
      if (key == "_id") {
        formData.append("menuId", menu[key]);
      } else if (key == "imageFile") {
        formData.append(key, menu[key], menu[key].name);
      } else {
        formData.append(key, menu[key]);
      }
    }

    axios
      .put(MENU, formData)
      .then((res) => {
        editMenuSuccess(dispatch, res.data);
      })
      .catch(() => {
        editMenuFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_EDIT_SUCCESS,
    payload: data,
  });

  // History.push({
  //   pathname: "/menuList/",
  // });
};

export const getMenuItemList = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_LIST_START,
    });

    const params = {
      menuId: id,
    };

    axios
      .get(MENU_ITEM, { params })
      .then((res) => {
        getMenuItemListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemListFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_LIST_SUCCESS,
    payload: data,
  });
};

export const getMenuItemDetail = (id: string, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_DETAIL_START,
    });

    const url = MENU_ITEM + "/" + id;

    axios
      .get(url)
      .then((res) => {
        getMenuItemDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemDetailFail = (dispatch, error) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DETAIL_FAIL,
    payload: {
      errorMessage: error.message,
    },
  });
  errorAlert(dispatch, error.message);
  History.push({
    pathname: `/menuItemList/${error.menuId}`,
  });
};

const getMenuItemDetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DETAIL_SUCCESS,
    payload: data,
  });
};

export const getMenuItemCategory = (menu: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_CATEGORY_START,
    });

    const params = {
      menuId: menu,
    };

    axios
      .get(MENU_CATEGORY, { params })
      .then((res) => {
        getMenuItemCategorySuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemCategorySuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_CATEGORY_SUCCESS,
    payload: data,
  });
};

export const getMenuItemAllergenes = () => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_ALLERGENES_START,
    });

    axios
      .get(ALLERGEN)
      .then((res) => {
        getMenuItemAllergenesSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemAllergenesFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemAllergenesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_ALLERGENES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemAllergenesSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_ALLERGENES_SUCCESS,
    payload: data,
  });
};

export const getMenuItemExtraCategories = (menuItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_START,
    });

    const params = {
      menuItemId: menuItem,
    };

    axios
      .get(MENU_ITEM_EXTRA_CATEGORY, { params })
      .then((res) => {
        getMenuItemExtraCategoriesSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemExtraCategoriesFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const getMenuItemExtraCategoriesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemExtraCategoriesSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_SUCCESS,
    payload: data,
  });
};

export const removeMenuItemExtraCategories = () => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.REMOVE_MENU_EXTRA_CATEGORY_SUCCESS,
    });
  };
};

export const getMenuExtraCategoryItems = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_START,
    });

    const params = {
      menuItemExtraCategoryId: categoryId,
    };

    axios
      .get(MENU_ITEM_EXTRA_CATEGORY_ITEM, { params })
      .then((res) => {
        getMenuExtraCategoryItemsSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuExtraCategoryItemsFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const getMenuExtraCategoryItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuExtraCategoryItemsSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_SUCCESS,
    payload: data,
  });
};

export const createMenuItemExtraCategories = (
  category: ExtrasCategory,
  menuId?: string
) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_CREATE_START,
    });

    const request = {
      menuItemId: category.menuItem,
      description: category.description,
      hasMaxMin: category.hasMaxMin,
      maxQuantity: category.maxQuantity,
      minQuantity: category.minQuantity,
      title: category.title,
      type: category.type,
    };

    axios
      .post(MENU_ITEM_EXTRA_CATEGORY, request)
      .then((res) => {
        createMenuItemExtraCategoriesSuccess(
          dispatch,
          res.data,
          category,
          menuId
        );
      })
      .catch(() => {
        createMenuItemExtraCategoriesFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const createMenuItemExtraCategoriesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuItemExtraCategoriesSuccess = (
  dispatch,
  data,
  category,
  menuId
) => {
  // successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_CREATE_SUCCESS,
    payload: data.data,
  });
  // dispatch(getMenuItemList(menuId));
};

export const editMenuItemExtraCategories = (
  category: ExtrasCategory,
  menuItemId: string,
  menuId?: string
) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_START,
    });

    const request = {
      menuItemExtraCategoryId: category._id,
      description: category.description,
      hasMaxMin: category.hasMaxMin,
      maxQuantity: category.maxQuantity,
      minQuantity: category.minQuantity,
      title: category.title,
      type: category.type,
      connectedCategory: category.connectedCategory,
    };

    axios
      .put(MENU_ITEM_EXTRA_CATEGORY, request)
      .then((res) => {
        editMenuItemExtraCategoriesSuccess(
          dispatch,
          menuItemId,
          category,
          menuId
        );
      })
      .catch((error) => {
        editMenuItemExtraCategoriesFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const editMenuItemExtraCategoriesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuItemExtraCategoriesSuccess = (
  dispatch,
  menuItemId,
  category,
  menuId
) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_SUCCESS,
    payload: category,
  });
  //dispatch(getMenuItemList(menuId));
};

export const createMenuExtraCatItems = (item: ExtrasCategoryItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());
    for (let key in item) {
      if (key == "imageFile" && item[key]) {
        formData.append(key, item[key], item[key].name);
      } else if (key == "extra_category") {
        formData.append("menuItemExtraCategoryId", item[key]);
      } else if (key == "connectedCategoryItem") {
        formData.append("connectedCategoryItem", item[key]);
      } else {
        formData.append(key, item[key]);
      }
    }
    axios
      .post(MENU_ITEM_EXTRA_CATEGORY_ITEM, formData)
      .then((res) => {
        const data = res.data;
        createMenuExtraCatItemsSuccess(dispatch, data, item);
      })
      .catch((error) => {
        createMenuExtraCatItemsFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuExtraCatItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuExtraCatItemsSuccess = (dispatch, data, category) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS,
    payload: category,
  });
  dispatch(getMenuExtraCategoryItems(category.extra_category));
};

export const editMenuExtraCatItems = (item: ExtrasCategoryItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_START,
    });
    let formData = new FormData();
    formData.append("userId", getUserId());
    for (let key in item) {
      if (key == "imageFile" && item[key]) {
        formData.append(key, item[key], item[key].name);
      } else if (key == "_id") {
        formData.append("menuItemExtraCategoryItemId", item[key]);
      } else {
        formData.append(key, item[key]);
      }
    }
    axios
      .put(MENU_ITEM_EXTRA_CATEGORY_ITEM, formData)
      .then((res) => {
        editMenuExtraCatItemsSuccess(dispatch, res.data, item);
      })
      .catch((error) => {
        editMenuExtraCatItemsFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuExtraCatItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuExtraCatItemsSuccess = (dispatch, data, category) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS,
    payload: category,
  });
  dispatch(getMenuExtraCategoryItems(category.extra_category));
};

export const deleteMenuExtraCatItems = (id: string, categoryId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_START,
    });

    axios
      .delete(MENU_ITEM_EXTRA_CATEGORY_ITEM, {
        data: { menuItemExtraCategoryItemId: id },
      })
      .then((res) => {
        deleteMenuExtraCatItemsSuccess(dispatch, res.data, categoryId);
      })
      .catch((error) => {
        deleteMenuExtraCatItemsFail(dispatch, "There was an error connection");
      });
  };
};

const deleteMenuExtraCatItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuExtraCatItemsSuccess = (dispatch, data, categoryId) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_SUCCESS,
    payload: categoryId,
  });
  dispatch(getMenuExtraCategoryItems(categoryId));
};

export const deleteMenuExtraCategory = (
  id: string,
  menuItem: string,
  menuId?: string
) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_EXTRA_CATEGORY_DELETE_START,
    });

    axios
      .delete(MENU_ITEM_EXTRA_CATEGORY, {
        data: { menuItemExtraCategoryId: id },
      })
      .then((res) => {
        deleteMenuExtraCategorySuccess(dispatch, id, menuItem, menuId);
      })
      .catch((error) => {
        deleteMenuExtraCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const deleteMenuExtraCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuExtraCategorySuccess = (dispatch, id, menuItem, menuId) => {
  //successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_EXTRA_CATEGORY_DELETE_SUCCESS,
    payload: id,
  });
  //dispatch(getMenuItemList(menuId));
};

export const createMenuItem = (menuItem: MenuItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in menuItem) {
      if (key == "idMenu") {
        formData.append("menuId", menuItem[key]);
      } else if (key == "imageFile") {
        formData.append(key, menuItem[key], menuItem[key].name);
      } else if (key != "id") {
        formData.append(key, menuItem[key]);
      }
    }

    axios
      .post(MENU_ITEM, formData)
      .then((res) => {
        const data = res.data;
        createMenuItemSuccess(dispatch, data, menuItem.idMenu);
      })
      .catch(() => {
        createMenuItemFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuItemSuccess = (dispatch, data, idMenu) => {
  //successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_ITEM_CREATE_SUCCESS,
    payload: data,
  });
  //dispatch(getMenuItemList(idMenu));
  // History.push({
  //   pathname: "/menuItemList/" + idMenu,
  // });
};

export const editMenuItem = (menuItem: MenuItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_EDIT_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());

    for (let key in menuItem) {
      if (key == "_id") {
        formData.append("menuItemId", menuItem[key]);
      } else if (key == "imageFile" && menuItem[key]) {
        formData.append(key, menuItem[key], menuItem[key].name);
      } else if (menuItem[key] != null) {
        formData.append(key, menuItem[key]);
      }
    }
    axios
      .put(MENU_ITEM, formData)
      .then((res) => {
        editMenuItemSuccess(dispatch, menuItem, menuItem.menuId);
      })
      .catch(() => {
        editMenuItemFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuItemSuccess = (dispatch, data, idMenu) => {
  //successAlert(dispatch, data.message);
  dispatch({
    type: MenuActionTypes.MENU_ITEM_EDIT_SUCCESS,
    payload: data,
  });
  //dispatch(getMenuItemList(idMenu));
  // History.push({
  //   pathname: "/menuItemList/" + idMenu,
  // });
};

export const deleteMenu = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(MENU, { data: { menuId: id } })
        .then((res) => {
          deleteMenuSuccess(dispatch, res.data);
        })
        .catch(() => {
          deleteMenuFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteMenuFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuList());
  dispatch({
    type: MenuActionTypes.MENU_DELETE_SUCCESS,
    payload: data,
  });
};

export const deleteMenuItem = (idArray: String[], menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_DELETE_START,
      payload: idArray[0],
    });

    idArray.map((id) => {
      axios
        .delete(MENU_ITEM, { data: { menuItemId: id } })
        .then((res) => {
          deleteMenuItemSuccess(dispatch, res.data, menuId);
        })
        .catch(() => {
          deleteMenuItemFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteMenuItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuItemSuccess = (dispatch, data, menuId) => {
  // successAlert(dispatch, data.message);
  // dispatch(getMenuItemList(menuId));
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DELETE_SUCCESS,
    payload: data,
  });
};

export const createMenuCategory = (menu: string, title: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_CAT_CREATE_START,
    });

    let request = {
      menuId: menu,
      title,
    };

    axios
      .post(MENU_CATEGORY, request)
      .then((res) => {
        createMenuCategorySuccess(dispatch, res.data, menu);
      })
      .catch(() => {
        createMenuCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_CAT_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuCategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategory(menuId));
  dispatch({
    type: MenuActionTypes.MENU_CAT_CREATE_SUCCESS,
    payload: data,
  });
};

export const editMenuCategory = (id: string, title: string, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_CAT_EDIT_START,
    });

    let request = {
      menuCategoryId: id,
      title,
    };

    axios
      .put(MENU_CATEGORY, request)
      .then((res) => {
        const data = res.data;
        editMenuCategorySuccess(dispatch, data, menuId);
      })
      .catch((error) => {
        editMenuCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_CAT_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuCategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategory(menuId));
  dispatch({
    type: MenuActionTypes.MENU_CAT_EDIT_SUCCESS,
    payload: data,
  });
};

export const deleteMenuCategory = (id: string, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_CAT_DELETE_START,
    });

    let request = {
      data: {
        menuCategoryId: id,
      },
    };

    axios
      .delete(MENU_CATEGORY, request)
      .then((res) => {
        deleteMenuCategorySuccess(dispatch, res.data, menuId);
      })
      .catch(() => {
        deleteMenuCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const deleteMenuCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_CAT_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuCategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategory(menuId));
  dispatch(getMenuItemList(menuId));
  dispatch({
    type: MenuActionTypes.MENU_CAT_DELETE_SUCCESS,
    payload: data,
  });
};

export const sortMenuList = (sortOrder, menuId) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.SORT_MENU_LIST_START,
      payload: sortOrder,
    });

    const request = {
      ...sortOrder,
      menuId,
    };

    axios
      .post(MENU_ITEMS_LIST_SORT, request)
      .then((res) => {
        sortMenuListSuccess(dispatch);
      })
      .catch(() => {
        sortMenuListFail(dispatch, "There was an error connection");
      });
  };
};

const sortMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.SORT_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortMenuListSuccess = (dispatch) => {
  dispatch({
    type: MenuActionTypes.SORT_MENU_LIST_SUCCESS,
  });
};

export const sortMenuListCategory = (sortDetails) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.SORT_MENU_LIST_CATEGORY_START,
    });

    const request = {
      ...sortDetails,
      userId: getUserId(),
    };

    axios
      .post(MENU_CATEGORY_SORT, request)
      .then((res) => {
        sortMenuListCategorySuccess(dispatch, sortDetails.menuId);
      })
      .catch(() => {
        sortMenuListCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const sortMenuListCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.SORT_MENU_LIST_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortMenuListCategorySuccess = (dispatch, menuId) => {
  dispatch({
    type: MenuActionTypes.SORT_MENU_LIST_CATEGORY_SUCCESS,
  });
  dispatch(getMenuItemCategory(menuId));
};

export const sortExtraItems = (sortDetails) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.SORT_EXTRA_ITEMS_START,
    });

    axios
      .post(MENU_EXTRA_ITEMS_SORT, { ...sortDetails })
      .then((res) => {
        sortExtraItemsSuccess(dispatch, sortDetails.categoryId);
      })
      .catch(() => {
        sortExtraItemsFail(dispatch, "There was an error connection");
      });
  };
};

const sortExtraItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.SORT_EXTRA_ITEMS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortExtraItemsSuccess = (dispatch, categoryId) => {
  dispatch({
    type: MenuActionTypes.SORT_EXTRA_ITEMS_SUCCESS,
  });
  dispatch(getMenuExtraCategoryItems(categoryId));
};

export const sortExtraCategories = (sortDetails, productId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.SORT_EXTRA_CATEGORIES_START,
      payload: sortDetails,
    });

    axios
      .post(MENU_ITEM_EXTRA_CATEGORY_SORT, sortDetails)
      .then((res) => {
        sortExtraCategoriesSuccess(dispatch);
      })
      .catch(() => {
        sortExtraCategoriesFail(dispatch, "There was an error connection");
      });
  };
};

const sortExtraCategoriesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.SORT_EXTRA_CATEGORIES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortExtraCategoriesSuccess = (dispatch) => {
  dispatch({
    type: MenuActionTypes.SORT_EXTRA_CATEGORIES_SUCCESS,
  });
  // dispatch(getMenuItemExtraCategories(productId));
};

export const duplicateMenu = (menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_DUPLICATE_START,
    });

    axios
      .post(MENU_DUPLICATE, { menuId })
      .then((res) => {
        duplicateMenuSuccess(dispatch, res.data.message);
      })
      .catch(() => {
        duplicateMenuFail(dispatch, "There was an error connection");
      });
  };
};

const duplicateMenuFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_DUPLICATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const duplicateMenuSuccess = (dispatch, message) => {
  successAlert(dispatch, message);
  dispatch({
    type: MenuActionTypes.MENU_DUPLICATE_SUCCESS,
  });
  dispatch(getMenuList());
};

export const duplicateMenuItem = (menuItemId: string, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_DUPLICATE_START,
    });

    axios
      .post(MENU_DUPLICATE_ITEM, { menuItemId })
      .then((res) => {
        duplicateMenuItemSuccess(dispatch, res.data, menuId);
      })
      .catch(() => {
        duplicateMenuItemFail(dispatch, "There was an error connection");
      });
  };
};

const duplicateMenuItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DUPLICATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const duplicateMenuItemSuccess = (dispatch, data, menuId) => {
  //successAlert(dispatch, message);
  dispatch({
    type: MenuActionTypes.MENU_ITEM_DUPLICATE_SUCCESS,
    payload: data,
  });
  dispatch(getMenuItemList(menuId));
};

export const updateMenuItemVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.MENU_ITEM_EDIT_START,
    });

    axios
      .post(MENU_ITEM_UPDATE_TIME, data)
      .then((res) => {
        updateMenuItemVisibilitySuccess(dispatch, res.data);
      })
      .catch(() => {
        updateMenuItemVisibilityFail(dispatch, "There was an error connection");
      });
  };
};

const updateMenuItemVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateMenuItemVisibilitySuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.MENU_ITEM_EDIT_SUCCESS,
    payload: data.data,
  });
};
