import OfferList from "./OfferList";
import OfferDetail from "./OfferDetail";

export const OfferListConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/offerList",
      component: OfferList
    },
    {
      path: "/offerDetail/:productId",
      component: OfferDetail
    }
  ]
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
