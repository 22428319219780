import { axiosInstance as axios } from "app/api/axios";

import { AuthActionTypes } from "./../redux/actionTypes";
import { User } from "app/models/User";
import {
  loginUserUrl,
  getUserInfoUrl,
  getUserId,
  CHANGE_PASSWORD,
  CLIENT,
  CLIENT_WEB,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHECK_TOKEN,
  CHECK_VALID_ACCOUNT_SETUP,
  CREATE_PASSWORD
} from "app/api/Endpoint";
import history from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import History from "@history";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_START = "LOGIN_START";

export const getUserInfo = () => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_INFO_START,
    });

    const url = getUserInfoUrl();
    let res = await axios.get(url);

    if (res.data.statusCode === 200) {
      getUserInfoSuccess(dispatch, res.data);
    } else {
      getUserInfoFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
};

const getUserInfoFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_INFO_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUserInfoSuccess = (dispatch, { data }) => {
  dispatch({
    type: AuthActionTypes.USER_INFO_SUCCESS,
    payload: data,
  });
};

export const registerToken = (token: string) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.TOKEN_REGISTER_START,
    });

    let res = await axios.patch(CLIENT, { fcmToken: token, userId: getUserId() });
    if (res.data.statusCode === 200) {
      registerTokenSuccess(dispatch, res.data);
    } else {
      registerTokenFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
}

const registerTokenFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.TOKEN_REGISTER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const registerTokenSuccess = (dispatch, { data }) => {
  dispatch({
    type: AuthActionTypes.TOKEN_REGISTER_SUCCESS,
    payload: data,
  });
};

export const submitLogin = (user: User) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_USER_START,
    });

    const request = {
      email: user.username,
      password: user.password,
    };

    const url = loginUserUrl();

    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data
        if (data.accessToken && data.accessToken !== "undefined" && data._id && data._id !== "undefined") {
          loginUserSuccess(dispatch, data);
        } else {
          loginUserFail(dispatch, "There was an error connection");
        }
      })
      .catch(() => {
        loginUserFail(dispatch, "There was an error connection");
      });
  };
};

const loginUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.LOGIN_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const loginUserSuccess = (dispatch, data) => {

  axios.defaults.headers.common['Authorization'] = "Bearer " + data.accessToken
  localStorage.setItem("access_token", data.accessToken)
  localStorage.setItem("idUser", data._id);
  localStorage.setItem("masterId", data.masterId ? data.masterId : 0);

  dispatch({
    type: AuthActionTypes.LOGIN_USER_SUCCESS,
    payload: data,
  });

  history.replace({
    pathname: "/home",
  });

};

export const changePassword = (password: string) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHANGE_PASSWORD_START,
    });

    const request = {
      password,
      clientId: getUserId(),
    };

    axios
      .put(CHANGE_PASSWORD, request)
      .then((res) => {
        changePasswordSuccess(dispatch, res.data);
      })
      .catch(() => {
        changePasswordFail(dispatch, "There was an error connection");
      });
  };
};

const changePasswordFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.CHANGE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const changePasswordSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/",
  });
};

export const changeUserLanguage = (locale: string) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHANGE_LANGUAGE_START,
    });

    const request = {
      userId: getUserId(),
      locale,
    };

    let res = await axios.patch(CLIENT, request);

    if (res.data.statusCode === 200) {
      changeUserLanguageSuccess(dispatch);
    } else {
      changeUserLanguageFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
};

const changeUserLanguageFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.CHANGE_LANGUAGE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const changeUserLanguageSuccess = (dispatch) => {
  dispatch({
    type: AuthActionTypes.CHANGE_LANGUAGE_SUCCESS,
  });
};


export const createUser = (user: User) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.CREATE_USER_START,
    });

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      locale,
      referralCode
    } = user

    const request = {
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      locale,
      referralCode
    }

    axios.post(CLIENT_WEB, request).then((res) => {
      const { data } = res.data
      if (data.accessToken && data.accessToken !== "undefined" && data._id && data._id !== "undefined") {

        axios.defaults.headers.common['Authorization'] = "Bearer " + data.accessToken
        localStorage.setItem("access_token", data.accessToken)
        localStorage.setItem("idUser", data._id);
        localStorage.setItem("masterId", data.masterId ? data.masterId : 0);

        createUserSuccess(dispatch, data);
      }
    }).catch((error) => {
      createUserFail(
        dispatch,
        error.response && error.response.data.message || "There was an error connection"
      );
    });

  };
};

const createUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.CREATE_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createUserSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.CREATE_USER_SUCCESS,
    payload: data
  });
  history.replace({
    pathname: "/home",
  });
}

export const updateUserGoals = (userId: string) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.UPDATE_USER_GOALS_START,
    });
    axios.put(CLIENT_WEB, {
      clientId:userId,
    }).then((res) => {
      const { data } = res.data
      updateUserGoalsSuccess(dispatch, data);
    }).catch((error) => {
      updateUserGoalsFail(
        dispatch,
        error.response && error.response.data.message || "There was an error connection"
      );
    });

  };
};

const updateUserGoalsFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.UPDATE_USER_GOALS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateUserGoalsSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.UPDATE_USER_GOALS_SUCCESS,
    payload: data
  });
  
}
export const forgotPassword = (email,languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.FORGOT_PASSWORD_START,
    })

    const url = FORGOT_PASSWORD
    const data = {
      email,
    }
    axios
      .put(url, data)
      .then((res) => {
        forgotPasswordSuccess(dispatch, res.data.data,languageStrings)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          forgotPasswordFail(dispatch, languageStrings["LOGIN_PAGE_.EMAIL_EXIST"])
        else forgotPasswordFail(dispatch, "There was an error connection")
      })
  }
}

const forgotPasswordFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const forgotPasswordSuccess = (dispatch, data,languageStrings) => {
  successAlert(dispatch,languageStrings["LOGIN_PAGE_.CHECK_EMAIL"])
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  })
  
}

export const resetPassword = (data,languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.RESET_PASSWORD_START,
    })

    const url = RESET_PASSWORD
    const request=data 
    axios
      .put(url, request)
      .then((res) => {
        resetPasswordSuccess(dispatch, res.data.data,languageStrings)
      })
      .catch((error) => {
         resetPasswordFail(dispatch, "There was an error connection")
      })
  }
}

const resetPasswordFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.RESET_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const resetPasswordSuccess = (dispatch, data,languageHelper) => {
  successAlert(dispatch,languageHelper["FORGOT_PASSWORD_PAGE.SUCCESS"])
  dispatch({
    type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    payload: data,
  })
  history.replace({
    pathname: "/login",
  });
}

export const createPassword = (data,languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CREATE_PASSWORD_START,
    })

    const url = CREATE_PASSWORD
    axios
      .put(url, data)
      .then((res) => {
        let { data } = res.data
        if (data.accessToken && data.accessToken !== "undefined" && data._id && data._id !== "undefined") {
          createPasswordSuccess(dispatch, res.data.data,languageStrings)
        }else{
          createPasswordFail(dispatch, "There was an error connection")
        }
      })
      .catch((error) => {
         createPasswordFail(dispatch, "There was an error connection")
      })
  }
}

const createPasswordFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.CREATE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const createPasswordSuccess = (dispatch, data,languageHelper) => {

  axios.defaults.headers.common['Authorization'] = "Bearer " + data.accessToken
  localStorage.setItem("access_token", data.accessToken)
  localStorage.setItem("idUser", data._id);
  localStorage.setItem("masterId", data.masterId ? data.masterId : 0);

  dispatch({
    type: AuthActionTypes.CREATE_PASSWORD_SUCCESS,
    payload: data,
  })

  history.replace({
    pathname: "/login",
  });
}

export const validateToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHECK_VALID_TOKEN_START,
    })

    const url = CHECK_TOKEN
    const data = {
      token,
    }
    axios
      .post(url, data)
      .then((res) => {
        validateTokenSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          validateTokenFail(dispatch, "There was an error connection")
        else validateTokenFail(dispatch, "There was an error connection")
      })
  }
}

const validateTokenFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.CHECK_VALID_TOKEN_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const validateTokenSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.CHECK_VALID_TOKEN_SUCCESS,
    payload: data,
  })
}

export const validateSetupAccount = (id) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.VALIDATE_CREATE_ACCOUNT_START,
    })

    const url = CHECK_VALID_ACCOUNT_SETUP
    axios
      .post(url, {id})
      .then((res) => {
        validateSetupAccountSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          validateSetupAccountFail(dispatch, "There was an error connection")
        else validateSetupAccountFail(dispatch, "There was an error connection")
      })
  }
}

const validateSetupAccountFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.VALIDATE_CREATE_ACCOUNT_FAIL,
    payload: {
      errorMessage,
    },
  })
  History.push({
    pathname: `/login`,
  });
}

const validateSetupAccountSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.VALIDATE_CREATE_ACCOUNT_SUCCESS,
    payload: data,
  })
}

export const deleteClient = () => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.DELETE_CLIENT_START,
    })
    const clientId=localStorage.getItem("idUser")

    axios
      .delete(CLIENT+"/"+clientId)
      .then((res) => {
        deleteClientSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          deleteClientFail(dispatch, "There was an error connection")
        else deleteClientFail(dispatch, "There was an error connection")
      })
  }
}

const deleteClientFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.DELETE_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const deleteClientSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.DELETE_CLIENT_SUCCESS,
    payload: data,
  })
  localStorage.removeItem("access_token");
  localStorage.removeItem("idUser");
  localStorage.removeItem("masterId");
  window.location.href="/login"
}

export const cancelClientSubscription = () => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_START,
    })
     const clientId=localStorage.getItem("idUser")
    
    axios
      .put(CLIENT+"/"+clientId)
      .then((res) => {
        cancelClientSubscriptionSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          cancelClientSubscriptionFail(dispatch, "There was an error connection")
        else cancelClientSubscriptionFail(dispatch, "There was an error connection")
      })
  }
}

const cancelClientSubscriptionFail = (dispatch, errorMessage) => {
  errorAlert(dispatch,errorMessage)
  dispatch({
    type: AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const cancelClientSubscriptionSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.CANCEL_CLIENT_SUBSCRIPTION_SUCCESS,
    payload: data,
  })
  window.location.href="/home"
}