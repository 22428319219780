import WhatsOnDetail from "./WhatsOnDetail";
import WhatsOnList from "./WhatsOnList";

export const WhatsOnListConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/whatsonList",
      component: WhatsOnList
    },
    {
      path: "/whatsonDetail/:productId",
      component: WhatsOnDetail
    }
  ]
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
