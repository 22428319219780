import React from "react";
import { TableCell } from "@material-ui/core";
import OrdersStatus from "app/main/orders/components/OrdersStatus";

const OrderStatusCell = (props) => {
  const { orderStatus } = props;
  return (
    <TableCell className="truncate" component="th" scope="row">
      {orderStatus && <OrdersStatus status={orderStatus} />}
    </TableCell>
  );
};

export default OrderStatusCell;
