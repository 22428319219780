import { StaffActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: StaffState = {
  staffList: [],
  staffDetail: {},
  loading: false,
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
}

const UserReducer = (
  state: StaffState = INITIAL_STATE,
  action: Action
): StaffState => {
  switch (action.type) {
    case StaffActionTypes.SET_STAFF_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case StaffActionTypes.STAFF_LIST_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.STAFF_LIST_SUCCESS: {
      return {
        ...state,
        staffList: action.payload.data,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StaffActionTypes.STAFF_DETAIL_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.STAFF_DETAIL_SUCCESS: {
      return {
        ...state,
        staffDetail: action.payload.data,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StaffActionTypes.CREATE_STAFF_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.CREATE_STAFF_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.CREATE_STAFF_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StaffActionTypes.EDIT_STAFF_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.EDIT_STAFF_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.EDIT_STAFF_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_DELETE_START:
      return { ...state, loading: true };

    case StaffActionTypes.STAFF_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case StaffActionTypes.STAFF_DELETE_FAIL:
      return {
        ...state,
        loading: false,
      };

    default: {
      return state;
    }
  }
};

export default UserReducer;
