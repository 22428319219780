import { PaymentActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: PaymentState = {
  loading: false,
  subscribed: false,
  cards: [],
};

interface Action {
  payload: any;
  type: string;
}

const PaymentReducer = (
  state: PaymentState = INITIAL_STATE,
  action: Action
): PaymentState => {
  switch (action.type) {
    case PaymentActionTypes.CREATE_SUBSCRIPTION_START:
      return updateObject(state, { loading: true });

    case PaymentActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return updateObject(state, { loading: false, subscribed: true });

    case PaymentActionTypes.CREATE_SUBSCRIPTION_FAIL:
      return updateObject(state, { loading: false });

    case PaymentActionTypes.RESET_SUBSCRIPTION_SUCCESS:
      return updateObject(state, { subscribed: false });

    case PaymentActionTypes.GET_CARD_LIST_START:
      return updateObject(state, { loading: true });

    case PaymentActionTypes.GET_CARD_LIST_SUCCESS:
      return updateObject(state, { loading: false, cards: action.payload });

    case PaymentActionTypes.GET_CARD_LIST_FAIL:
      return updateObject(state, { loading: false });

    case PaymentActionTypes.DELETE_CARD_DETAILS_START:
      return updateObject(state, { loading: true });

    case PaymentActionTypes.DELETE_CARD_DETAILS_SUCCESS:
      return updateObject(state, { loading: false });

    case PaymentActionTypes.DELETE_CARD_DETAILS_FAIL:
      return updateObject(state, { loading: false });

    case PaymentActionTypes.SET_DEFAULT_CARD_START:
      return updateObject(state, { loading: true });

    case PaymentActionTypes.SET_DEFAULT_CARD_SUCCESS:
      return updateObject(state, { loading: false });

    case PaymentActionTypes.SET_DEFAULT_CARD_FAIL:
      return updateObject(state, { loading: false });

    default: {
      return state;
    }
  }
};

export default PaymentReducer;
