import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import history from "@history";
import _ from "@lodash";

import ListHeader from "app/main/common/List/ListHeader";
import DraggableListTable from "app/main/common/DraggableList/DraggableListTable";
import {
  getOfferList,
  deleteOffer,
  setOfferSearchText,
  sortOfferList
} from "app/store/offers/OffersActions";
import { translate } from "app/helpers/LanguageHelper/index";

class OfferList extends Component {
  componentDidMount() {
    this.props.getOfferList();
  }
  onHandleDelete = (selected) => {
    this.props.deleteOffer(selected);
  };
  handleClick = (item) => {
    history.push("/offerDetail/" + item._id);
  };

  setSearchText = (ev) => {
    this.props.setOfferSearchText(ev);
  };

  sortOffersList = (sortOrder) => {
    
    let { offerList } = this.props
    let offerOrder = {
      ...sortOrder,
      categoryId: offerList.find((menu) => menu._id === sortOrder.id).categoryId,
    };

    const { from, to } = sortOrder;

    const sortedOfferList = [...offerList];
    let movedOffer = sortedOfferList.splice(from, 1);
    sortedOfferList.splice(to, 0, _.first(movedOffer));

    this.props.sortOfferList(offerOrder,sortedOfferList);
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "image",
          align: "left",
          disablePadding: true,
          label: languageStrings["GENERAL.IMAGE"],
          sort: false,
          type: "image",
        },
        {
          id: "title",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.NAME"],
          sort: true,
          type: "text",
        },
        {
          id: "venueTitle",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.VENUE"],
          sort: true,
          type: "text",
        },
        {
          id: "originalPrice",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.PRICE"],
          sort: true,
          type: "reducedPrice",
        },
      ],
      checkbox: true,
      clickable: true,
      locationSearch: false,
      searchBy: ["title","venueTitle"],
    };

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <ListHeader
              title={languageStrings["OFFER_LIST_HEADER_PAGE.TITLE"]}
              setSearchText={this.setSearchText}
              createUrl={"/offerDetail/new"}
              searchType={"offer"}
              createButton
              buttonText={languageStrings["OFFER_LIST_HEADER_PAGE.BUTTON_TEXT"]}
            />
          }
          content={
            <DraggableListTable
              handleClick={this.handleClick}
              handleDelete={this.onHandleDelete}
              headerRows={headerRows}
              data={this.props.offerList}
              searchText={this.props.searchText}
              sortList={this.sortOffersList}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.offer.loading,
    offerList: state.banzzu.offer.offerList,
    searchText: state.banzzu.offer.searchText,
  };
};
const mapDispatchToProps = {
  getOfferList,
  deleteOffer,
  setOfferSearchText,
  sortOfferList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(OfferList));
