import React from "react";
import { TableCell } from "@material-ui/core";
import { formatImageUrl } from "app/helpers/utilsHelper";

const ImageCell = ({ src, alt }) => {
  return (
    <TableCell className="w-80" component="th" scope="row" padding="none">
      {src ? (
        <img className="w-full block rounded" src={formatImageUrl(src)} />
      ) : (
        <img
          className="w-full block rounded"
          src={"assets/images/avatars/noimage.png"}
          alt={alt}
        />
      )}
    </TableCell>
  );
};

export default ImageCell;
