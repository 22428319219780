import React from "react";

import { Typography } from "@material-ui/core";

const FaqItem = ({ title, description }) => {
  return (
    <div className="w-full sm:w-1/2 p-24">
      <Typography className="text-20 mb-8">{title}</Typography>
      <Typography className="text-16" color="textSecondary">
        {description}
      </Typography>
    </div>
  );
};
export default FaqItem;
