import React, { useState } from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const RegisterMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "0px",
          padding: "0px",
          position: "absolute",
        }}
      />
    ),
    mapElement: (
      <div
        style={{
          height: "100%",
        }}
      />
    ),
  }),
  lifecycle({
    componentWillMount() {
      const refs = {
        marker: null,
      };

      this.setState({
        position: null,
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },

        onPositionChanged: (setForm) => {
          const position = refs.marker.getPosition();

          setForm((prevFrom) => ({
            ...prevFrom,
            latitude: position.lat(),
            longitude: position.lng(),
          }));
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [draggable, setDraggable] = useState(true);
  return (
    <GoogleMap
      zoom={props.zoom}
      defaultZoom={0}
      defaultCenter={{ lat: 17.308688, lng: 7.03125 }}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: props.firstStep,
        draggable: props.firstStep,
      }}
      center={{
        lat: draggable && props.latitude,
        lng: draggable && props.longitude,
      }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: props.latitude, lng: props.longitude }}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragStart={() => setDraggable(false)}
          onDragEnd={() => setDraggable(true)}
          onPositionChanged={() => props.onPositionChanged(props.setForm)}
        />
      )}
    </GoogleMap>
  );
});

export default RegisterMap;
