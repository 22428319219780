// By default, this project supports all modern browsers.
// Support for Internet Explorer 11 requires polyfills.
// For to support Internet Explorer 11, install react-app-polyfill,
// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import "typeface-muli";
import "./react-table-defaults";
import "./react-chartjs-2-defaults";
import "./styles/index.css";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "app/App";
import { axiosInstance as axios } from "app/api/axios";
import { initializeFirebase } from "./app/helpers/push-notifications";
import Geocode from "react-geocode";
import ReactGA from "react-ga";

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("locale") || "en";

initializeFirebase();
if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
  ReactGA.initialize("UA-166342159-1");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
