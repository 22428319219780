import React from 'react'
import {TableCell} from "@material-ui/core";

export default function MenuTypeCell(n) {
    return (
        <TableCell component="th" scope="row">
            {n === "1" ? "PDF" : "Virtual Menu"}
        </TableCell>
    )
}
