import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "& .logo-icon": {
      width: 24,
      height: 24,
      transition: theme.transitions.create(["width", "height"], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    },
    "& .react-badge, & .logo-text": {
      transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  reactBadge: {
    backgroundColor: "rgba(0,0,0,0.6)",
    color: "#61DAFB",
    opacity: 0
  }
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, "flex flex-1")}>
      <img
        className="logo-icon"
        src="assets/images/logos/banzzuOrange.png"
        alt="logo"
      />

      <div
        className={clsx(
          classes.reactBadge,
          "react-badge flex items-center ml-12 mr-8 py-4 px-8 rounded"
        )}
      />
    </div>
  );
}

export default Logo;
