import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs, Icon, Typography } from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getUserDetail } from "app/store/user/UserActions";
import AddressListCard from "./components/AddressListCard";
import UserDetailCard from "./components/UserDetailCard";
import OrderListCard from "./components/OrderListCard";

function UserDetail(props) {
  const { userId } = props.match.params;
  const loading = false;
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const { userDetails } = useSelector(({ banzzu }) => banzzu.user);
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    dispatch(getUserDetail(userId));
  }, []);
  function previousPage() {
    History.push({
      pathname: `/userlist`,
    });
  }

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full">
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button onClick={() => previousPage()}>
                  <Icon className="mr-4 text-20">arrow_back</Icon>
                  {languageStrings["USER_DETAIL.BACK_LIST"]}
                </Button>
              </FuseAnimate>
            </div>
          </div>
        }
        content={
          <div className="p-16 sm:p-24 max-w-2xl">
            <UserDetailCard customer={userDetails} />
            {userDetails &&
              userDetails.addressList &&
              userDetails.addressList.length > 0 && (
                <AddressListCard addressList={userDetails.addressList} />
              )}
            {userDetails &&
              userDetails.orderList &&
              userDetails.orderList.length > 0 && (
                <OrderListCard orderList={userDetails.orderList} />
              )}
          </div>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default UserDetail;
