import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { getVenueList } from "app/store/venues/VenuesActions";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getReservationDetail,
  confirmDenyReservation,
} from "app/store/reservation/ReservationActions";
import { isEmailValid } from "app/helpers/validators";
import * as Actions from "app/store/actions";
import History from "@history";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function BookingDetail(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const loading = useSelector(({ banzzu }) => banzzu.reservation.loading);
  let reservation = useSelector(
    ({ banzzu }) => banzzu.reservation.reservationDetail
  );
  const { languageStrings } = useLanguageHelper();
  const { productId } = props.match.params;

  useEffect(() => {
    dispatch(getReservationDetail(productId));
  }, [dispatch, productId]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  if (productId === "new") {
    reservation = {
      _id: "0",
      title: "",
      email: "",
      description: "",
      phone: "",
      people: "1",
    };
  }

  const dirtyInitial = {
    title: false,
    email: false,
    phone: false,
  };

  const errorInitial = {
    title: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);

  useEffect(() => {
    if (
      (reservation && !form) ||
      (reservation && form && reservation._id !== form._id)
    ) {
      setForm(reservation);
    }
  }, [form, reservation, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "";
  }

  function hasNoErrors() {
    return !errorName() && !errorEmail() && !errorPhone();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorPhone() {
    return dirty.phone && form.phone.length < 6;
  }

  function errorEmail() {
    return dirty.email && !isEmailValid(form.email);
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleDateChange(start) {
    setForm((form) => ({
      ...form,
      start,
    }));
  }

  function handlePhoneChange(event) {
    handleChange(event);
    debouncedPhone(event.target.value);
  }

  const debouncedPhone = useDebounce((phone) => {
    setDirty((prevState) => {
      return { ...prevState, phone: true };
    });
    if (phone.length < 6) {
      setError((prevState) => {
        return { ...prevState, phone: "Phone Invalid" };
      });
    }
  }, 500);

  function handleEmailChange(event) {
    handleChange(event);
    debouncedEmail(event.target.value);
  }

  const debouncedEmail = useDebounce((email) => {
    setDirty((prevState) => {
      return { ...prevState, email: true };
    });
    if (!isEmailValid(email)) {
      setError((prevState) => {
        return { ...prevState, email: languageStrings["ERRORS.INVALID_EMAIL"] };
      });
    }
  }, 500);

  function previousPage() {
    History.push({
      pathname: "/bookingConfirmation",
    });
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    onClick={() => {
                      dispatch(
                        Actions.openDialog({
                          children: (
                            <AlertDialog
                              onSuccess={previousPage}
                              title={languageStrings["GENERAL.ALERT_TITLE"]}
                              message={languageStrings["GENERAL.ALERT_DESC"]}
                            />
                          ),
                        })
                      );
                    }}
                  >
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["BOOKING_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form._id !== "0" ? form.title : "New Reservation"}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["BOOKING_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              {form.status === 0 && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <div>
                    <Button
                      color="secondary"
                      className="whitespace-no-wrap mr-16"
                      variant="contained"
                      disabled={!canBeSubmitted()}
                      style={{
                        backgroundColor: "#34bfa3",
                      }}
                      onClick={() =>
                        dispatch(
                          Actions.openDialog({
                            children: (
                              <React.Fragment>
                                <DialogTitle id="alert-dialog-title">
                                  Confirm Reservation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    Do you want to confirm this reservation?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        confirmDenyReservation(
                                          form._id,
                                          "confirm"
                                        )
                                      )
                                    }
                                    color="secondary"
                                  >
                                    {languageStrings["GENERAL.CONFIRM_BUTTON"]}
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      dispatch(Actions.closeDialog())
                                    }
                                    color="primary"
                                  >
                                    {languageStrings["GENERAL.DENY_BUTTON"]}
                                  </Button>
                                </DialogActions>
                              </React.Fragment>
                            ),
                          })
                        )
                      }
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        check
                      </Icon>
                      {languageStrings["GENERAL.CONFIRM_BUTTON"]}
                    </Button>
                    <Button
                      color="secondary"
                      className="whitespace-no-wrap"
                      variant="contained"
                      style={{
                        backgroundColor: "#f4516c",
                      }}
                      //disabled={!canBeSubmitted()}
                      onClick={() =>
                        dispatch(
                          Actions.openDialog({
                            children: (
                              <React.Fragment>
                                <DialogTitle id="alert-dialog-title">
                                  Deny Reservation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    Do you want to deny this reservation?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() =>
                                      dispatch(
                                        confirmDenyReservation(form._id, "deny")
                                      )
                                    }
                                    color="secondary"
                                  >
                                    {languageStrings["GENERAL.CONFIRM_BUTTON"]}
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      dispatch(Actions.closeDialog())
                                    }
                                    color="primary"
                                  >
                                    {languageStrings["GENERAL.CANCEL_BUTTON"]}
                                  </Button>
                                </DialogActions>
                              </React.Fragment>
                            ),
                          })
                        )
                      }
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        close
                      </Icon>
                      Deny
                    </Button>
                  </div>
                </FuseAnimate>
              )}
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <div className="flex">
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      error={errorName()}
                      required
                      label={languageStrings["GENERAL.NAME"]}
                      //disabled={form.status === "0"}
                      disabled
                      id="title"
                      name="title"
                      value={form.firstName + " " + form.lastName}
                      onChange={handleTitleChange}
                      variant="outlined"
                      fullWidth
                      helperText={errorName() ? error.title : ""}
                    />
                    <TextField
                      className="mt-8 mb-16 ml-8 mr-8"
                      error={errorPhone()}
                      required
                      label={languageStrings["GENERAL.PHONE"]}
                      disabled
                      id="phone"
                      name="phone"
                      value={form.phone}
                      onChange={handlePhoneChange}
                      variant="outlined"
                      fullWidth
                      helperText={errorPhone() ? error.phone : ""}
                    />
                    <TextField
                      className="mt-8 mb-16 ml-8"
                      error={errorEmail()}
                      required
                      disabled
                      label={languageStrings["GENERAL.EMAIL"]}
                      id="email"
                      name="email"
                      value={form.email}
                      onChange={handleEmailChange}
                      variant="outlined"
                      fullWidth
                      helperText={errorEmail() ? error.email : ""}
                    />
                  </div>
                  <div className="flex">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {languageStrings["BOOKING_DETAIL_PAGE.SELECT_VENUE"]}
                      </InputLabel>
                      <Select
                        className="mt-8 mb-16 mr-8"
                        native
                        value={form.venueId}
                        disabled
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="venueId"
                            labelWidth={100}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        <option value={0}>
                          {languageStrings["BOOKING_DETAIL_PAGE.SELECT_VENUE"]}
                        </option>
                        {venueList &&
                          venueList.map((item) => (
                            <option value={item._id}>{item.title}</option>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {languageStrings["BOOKING_DETAIL_PAGE.N_PEOPLE"]}
                      </InputLabel>
                      <Select
                        className="mt-8 mb-16 ml-8"
                        native
                        fullWidth
                        disabled
                        value={form.people}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="people"
                            labelWidth={80}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                      </Select>
                    </FormControl>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="flex">
                      <DatePicker
                        className="mt-8 mb-16 mr-8"
                        margin="normal"
                        fullWidth
                        id="start"
                        name="start"
                        disabled
                        label={languageStrings["GENERAL.DATE"]}
                        value={form.start}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <TextField
                        id="time"
                        fullWidth
                        label={languageStrings["GENERAL.TIME"]}
                        type="time"
                        name="time"
                        disabled
                        variant="outlined"
                        value={form.time}
                        onChange={handleChange}
                        className="mt-8 mb-16 ml-8"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    label={languageStrings["GENERAL.COMMENTS"]}
                    multiline
                    rows={8}
                    disabled
                    id="description"
                    name="description"
                    value={form.comments}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 4000,
                    }}
                  />
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("BookingReducer", reducer)(BookingDetail);
