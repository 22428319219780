import React, { FC } from "react";
import { Typography, Card, IconButton, Icon } from "@material-ui/core";
import { ExtrasCategory } from "app/models/ExtrasCatergory";
import { Draggable } from "react-beautiful-dnd";

interface Props {
  extraCategory: ExtrasCategory;
  onEdit: () => void;
  onDelete: () => void;
  index: number;
  onlyRead?: boolean;
}

const ExtraCategory = ({
  extraCategory,
  onEdit,
  onDelete,
  index,
  onlyRead,
}: Props) => {
  return (
    <Draggable
      key={extraCategory._id}
      draggableId={extraCategory._id}
      index={index}
      isDragDisabled={onlyRead}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Card className="mt-8 ml-16 pl-8 pr-8 w-11/12 mr-16 bg-grey-lightest">
            <div className="flex">
              <Typography
                variant="subtitle1"
                component="h3"
                className="ml-16 w-full self-center"
              >
                {extraCategory.title}
              </Typography>
              <IconButton onClick={onEdit} hidden={onlyRead ? true : false}>
                <Icon className="pr-16 text-blue">edit</Icon>
              </IconButton>
              <IconButton onClick={onDelete} hidden={onlyRead ? true : false}>
                <Icon className="pr-16 text-red">delete</Icon>
              </IconButton>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default ExtraCategory;
