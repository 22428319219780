import React, { useEffect, useState } from "react";
import { Button, TextField, Icon, Typography } from "@material-ui/core";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { changePassword } from "app/store/auth/LoginActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
}));

function ChangePassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper()
  const loading = useSelector(({ banzzu }) => banzzu.auth.loading);

  const formInit = {
    newPassword: "",
    confirmPassword: ""
  };

  const dirtyInitial = {
    newPassword: false,
    confirmPassword: false
  };

  const errorInitial = {
    newPassword: "",
    confirmPassword: ""
  };

  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);

  useEffect(() => {
    if (formInit && !form) {
      setForm(formInit);
    }
  }, [form, formInit, setForm]);

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.confirmPassword !== "" && form.newPassword;
  }

  function hasNoErrors() {
    return !errorNewPassword() && !errorConfirmPassword();
  }

  function errorNewPassword() {
    return dirty.newPassword && form.newPassword.length < 7;
  }

  function errorConfirmPassword() {
    return dirty.confirmPassword && form.confirmPassword.length < 7;
  }

  function handleNewPasswordChange(event) {
    handleChange(event);
    debounceNewPassword(event.target.value);
  }

  const debounceNewPassword = useDebounce(newPassword => {
    setDirty(prevState => {
      return { ...prevState, newPassword: true };
    });
    if (newPassword.length < 7) {
      setError(prevState => {
        return { ...prevState, newPassword: languageStrings["ERRORS.MINIMUM_6_CHAR"] };
      });
    }
  }, 500);

  function handleConfirmPasswordChange(event) {
    handleChange(event);
    debounceConfirmPassword(event.target.value);
  }

  const debounceConfirmPassword = useDebounce(confirmPassword => {
    setDirty(prevState => {
      return { ...prevState, confirmPassword: true };
    });
    if (confirmPassword.length < 7) {
      setError(prevState => {
        return { ...prevState, confirmPassword: languageStrings["ERRORS.MINIMUM_6_CHAR"] };
      });
    }
  }, 500);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll"
        }
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {languageStrings["CHANGE_PASSWORD_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    dispatch(changePassword(form.newPassword));
                  }}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              <TextField
                className="mt-8 mb-16"
                error={errorNewPassword()}
                required
                label={"New Password"}
                id="newPassword"
                name="newPassword"
                value={form.newPassword}
                onChange={handleNewPasswordChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 100
                }}
                helperText={errorNewPassword() ? error.newPassword : ""}
              />
              <TextField
                className="mt-8 mb-16"
                error={errorConfirmPassword()}
                required
                label={"Confirm Password"}
                id="confirmPassword"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleConfirmPasswordChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 100
                }}
                helperText={errorConfirmPassword() ? error.confirmPassword : ""}
              />
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("MenuReducer", reducer)(ChangePassword);
