import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import history from "@history";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  getDrawList,
  deleteDraw,
  setDrawSearchText,
} from "app/store/draws/DrawActions";
import { translate } from "app/helpers/LanguageHelper/index";

class DrawList extends Component {
  componentDidMount() {
    this.props.getDrawList();
  }
  onHandleDelete = (selected) => {
    this.props.deleteDraw(selected);
  };
  handleClick = (item) => {
    history.push("/drawDetail/" + item._id);
  };

  setSearchText = (ev) => {
    this.props.setDrawSearchText(ev);
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "image",
          align: "left",
          disablePadding: true,
          label: languageStrings["GENERAL.IMAGE"],
          sort: false,
          type: "image",
        },
        {
          id: "title",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.NAME"],
          sort: true,
          type: "text",
        },
        {
          id: "date",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "date",
        },
      ],
      checkbox: true,
      clickable: true,
      locationSearch: false,
      searchBy: ["title"],
    };

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: this.props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <ListHeader
              title={languageStrings["DRAW_LIST_PAGE.TITLE"]}
              setSearchText={this.setSearchText}
              createUrl={"/drawDetail/new"}
              searchType={"draw"}
              createButton
              buttonText={languageStrings["DRAW_LIST_PAGE.BUTTON_TEXT"]}
            />
          }
          content={
            <ListTable
              handleClick={this.handleClick}
              handleDelete={this.onHandleDelete}
              headerRows={headerRows}
              data={this.props.drawList}
              searchText={this.props.searchText}
            />
          }
          innerScroll
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.draw.loading,
    drawList: state.banzzu.draw.drawList,
    searchText: state.banzzu.draw.searchText,
  };
};
const mapDispatchToProps = {
  getDrawList,
  deleteDraw,
  setDrawSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(DrawList));
