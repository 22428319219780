import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { Table, TablePagination } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import ListTableHead from "./components/ListTableHead";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListTableBody from "./components/ListTableBody";

function DraggableListTable({
  data,
  headerRows,
  handleDelete,
  handleClick,
  sortList,
  handleDuplicate
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { languageStrings } = useLanguageHelper();

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(data.map((n) => n._id));
      return;
    }
    setSelected([]);
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function deleteHandler() {
    handleDelete(selected);
    setSelected([]);
  }

  const handleDuplicateHandler = () =>{
    handleDuplicate(selected)
    setSelected([]);
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
    };

    sortList(sortOrder);
  };

  return (
    <div className="w-full flex flex-col">
      <FuseScrollbars className="flex-grow overflow-x-auto">
        <Table className="min-w-xl" aria-labelledby="tableTitle">
          <ListTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            handleDelete={deleteHandler}
            handleDuplicate={handleDuplicateHandler}
            duplicate
            headerRows={headerRows}
            rowCount={data && data.length}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <ListTableBody
              data={data}
              handleClick={handleClick}
              headerRows={headerRows}
              handleCheck={handleCheck}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </DragDropContext>
        </Table>
      </FuseScrollbars>
      <TablePagination
        component="div"
        count={data && data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={languageStrings["GENERAL.ROWS_PER_PAGE"]}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default DraggableListTable;
