import Pricing from "./Pricing";
import PaymentPage from "./PaymentPage";
import UpgradeSubscriptionPage from "./UpgradeSubscriptionPage";

export const PricingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/pricing",
      component: Pricing,
    },
    {
      path: "/payment",
      component: PaymentPage,
    },
    {
      path: "/upgrade",
      component: UpgradeSubscriptionPage,
    },
  ],
};
