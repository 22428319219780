import React from "react";

import { Card, Typography } from "@material-ui/core";
import { useLanguageHelper } from 'app/helpers/LanguageHelper/useLanguageHelper';

const DetailCard = () => {
  const { languageStrings } = useLanguageHelper();
  return (
    <Card
      className="mx-24 mt-12 p-24 flex flex-col items-center justify-center shadow-lg md:w-1/3 w-full"
      style={{ borderRadius: "2rem" }}
    >
      <Typography
        variant="h4"
        className="font-bold"
        style={{ color: "#f5aa00", textAlign: "center" }}
      >
        {languageStrings["GENERAL.YOUR_ARE_THERE"]}
      </Typography>
      <Typography variant="body1" className="text-center mt-12 px-32">
        {languageStrings["GENERAL.ONLY_THE_LAST"]}
      </Typography>
      <img width="300" src="assets/images/backgrounds/Cooking.gif" />
    </Card>
  );
};
export default DetailCard;
