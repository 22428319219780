import DrawList  from "./DrawList";
import DrawDetail from "./DrawDetail";

export const DrawListConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/drawList",
      component: DrawList
    },
    {
      path: "/drawDetail/:productId/:productHandle?",
      component: DrawDetail
    }
  ]
};