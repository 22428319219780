import React from "react";

const StripeGuide = () => {
  return (
    <>
      <div className="flex flex-col p-32  mt-8 bg-grey-lighter">
        <div className="flex flex-col pl-10">
          <h1>Cómo crear una cuenta de Stripe y conseguir tus Claves.</h1>
          <h1 className={"mt-10"}>
            Cómo crear tu cuenta de Stripe paso a paso
          </h1>

          <h4 className={"mt-5"}>
            1. Entra en la{" "}
            <a target="_blank" href="https://stripe.com/">
              web oficial
            </a>{" "}
            de Stripe.
          </h4>
          <h4 className={"mt-5"}>2. Pincha en el botón [Empezar ahora].</h4>
        </div>
        <img
          src={`assets/images/stripeguide/stripeguide1.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-10">
          <h4 className={"mt-10"}>
            3. Rellena tus datos en el formulario (correo electrónico, nombre
            completo contraseña segura) y haz clic en el botón [Crear cuenta].
          </h4>
        </div>
      </div>
      {/* 2 */}
      <div className="flex flex-col p-32 bg-grey-lighter">
        <img
          src={`assets/images/stripeguide/stripeguide2.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-10">
          <h4 className={"mt-10"}>
            4. En este momento recibirás un email de confirmación en tu correo.
          </h4>
        </div>
      </div>
      {/* 3 */}
      <div className="flex flex-col p-32 bg-grey-lighter">
        <img
          src={`assets/images/stripe/image--002.jpg`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-10">
          <h4 className={"mt-10"}>
            Pincha en el botón de “Verificar dirección de correo electrónico” e
            introduce tu contraseña
          </h4>
        </div>
      </div>
      {/* 4 */}
      <div className="flex flex-col p-32 bg-grey-lighter">
        <img
          src={`assets/images/stripeguide/stripeguide7.jpg`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-10">
          <h4 className={"mt-10"}>
            5. A continuación llegarás a tu panel de inicio en Stripe
          </h4>
        </div>
        <img
          src={`assets/images/stripeguide/stripeguide3.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
      </div>
      {/* 5 */}
      <div className="flex flex-col p-32 bg-grey-lighter">
        <h4 className={"mt-5"}>
          6. Para comenzar debes rellenar la información de tu empresa en el
          botón “INICIAR” y complementar la información solicitada.
        </h4>
        <h4 className={"mt-5"}>
          7. Una vez completada la información, tu cuenta estará activada
        </h4>
        <img
          src={`assets/images/stripeguide/stripeguide4.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
      </div>
      <div className="flex flex-col p-32 bg-grey-lighter">
        <h4 className={"mt-5"}>8. Una vez la cuenta está activada;</h4>
        <img
          src={`assets/images/stripeguide/stripeguide5.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-20">
          <h4 className={"mt-5"}>
            1. Asegúrate de que el modo de prueba está DESACTIVADO.
          </h4>
          <h4 className={"mt-5"}>
            2. Copia la clave secreta que obtendrás al pulsar sobre ella. Para
            asegurarte de que es la clave correcta, esta debe empezar por:
            “sk_live_”. Deberás pegarla en el siguiente paso.
          </h4>
          <h4 className={"mt-5"}>
            3. Copia la clave publicable que empieza por “pk_live_”. Deberás
            pegarla en el siguiente paso.
          </h4>
        </div>
      </div>

      <div className="flex flex-col p-32 bg-grey-lighter">
        <h4 className={"mt-5"}>
          9. Finalmente introducimos las claves en nuestro panel de control,
          para ello:
        </h4>
        <div className="flex flex-col pl-20">
          <h4 className={"mt-5"}>1: Seleccionamos Pedidos Online.</h4>
          <h4 className={"mt-5"}>2: Seleccionamos Integración de Pagos.</h4>
          <h4 className={"mt-5"}>
            3: Seleccionamos la opción de Stripe e introducimos nuestras claves
            (que previamente hemos obtenido de nuestra página de Stripe). En el
            panel de control van en este orden:
          </h4>
          <div className="flex flex-col pl-20">
            <h4 className={"mt-3"}>1) Sk_live (clave secreta)</h4>
            <h4 className={"mt-3"}>2) Pk_live (clave publicable)</h4>
          </div>
        </div>
        <img
          src={`assets/images/stripeguide/stripeguide6.png`}
          className={"mt-5 object-contain"}
          style={{ width: "auto", height: "auto" }}
        />
        <div className="flex flex-col pl-20">
          <h4 className={"mt-5"}>
            ¡LISTO! Una vez hayas conseguido introducir tus claves de Stripe en
            el panel de control, ya podrás recibir pedidos pagados con tarjeta.
          </h4>
        </div>
      </div>
    </>
  );
};

export default StripeGuide;
