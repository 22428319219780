import React from "react";
import _ from "@lodash";
import { Typography, Icon, Avatar, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const UserDetailCard = ({ customer }) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Avatar className="mr-8" src={customer.image} />
          <Typography className="h2" color="textSecondary">
            {customer && customer.firstName && customer.lastName
              ? customer.firstName + " " + customer.lastName
              : ""}
          </Typography>
        </div>
        <div className="table-responsive ">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["ORDER_PAGE.EMAIL"]}</th>
                <th>{languageStrings["ORDER_PAGE.PHONE"]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography className="truncate">{customer.email}</Typography>
                </td>
                <td>
                  <Typography className="truncate">{customer.phone}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default UserDetailCard;
