import React from "react";
import { TableBody } from "@material-ui/core";
import _ from "@lodash";
import { Droppable } from "react-beautiful-dnd";

import ListRow from "./ListRow";

const ListTableBody = ({
  data,
  handleClick,
  handleCheck,
  page,
  rowsPerPage,
  selected,
  headerRows,
}) => {
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((n, index) => {
              const isSelected = selected.indexOf(n._id) !== -1;
              return (
                <ListRow
                  key={n._id}
                  headerRows={headerRows}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  handleCheck={handleCheck}
                  n={n}
                  index={index}
                />
              );
            })}
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  );
};

export default ListTableBody;
