import { useLanguageHelper } from "app/helpers/LanguageHelper";
import React from "react";
import { DescribeCard } from "./DescribeCard";
import { TextField, Radio, Card, Button, Switch } from "@material-ui/core";

const isDev =
process.env.REACT_APP_ENVIRONMENT === "staging" || process.env.REACT_APP_ENVIRONMENT === "development";

export const PaymentTypeCard = ({ ...rest }) => {
  const { languageStrings } = useLanguageHelper();
  const {
    onClick,
    value,
    checked,
    advantages = [],
    disAdvantages = [],
    inputs = [],
    type = "",
    onUserGuidePress,
    imagePath = "",
    form = null,
    handleChange,
    name = "",
    onChange,
    switchName = "",
    testMode = false,
  } = rest || {};

  function errorInput(errorData = null) {
    const { id = null, valueCheck = null, errorMessage = "" ,devValueCheck = ''} = errorData || {};
    let isErr = form && form[id] && !form[id].includes(valueCheck);
if (isDev) {
  isErr = form && form[id] && !(form[id].includes(valueCheck)||form[id].includes(devValueCheck))
}
    return { isErr, errorMsg: isErr ? errorMessage : "" };
  }
  return (
    <Card
      onClick={onClick}
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: 20,
        boxShadow: checked ? "rgb(158 158 158) 0px 0px 3px" : null,
        padding: 20,
        borderWidth: 1,
        borderColor: checked ? "rgb(253 173 118)" : "rgb(225 225 225)",
        height: "fit-content",
        transition: "height 5s linear 5s",
      }}
    >
      <Radio
        name={name}
        type={name}
        onClick={onClick}
        value={value}
        checked={checked}
      />
      <br />
      <img
        src={imagePath}
        className={"mt-5 object-contain"}
        style={{
          width: "70px",
          height: "50px",
          maxHeight: "50px",
          maxWidth: "70px",
          marginTop: "10px",
        }}
      />
      <DescribeCard {...advantages} />
      <DescribeCard {...disAdvantages} />
      {checked && (
        <>
          {inputs.map((item) => (
            <div className="flex">
              <TextField
                className="mt-8 mb-16 mr-8"
                {...item}
                label={languageStrings[item.label]}
                value={form[item.id]}
                error={errorInput(item).errorMsg}
                onChange={handleChange}
                helperText={languageStrings[errorInput(item).errorMsg]}
              />
            </div>
          ))}
          {type === "Stripe" ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <h4>{languageStrings["GENERAL.USER_GUIDE"]}</h4>
              <Button
                onClick={onUserGuidePress}
                variant="contained"
                color="primary"
              >
                {languageStrings["GENERAL.USER_GUIDE"]}
              </Button>
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <h4>{languageStrings["GENERAL.REDSYS_IS_TEST_MODE"]}</h4>
              <Switch
                checked={testMode}
                onChange={onChange}
                name={switchName}
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
};
