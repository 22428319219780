import UserList from "./UserList";
import UserDetail from "./UserDetail";

export const UserListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/userlist",
      component: UserList,
    },
    {
      path: "/userDetail/:userId",
      component: UserDetail,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
