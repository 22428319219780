import { getUserId } from "./Endpoint";

const VENUE = "venue";
const CLIENT_VENUE = "venue/client";
const VENUE_VALIDATE_EMAIL = "/venue/validate-email";
const DELIVERY_AVAILABILITY = "/venue/delivery-availability";
const COLLECTION_AVAILABILITY = "/venue/collection-availability";

const getVenueListUrl = (): string => {
  return VENUE + "?userId=" + getUserId();
};

const getVenueDetailUrl = (id: string): string => {
  return VENUE + "/" + id;
};

const getDeliveryAvailabilityUrl = (id: string): string => {
  return DELIVERY_AVAILABILITY + "/" + id;
};

const getCollectionAvailabilityUrl = (id: string): string => {
  return COLLECTION_AVAILABILITY + "/" + id;
};

export {
  getVenueListUrl,
  getVenueDetailUrl,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  COLLECTION_AVAILABILITY,
  DELIVERY_AVAILABILITY,
  VENUE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
};
