import React from "react";
import { Card, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import AlertDialog from "../../common/AlertDialog";
import * as Actions from "app/store/actions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index"
import { cancelClientSubscription } from "app/store/auth/LoginActions"
const CancelSubscription = ({ title }) => {
    const dispatch = useDispatch()
    const { languageStrings } = useLanguageHelper()

    return (<Card className="flex flex-col items-center p-40 mt-64">
        <h1 className="text-center">{title}</h1>
        <Button className={"mt-64 md:w-256 bg-red"} variant="contained" color="primary" onClick={() => {
            dispatch(
                Actions.openDialog({
                    children: (
                        <AlertDialog
                            onSuccess={() => { dispatch(cancelClientSubscription()) }}
                            title={languageStrings["ACCOUNT.CANCEL_SUBSCRIPTION"]}
                            message={languageStrings["ACCOUNT.ARE_YOU_SURE_TO_CANCEL_SUBSCRIPTION"]}
                        />
                    )
                })
            );
        }}>{languageStrings["ACCOUNT.CANCEL_SUBSCRIPTION"]}</Button>
    </Card>);
}

export default CancelSubscription;