import React from "react";
import _ from "@lodash";
import { Typography, Icon, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const OrderComments = ({ comments }) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Icon className="mr-16" color="action">
            assignment
          </Icon>
          <Typography className="h2" color="textSecondary">
            {languageStrings["ORDER_PAGE.COMMENTS"]}
          </Typography>
        </div>
        <div>{comments}</div>
      </Card>
    </div>
  );
};

export default OrderComments;
