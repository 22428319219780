import React from "react";

import { TableCell } from "@material-ui/core";
import { formatDate } from "../../../../helpers/validators";

const DateComparison = ({ data }) => {
  return data.eventType === "1" ? (
    <TableCell>{formatDate(data.dateDay)}</TableCell>
  ) : (
    <TableCell>
      {formatDate(data.dateFrom) + " - " + formatDate(data.dateTo)}
    </TableCell>
  );
};

export default DateComparison;
