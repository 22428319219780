import React from "react";
import { TableCell } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const useStyles = makeStyles((theme) => ({
  red: {
    color: "red",
    fontWeight: "bold",
  },
}));

const PriceReduceCell = (props) => {

  const { languageStrings } = useLanguageHelper()
  const currency = useSelector(({ banzzu }) => banzzu.auth.user.currency);
  const classes = useStyles(props);
  const { n } = props;
  return (
    <TableCell className="truncate" component="th" scope="row">
      {n.hidePrice ? (
        <font>{languageStrings["PRICE_REDUCE_CELL.DISABLED_PRICE"]}</font>
      ) : (
        <font>
          {n.reducedPrice && (
            <font className={clsx(classes.red)}>{n.reducedPrice} {currency}</font>
          )}{" "}
          {n.originalPrice && <strike>{n.originalPrice} {currency} </strike>}
        </font>
      )}
    </TableCell>
  );
};

export default PriceReduceCell;
