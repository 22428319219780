import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import {
  getWhatsOnDetail,
  editWhatsOn,
  createWhatsOn,
} from "app/store/whatson/WhatsOnActions";
import { getVenueList } from "app/store/venues/VenuesActions";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

function WhatsOnDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const loading = useSelector(({ banzzu }) => banzzu.whatson.loading);
  let whatson = useSelector(({ banzzu }) => banzzu.whatson.whatsOnDetail);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();
  const { productId } = props.match.params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getWhatsOnDetail(productId));
    }
  }, [dispatch, productId]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  if (productId === "new") {
    whatson = {
      id: "0",
      title: "",
      description: "",
      eventType: "1",
      venueId: "0",
      dateDay: new Date(),
      dateFrom: new Date(),
      dateTo: new Date(),
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
    rangeDate: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if ((whatson && !form) || (whatson && form && whatson._id !== form._id)) {
      setForm(whatson);
      setInitialForm(whatson);
    }
  }, [form, whatson, setInitialForm, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !form.onlyRead;
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.description !== "";
  }

  function hasNoErrors() {
    return (
      !errorName() && !errorDescription() && !errorRangeDate() && !errorImage()
    );
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorImage() {
    return !form.image;
  }

  function errorDescription() {
    return dirty.description && form.description.length < 10;
  }

  function errorRangeDate() {
    return (
      form.eventType === "2" && new Date(form.dateFrom) >= new Date(form.dateTo)
    );
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  function handleDateChange(dateDay) {
    setForm((form) => ({
      ...form,
      dateDay,
    }));
  }

  function handleDateToChange(dateTo) {
    if (errorRangeDate()) {
      setError((prevState) => {
        return { ...prevState, rangeDate: "The date range is invalid" };
      });
    }
    setForm((form) => ({
      ...form,
      dateTo,
    }));
  }

  function handleDateFromChange(dateFrom) {
    if (errorRangeDate()) {
      setError((prevState) => {
        return { ...prevState, rangeDate: "The date range is invalid" };
      });
    }
    setForm((form) => ({
      ...form,
      dateFrom,
    }));
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return {
          ...prevState,
          description: languageStrings["ERRORS.MINIMUM_10_CHAR"],
        };
      });
    }
  }, 500);

  function goBack() {
    History.push({
      pathname: `/whatsonList`,
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  const onSubmit = () => {
    if (form.id !== "0") {
      dispatch(editWhatsOn(form));
    } else {
      let finalForm = {
        ...form,
      };
      if (
        user &&
        user.permission &&
        !user.permission.isMaster &&
        venueList.length &&
        form.venueId == "0"
      ) {
        finalForm = {
          ...form,
          venueId: venueList[0]._id,
        };
      }
      dispatch(createWhatsOn(finalForm));
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["WHAT_ON_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings[
                              "WHATS_ON_LIST_HEADER_PAGE.MAIN_TITLE"
                            ]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["WHAT_ON_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={onSubmit}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <input
                    accept="image/jpg, image/jpeg, image/png, image/gif"
                    className="hidden"
                    id="button-file"
                    type="file"
                    disabled={form.onlyRead}
                    onChange={handleUploadChange}
                  />
                  <div className="flex justify-center sm:justify-start flex-wrap">
                    <label
                      htmlFor="button-file"
                      className={clsx(
                        form.onlyRead ? classes.enable : classes.disabled,
                        "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                      )}
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          alt="whatson"
                          src={
                            form.image
                              ? formatImageUrl(form.image)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                      </div>
                    }
                    {errorImage() && (
                      <div class="text-red">
                        {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
                      </div>
                    )}
                  </div>
                  <div className="flex">
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      error={errorName()}
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      disabled={form.onlyRead}
                      value={form.title}
                      onChange={handleTitleChange}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={errorName() ? error.title : ""}
                    />

                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {languageStrings["WHAT_ON_DETAIL_PAGE.SELECT_VENUE"]}
                      </InputLabel>
                      <Select
                        className="mt-8 mb-16 ml-8"
                        native
                        disabled={form.onlyRead}
                        value={form.venueId}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="venueId"
                            labelWidth={100}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        {user.permission && user.permission.isMaster && (
                          <option value={0}>
                            {languageStrings["GENERAL.ALL_VENUES"]}
                          </option>
                        )}

                        {venueList &&
                          venueList.map((item) => (
                            <option value={item._id}>{item.title}</option>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-venue-native-simple">
                      {languageStrings["WHAT_ON_DETAIL_PAGE.EVENT_DURATIONL"]}
                    </InputLabel>
                    <Select
                      className="mt-8 mb-16 mr-8"
                      native
                      fullWidth
                      disabled={form.onlyRead}
                      value={form.eventType}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          name="eventType"
                          labelWidth={100}
                          id="outlined-venue-native-simple"
                        />
                      }
                    >
                      <option value={1}>
                        {languageStrings["WHAT_ON_DETAIL_PAGE.ONE_DAY"]}
                      </option>
                      <option value={2}>
                        {languageStrings["WHAT_ON_DETAIL_PAGE.MULTIPLE_DAYS"]}
                      </option>
                    </Select>
                  </FormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {form.eventType === "2" ? (
                      <div className="flex">
                        <DatePicker
                          className="mt-8 mb-16 mr-8"
                          margin="normal"
                          fullWidth
                          disabled={form.onlyRead}
                          id="dateFrom"
                          label={
                            languageStrings["WHAT_ON_DETAIL_PAGE.DATE_FROM"]
                          }
                          error={errorRangeDate()}
                          helperText={errorRangeDate() ? error.rangeDate : ""}
                          value={form.dateFrom}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          onChange={handleDateFromChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <DatePicker
                          className="mt-8 mb-16 ml-8"
                          margin="normal"
                          fullWidth
                          id="date_to"
                          disabled={form.onlyRead}
                          label={languageStrings["WHAT_ON_DETAIL_PAGE.DATE_TO"]}
                          error={errorRangeDate()}
                          helperText={errorRangeDate() ? error.rangeDate : ""}
                          value={form.dateTo}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          onChange={handleDateToChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <DatePicker
                          className="mt-8 mb-16 mr-8"
                          margin="normal"
                          fullWidth
                          id="dateDay"
                          disabled={form.onlyRead}
                          label={languageStrings["GENERAL.DATE"]}
                          value={form.dateDay}
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                    )}
                  </MuiPickersUtilsProvider>
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorDescription()}
                    required
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    multiline
                    disabled={form.onlyRead}
                    rows={8}
                    id="description"
                    name="description"
                    value={form.description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 4000,
                    }}
                    helperText={errorDescription() ? error.description : ""}
                  />
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("VenuesReducer", reducer)(WhatsOnDetail);
