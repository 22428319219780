import StaffList from "./StaffList";
import StaffDetail from "./StaffDetail";

export const StaffListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/staff-list",
      component: StaffList,
    },
    {
      path: "/staff-detail/:productId?",
      component: StaffDetail,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
