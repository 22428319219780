import { ReservationActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";
import _ from "@lodash";

const INITIAL_STATE: ReservationState = {
  reservationList: [],
  confirmationList: [],
  reservationDetail: {},
  loading: false,
  searchText: ""
};

interface Action {
  payload: any;
  type: string;
}

const OffersReducer = (
  state: ReservationState = INITIAL_STATE,
  action: Action
): ReservationState => {
  switch (action.type) {
    case ReservationActionTypes.RESERVATION_LIST_START:
      return updateObject(state, { loading: true, reservationList: [] });

    case ReservationActionTypes.RESERVATION_LIST_SUCCESS: {
      let reservationFiltered = _.filter(action.payload.data, item => {
        return item.status == "0";
      });

      return updateObject(state, {
        reservationList: action.payload.data,
        confirmationList: reservationFiltered,
        loading: false
      });
    }
    case ReservationActionTypes.RESERVATION_LIST_FAIL:
      return updateObject(state, { loading: false })

    case ReservationActionTypes.SET_RESERVATION_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText })

    case ReservationActionTypes.RESERVATION_DETAIL_START:
      return updateObject(state, { loading: true, reservationDetail: {} });

    case ReservationActionTypes.RESERVATION_DETAIL_SUCCESS:
      return updateObject(state, { reservationDetail: action.payload.data, loading: false });

    case ReservationActionTypes.RESERVATION_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case ReservationActionTypes.RESERVATION_CREATE_START:
      return updateObject(state, { loading: true });

    case ReservationActionTypes.RESERVATION_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case ReservationActionTypes.RESERVATION_CREATE_FAIL:
      return updateObject(state, { loading: false })

    case ReservationActionTypes.RESERVATION_EDIT_START:
      return updateObject(state, { loading: true });

    case ReservationActionTypes.RESERVATION_EDIT_SUCCESS:
      return updateObject(state, { loading: false });

    case ReservationActionTypes.RESERVATION_EDIT_FAIL: {
      return updateObject(state, { loading: false });
    }

    default: {
      return state;
    }
  }
};

export default OffersReducer;
