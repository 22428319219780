import { WhatsOnActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: WhatsOnState = {
  whatsOnList: [],
  loading: false,
  searchText: "",
  whatsOnDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const WhatsOnReducer = (
  state: WhatsOnState = INITIAL_STATE,
  action: Action
): WhatsOnState => {
  switch (action.type) {
    case WhatsOnActionTypes.WHATSON_LIST_START: {
      return { ...state, loading: true, whatsOnList: [] };
    }
    case WhatsOnActionTypes.WHATSON_LIST_SUCCESS: {
      return {
        ...state,
        whatsOnList: action.payload,
        loading: false,
      };
    }
    case WhatsOnActionTypes.WHATSON_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case WhatsOnActionTypes.SET_WHATSON_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case WhatsOnActionTypes.WHATSON_DETAIL_START: {
      return { ...state, loading: true, whatsOnDetail: {} };
    }
    case WhatsOnActionTypes.WHATSON_DETAIL_SUCCESS: {
      return {
        ...state,
        whatsOnDetail: action.payload,
        loading: false,
      };
    }
    case WhatsOnActionTypes.WHATSON_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case WhatsOnActionTypes.WHATSON_CREATE_START: {
      return { ...state, loading: true };
    }
    case WhatsOnActionTypes.WHATSON_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case WhatsOnActionTypes.WHATSON_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case WhatsOnActionTypes.WHATSON_EDIT_START: {
      return { ...state, loading: true };
    }
    case WhatsOnActionTypes.WHATSON_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case WhatsOnActionTypes.WHATSON_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case WhatsOnActionTypes.WHATSON_DELETE_START: {
      return { ...state, loading: true };
    }
    case WhatsOnActionTypes.WHATSON_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case WhatsOnActionTypes.WHATSON_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default WhatsOnReducer;
