import PaymentOnline from "./PaymentOnline";
import PaymentMethods from "./PaymentMethods";

export const PaymentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/payment-settings/:tab?",
      component: PaymentOnline,
    },
    {
      path: "/payment-methods",
      component: PaymentMethods,
    },
  ],
};
