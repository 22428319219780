import { axiosInstance as axios } from 'app/api/axios';

import { OrderActionTypes } from '../redux/actionTypes';
import {
  getOrderListUrl,
  getOrderDetailUrl,
  getTableListUrl,
  getUserId,
  getMasterId,
  getOrderRevenueUrl,
  ORDER_TABLE,
  ORDERS,
  getOrderAnalyticsUrl,
} from 'app/api/Endpoint';
import _ from '@lodash';
import History from '@history';
import { errorAlert } from 'app/helpers/alerts';
import { getOrderListUrl2, getExportList} from 'app/api/OrderEndpoints';

export function setOrderSearchText(event) {
  return {
    type: OrderActionTypes.SET_ORDER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getOrderList = ({
  searchText,
  page = '',
  limit = '',
  order,
  status,
  type,
}) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.ORDERS_LIST_START,
    });
    const url = getOrderListUrl2();
    axios
      .get(
        `${url}&searchText=${searchText}&page=${page}&limit=${limit}&order=${JSON.stringify(
          order,
        )}&status=${status}&type=${type}`,
      )
      .then(res => {
        getOrderListSuccess(dispatch, res.data.data);
      })
      .catch(error => {
        getOrderListFail(
          dispatch,
          error.response
            ? error.response.data.message
            : 'There was an error connection',
        );
      });
  };
};

const getOrderListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_SUCCESS,
    payload: data,
  });
};

const getExportOrderFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDERS_EXPORT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getExportOrderSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDERS_EXPORT_SUCCESS,
    payload: data,
  });
};

export const getExportOrders = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.ORDERS_EXPORT_START,
    });
    const url = getExportList();
    axios
      .get(
        `${url}&startDate=${startDate}&endDate=${endDate}`,
      )
      .then(res => {
        getExportOrderSuccess(dispatch, res.data.data);
      })
      .catch(error => {
        getExportOrderFail(
          dispatch,
          error.response
            ? error.response.data.message
            : 'There was an error connection',
        );
      });
  };
}


export const getOrderDetail = (id: string) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.ORDER_DETAIL_START,
    });

    const url = getOrderDetailUrl(id);

    axios
      .get(url)
      .then(res => {
        getOrderDetailSuccess(dispatch, res.data);
      })
      .catch(error => {
        getOrderDetailFail(
          dispatch,
          'There was an error connection',
        );
      });
  };
};

const getOrderDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/orders`,
  });
};

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const getOrderRevenue = () => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.ORDER_REVENUE_START,
    });

    const url = getOrderRevenueUrl();

    axios
      .get(url)
      .then(res => {
        getOrderRevenueSuccess(dispatch, res.data);
      })
      .catch(error => {
        getOrderRevenueFail(
          dispatch,
          error.response
            ? error.response.data.message
            : 'There was an error connection',
        );
      });
  };
};

const getOrderRevenueFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_REVENUE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderRevenueSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_REVENUE_SUCCESS,
    payload: data,
  });
};

export const getOrderAnalytics = () => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.ORDER_ANALYTICS_START,
    });

    const url = getOrderAnalyticsUrl();

    axios
      .get(url)
      .then(res => {
        getOrderAnalyticsSuccess(dispatch, res.data);
      })
      .catch(error => {
        getOrderAnalyticsFail(
          dispatch,
          error.response
            ? error.response.data.message
            : 'There was an error connection',
        );
      });
  };
};

const getOrderAnalyticsFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_ANALYTICS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderAnalyticsSuccess = (dispatch, data) => {
  console.log('data :>> ', data);
  dispatch({
    type: OrderActionTypes.ORDER_ANALYTICS_SUCCESS,
    payload: data,
  });
};
export const changeOrderStatus = (
  id: string,
  status: string,
  estimationTime?: any,
) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.CHANGE_ORDER_STATUS_START,
    });

    axios
      .patch(ORDERS, {
        id,
        status,
        userId: getUserId(),
        estimationTime,
      })
      .then(res => {
        changeOrderStatusSuccess(dispatch, res.data, id);
      })
      .catch(error => {
        changeOrderStatusFail(
          dispatch,
          error.response.data.message ||
            'There was an error connection',
        );
      });
  };
};

const changeOrderStatusFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.CHANGE_ORDER_STATUS_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
};

const changeOrderStatusSuccess = (
  dispatch,
  data,
  orderId,
) => {
  dispatch({
    type: OrderActionTypes.CHANGE_ORDER_STATUS_SUCCESS,
    payload: data,
  });
  dispatch(getOrderDetail(orderId));
  dispatch(
    getOrderList({
      searchText: '',
      page: '',
      limit: '',
      order: {},
      status: '0',
      type: '0',
    }),
  );
};

export const getTableList = (venueSelected: string) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.TABLE_LIST_START,
    });

    const url = getTableListUrl(venueSelected);

    axios
      .get(url)
      .then(res => {
        getTableListSuccess(dispatch, res.data);
      })
      .catch(() => {
        getOrderListFail(
          dispatch,
          'There was an error connection',
        );
      });
  };
};

const getTableListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getTableListSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.TABLE_LIST_SUCCESS,
    payload: data,
  });
};

export const editTable = (
  title: string,
  id: string,
  venueSelected: string,
) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.TABLE_UPDATE_START,
    });

    axios
      .put(ORDER_TABLE, { title, id })
      .then(res => {
        editTableSuccess(dispatch);
        dispatch(getTableList(venueSelected));
      })
      .catch(error => {
        editTableFail(
          dispatch,
          'There was an error connection',
        );
      });
  };
};

const editTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_UPDATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editTableSuccess = dispatch => {
  dispatch({
    type: OrderActionTypes.TABLE_UPDATE_SUCCESS,
  });
};

export const deleteTable = (
  id: string,
  venueSelected: string,
) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.TABLE_DELETE_START,
    });

    axios
      .delete(ORDER_TABLE, { data: { id } })
      .then(res => {
        const data = res.data;
        deleteTableSuccess(dispatch, data);
        dispatch(getTableList(venueSelected));
      })
      .catch(error => {
        deleteTableFail(
          dispatch,
          'There was an error connection',
        );
      });
  };
};

const deleteTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteTableSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.TABLE_DELETE_SUCCESS,
  });
};

export const createTable = (item, venueSelected) => {
  return dispatch => {
    dispatch({
      type: OrderActionTypes.TABLE_CREATE_START,
    });

    const body = {
      title: item.title,
      venueId: venueSelected,
    };

    axios
      .post(ORDER_TABLE, body)
      .then(res => {
        createTableSuccess(dispatch);
        dispatch(getTableList(venueSelected));
      })
      .catch(error => {
        createTableFail(
          dispatch,
          'There was an error connection',
        );
      });
  };
};

const createTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createTableSuccess = dispatch => {
  dispatch({
    type: OrderActionTypes.TABLE_CREATE_SUCCESS,
  });
};
