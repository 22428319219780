import React from "react";
import Geocode from "react-geocode";

import { useLanguageHelper } from "../../../helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import CountryPicker from "app/main/common/CountryPicker";

const RestaurantDetails = ({ form, handleChange, setForm, setZoom }) => {
  const { languageStrings } = useLanguageHelper();

  const setLatAndLong = (e, value) => {
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setForm((prevForm) => ({
          ...prevForm,
          latitude: lat,
          longitude: lng,
        }));
      },
      (error) => {
        console.error(error);
      }
    );
    handleChange(e);
  };

  const handleCountryChange = (e) => {
    const query = [e.target.value];

    if (form.city.length > 0) {
      query.push(form.city);
    }

    if (form.address.length > 0) {
      query.push(form.address);
    }

    if (form.postcode.length > 0) {
      query.push(form.postcode);
    }

    setLatAndLong(e, query.join(" "));
  };

  const handleCityChange = (e) => {
    const query = [];
    setZoom(6);
    if (form.country.length > 0) {
      query.push(form.country);
    }

    query.push(e.target.values);

    if (form.address.length > 0) {
      query.push(form.address);
    }

    if (form.postcode.length > 0) {
      query.push(form.postcode);
    }

    setLatAndLong(e, query.join(" "));
  };

  const handlePostalCodeChange = (e) => {
    const query = [];

    if (form.country.length > 0) {
      query.push(form.country);
    }

    if (form.city.length > 0) {
      query.push(form.city);
    }

    if (form.address.length > 0) {
      query.push(form.address);
    }

    query.push(e.target.value);
    setLatAndLong(e, query.join(" "));
  };

  const handleAddressChange = (e) => {
    const query = [];

    if (form.country.length > 0) {
      query.push(form.country);
    }

    if (form.city.length > 0) {
      query.push(form.city);
    }

    query.push(e.target.value);

    if (form.postcode.length > 0) {
      query.push(form.postcode);
    }

    setZoom(18);
    setLatAndLong(e, query.join(" "));
  };

  const handleTitleChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      permalink: e.target.value.toLowerCase().split(" ").join("-"),
    }));
    handleChange(e);
  };

  return (
    <>
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mx-8"
          required
          disabled={false}
          type={"text"}
          label={languageStrings["REGISTER_STEPS.RESTAURANT_NAME"]}
          id="title"
          name="title"
          value={form.title}
          onValueChange={handleTitleChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => console.log(error)}
          minLength={3}
        />
      </div>

      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mx-8"
          required
          disabled={false}
          type={"text"}
          label={languageStrings["REGISTER_STEPS.TELEPHONE"]}
          id="phone"
          name="phone"
          value={form.phone}
          onValueChange={handleChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => console.log(error)}
          minLength={3}
        />
        <CountryPicker value={form.country} onChange={handleCountryChange} />
      </div>
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 ml-8"
          required
          disabled={false}
          type={"text"}
          label={languageStrings["GENERAL.CITY"]}
          id="city"
          name="city"
          value={form.city}
          onValueChange={handleCityChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => console.log(error)}
          minLength={3}
        />
        <CustomInput
          className="mt-8 mb-16 mx-8"
          required
          disabled={false}
          type={"text"}
          label={languageStrings["REGISTER_STEPS.POSTAL_CODE"]}
          id="postcode"
          name="postcode"
          value={form.postcode}
          onValueChange={handlePostalCodeChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => console.log(error)}
          minLength={3}
          maxLength={10}
        />
      </div>
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mx-8"
          required
          disabled={false}
          type={"text"}
          label={languageStrings["REGISTER_STEPS.ADDRESS"]}
          id="address"
          name="address"
          value={form.address}
          onValueChange={handleAddressChange}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => console.log(error)}
          minLength={3}
        />
      </div>
    </>
  );
};

export default RestaurantDetails;
