import { axiosInstance as axios } from "app/api/axios";

import { DiscountVoucherActionTypes } from "app/store/redux/actionTypes";
import {
  getVoucherDetailUrl,
  getUserId,
  DISCOUNT_VOUCHER_URL,
} from "app/api/Endpoint";
import { DiscountVoucher } from "app/models/DiscountVoucher";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";

export const getDiscountVoucherList = () => {
  return (dispatch) => {
    dispatch({
      type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_LIST_START,
    });

    const params = {
      userId: getUserId(),
    };

    axios
      .get(DISCOUNT_VOUCHER_URL, {
        params,
      })
      .then((res) => {
        getDiscountVoucherListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getDiscountVoucherListFail(dispatch, "There was an error connection");
      });
  };
};

const getDiscountVoucherListFail = (dispatch, errorMessage) => {
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getDiscountVoucherListSuccess = (dispatch, data) => {
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_LIST_SUCCESS,
    payload: data,
  });
};

export const getVoucherDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_DETAIL_START,
    });

    const url = getVoucherDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getVoucherDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getVoucherDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getVoucherDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/voucherList`,
  });
};

const getVoucherDetailSuccess = (dispatch, data) => {
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createDiscountVoucher = (
  discountVoucher: DiscountVoucher,
  closeModal
) => {
  return (dispatch) => {
    dispatch({
      type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_CREATE_START,
    });
    axios
      .post(DISCOUNT_VOUCHER_URL, { ...discountVoucher, userId: getUserId() })
      .then((res) => {
        createDiscountVoucherSuccess(
          dispatch,
          discountVoucher,
          res.data.message
        );
        closeModal();
      })
      .catch((error) => {
        createDiscountVoucherFail(dispatch, "There was an error connection");
      });
  };
};

const createDiscountVoucherFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createDiscountVoucherSuccess = (dispatch, data, message) => {
  successAlert(dispatch, message);
  dispatch(getDiscountVoucherList());
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_CREATE_SUCCESS,
    payload: data,
  });
};

export const editDiscountVoucher = (
  discountVoucner: DiscountVoucher,
  callback
) => {
  return (dispatch) => {
    dispatch({
      type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_EDIT_START,
    });

    axios
      .put(DISCOUNT_VOUCHER_URL, { ...discountVoucner, userId: getUserId() })
      .then((res) => {
        editDiscountVoucherSuccess(dispatch, res.data);
        callback();
      })
      .catch(() => {
        editDiscountVoucherFail(dispatch, "There was an error connection");
      });
  };
};

const editDiscountVoucherFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editDiscountVoucherSuccess = (dispatch, data) => {
  dispatch(getDiscountVoucherList());
  dispatch({
    type: DiscountVoucherActionTypes.DISCOUNT_VOUCHER_EDIT_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, data.message);
};

export const deleteDiscountVoucher = (id: String, action) => {
  return (dispatch) => {
    dispatch({
      type: DiscountVoucherActionTypes.VOUCHER_DELETE_START,
    });

    axios
      .delete(DISCOUNT_VOUCHER_URL, { data: { id } })
      .then((res) => {
        action();
        deleteDiscountVoucherSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteDiscountVoucherFail(dispatch, "There was an error connection");
      });
  };
};

const deleteDiscountVoucherFail = (dispatch, errorMessage) => {
  dispatch({
    type: DiscountVoucherActionTypes.VOUCHER_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteDiscountVoucherSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getDiscountVoucherList());
  dispatch({
    type: DiscountVoucherActionTypes.VOUCHER_DELETE_SUCCESS,
    payload: data,
  });
};
