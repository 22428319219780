import React, { useState } from "react";
import clsx from "clsx";
import _ from "@lodash";
import { FuseAnimate } from "@fuse";
import { Button, Icon, Theme } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { makeStyles } from "@material-ui/styles";
import { changeOrderStatus } from "app/store/orders/OrdersActions";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "app/main/common/CustomModal";
import { AlertInputDialog } from "./OrderConfirmAlert";
import * as Actions from "app/store/actions";
import { updateClient } from "app/store/user/UserActions";
import { useEffect } from "react";

interface Props {
  orderId: string;
  textPrimary: string;
  iconPrimary: string;
  statusPrimary: string;
  textSecondary?: string;
  iconSecondary?: string;
  statusSecondary?: string;
  orderType?: string;
  orderStatus?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const OrderButtons = ({
  orderId,
  textPrimary,
  iconPrimary,
  statusPrimary,
  textSecondary,
  iconSecondary,
  orderType,
  statusSecondary,
  orderStatus,
}: Props) => {
  const { languageStrings } = useLanguageHelper();
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState({
    title: "",
    key: "",
    showModal: false,
    time: 0,
  });
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { estimationTimeSettings } = user;

  const {
    collectionSettings = { isEnabled: false, time: 30 },
    deliverySettings = { isEnabled: false, time: 30 },
  } = estimationTimeSettings || {};

  const fuseProps = {
    animation: "transition.slideRightIn",
    delay: 300,
  };

  return (
    // @ts-ignore
    <FuseAnimate animation="transition.slideRightIn" delay={300}>
      <>
        <div>
          <Button
            color="secondary"
            className="whitespace-no-wrap mr-16"
            variant="contained"
            style={{
              backgroundColor: "#34bfa3",
            }}
            onClick={() => {
              if (
                collectionSettings &&
                collectionSettings.isEnabled === true &&
                orderType === "1" &&
                (orderStatus === "0" || orderStatus === "1")
              ) {
                setDeliveryTime({
                  title: "GENERAL.COLLECTION_ORRDER_CONFIRMATION_TITLE",
                  key: "collectionSettings",
                  showModal: true,
                  time: +collectionSettings.time || 30,
                });
                return;
              } else if (
                deliverySettings &&
                deliverySettings.isEnabled === true &&
                orderType === "2" &&
                (orderStatus === "0" || orderStatus === "1")
              ) {
                setDeliveryTime({
                  title: "GENERAL.DELIVERY_ORRDER_CONFIRMATION_TITLE",
                  key: "deliverySettings",
                  showModal: true,
                  time: +deliverySettings.time || 30,
                });
                return;
              } else {
                dispatch(changeOrderStatus(orderId, statusPrimary));
              }
            }}
          >
            <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
              {iconPrimary}
            </Icon>
            {textPrimary}
          </Button>
          {textSecondary && iconSecondary && statusSecondary && (
            <Button
              color="secondary"
              className="whitespace-no-wrap"
              variant="contained"
              style={{
                backgroundColor: "#f4516c",
              }}
              onClick={() =>
                dispatch(changeOrderStatus(orderId, statusSecondary))
              }
            >
              <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                {iconSecondary}
              </Icon>
              {textSecondary}
            </Button>
          )}
        </div>
        <AlertInputDialog
          onCloseModal={() => {
            setDeliveryTime({
              title: "",
              key: "",
              showModal: false,
              time: 30,
            });
          }}
          showModal={deliveryTime.showModal}
          title={deliveryTime.title}
          value={deliveryTime.time}
          handleChange={(value) => {
            setDeliveryTime({ ...deliveryTime, time: +value });
          }}
          onSuccess={() => {
            setDeliveryTime({
              title: "",
              key: "",
              showModal: false,
              time: 30,
            });
            dispatch(
              changeOrderStatus(orderId, statusPrimary, deliveryTime.time)
            );
          }}
        />
      </>
    </FuseAnimate>
  );
};

export default OrderButtons;
