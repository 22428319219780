import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/draws/DrawReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import {
  getDrawDetail,
  editDraw,
  createDraw,
} from "app/store/draws/DrawActions";
import { getDrawList } from "app/store/draws/DrawActions";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { formatImageUrl } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

function DrawDetail(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.draw.loading);
  let draw = useSelector(({ banzzu }) => banzzu.draw.drawDetail);
  let [drawExpired, setDrawExpired] = useState(false);
  const { languageStrings } = useLanguageHelper();

  const { productId } = props.match.params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getDrawDetail(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(getDrawList());
  }, [dispatch]);

  useEffect(() => {
    let drawDate = new Date(draw.date);
    let today = new Date();
    drawDate.setHours(24, 0, 0);
    today.setHours(0, 0, 0);

    if (drawDate.getTime() < today.getTime()) {
      setDrawExpired(true);
    } else {
      setDrawExpired(false);
    }
  }, [draw]);

  if (productId === "new") {
    draw = {
      id: "0",
      title: "",
      description: "",
      eventType: "1",
      date: new Date(),
      numberOfPeople: 1,
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if ((draw && !form) || (draw && form && draw._id !== form._id)) {
      setForm(draw);
      setInitialForm(draw);
    }
  }, [form, draw, setForm, setInitialForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !drawExpired;
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.description !== "";
  }

  function hasNoErrors() {
    return !errorName() && !errorDescription() && !errorImage();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorImage() {
    return !form.image;
  }

  function errorDescription() {
    return dirty.description && form.description.length < 10;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  function handleDateChange(date) {
    setForm((form) => ({
      ...form,
      date,
    }));
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return {
          ...prevState,
          description: languageStrings["ERRORS.MINIMUM_10_CHAR"],
        };
      });
    }
  }, 500);

  const headerRows = {
    data: [
      {
        id: ["firstName", "lastName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: false,
  };

  const winnerHeaderRows = {
    data: [
      {
        id: ["firstName", "lastName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: false,
  };

  function goBack() {
    History.push({
      pathname: `/drawList`,
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["DRAW_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["DRAW_DETAIL_PAGE.NEW_DRAW"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["DRAW_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form.id !== "0"
                      ? dispatch(editDraw(form))
                      : dispatch(createDraw(form));
                  }}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.DETAIL"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.USER_LIST"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.WINNERS_LIST"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <input
                    accept="image/jpg, image/jpeg, image/png, image/gif"
                    className="hidden"
                    id="button-file"
                    disabled={drawExpired}
                    type="file"
                    onChange={handleUploadChange}
                  />
                  <div className="flex justify-center sm:justify-start flex-wrap">
                    <label
                      htmlFor="button-file"
                      className={
                        "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5" +
                        clsx(drawExpired ? classes.enable : classes.disabled)
                      }
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          alt="draw"
                          src={
                            form.image
                              ? formatImageUrl(form.image)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                      </div>
                    }
                    {errorImage() && (
                      <div class="text-red">
                        {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
                      </div>
                    )}
                  </div>
                  <div className="flex">
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      error={errorName()}
                      required
                      disabled={drawExpired}
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title}
                      onChange={handleTitleChange}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={errorName() ? error.title : ""}
                    />
                  </div>
                  <div className="flex">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="mt-8 mb-16 mr-8"
                        margin="normal"
                        fullWidth
                        id="date"
                        disabled={drawExpired}
                        label={languageStrings["GENERAL.DATE"]}
                        value={form.date}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-voucher-native-simple">
                        {languageStrings["GENERAL.NO_OF_PEOPLE"]}
                      </InputLabel>
                      <Select
                        className="flex mt-8 mb-16 min-w-128 required"
                        native
                        required
                        disabled={drawExpired}
                        value={form.numberOfPeople}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="numberOfPeople"
                            labelWidth={100}
                            id="outlined-voucher-native-simple"
                          />
                        }
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </Select>
                    </FormControl>
                  </div>
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorDescription()}
                    required
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    multiline
                    rows={8}
                    disabled={drawExpired}
                    id="description"
                    name="description"
                    value={form.description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 4000,
                    }}
                    helperText={errorDescription() ? error.description : ""}
                  />
                </div>
              )}
              {tabValue === 1 && (
                <ListTable
                  headerRows={headerRows}
                  data={draw.participants}
                  searchText={""}
                />
              )}
              {tabValue === 2 && (
                <ListTable
                  headerRows={winnerHeaderRows}
                  data={draw.winners}
                  searchText={""}
                />
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("DrawReducer", reducer)(DrawDetail);
