import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Confetti from "react-confetti";

import { Modal, Typography, Button, Card, Backdrop } from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import RegisterSteppers from "./VenueSteppers";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import RegisterMap from "./VenueMap";
import UpgradeModal from "app/main/common/UpgradeModal";
import { getVenueList } from "app/store/venues/VenuesActions";
import history from "@history";

const RegisterStepsPage = (props) => {
  const { form, handleChange, setForm } = useForm(null);
  const { languageStrings } = useLanguageHelper();
  const [activeStep, setActiveStep] = React.useState(0);
  const [zoom, setZoom] = React.useState(2);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const success = useSelector((state) => state.banzzu.venue.success);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);

  const restaurant = {
    _id: "",
    title: "",
    phone: "",
    city: "",
    country: "",
    postcode: "",
    address: "",
    latitude: 17.308688,
    longitude: 7.03125,
    hasDelivery: false,
    hasCollection: false,
  };

  useEffect(() => {
    dispatch(getVenueList());
  }, []);

  useEffect(() => {
    if (
      (restaurant && !form) ||
      (restaurant && form && restaurant._id !== form._id)
    ) {
      setForm(restaurant);
    }
  }, [form, restaurant, setForm]);

  useEffect(() => {
    if (
      (user && user.accessLevel == "1") ||
      (user.settings && user.settings.venuesPaid === venueList.length)
    ) {
      setOpen(true);
    }
  }, [user, venueList]);

  return (
    <>
      <UpgradeModal
        open={open}
        setOpen={() => {
          history.goBack();
          setOpen(false);
        }}
      />
      {form ? (
        <>
          {success ? (
            <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={true}
              onClose={() => window.location.reload()}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropComponent={Backdrop}
            >
              <div className="widget flex w-full p-16 items-center justify-center">
                <Confetti
                  width={window.innerWidth}
                  height={window.innerHeight}
                />
                <Card className="flex flex-col rounded-8 p-16 shadow-none z-10 justify-around items-center">
                  <Typography className="mt-12" variant="h5" gutterBottom>
                    {languageStrings["REGISTER_STEPS.CONGRATULATION"]}
                  </Typography>
                  <img
                    width="128"
                    className="mt-12 mb-12"
                    src="assets/images/logos/alcohol.png"
                    alt="logo"
                  />
                  <Typography
                    className="mx-32 mt-32 mb-32"
                    variant="subtitle1"
                    gutterBottom
                  >
                    {
                      languageStrings[
                        "REGISTER_STEPS.CONGRATULATION_DESCRIPTION"
                      ]
                    }
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                  >
                    {languageStrings["REGISTER_STEPS.PROCEED"]}
                  </Button>
                </Card>
              </div>
            </Modal>
          ) : (
            <>
              <RegisterMap
                latitude={form.latitude}
                longitude={form.longitude}
                zoom={zoom}
                isMarkerShown={true}
                firstStep={activeStep === 1}
                setForm={setForm}
              />

              <RegisterSteppers
                setForm={setForm}
                handleChange={handleChange}
                form={form}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setZoom={setZoom}
              />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default RegisterStepsPage;
